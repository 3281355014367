import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/admin/user.service';
@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {

  public token:any;
  constructor(public userService: UserService) { }

  ngOnInit(): void {
  
   
    this.token = localStorage.getItem('access_token');
  }
 
  logout(){
    this.userService.logout();
  }


}
