import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { PageColumnsService } from '../../../services/admin/page-columns.service';
import { ColumnsService } from '../../../services/admin/columns.service';
import { StocksService } from '../../../services/admin/stocks.service';
import { ToastrService } from 'ngx-toastr';
import { ReplaySubject, Subject, merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-edit-stock-value',
  templateUrl: './edit-stock-value.component.html',
  styleUrls: ['./edit-stock-value.component.css']
})
export class EditStockValueComponent implements OnInit {

  submitted = false;
  error = '';
  page_id: string;
  stockDetail: object = {};
  stockForm: FormGroup;
  columnList = [];
  stocksList: [];
  pageDetail: [];
  public filteredStocksMulti: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _onDestroy = new Subject<void>();
  pageColumnsList: object[] = [];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  slug = "export-stocks";
  market = "";
  display_slug = "";
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private pageColumnsService: PageColumnsService,
    private columnsService: ColumnsService,
    private stocksService: StocksService,
    private toastr: ToastrService,
  ) { }

  marketList = [
    { name: 'NSD', value: 'NSD' },
    { name: 'NYE', value: 'NYE' },
    { name: 'TSX', value: 'TSX' },
  ];

  get f() { return this.stockForm.controls; }

  ngOnInit(): void {
    this.stockForm = this.fb.group({
      select_stock: ['', [Validators.required]],
      column_value: ['', [Validators.required]],
      select_column: ['', [Validators.required]],
      exchange: ['', [Validators.required]],
      stockMultiFilterCtrl: '',
    });
    this.getStocks();
    this.getColumns();
    this.getPageDetails();
  }

  getStocks(symbolstring = "symbolstring", order = "asc", page = 0, limit = 200, filter = "") {
    this.stocksService.getAllStocks(symbolstring, order, page, limit, filter).subscribe((data: any) => {
      if (data.success == true) {
        this.stocksList = data.data
      } else {
        this.toastr.error(data.message, 'Column');
      }
    },
      (err: HttpErrorResponse) => {
        this.toastr.error('Error in fetching Stocks');
      });
  }

  getColumns() {
    this.columnsService.getColumns('column_order', 'asc', 0, 150).subscribe((data: any) => {
      if (data.success == true) {
        this.columnList = data.data

      } else {
        this.toastr.error(data.message, 'Error in fetching column data');
      }
    },
      (err: HttpErrorResponse) => {
        this.toastr.error('Error in fetching columns');
      });
  }

  getPageDetails(): void {
    //this.action_type = 'Update';
    this.pageColumnsService.getPageColumnsBySlug(this.slug)
      .subscribe((data: any) => {
        if (data.success) {
          this.pageColumnsList = data.data.column_ids;
        } else {
          this.toastr.error(data.message, 'Page Not Found');
        }
      },
        (err: HttpErrorResponse) => {
          alert("Server Get Job Page Detail Error");
        });
  }

  private filterStocksMulti(event: Event) {
    if (!this.stocksList) {
      return;
    }
    // get the search keyword
    let search = (event.target as HTMLInputElement).value;
    this.getStocks('symbolstring', 'asc', 0, 200, search);
  }

  submitForm() {
    if (this.stockForm.invalid) {
      return;
    }
    console.log(this.stockForm.value);

    this.pageColumnsService.updateStockDetails(this.slug, this.stockForm.value)
      .subscribe((data: any) => {
        if (data.success) {
          this.toastr.success(data.message);
        } else {
          this.toastr.error(data.message);
        }
      },
        (err: HttpErrorResponse) => {
          alert("Server Update Job Sub Category Error");
        });
  }
}
