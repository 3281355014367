import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ColumnsService } from '../../../services/admin/columns.service';
import { StocksService } from '../../../services/admin/stocks.service';
import { ToastrService } from 'ngx-toastr';
import { ReplaySubject, Subject, merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-stocks-history',
  templateUrl: './stocks-history.component.html',
  styleUrls: ['./stocks-history.component.css']
})
export class StocksHistoryComponent implements OnInit, AfterViewInit {

  show_ids = [];
  stocksList: object[] = [];
  displayedColumns: string[] = [];
  header = {};
  pagesize = 50;
  resultsLength = 0;
  filter = "";
  page = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(public fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private stocksService: StocksService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getTopRankedList()
  }

  ngAfterViewInit() {


    // this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    // merge(this.sort.sortChange, this.paginator.page)
    // .pipe(
    //   startWith({}),
    //   switchMap(() => {
    //     return  this.stocksService!.getStocksHistory(this.sort.active, this.sort.direction, this.paginator.pageIndex,this.paginator.pageSize);
    //   }),
    //   map(data => {


    //     //this.isLoadingResults = false;
    //     //this.isRateLimitReached = false;
    //     //this.resultsLength = data.count;

    //     return data.data;
    //   }),
    //   catchError(() => {
    //     //this.isLoadingResults = false;
    //     // Catch if the GitHub API has reached its rate limit. Return empty data.
    //     //this.isRateLimitReached = true;
    //     return observableOf([]);
    //   })
    // ).subscribe((data: any) => {

    //     this.displayedColumns = [];

    //     this.stocksList = data.stocks;
    //       this.resultsLength = 200;
    //       this.displayedColumns = data.years;
    //       console.log(this.displayedColumns);
    //   });
  }


  getTopRankedList() {
    this.stocksService.getStocksHistory(this.filter, "", "", this.page, this.pagesize)
      .subscribe((data: any) => {
        if (data.success) {
          this.toastr.success(data.message);
          this.displayedColumns = [];
          this.stocksList = data.data.stocks;
          this.resultsLength = data.data.count;

          // this.displayedColumns = data.data.years;
          // var ids_data = data.data.ids;
          // ids_data.sort((a,b) => console.log(a));
          // ids_data.sort(function (a, b) {
          //   return a.column_order - b.column_order;
          // });
          for (let v in data.data.years) {
            this.displayedColumns.push("" + data.data.years[v]);
          }
        } else {
          this.toastr.error(data.message);
        }
      },
        (err: HttpErrorResponse) => {
          alert("Server not conncted");
        });
  }


  getDividendColVal(column_id, stock_data, column_name) {
    let arr = stock_data.data;
    let index = arr.findIndex(x => x.year == column_id);
    // var val ="";
    // if(index != -1){
    //   if(isNaN(parseFloat(stock_data.values_data[index]['column_value']))){
    //     val = stock_data.values_data[index]['column_value'];
    //   }else{
    //     val = parseFloat(stock_data.values_data[index]['column_value']).toFixed(2);
    //   }
    // }else{
    //   val = "N/A"
    // }
    if (index != -1) {
      if (stock_data.data[index][column_name] == 0) {
        return "N/A";
      } else {
        if (isNaN(parseFloat(stock_data.data[index][column_name]))) {
          return "N/A";
        } else {
          if (column_name == "avg_dividend_yield" || column_name == "low_dividend_yield" || column_name == "high_dividend_yield" || column_name == "year_over_growth" || column_name == "year_over_eps_growth") {
            var val = parseFloat(stock_data.data[index][column_name]).toFixed(4);
            return val + "%";
          } else {
            return parseFloat(stock_data.data[index][column_name]).toFixed(4);
          }
        }
      }
    }
  }

  getStockSymbol(column_id, stock_data) {
    return stock_data.symbolstring;
  }


  pageChanged(event) {
    this.page = event;
    this.getTopRankedList();
  }

  applyFilter(event: Event) {
    // this.filter = (event.target as HTMLInputElement).value;
    this.filter = (<HTMLInputElement>document.getElementById("myValue")).value;
    this.getTopRankedList();
  }

}


