import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError,of, BehaviorSubject } from 'rxjs';
import { Pages } from '../../models/pages.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class FaqService {

  constructor(private httpClient: HttpClient,public router: Router) { }

  // addPage(page: Pages): Observable<any> {
  //   return this.httpClient.post(environment.apiBaseUrl+'/add-page',page);
  // }

  add(question): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl+'/admin/add-faq',question)
      .pipe(
      tap(),
      catchError(this.handleError('postJob', []))
      );
  }
  getCount(data): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl+'/admin/faq/count', data)
      .pipe(
      tap(),
      catchError(this.handleError('getJobsList', []))
      );
  }

  getFaqs(data): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/faq', data)
      .pipe(
      tap(),
      catchError(this.handleError('getFilterComponentList', []))
      );
  }

  update(id, page: Pages): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/update-faq/' + id, page)
      .pipe(
      tap(),
      catchError(this.handleError('updateJobCategory', []))
      );
  }

  getDetails(id): Observable<any> {
    return this.httpClient.get(environment.apiBaseUrl + '/admin/get-faq-details/' + id)
      .pipe(
      tap(),
      catchError(this.handleError('updateJobCategory', []))
      );
  }

  deleteFaq(id): Observable<any> {
    return this.httpClient.get(environment.apiBaseUrl + '/admin/delete-faq/' + id)
      .pipe(
      tap(),
      catchError(this.handleError('deleteJobCategory', []))
      );
  }

  getAllFaqs(): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/faqs','')
      .pipe(
      tap(),
      catchError(this.handleError('getFilterComponentList', []))
      );
  }




    /**
* Handle Http operation that failed.
* Let the app continue.
* @param operation - name of the operation that failed
* @param result - optional value to return as the observable result
*/
private handleError<T>(operation = 'operation', result?: T) {
  return (error: any): Observable<T> => {

    // TODO: send the error to remote logging infrastructure
    console.error(error); // log to console instead

    // TODO: better job of transforming error for user consumption
    //console.log(`${operation} failed: ${error.message}`);

    // Let the app keep running by returning an empty result.
    return of(result as T);
  };
}

/** Log a HeroService message with the MessageService */



}
