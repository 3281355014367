import { Component, ViewChild, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PagesService } from '../../../services/admin/pages.service';
import { FaqService } from '../../../services/admin/faq.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login-page-message',
  templateUrl: './login-page-message.component.html',
  styleUrls: ['./login-page-message.component.css']
})
export class LoginPageMessageComponent implements OnInit {

  pageForm: FormGroup;
  status: boolean;

  constructor(
    public fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public pagesService: PagesService,
    public faqService: FaqService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.pageForm = this.fb.group({
      content: ['', [Validators.required]],
    });
    this.getLoginPageMess();
  }

  get f() { return this.pageForm.controls; }

  submitForm() {
    this.pagesService.saveUserLoginMess(this.pageForm.value)
      .subscribe(data => {
        if (data.success) {
          this.toastr.success(data.message);
        } else {
          console.log(data);
        }
      },
        error => {
          console.log(error);
        });
  }

  getLoginPageMess() {
    var tempdata = "loginMess"
    this.pagesService.getLoginPageMess(tempdata).subscribe((data: any) => {
      data = data.data;
      this.pageForm.controls['content'].setValue(data.content);
      this.status = data.status;
    }, error => {
      console.log(error);
    })
  }

  updateLoginMessStatus(data: any) {
    if (data === "On") {
      let value = 0;
      this.pagesService.updateLoginMessStatus(value).subscribe((data: any) => {
        if (data.success) {
          this.toastr.success(data.message);
          this.getLoginPageMess();
        }
      }, error => {
        console.log(error);
      })
    } else if (data === "Off") {
      let value = 1;
      this.pagesService.updateLoginMessStatus(value).subscribe((data: any) => {
        if (data.success) {
          this.toastr.success(data.message);
          this.getLoginPageMess();
        }
      }, error => {
        console.log(error);
      })
    }
  }

}
