import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserService } from '../../../services/admin/user.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  error = '';

  constructor(
    public formBuilder: FormBuilder,
    public userService: UserService,
    public router: Router
  ) {

  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', Validators.required]
    })

  }

  get f() { return this.loginForm.controls; }

  loginUser() {

    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.userService.login(this.loginForm.value)
      .pipe(first())
      .subscribe((data: any) => {
        if (data.message == "Auth Successful") {
          this.router.navigate(['admks/admin-login', data.id]);
        }
      },
        error => {
          /* Swal.fire("", "Error in login...Please check you credentials or your subscription date", "error");*/
          // this.commonService.errorMessage(error.error.message)
          this.error = error.error.message;
          console.log(this.error);
          // this.loading = false;
        });
  }




}
