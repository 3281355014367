import { Component, ViewChild, AfterViewInit, OnInit, ElementRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { StocksService } from '../../../services/user/stocks.service';
import { UserService } from '../../../services/user/user.service';
import { FilterService } from '../../../services/user/filter.service';
import { ColumnsService } from '../../../services/admin/columns.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PagesService } from '../../../services/admin/pages.service';
import { ChartType } from 'chart.js';
import { MultiDataSet, Label } from 'ng2-charts';
import { findEndOfBlock } from '@angular/localize/src/utils';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { ModalService } from '../../../services/user/modal.service';
import { UserSideMenuComponent } from '../user-side-menu/user-side-menu.component';

@Component({
  selector: 'app-my-stocks',
  templateUrl: './my-stocks.component.html',
  styleUrls: ['./my-stocks.component.css']
})
export class MyStocksComponent implements OnInit, AfterViewInit {

  stocksList = [];
  stocksListData: any = [];
  stockForm: FormGroup;
  selectedstocks = [];
  selected_filter;
  displayedColumns: string[] = [];
  header = {};
  pagesize = environment.pagesize;
  resultsLength = 0;
  falsedata = true;
  company_name = "";
  stock_symbol = "";
  previousIndex: number;
  initialColumns = [];
  columnList = [];
  slug = "my-stocks";
  user_name = "";
  videoSource: any;
  closeResult: string;
  content = "";
  selected_stocks_ids = [];
  selected_watchlist_ids = [];
  selected_alert_ids = [];
  show_chart = false;
  status: boolean = false;
  pie_chart: any;
  fixed_displayedColummns: string[] = [];
  quantity: any;
  accounts: any;
  user_type = "";
  free_user = false;
  consumer_discretionary = "0";
  consumer_staples = "0";
  energy = "0";
  finance = "0";
  healthcare = "0";
  industrials = "0";
  materials = "0";
  media = "0";
  real_estate = "0";
  technology = "0";
  utilities = "0";
  na = "0";
  consumer_discretionary_pro = "0";
  consumer_staples_pro = "0";
  energy_pro = "0";
  finance_pro = "0";
  healthcare_pro = "0";
  industrials_pro = "0";
  materials_pro = "0";
  media_pro = "0";
  real_estate_pro = "0";
  technology_pro = "0";
  utilities_pro = "0";
  na_pro = "0";
  total = "0";
  total_pro = "0";
  estimated_total = "0";
  dividend_yield_total = "0";
  tooltip = "tooltip";
  allToolTips: any;
  disaplayToolTip: string;
  donughtColor: any[] = [];
  custome_filter: string = "Default Filter";
  model_stock: any;
  model_quantity: any;
  model_account: any;
  modalReference: any;
  accounts_data: any;
  hidden: boolean = false;
  basicSearch: boolean = true;
  advanceSearch: boolean = false;
  exchangeRate: any;
  usdRate = "";
  cadRate = "";
  stockTypes = "USA";
  usaTotal = "0.00";
  cadTotal = "0.00";
  usa_estimated_total = "0.00";
  cad_estimated_total = "0.00";
  usaAddTotal = 0;
  cadAddTotal = 0;
  usaAddEstimat = 0;
  cadAddEstimat = 0;
  usa_dividend_yield = "0.00";
  cad_dividend_yield = "0.00";
  temp_data_total = 0;
  summary_table: boolean = false;
  temp_total = 0;
  temp_estimate = 0;
  load_gif: boolean = true;
  videoID: string = "";
  videoHeading: string = "";

  searchForm = new FormGroup({
    column: new FormControl('', [Validators.required]),
    column_value: new FormControl('', [Validators.required]),
    sortFilterCtrl: new FormControl(''),
    column_op: new FormControl('', [Validators.required]),
  });
  stockForm1 = new FormGroup({
    symbol: new FormControl('', [Validators.required]),
    quantity: new FormControl('', [Validators.required]),
    stock_id: new FormControl('', [Validators.required]),
    account: new FormControl(''),
  });
  operatorList = [

    { name: 'Equal to and less than', value: '<=' },
    { name: 'Equal to and greater than', value: '>=' },
    { name: 'Less than', value: '<' },
    { name: 'Greater than', value: '>' },
    { name: 'Equal To', value: '==' }
  ];


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  @ViewChild(UserSideMenuComponent) userSideMenuCom: UserSideMenuComponent;

  public stockMultiFilterCtrl: FormControl = new FormControl();

  public sectorLabels = [];
  public doughnutChartLabels: Label[] = ['test', 'test1'];
  public doughnutChartData = [1, 2];
  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartOptions = {
    tooltips: {
      callbacks: {
        label: function (tooltipItems, data) {
          return data.labels[tooltipItems.index] + ' ' + data.datasets[0].data[tooltipItems.index] + '' + ' %';
        }
      }
    },
  };

  public donutColors: any[] = [
    {
      backgroundColor: []
    }
  ];

  constructor(private stocksService: StocksService,
    private userService: UserService,
    private filterService: FilterService,
    private columnsService: ColumnsService,
    private toastr: ToastrService,
    public fb: FormBuilder,
    private modalService: NgbModal,
    public pagesService: PagesService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private modelService: ModalService

  ) { }

  ngOnInit(): void {
    this.stockForm = this.fb.group({
      stocks: ['', [Validators.required]],
      quantity: ['', [Validators.required]],
      // stockMultiFilterCtrl: '',
      sortFilterCtrl: '',
      account: '',
    });
    var sideMenuStatus = this.filterService.getSideMenuStaus();
    if (sideMenuStatus !== null) {
      this.status = !sideMenuStatus;
    }
    this.getExchangeRate();
    this.company_name = environment.compnay_name_column_id;
    this.stock_symbol = environment.symbol_column_id;
    this.selected_filter = this.filterService.getItem(this.slug);
    this.user_name = this.userService.user_name;
    this.user_type = this.userService.user_type;
    if (this.userService.user_type == "Free_platform") {
      this.router.navigate(['/user/dashboard']);
    } else if (this.userService.user_type == "course_platform" || this.userService.user_type == "Free_platformCourse_platform") {
      this.router.navigate(['/user/pages/course']);
    }
    if (this.userService.user_type == "course_platform" || this.userService.user_type == "Free_platformCourse_platform") {
      this.user_type = "course_platform";
    } else if (this.userService.user_type == "Free_platform") {
      this.user_type = "Free_platform";
      this.free_user = true;
    } else if (this.userService.user_type == "CAD_only" || this.userService.user_type == "CAD_onlyCourse_platform") {
      this.user_type = "CAD_only";
      this.stockTypes = "CAD";
      this.advanceSearch = true;
      this.basicSearch = false;
    } else if (this.userService.user_type == "US_only" || this.userService.user_type == "US_onlyCourse_platform") {
      this.user_type = "US_only";
      this.stockTypes = "USA";
      this.basicSearch = true;
      this.advanceSearch = false;
    } else if (this.userService.user_type == "US_and_CAD" || this.userService.user_type == "US_and_CADCourse_platform") {
      this.user_type = "US_and_CAD";
      this.summary_table = true;
    }

    this.custome_filter = this.filterService.getFilterName(this.slug);
    if (this.custome_filter == null) {
      this.custome_filter = "Default filter";
    }
    this.getStocks();
    this.getColumns();
    this.getPageContent();
    this.getUserSavedWatchListIds();
    this.getUserSavedStocksIds();
    this.getUserSavedAlertIds();
    this.getToolTips();
    this.clearStockForm();
    this.getHidden();
    this.getVideoID();
    if (this.user_type == "US_and_CAD") {
      this.getUserSelectedStocks();
    }

    // var checkSpinner = this.filterService.getCompareStockCountry(this.slug + "stocks");
    // if (checkSpinner == null) {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 1500);
    // }
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.stocksService!.getAllUserSavedStocks(this.searchForm.controls['column'].value,
            this.searchForm.controls['column_value'].value, this.searchForm.controls['column_op'].value,
            this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize,
            this.userService.user_id, this.selected_filter, this.stockTypes);
        }),
        map(data => {
          return data.data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe((data: any) => {
        // data = data.data;
        this.displayedColumns = ['1', this.company_name, this.stock_symbol];
        this.stocksListData = data.stocks;
        this.resultsLength = data.count;
        this.accounts_data = data.accounts_data;
        var ids_data = data.ids;
        if (this.resultsLength == 0) {
          this.falsedata = true;
        }
        var temp = {};
        var obj = null;
        for (var i = 0; i < data.graph_data.length; i++) {
          obj = data.graph_data[i];
          if (!temp[obj.sector]) {
            temp[obj.sector] = obj;
          } else {
            temp[obj.sector].price += obj.price;
            temp[obj.sector].market_value += obj.market_value;
            temp[obj.sector].portfolio += obj.portfolio;
          }
        }
        var result = [];
        for (var prop in temp) {
          result.push(temp[prop]);
        }
        this.show_chart = true;
        this.doughnutChartData = [];
        this.doughnutChartLabels = [];
        this.fixed_displayedColummns = [];
        this.donutColors[0].backgroundColor.splice(0, this.donutColors[0].backgroundColor.length);
        for (let i = 0; i < result.length; i++) {
          this.doughnutChartLabels.push(result[i].sector ? result[i].sector : "N/A");
          this.pie_chart = Math.round(result[i].portfolio * 100) / 100;
          this.doughnutChartData.push(this.pie_chart);
        }
        this.sortLabels();
        this.setdonutColors(this.doughnutChartLabels);
        ids_data.sort(function (a, b) {
          return a.column_order - b.column_order;
        });
        for (let values in data.columns_data) {
          this.fixed_displayedColummns.push(data.columns_data[values]);
        }
        if (this.resultsLength > 0) {
          this.falsedata = false;
          for (let v in ids_data) {
            this.displayedColumns.push(ids_data[v]['column_id']);
            this.initialColumns.push(ids_data[v]['column_id']);
            this.header[ids_data[v]['column_id']] = ids_data[v]['column_name'];
          }
        }
        this.setTabelData(result, this.fixed_displayedColummns);
        if (this.selected_filter == null) {
          var filter_obj = { 'columns': data.columns };
          this.filterService.setItem(this.slug, filter_obj);
          this.selected_filter = this.filterService.getItem(this.slug);
        }
        if (this.user_type == "US_and_CAD") {
          this.getUserSelectedStocks();
        }
        this.getUserSavedWatchListIds();
        this.getUserSavedStocksIds();
        // if (this.filterService.getCompareStockCountry(this.slug + "stocks") != null) {
        //   localStorage.removeItem(this.slug + "stocks");
        //   this.filterService.setCompareStockCountry(this.slug + "stocks", true);
        // } else {
        //   this.filterService.setCompareStockCountry(this.slug + "stocks", true);
        // }
      });
  }

  sortLabels() {
    const labelsWithIndexes = this.doughnutChartLabels.map((label: string, index) => ({ label, index }));
    labelsWithIndexes.sort((a, b) => a.label.localeCompare(b.label));

    const naIndex = labelsWithIndexes.findIndex(obj => obj.label === 'N/A');
    if (naIndex >= 0) {
      labelsWithIndexes.push(labelsWithIndexes.splice(naIndex, 1)[0]);
    }

    const newLabels = labelsWithIndexes.map(obj => obj.label);
    const newData = labelsWithIndexes.map(obj => this.doughnutChartData[obj.index]);

    this.doughnutChartLabels = newLabels;
    this.doughnutChartData = newData;

    this.sectorLabels = [];
    this.sectorLabels = [
      { label: "Consumer Discretionary", color: "#88D18A" },
      { label: "Consumer Staples", color: "#CCDDB7" },
      { label: "Energy", color: "#6A5B6E" },
      { label: "Finance", color: "#F0B7B3" },
      { label: "Healthcare", color: "#A833B9" },
      { label: "Industrials", color: "#0EB1D2" },
      { label: "Materials", color: "#34E4EA" },
      { label: "Media", color: "#8AB9B5" },
      { label: "Real Estate", color: "#C8C2AE" },
      { label: "Technology", color: "#F4B860" },
      { label: "Utilities", color: "#C83E4D" },
    ];
  }



  // ngAfterViewInit() {
  //   this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
  //   merge(this.sort.sortChange, this.paginator.page)
  //     .pipe(
  //       startWith({}),
  //       switchMap(() => {
  //         return this.stocksService!.getAllUserSavedStocks(this.searchForm.controls['column'].value,
  //           this.searchForm.controls['column_value'].value, this.searchForm.controls['column_op'].value,
  //           this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize,
  //           this.userService.user_id, this.selected_filter, this.stockTypes);
  //       }),
  //       map(data => {
  //         return data.data;
  //       }),
  //       catchError(() => {
  //         return observableOf([]);
  //       })
  //     ).subscribe((data: any) => {
  //       this.displayedColumns = ['1', this.stock_symbol, this.company_name];
  //       this.stocksListData = data.stocks;
  //       this.resultsLength = data.count;
  //       this.accounts_data = data.accounts_data;
  //       var ids_data = data.ids;
  //       if (this.resultsLength == 0) {
  //         this.falsedata = true;
  //       }
  //       var temp = {};
  //       var obj = null;
  //       for (var i = 0; i < data.graph_data.length; i++) {
  //         obj = data.graph_data[i];
  //         if (!temp[obj.sector]) {
  //           temp[obj.sector] = obj;
  //         } else {
  //           temp[obj.sector].price += obj.price;
  //           temp[obj.sector].market_value += obj.market_value;
  //           temp[obj.sector].portfolio += obj.portfolio;
  //         }
  //       }
  //       var result = [];
  //       for (var prop in temp) {
  //         result.push(temp[prop]);
  //       }
  //       this.show_chart = true;
  //       this.doughnutChartData = [];
  //       this.doughnutChartLabels = [];
  //       this.fixed_displayedColummns = [];
  //       this.donutColors[0].backgroundColor.splice(0, this.donutColors[0].backgroundColor.length);
  //       for (let i = 0; i < result.length; i++) {
  //         this.doughnutChartLabels.push(result[i].sector);
  //         this.pie_chart = Math.round(result[i].portfolio * 100) / 100;
  //         this.doughnutChartData.push(this.pie_chart);
  //       }
  //       this.setdonutColors(this.doughnutChartLabels);
  //       ids_data.sort(function (a, b) {
  //         return a.column_order - b.column_order;
  //       });
  //       for (let values in data.columns_data) {
  //         this.fixed_displayedColummns.push(data.columns_data[values]);
  //       }
  //       if (this.resultsLength > 0) {
  //         this.falsedata = false;
  //         for (let v in ids_data) {
  //           this.displayedColumns.push(ids_data[v]['column_id']);
  //           this.initialColumns.push(ids_data[v]['column_id']);
  //           this.header[ids_data[v]['column_id']] = ids_data[v]['column_name'];
  //         }
  //       }
  //       this.setTabelData(result, this.fixed_displayedColummns);
  //       if (this.selected_filter == null) {
  //         var filter_obj = { 'columns': data.columns };
  //         this.filterService.setItem(this.slug, filter_obj);
  //         this.selected_filter = this.filterService.getItem(this.slug);
  //       }
  //       if (this.user_type == "US_and_CAD") {
  //         this.getUserSelectedStocks();
  //       }
  //       this.getUserSavedWatchListIds();
  //       this.getUserSavedStocksIds();
  //     });
  // }

  setdonutColors(labels) {
    for (let i = 0; i < labels.length; i++) {
      const label = labels[i];
      const colorObj = this.sectorLabels.find(obj => obj.label === label);
      if (colorObj) {
        this.donutColors[0].backgroundColor.push(colorObj.color);
      } else {
        this.sectorLabels.push({ label, color: "#F6969F" });
        this.donutColors[0].backgroundColor.push("#F6969F");
      }
    }
  }

  setTabelData(tabel_data, last_field) {
    this.clearAllField();
    var total_pro = 0;
    var total = 0;
    var total_final = "";
    var total_pro_final = "";
    this.temp_total = 0;
    var estimated = 0;
    for (let j = 0; j < tabel_data.length; j++) {
      if (tabel_data[j].sector === "Consumer Discretionary") {
        var market_value = parseFloat(tabel_data[j].market_value).toFixed(2);
        this.consumer_discretionary = market_value.replace(/(.)(?=(.{3})+$)/g, "$1,").replace(',.', '.');
        this.consumer_discretionary_pro = parseFloat(tabel_data[j].portfolio).toFixed(2);
        total_pro += parseFloat(tabel_data[j].portfolio);
        total += parseFloat(tabel_data[j].market_value);
      } else if (tabel_data[j].sector === "Consumer Staples") {
        var market_value = parseFloat(tabel_data[j].market_value).toFixed(2);
        this.consumer_staples = market_value.replace(/(.)(?=(.{3})+$)/g, "$1,").replace(',.', '.');
        this.consumer_staples_pro = parseFloat(tabel_data[j].portfolio).toFixed(2);
        total_pro += parseFloat(tabel_data[j].portfolio);
        total += parseFloat(tabel_data[j].market_value);
      } else if (tabel_data[j].sector === "Energy") {
        var market_value = parseFloat(tabel_data[j].market_value).toFixed(2);
        this.energy = market_value.replace(/(.)(?=(.{3})+$)/g, "$1,").replace(',.', '.');
        this.energy_pro = parseFloat(tabel_data[j].portfolio).toFixed(2);
        total_pro += parseFloat(tabel_data[j].portfolio);
        total += parseFloat(tabel_data[j].market_value);
      } else if (tabel_data[j].sector === "Finance") {
        var market_value = parseFloat(tabel_data[j].market_value).toFixed(2);
        this.finance = market_value.replace(/(.)(?=(.{3})+$)/g, "$1,").replace(',.', '.');
        this.finance_pro = parseFloat(tabel_data[j].portfolio).toFixed(2);
        total_pro += parseFloat(tabel_data[j].portfolio);
        total += parseFloat(tabel_data[j].market_value);
      } else if (tabel_data[j].sector === "Healthcare") {
        var market_value = parseFloat(tabel_data[j].market_value).toFixed(2);
        this.healthcare = market_value.replace(/(.)(?=(.{3})+$)/g, "$1,").replace(',.', '.');
        this.healthcare_pro = parseFloat(tabel_data[j].portfolio).toFixed(2);
        total_pro += parseFloat(tabel_data[j].portfolio);
        total += parseFloat(tabel_data[j].market_value);
      } else if (tabel_data[j].sector === "Industrials") {
        var market_value = parseFloat(tabel_data[j].market_value).toFixed(2);
        this.industrials = market_value.replace(/(.)(?=(.{3})+$)/g, "$1,").replace(',.', '.');
        this.industrials_pro = parseFloat(tabel_data[j].portfolio).toFixed(2);
        total += parseFloat(tabel_data[j].market_value);
        total_pro += parseFloat(tabel_data[j].portfolio);
      } else if (tabel_data[j].sector === "Materials") {
        var market_value = parseFloat(tabel_data[j].market_value).toFixed(2);
        this.materials = market_value.replace(/(.)(?=(.{3})+$)/g, "$1,").replace(',.', '.');
        this.materials_pro = parseFloat(tabel_data[j].portfolio).toFixed(2);
        total_pro += parseFloat(tabel_data[j].portfolio);
        total += parseFloat(tabel_data[j].market_value);
      } else if (tabel_data[j].sector === "Media") {
        var market_value = parseFloat(tabel_data[j].market_value).toFixed(2);
        this.media = market_value.replace(/(.)(?=(.{3})+$)/g, "$1,").replace(',.', '.');
        this.media_pro = parseFloat(tabel_data[j].portfolio).toFixed(2);
        total_pro += parseFloat(tabel_data[j].portfolio);
        total += parseFloat(tabel_data[j].market_value);
      } else if (tabel_data[j].sector === "Real Estate") {
        var market_value = parseFloat(tabel_data[j].market_value).toFixed(2);
        this.real_estate = market_value.replace(/(.)(?=(.{3})+$)/g, "$1,").replace(',.', '.');
        this.real_estate_pro = parseFloat(tabel_data[j].portfolio).toFixed(2);
        total += parseFloat(tabel_data[j].market_value);
        total_pro += parseFloat(tabel_data[j].portfolio);
      } else if (tabel_data[j].sector === "Technology") {
        var market_value = parseFloat(tabel_data[j].market_value).toFixed(2);
        this.technology = market_value.replace(/(.)(?=(.{3})+$)/g, "$1,").replace(',.', '.');
        this.technology_pro = parseFloat(tabel_data[j].portfolio).toFixed(2);
        total += parseFloat(tabel_data[j].market_value);
        total_pro += parseFloat(tabel_data[j].portfolio);
      } else if (tabel_data[j].sector === "Utilities") {
        var market_value = parseFloat(tabel_data[j].market_value).toFixed(2);
        this.utilities = market_value.replace(/(.)(?=(.{3})+$)/g, "$1,").replace(',.', '.');
        this.utilities_pro = parseFloat(tabel_data[j].portfolio).toFixed(2);
        total += parseFloat(tabel_data[j].market_value);
        total_pro += parseFloat(tabel_data[j].portfolio);
      } else if (tabel_data[j].sector === "") {
        var market_value = parseFloat(tabel_data[j].market_value).toFixed(2);
        this.na = market_value.replace(/(.)(?=(.{3})+$)/g, "$1,").replace(',.', '.');
        this.na_pro = parseFloat(tabel_data[j].portfolio).toFixed(2);
        total += parseFloat(tabel_data[j].market_value);
        total_pro += parseFloat(tabel_data[j].portfolio);
      }
    }
    total_final = (total.toFixed(2)).toString();
    total_pro_final = (total_pro.toFixed(0)).toString();
    for (let i = 0; i < last_field.length; i++) {
      estimated += parseFloat(last_field[i].divid_income);
    }
    this.temp_estimate = estimated;
    this.temp_total = total;
    this.total = total_final.replace(/(.)(?=(.{3})+$)/g, "$1,").replace(',.', '.');
    this.total_pro = total_pro_final;
    this.calculateTableData(last_field, total);
  }

  calculateTableData(last_field: any, total: any) {
    if (total === 0 && last_field.length === 0) {
      this.clearAllField();
    } else {
      var estimated = 0;
      var total_dividend_yield = 0;
      for (let i = 0; i < last_field.length; i++) {
        estimated += parseFloat(last_field[i].divid_income);
      }
      let estimated_data = (estimated.toFixed(2)).toString();
      total_dividend_yield = ((estimated / total) * 100);
      this.estimated_total = estimated_data.replace(/(.)(?=(.{3})+$)/g, "$1,").replace(',.', '.');
      this.dividend_yield_total = !(isNaN(Number(total_dividend_yield.toFixed(3)))) ? (total_dividend_yield.toFixed(3)).toString() : "0.00";
      // if (this.user_type == "US_and_CAD") {
      //   if (this.stockTypes == "USA") {
      //     var total_dividend_yields = 0;
      //     var estimateds = 0;
      //     this.usaAddEstimat = (estimated * parseFloat(this.cadRate));
      //     estimateds = (estimated + this.cadAddEstimat);
      //     let estimated_datas = (estimateds.toFixed(2)).toString();
      //     this.usa_estimated_total = estimated_datas.replace(/(.)(?=(.{3})+$)/g, "$1,").replace(',.', '.');
      //     if (temp_data_total !== 0) {
      //       total_dividend_yields = ((estimateds / temp_data_total) * 100);
      //       this.usa_dividend_yield = !(isNaN(Number(total_dividend_yields.toFixed(3)))) ? (total_dividend_yields.toFixed(3)).toString() : "0.00";
      //     }
      //   } else if (this.stockTypes == "CAD") {
      //     var total_dividend_yields = 0;
      //     var estimateds = 0;
      //     this.cadAddEstimat = (estimated * parseFloat(this.usdRate));
      //     estimateds = (estimated + this.usaAddEstimat);
      //     let estimated_datas = (estimateds.toFixed(2)).toString();
      //     this.cad_estimated_total = estimated_datas.replace(/(.)(?=(.{3})+$)/g, "$1,").replace(',.', '.');
      //     if (temp_data_total !== 0) {
      //       total_dividend_yields = ((estimateds / temp_data_total) * 100);
      //       this.cad_dividend_yield = !(isNaN(Number(total_dividend_yields.toFixed(3)))) ? (total_dividend_yields.toFixed(3)).toString() : "0.00";
      //     }
      //   }
      // }
    }
    if (this.user_type == "US_and_CAD") {
      this.calculateSummeryTable(total, this.temp_estimate);
    }
  }

  calculateSummeryTable(total: any, estimated: any) {
    total = parseFloat(total);
    estimated = parseFloat(estimated);
    if (this.stockTypes == "USA") {
      var temp_data = 0;
      var total_dividend_yields = 0;
      var estimateds = 0;
      this.usaAddTotal = (total * parseFloat(this.cadRate));
      temp_data = (total + this.cadAddTotal);
      var total_final = !(isNaN(Number(temp_data.toFixed(2)))) ? (temp_data.toFixed(2)) : "0.00";
      var total_finals = total_final.toString();
      this.usaTotal = total_finals.replace(/(.)(?=(.{3})+$)/g, "$1,").replace(',.', '.');

      this.usaAddEstimat = (estimated * parseFloat(this.cadRate));
      estimateds = (estimated + this.cadAddEstimat);
      var total_final2 = !(isNaN(Number(estimateds.toFixed(2)))) ? (estimateds.toFixed(2)) : "0.00";
      let estimated_datas = total_final2.toString();
      this.usa_estimated_total = estimated_datas.replace(/(.)(?=(.{3})+$)/g, "$1,").replace(',.', '.');
      total_dividend_yields = ((estimateds / temp_data) * 100);
      this.usa_dividend_yield = !(isNaN(Number(total_dividend_yields.toFixed(3)))) ? (total_dividend_yields.toFixed(3)).toString() : "0.00";
    } else if (this.stockTypes == "CAD") {
      var temp_data = 0;
      var total_dividend_yields = 0;
      var estimateds = 0;
      this.cadAddTotal = (total * parseFloat(this.usdRate));
      temp_data = (total + this.usaAddTotal);
      var total_final = !(isNaN(Number(temp_data.toFixed(2)))) ? (temp_data.toFixed(2)) : "0.00";
      var total_finals = total_final.toString();
      this.cadTotal = total_finals.replace(/(.)(?=(.{3})+$)/g, "$1,").replace(',.', '.');

      this.cadAddEstimat = (estimated * parseFloat(this.usdRate));
      estimateds = (estimated + this.usaAddEstimat);
      var total_final2 = !(isNaN(Number(estimateds.toFixed(2)))) ? (estimateds.toFixed(2)) : "0.00";
      let estimated_datas = total_final2.toString();
      // let estimated_datas = (estimateds.toFixed(2)).toString();
      this.cad_estimated_total = estimated_datas.replace(/(.)(?=(.{3})+$)/g, "$1,").replace(',.', '.');
      total_dividend_yields = ((estimateds / temp_data) * 100);
      this.cad_dividend_yield = !(isNaN(Number(total_dividend_yields.toFixed(3)))) ? (total_dividend_yields.toFixed(3)).toString() : "0.00";
    }
  }

  clearAllField() {
    this.consumer_discretionary = "0";
    this.consumer_staples = "0";
    this.energy = "0";
    this.finance = "0";
    this.healthcare = "0";
    this.industrials = "0";
    this.materials = "0";
    this.media = "0";
    this.real_estate = "0";
    this.technology = "0";
    this.utilities = "0";
    this.consumer_discretionary_pro = "0";
    this.consumer_staples_pro = "0";
    this.energy_pro = "0";
    this.finance_pro = "0";
    this.healthcare_pro = "0";
    this.industrials_pro = "0";
    this.materials_pro = "0";
    this.media_pro = "0";
    this.real_estate_pro = "0";
    this.technology_pro = "0";
    this.utilities_pro = "0";
    this.total = "0";
    this.total_pro = "0";
    this.estimated_total = "0";
    this.dividend_yield_total = "0";
  }

  getStocks(symbolstring = "symbolstring", order = "asc", page = 0, limit = 200, filter = "") {
    this.stocksService.getAllStocks(symbolstring, order, page, limit, filter, this.selectedstocks, this.user_type).subscribe((data: any) => {
      if (data.success == true) {
        this.stocksList = data.data;
        this.stocksList.map(stocks => stocks.symbolstring = `${stocks.companyName} (${stocks.symbolstring})`);
      } else if (data.success == false) {
        this.toastr.error(data.message);
      }
    },
      (error) => {
        this.loginPopUpNow();
        throw error;
      });
  }

  onChangeSearch(event: Event) {
    if (this.userService.user_type == "course_platform" || this.userService.user_type == "Free_platformCourse_platform") {
      this.user_type = "course_platform";
    } else if (this.userService.user_type == "Free_platform") {
      this.user_type = "Free_platform";
      this.free_user = true;
    } else if (this.userService.user_type == "CAD_only" || this.userService.user_type == "CAD_onlyCourse_platform") {
      this.user_type = "CAD_only";
      this.stockTypes = "CAD";
    } else if (this.userService.user_type == "US_only" || this.userService.user_type == "US_onlyCourse_platform") {
      this.user_type = "US_only";
      this.stockTypes = "USA";
    } else if (this.userService.user_type == "US_and_CAD" || this.userService.user_type == "US_and_CADCourse_platform") {
      this.user_type = "US_and_CAD";
    }
    let search = (event.target as HTMLInputElement).value;
    var symbolstring = "symbolstring", order = "asc", page = 0, limit = 200, filter = search;
    this.selectedstocks = [];
    var userType = "";
    if (this.userService.user_type == "US_onlyCourse_platform" || this.userService.user_type == "CAD_onlyCourse_platform" || this.userService.user_type == "US_and_CAD" || this.userService.user_type == "US_and_CADCourse_platform") {
      userType = "US_and_CAD";
    } else {
      userType = this.user_type;
    }
    this.stocksService.getAllStocks(symbolstring, order, page, limit, filter, this.selectedstocks, userType).subscribe((data: any) => {
      if (data.success == true) {
        this.stocksList = data.data;
        this.stocksList.map(stocks => stocks.symbolstring = `${stocks.companyName} (${stocks.symbolstring})`);
      }
    },
      (error) => {
        throw error;
      });
  }

  get f() { return this.stockForm.controls; }
  get s() { return this.searchForm.controls; }
  get st() { return this.stockForm1.controls; }



  submitForm(content) {
    this.selectedstocks = this.stockForm.controls['stocks'].value;
    this.quantity = this.stockForm.controls['quantity'].value;
    this.accounts = this.stockForm.controls['account'].value;

    if ((this.quantity == null || this.quantity.length === 0)) { return; }
    if ((this.selectedstocks == null || this.selectedstocks.length === 0)) { return; }
    if (this.selectedstocks != null && this.quantity != null) {
      if (this.accounts_data && this.accounts_data.length > 0) {
        this.accounts = (this.accounts !== "") ? this.accounts : 'N/A';
        let index = this.accounts_data.findIndex(x => (x.stock === this.selectedstocks && x.account === this.accounts));
        if (index !== -1) {
          this.model_quantity = this.quantity;
          let stock_index = this.stocksList.findIndex(x => x.stockID == this.selectedstocks);
          this.model_stock = this.stocksList[stock_index]['symbolstring'];
          this.model_account = (this.accounts != "") ? this.accounts : "N/A";
          this.modalReference = this.modalService.open(content, { size: 'md', ariaLabelledBy: 'modal-basic-title' })
          this.modalReference.result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
            this.getColumns();
          }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
        } else {
          this.stocksService.saveUserStocks(this.selectedstocks, this.quantity, this.accounts, this.userService.user_id)
            .subscribe((data: any) => {
              if (data.success) {
                this.toastr.success(data.message);
                this.ngOnInit();
              } else if (data.success == false) {
                this.toastr.error(data.message, "My Stocks ");
              }
              this.ngOnInit();
              this.ngAfterViewInit();
            },
              (error) => {
                throw error;
              });
        }
      } else {
        this.stocksService.saveUserStocks(this.selectedstocks, this.quantity, this.accounts, this.userService.user_id)
          .subscribe((data: any) => {
            if (data.success) {
              this.toastr.success(data.message);
              this.stockForm.controls['quantity'].reset();
            } else if (data.success == false) {
              this.toastr.error(data.message);
            }
            this.ngOnInit();
            this.ngAfterViewInit();
          },
            (error) => {
              throw error;
            });
      }
    } else {
      this.toastr.error("Please select a Stock or enter a Quantity");
    }
  }

  proceed() {
    this.selectedstocks = this.stockForm.controls['stocks'].value;
    this.quantity = this.stockForm.controls['quantity'].value;
    this.accounts = this.stockForm.controls['account'].value;
    this.stocksService.saveUserStocksQuantity(this.selectedstocks, this.quantity, this.accounts, this.userService.user_id)
      .subscribe((data: any) => {
        if (data.success) {
          this.toastr.success(data.message);
        } else if (data.success == false) {
          this.toastr.error(data.message, "My Stocks ");
        }
        this.modalReference.close();
        this.ngOnInit();
        this.ngAfterViewInit();
      },
        (error) => {
          throw error;
        });
  }

  getFixValue(row, fixCol, col_name) {
    // let index = fixCol.findIndex(x => x.id == row.stock_no && x.stock_id == row.stock_id);
    let index = fixCol.findIndex(x => x.stock_id == row.stock_id);
    if (index != -1) {
      if (col_name == 'quantity') {
        return fixCol[index].quantity;
      } else if (col_name == 'market_value') {
        var market_value = parseFloat(fixCol[index].market_value).toFixed(2);
        return market_value.replace(/(.)(?=(.{3})+$)/g, "$1,").replace(',.', '.');
      } else if (col_name == 'portfolio') {
        return fixCol[index].portfolio + '%';
      } else if (col_name == 'income') {
        return parseFloat(fixCol[index].divid_income).toFixed(2);
      } else if (col_name == 'account') {
        return fixCol[index].account;
      } else {
        return "N/A";
      }
    }
  }

  getUserSavedStocks(filter_column = "", filter = "", filter_op = "", sort = '', order = "asc", page = 0, limit = this.pagesize) {
    this.stocksService.getAllUserSavedStocks(filter_column, filter, filter_op, sort, order, page, limit, this.userService.user_id, this.selected_filter, this.stockTypes).subscribe((data: any) => {
      if (data.success == true) {
        var data = data.data;
        this.displayedColumns = ['1', this.company_name, this.stock_symbol];
        this.stocksListData = data.stocks;
        this.resultsLength = data.count;
        this.accounts_data = data.accounts_data;
        var ids_data = data.ids;
        if (this.resultsLength == 0) {
          this.falsedata = true;
        }
        var temp = {};
        var obj = null;
        for (var i = 0; i < data.graph_data.length; i++) {
          obj = data.graph_data[i];
          if (!temp[obj.sector]) {
            temp[obj.sector] = obj;
          } else {
            temp[obj.sector].price += obj.price;
            temp[obj.sector].market_value += obj.market_value;
            temp[obj.sector].portfolio += obj.portfolio;
          }
        }
        var result = [];
        for (var prop in temp) {
          result.push(temp[prop]);
        }
        this.show_chart = true;
        this.doughnutChartData = [];
        this.doughnutChartLabels = [];
        this.donutColors[0].backgroundColor.splice(0, this.donutColors[0].backgroundColor.length);
        for (let i = 0; i < result.length; i++) {
          this.doughnutChartLabels.push(result[i].sector);
          this.pie_chart = Math.round(result[i].portfolio * 100) / 100;
          this.doughnutChartData.push(this.pie_chart);
        }
        this.setdonutColors(this.doughnutChartLabels)
        ids_data.sort(function (a, b) {
          return a.column_order - b.column_order;
        });
        if (this.resultsLength > 0) {
          this.falsedata = false;
          for (let v in ids_data) {
            this.displayedColumns.push(ids_data[v]['column_id']);
            this.initialColumns.push(ids_data[v]['column_id']);
            this.header[ids_data[v]['column_id']] = ids_data[v]['column_name'];
          }
        }
        if (this.selected_filter == null) {
          var filter_obj = { 'columns': data.columns };
          this.filterService.setItem(this.slug, filter_obj);
          this.selected_filter = this.filterService.getItem(this.slug);
        }
      } else if (data.success == false) {
        console.log(data.message);
      }
    },
      (error) => {
        throw error;
      });
  }

  getColCss(column_id, stock_data) {
    let dummy_column_index5 = stock_data.values_data.findIndex(x => x.column_id == "60781c759008e551ad66d40c");
    let dummy_column_index10 = stock_data.values_data.findIndex(x => x.column_id == "60791e199008d379355a36f9");
    let dummy_column_index15 = stock_data.values_data.findIndex(x => x.column_id == "60791e219008d379355a36fb");
    let dummy_column_index20 = stock_data.values_data.findIndex(x => x.column_id == "60791e299008d379355a36fd");
    let dummy_column_dy = stock_data.values_data.findIndex(x => x.column_id == "63c9355b2265834d3487fcf2");

    if (dummy_column_dy != -1) {
      var values = stock_data.values_data[dummy_column_dy]['column_value'];
      for (let val in values) {
        if (values[val] == column_id) {
          return "highlightDY";
        }
      }
    }
    if (dummy_column_index5 != -1) {
      var values = stock_data.values_data[dummy_column_index5]['column_value'];
      for (let val in values) {
        if (values[val] == column_id) {
          return "highlight5";
        }
      }
    }
    if (dummy_column_index10 != -1) {
      var values = stock_data.values_data[dummy_column_index10]['column_value'];
      for (
        let val in values) {
        if (values[val] == column_id) {
          return "highlight10";
        }
      }
    }
    if (dummy_column_index15 != -1) {
      var values = stock_data.values_data[dummy_column_index15]['column_value'];
      for (let val in values) {
        if (values[val] == column_id) {
          return "highlight15";
        }
      }
    }
    if (dummy_column_index20 != -1) {
      var values = stock_data.values_data[dummy_column_index20]['column_value'];
      for (let val in values) {
        if (values[val] == column_id) {
          return "highlight20";
        }
      }
    }
  }

  getColVal(column_id, stock_data) {
    if (column_id == "60a50ee1900801bfa078b24e") {
      return this.getFixValue(stock_data, this.fixed_displayedColummns, 'quantity');
    } else if (column_id == "60a50f02900801bfa078b252") {
      return this.getFixValue(stock_data, this.fixed_displayedColummns, 'market_value');
    } else if (column_id == "60a50f0d900801bfa078b254") {
      return this.getFixValue(stock_data, this.fixed_displayedColummns, 'portfolio');
    } else if (column_id == "60a50f19900801bfa078b256") {
      return this.getFixValue(stock_data, this.fixed_displayedColummns, 'income');
    } else if (column_id == "60a50f2c900801bfa078b258") {
      return this.getFixValue(stock_data, this.fixed_displayedColummns, 'account');
    } else {
      let index = stock_data.values_data.findIndex(x => x.column_id == column_id);
      var val = "";
      if (index != -1) {
        if (typeof stock_data.values_data[index]['column_value'] === "number") {
          if (Number.isInteger(stock_data.values_data[index]['column_value'])) {

            val = stock_data.values_data[index]['column_value'];

            if (this.header[column_id] == "Dividends Paid Since" || this.header[column_id] == "Annual Forward Dividend vs Previous Year, Rule #9") {
              if (this.header[column_id] == "Annual Forward Dividend vs Previous Year, Rule #9") {
                if (stock_data.values_data[index]['column_value'] == 0) {
                  return Number(val) + "%";
                } else {
                  return Number(val).toFixed(2) + "%";
                }
              }
              return val;
            } else {
              val = val.toLocaleString();
            }
            if (this.header[column_id] == "Consecutive Years of Dividend Increases" || this.header[column_id] == "Consecutive Years of EPS Increases") {
              return val;
            }
            if (this.header[column_id] == "Annual Forward Dividend") {
              return "$" + val;
            }
            if (stock_data.values_data[index]['column_value'] == 0) {
              val = "N/A";
            }
          } else {
            if (this.header[column_id] == "Annual Forward Dividend") {
              return "$" + parseFloat(stock_data.values_data[index]['column_value']).toFixed(2);
            }
            if (this.header[column_id] == "Annual Forward Dividend vs Previous Year, Rule #9") {
              val = (parseFloat(stock_data.values_data[index]['column_value']).toFixed(2) + "%");
            } else {
              val = parseFloat(stock_data.values_data[index]['column_value']).toFixed(2);
            }
            // val = parseFloat(stock_data.values_data[index]['column_value']).toFixed(2);
          }
        } else {
          if (stock_data.values_data[index]['column_value'] == "") {
            return val = "N/A";
          } else {
            val = stock_data.values_data[index]['column_value'];
          }
          if (stock_data.values_data[index]['column_value'] == null) {
            val = "N/A";
          } else {
            val = stock_data.values_data[index]['column_value'];
          }
          // val = stock_data.values_data[index]['column_value'];
        }
      }
      else {
        val = "N/A";
      }

      var percent_arr = ['%difference between Graham Price vs Share Price',
        'Average Dividend Growth (5-year), Rule #6', 'Average Dividend Growth (10-year), Rule #6',
        'Average Dividend Growth (15-year), Rule #6', 'Average Dividend Growth (20-year), Rule #6',
        'Average Dividend Yield (5-year)', 'Average Dividend Yield (10-year)',
        'Average Dividend Yield (15-year)', 'Average Dividend Yield (20-year)',
        'Average EPS Growth (5-year), Rule #5a', 'Average EPS Growth (10-year), Rule #5a',
        'Average EPS Growth (15-year), Rule #5a',
        'Average EPS Growth (20-year), Rule #5a',
        'Average High Dividend Yield (5-year)',
        'Average High Dividend Yield (10-year)',
        'Average High Dividend Yield (15-year)',
        'Average High Dividend Yield (20-year)',
        'Average Low Dividend Yield (5-year)',
        'Average Low Dividend Yield (10-year)',
        'Average Low Dividend Yield (15-year)',
        'Average Low Dividend Yield (20-year)',
        'Current Dividend Yield',
        'Difference Between Current Dividend Yield and Average Dividend Yield (5-year)',
        'Difference Between Current Dividend Yield and Average Dividend Yield (10-year)',
        'Difference Between Current Dividend Yield and Average Dividend Yield (15-year)',
        'Difference Between Current Dividend Yield and Average Dividend Yield (20-year)',
        'Profit Margin (Total Operating)',
        'Total Debt to Equity Ratio',
        'Payout Ratio, Rule #7',
        'Long-term Debt/Equity Ratio, Rule #8',
        // 'Annual Forward Dividend vs Previous Year, Rule #9'
      ]
      if (percent_arr.indexOf(this.header[column_id]) !== -1) {
        if (val != "N/A" && val != null && val != "") {
          if (val != "0") {
            val = val + "%";
          } else {
            val = "N/A";
          }
        }
      }
      let dummy_column_dy = stock_data.values_data.findIndex(x => x.column_id == "63c9355b2265834d3487fcf2");
      if (this.header[column_id] == "Current Dividend Yield" && dummy_column_dy != -1) {
        var values = stock_data.values_data[dummy_column_dy]['column_value'];
        for (let vale in values) {
          if (values[vale] == column_id) {
            return val + ' <a target="_blank" href="user/pages/dividend-trap">DT</a>';
          }
        }
      }
      return val;
    }
  }

  // getColVal(column_id, stock_data) {
  //   let index = stock_data.values_data.findIndex(x => x.column_id == column_id);
  //   var val = "";
  //   if (index != -1) {
  //     if (isNaN(parseFloat(stock_data.values_data[index]['column_value']))) {
  //       val = stock_data.values_data[index]['column_value'];
  //     } else {
  //       val = parseFloat(stock_data.values_data[index]['column_value']).toFixed(2);
  //     }
  //   } else {
  //     val = "N/A"
  //   }
  //   return val;
  // }

  dropListDropped(event: CdkDragDrop<string[]>) {
    this.displayedColumns.splice(0, 1);
    this.displayedColumns.splice(0, 1);
    this.displayedColumns.splice(0, 1);

    var element_id = this.displayedColumns[event.previousIndex];
    this.displayedColumns.splice(event.previousIndex, 1);
    this.displayedColumns.splice(event.currentIndex, 0, element_id);
    for (let i = 0; i < this.displayedColumns.length; i++) {
      let index = this.selected_filter.columns.findIndex(x => x._id == this.displayedColumns[i]);
      if (index != -1) {
        this.selected_filter.columns[index].column_order = i;
      }
    }
    this.displayedColumns.splice(0, 0, '1');
    this.displayedColumns.splice(1, 0, '2');
    this.displayedColumns.splice(2, 0, '3');

    this.filterService.setItem(this.slug, this.selected_filter);
    this.custome_filter = "Custom filter applied";
    this.checkFilterName();
  }


  removeColumn(index) {
    let sel_index = this.selected_filter.columns.findIndex(x => x._id == this.displayedColumns[index]);
    if (sel_index != -1) {
      this.selected_filter.columns[sel_index].show_column = 0;
    }
    this.displayedColumns.splice(index, 1);
    this.filterService.setItem(this.slug, this.selected_filter);
    this.custome_filter = "Custom filter applied";
    this.checkFilterName();
  }

  private filterSortColumn(event: Event) {
    if (!this.stocksList) {
      return;
    }
    // get the search keyword
    let search = (event.target as HTMLInputElement).value;
    if (search != "") {
      this.columnList = this.columnList.filter(x => x.column_name.toLowerCase().indexOf(search.toLowerCase()) > -1);
    } else {
      this.getColumns();
    }
  }

  private filterStocksMulti(event: Event) {
    if (!this.stocksList) {
      return;
    }
    this.selectedstocks = this.stockForm.controls['stocks'].value;
    // get the search keyword
    let search = (event.target as HTMLInputElement).value;
    if (search != "") {
      this.getStocks('symbolstring', 'asc', 0, 200, search.toUpperCase());
    } else {
      this.getStocks('symbolstring', 'asc', 0, 200, '');
    }

  }

  getColumns() {
    this.columnsService.getColumns('column_order', 'asc', 0, 150).subscribe((data: any) => {
      if (data.success == true) {
        this.columnList = data.data
      } else if (data.success == false) {
        console.log(data.message);
      }
    },
      (error) => {
        this.loginPopUpNow();
        throw error;
      });
  }

  searchStocks(event: Event) {
    // this.getUserSavedStocks(this.searchForm.controls['column'].value, this.searchForm.controls['column_value'].value, this.searchForm.controls['column_op'].value, '', "asc", 0, this.pagesize);
  }

  scrollLeft() {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
  }

  scrollRight() {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
  }

  open(content) {
    this.modalService.open(content, { size: 'xl', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  opendialog(content, stock) {
    if (this.selected_stocks_ids.indexOf(stock.stock_id) !== -1) {
      this.addToList("stocks", stock);
    } else {
      let newStockName = (stock.longname) + "  " + ('(' + stock.symbolstring + ')');

      this.stockForm1.controls['symbol'].setValue(newStockName);
      this.stockForm1.controls['stock_id'].setValue(stock.stock_id);

      this.modalService.open(content, { size: 'md', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  addStocksToList() {

    var selectedstocks = this.stockForm1.controls['stock_id'].value;
    var quantity = this.stockForm1.controls['quantity'].value;
    var account = this.stockForm.controls['account'].value;

    this.stocksService.saveUserStocks(selectedstocks, quantity, account, this.userService.user_id)
      .subscribe((data: any) => {
        if (data != null) {
          if (this.selected_stocks_ids.indexOf(data.data.stock_id) != -1) {
            this.selected_stocks_ids.splice(this.selected_stocks_ids.indexOf(data.data.stock_id), 1);
          }
          // this.getUserSavedStocks();
          this.ngAfterViewInit();
        }
        if (data.success) {
          this.toastr.success(data.message);
          this.getUserSavedStocksIds();
          this.ngAfterViewInit();
        } else if (data.success == false) {
          this.toastr.error(data.message);
        }
        this.ngOnInit();
      },
        (error) => {
          throw error;
        });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  addToList(type, stock) {
    if (type == 'watchlist') {
      if (this.selected_watchlist_ids.indexOf(stock.stock_id) !== -1) {
        this.removeFromList(type, stock);
      } else {
        this.saveToList(type, stock);
      }
    } else if (type == 'stocks') {
      if (this.selected_stocks_ids.indexOf(stock.stock_id) !== -1) {
        this.removeFromList(type, stock);
      }
      // else {
      //   this.saveToList(type, stock);
      // }
    }
  }

  saveToList(type, stock) {
    this.stocksService.addToList(type, stock.stock_id, this.userService.user_id).subscribe((data: any) => {
      if (data.success == true) {
        if (type == 'watchlist') {
          this.selected_watchlist_ids.push(stock.stock_id);
          this.toastr.success(data.message);
        } else if (type == 'stocks') {
          this.selected_stocks_ids.push(stock.stock_id);
          this.toastr.success(data.message);
        }
      } else if (data.success == false) {
        this.toastr.error(data.message);
      }
    },
      (error) => {
        throw error;
      });
  }

  removeFromList(type, stock) {
    var stock_data = { 'stock_id': stock.stock_id, 'stock_no': stock.stock_no };
    this.stocksService.addToList(type, stock_data, this.userService.user_id).subscribe((data: any) => {
      if (type == "watchlist") {
        if (this.selected_watchlist_ids.indexOf(data.data.stock_id) != -1) {
          this.selected_watchlist_ids.splice(this.selected_stocks_ids.indexOf(data.data.stock_id), 1);
        }
      } else if (type == "stocks") {
        if (this.selected_stocks_ids.indexOf(data.data.stock_id) != -1) {
          this.selected_stocks_ids.splice(this.selected_stocks_ids.indexOf(data.data.stock_id), 1);
        }
      }
      if (data.success == true) {
        this.toastr.success(data.message);
        this.getUserSavedWatchListIds();
        this.getUserSavedStocksIds();
        this.ngAfterViewInit();
      } else if (data.success == false) {
        this.toastr.success(data.message);
        this.getUserSavedWatchListIds();
        this.getUserSavedStocksIds();
        this.ngAfterViewInit();
      }
    },
      (error) => {
        throw error;
      });
  }


  getIcon(icon_type: string, stock) {

    if (icon_type == 'watchlist') {

      if (this.selected_watchlist_ids.indexOf(stock.stock_id) !== -1) {
        return "assets/images/marketing-yellow.png";
      } else {
        return "assets/images/marketing.png";
      }
    } else if (icon_type == 'stocks') {
      //console.log(this.selected_stocks_ids.indexOf(stock.stock_id));
      if (this.selected_stocks_ids.indexOf(stock.stock_id) !== -1) {
        return "assets/images/stock-icon2.png";
      } else {
        return "assets/images/growth.png";
      }
    } else if (icon_type == 'alerts') {
      if (this.selected_alert_ids != undefined) {
        if (this.selected_alert_ids.indexOf(stock.stock_id) !== -1) {
          return "assets/images/alerts-blue.png";
        } else {
          return "assets/images/alerts.png";
        }
      } else {
        return "assets/images/alerts.png";
      }
    }


  }

  getTitle(icon_type: string, stock) {
    if (icon_type == 'watchlist') {

      if (this.selected_watchlist_ids.indexOf(stock.stock_id) !== -1) {
        return "Remove From My Watchlist ";
      } else {
        return "Add to My Watchlist";
      }
    } else if (icon_type == 'stocks') {

      if (this.selected_stocks_ids.indexOf(stock.stock_id) !== -1) {
        return "Remove From My Stocks";
      } else {
        return "Add to My Stocks";
      }
    } else if (icon_type == 'alerts') {
      if (this.selected_alert_ids != undefined) {
        if (this.selected_alert_ids.indexOf(stock.stock_id) !== -1) {
          return "View My Alerts";
        } else {
          return "Add to My Alerts";
        }
      } else {
        return "Add to My Alerts";
      }
    }
  }

  getUserSavedWatchListIds() {
    this.stocksService.getAllWatchlistStockIds(this.userService.user_id).subscribe((data: any) => {
      if (data.success == true) {
        this.selected_watchlist_ids = data.data.ids;
      } else if (data.success == false) {
        console.log(data.message);
      }
    },
      (error) => {
        this.loginPopUpNow();
        throw error;
      });
  }


  getUserSavedStocksIds() {
    this.stocksService.getAllSavedStockIds(this.userService.user_id).subscribe((data: any) => {
      if (data.success == true) {
        this.selected_stocks_ids = data.data.ids;
        //console.log(this.selected_watchlist_ids);
      } else if (data.success == false) {
        console.log(data.message);
      }
    },
      (error) => {
        throw error;
      });
  }

  getUserSavedAlertIds() {
    this.stocksService.getAllUserAlerts(this.userService.user_id).subscribe((data: any) => {
      if (data.success == true) {
        this.selected_alert_ids = data.data;
      } else if (data.success == false) {
        console.log(data.message);
      }
    },
      (error) => {
        throw error
      });
  }

  getPageContent() {
    this.pagesService.getPageContent(this.slug)
      .subscribe((data: any) => {
        if (data.success) {
          this.content = data.data.content;
          // if (data.data.video_path) {
          //   var path = data.data.video_path;
          //   this.videoSource = path.replace("src/", "");
          // }
        } else if (data.success == false) {
          this.toastr.error(data.message);
        }
      },
        (error) => {
          this.loginPopUpNow();
          throw error;
        });
  }

  toggleVideo(event: any) {
    this.videoplayer.nativeElement.play();
  }

  public isSticky(col) {

    if (col == this.stock_symbol || col == this.company_name) {
      return true;
    } else {
      return false;
    }
  }

  clickEvent() {
    this.status = !this.status;
    this.filterService.setSideMenuStaus(!this.status);
  }

  changeSideIcon() {
    if (this.status == false) {
      return "fa fa-caret-left fa-2x";
    } else if (this.status == true) {
      return "fa fa-caret-right fa-2x";
    }
  }

  getToolTips() {
    this.pagesService.getToolTips(this.tooltip)
      .subscribe(data => {
        if (data.success) {
          this.allToolTips = data.data;
        }
      },
        (error) => {
          throw error;
        });
  }

  showToolTipData(column_id) {
    this.disaplayToolTip = "";
    let index = this.allToolTips.findIndex(x => x.column_name == column_id);
    if (index != -1) {
      this.disaplayToolTip = this.allToolTips[index].tool_tips;
      return true;
    } else {
      return false;
    }
  }

  checkFilterName() {
    this.filterService.removeFilterName(this.slug);
    this.filterService.setFilterName(this.slug, this.custome_filter);
  }

  clearStockForm() {
    localStorage.removeItem('active-form');
    localStorage.removeItem("search-stock2");
    localStorage.removeItem("search-stock-con");
    localStorage.removeItem("checkMarket");
  }

  openFilterpop() {
    if (this.userService.user_type == "Free_platform" || this.userService.user_type == "course_platform" || this.userService.user_type == "Free_platformCourse_platform") {
      this.modelService.confirmMessage().pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    } else {
      this.modelService.confirm().pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    }
  }

  restore() {
    if (this.userService.user_type == "course_platform") {
      this.modelService.confirmMessage().pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    } else {
      this.userSideMenuCom.restore();
    }
  }

  columnDefination() {
    if (this.userService.user_type == "course_platform") {
      this.modelService.confirmMessage().pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    } else {
      this.router.navigate([]).then(result => {
        window.open('/user/pages/column-definitions', '_blank');
      });
    }
  }

  clickHidde() {
    this.hidden = !this.hidden;
    this.userService.saveDetailSection(this.slug, this.hidden, this.userService.user_id)
      .subscribe((data: any) => { })
  }

  getHidden() {
    this.userService.getDetailSection(this.slug, this.userService.user_id)
      .subscribe((data: any) => {
        this.hidden = data.data;
      })
  }

  checkHidden() {
    if (this.hidden == false) {
      return "fas fa-caret-up fa-2x";
    } else if (this.hidden == true) {
      return "fas fa-caret-down fa-2x";
    }
  }

  navigetToalert(data: any) {
    this.router.navigate([]).then((result) => {
      let encoID = btoa(data.symbolstring);
      window.open('/user/my-alert/' + encoID, '_blank');
    });
  }

  getUserSelectedStocks(filter_column = "", filter = "", filter_op = "", sort = '', order = "asc", page = 0, limit = this.pagesize) {
    var stocktype = "";
    if (this.stockTypes == "USA") {
      stocktype = "CAD";
    } else if (this.stockTypes == "CAD") {
      stocktype = "USA";
    }
    this.stocksService.getAllUserSavedStocks(filter_column, filter, filter_op, sort, order, page, limit, this.userService.user_id, this.selected_filter, stocktype).subscribe((data: any) => {
      var result = [];
      var fixed_displayedColummns = [];
      if (data.success == true) {
        var data = data.data;
        var temp = {};
        var obj = null;
        for (var i = 0; i < data.graph_data.length; i++) {
          obj = data.graph_data[i];
          if (!temp[obj.sector]) {
            temp[obj.sector] = obj;
          } else {
            temp[obj.sector].price += obj.price;
            temp[obj.sector].market_value += obj.market_value;
            temp[obj.sector].portfolio += obj.portfolio;
          }
        }
        for (var prop in temp) {
          result.push(temp[prop]);
        }
        for (let values in data.columns_data) {
          fixed_displayedColummns.push(data.columns_data[values]);
        }
        this.calcultTabelData(result, fixed_displayedColummns);
      } else {
        this.calcultTabelData(result, fixed_displayedColummns);
      }
    },
      (error) => {
        throw error;
      });
  }

  calcultTabelData(tabel_data, last_field) {
    var total = 0;
    var cadRates = parseFloat(this.cadRate);
    var usdRates = parseFloat(this.usdRate);
    if (tabel_data.length != 0 && last_field.length != 0) {
      for (let j = 0; j < tabel_data.length; j++) {
        total += parseFloat(tabel_data[j].market_value);
      }
    }
    if (this.stockTypes == "USA") {
      var temp_data = 0;
      let estimateds = 0;
      this.cadAddTotal = (total * usdRates);
      temp_data = (total + this.usaAddTotal);
      var total_final = !(isNaN(Number(temp_data.toFixed(2)))) ? (temp_data.toFixed(2)) : "0.00";
      // temp_data = !isNaN(temp_data) ? temp_data : 0;
      var total_finals = total_final.toString();
      this.cadTotal = total_finals.replace(/(.)(?=(.{3})+$)/g, "$1,").replace(',.', '.');
      var estimated = 0;
      for (let i = 0; i < last_field.length; i++) {
        estimated += parseFloat(last_field[i].divid_income);
      }
      this.cadAddEstimat = (estimated * usdRates);
      estimateds = (estimated + this.usaAddEstimat);
      var total_final2 = !(isNaN(Number(estimateds.toFixed(2)))) ? (estimateds.toFixed(2)) : "0.00";
      // estimateds = !isNaN(estimateds) ? estimateds : 0;
      let estimated_data = total_final2.toString();
      this.cad_estimated_total = estimated_data.replace(/(.)(?=(.{3})+$)/g, "$1,").replace(',.', '.');
      let total_dividend_yields = 0;
      total_dividend_yields = ((estimateds / temp_data) * 100);
      this.cad_dividend_yield = !Number.isNaN(total_dividend_yields) ? (total_dividend_yields.toFixed(3)).toString() : "0.00";
    } else if (this.stockTypes == "CAD") {
      var temp_data = 0;
      this.usaAddTotal = (total * cadRates);
      temp_data = (total + this.cadAddTotal);
      var total_final = !(isNaN(Number(temp_data.toFixed(2)))) ? (temp_data.toFixed(2)) : "0.00";
      var total_finals = temp_data.toString();
      this.usaTotal = total_final.replace(/(.)(?=(.{3})+$)/g, "$1,").replace(',.', '.');
      var estimated = 0;
      for (let i = 0; i < last_field.length; i++) {
        estimated += parseFloat(last_field[i].divid_income);
      }
      let estimateds = 0;
      this.usaAddEstimat = (estimated * cadRates);
      estimateds = (estimated + this.cadAddEstimat);
      var total_final2 = !(isNaN(Number(estimateds.toFixed(2)))) ? (estimateds.toFixed(2)) : "0.00";
      let estimated_data = total_final2.toString();
      this.usa_estimated_total = estimated_data.replace(/(.)(?=(.{3})+$)/g, "$1,").replace(',.', '.');
      let total_dividend_yields = 0;
      total_dividend_yields = ((estimateds / temp_data) * 100);
      this.usa_dividend_yield = !Number.isNaN(total_dividend_yields) ? (total_dividend_yields.toFixed(3)).toString() : "0.00";
    }
    let para_total = (this.temp_total !== 0) ? this.temp_total : 0;
    let para_estimate = (this.temp_estimate !== 0) ? this.temp_estimate : 0;

    this.calculateSummeryTable(para_total, para_estimate);
  }

  // changeSearchCard(searchForm: string) {
  //   if (searchForm == 'basic') {
  //     this.stockTypes = "USA";
  //     this.ngAfterViewInit();
  //     this.basicSearch = true;
  //     this.advanceSearch = false;
  //   } else if (searchForm == 'advance') {
  //     this.stockTypes = "CAD";
  //     this.ngAfterViewInit();
  //     this.advanceSearch = true;
  //     this.basicSearch = false;
  //   } else {
  //     this.basicSearch = false;
  //     this.advanceSearch = false;
  //   }
  // }

  changeSearchCardUSA(searchForm: string) {
    if (searchForm == 'basic') {
      this.stockTypes = "USA";
      this.ngOnInit();
      this.ngAfterViewInit();
      this.basicSearch = true;
      this.advanceSearch = false;
    }
  }

  changeSearchCardCAD(searchForm: string) {
    if (searchForm == 'advance') {
      this.stockTypes = "CAD";
      this.ngOnInit();
      this.ngAfterViewInit();
      this.advanceSearch = true;
      this.basicSearch = false;
    }
  }

  changeTagColor(searchForm: string) {
    if (searchForm == 'basic') {
      if (this.basicSearch) {
        return "activebcards";
      } else {
        return "bcards";
      }
    } else if (searchForm == 'advance') {
      if (this.advanceSearch) {
        return "activebcards";
      } else {
        return "bcards";
      }
    }
  }

  getExchangeRate() {
    this.userService.getExchangeRate(this.slug).subscribe((result: any) => {
      if (result.success == true) {
        this.exchangeRate = result.data;
        let usaIndex = this.exchangeRate.findIndex(symbol => symbol.currency == "USA");
        let cadIndex = this.exchangeRate.findIndex(symbol => symbol.currency == "CAD");

        if (usaIndex !== -1 && cadIndex !== -1) {
          this.usdRate = (this.exchangeRate[usaIndex].exchangeType[0].exchangerate).toFixed(4);
          this.cadRate = (this.exchangeRate[cadIndex].exchangeType[0].exchangerate).toFixed(4);
        }
      } else if (result.success == false) {
        this.exchangeRate = result.data;
      }
    })
  }

  loginPopUpNow() {
    this.modelService.logoutPop().pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => { });
  }

  getVideoID() {
    var slugs = "my-stocks";
    this.userService.getVideoID(slugs)
      .subscribe((data: any) => {
        data = data.data;
        this.videoID = data.videoID;
        this.videoHeading = data.heading;
      });
  }

  openVideoPop() {
    if (this.videoID !== undefined && this.videoHeading !== undefined) {
      this.modelService.videoPop(this.videoID, this.videoHeading).pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    } else {
      this.videoHeading = "Video is not available on this page.";
      this.videoID = "";
      this.modelService.videoPop(this.videoID, this.videoHeading).pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    }
  }

  sortData(sort: Sort) {
    const data = this.stocksListData.slice();
    if (!sort.active || sort.direction === '') {
      this.stocksListData = data;
      return;
    }
    this.stocksListData = data.sort((a: any, b: any) => {
      let aindex = a.values_data.findIndex(x => x.column_id == sort.active);
      let bindex = b.values_data.findIndex(x => x.column_id == sort.active);
      if (bindex !== -1 && aindex !== -1) {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case (a.values_data[aindex].column_id && b.values_data[bindex].column_id):
            return this.compare(a.values_data[aindex].column_value, b.values_data[bindex].column_value, isAsc);
          default: return 0;
        }
      } else {
        var symbolStringID = "5f3bd442cd95fa3b5815b237";
        const isAsc = sort.direction === 'asc';
        let aindex = a.values_data.findIndex(x => x.column_id == symbolStringID);
        let bindex = b.values_data.findIndex(x => x.column_id == symbolStringID);
        switch (symbolStringID) {
          case (a.values_data[aindex].column_id && b.values_data[bindex].column_id):
            return this.compare(a.values_data[aindex].column_value, b.values_data[bindex].column_value, isAsc);
          default: return 0;
        }
      }
    });

  }

  compare(a: any, b: any, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  checkHeaderCol(columnName: any) {
    var columnArray = [
      { id: 1, text: "Quantity" },
      { id: 2, text: "Market Value" },
      { id: 3, text: " % of Portfolio" },
      { id: 4, text: "Estimated Annual Dividend Income" },
      { id: 5, text: "My Account Type" },
    ];
    let index = columnArray.findIndex(x => x.text == columnName);
    if (index !== -1) {
      return true;
    } else {
      return false;
    }
  }

  transformedLabel = (label: string) => label.toLowerCase().replace(' ', '_');
}
