import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GenerateListService {

  constructor(private httpClient: HttpClient, public router: Router) { }


  uploadFile(files: any, name: any): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/generate-list-undervalued-stocks', { "fileData": files, "name": name }, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      tap(),
      catchError(this.handleError('uploadFile', []))
    );
  }

  exportToCsvMyStocks(csv: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/export-to-csv-my-stocks', {}, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      tap(),
      catchError(this.handleError('exportToCsvMyStocks', []))
    );
  }

  exportToCsvMyWatchlist(csv: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/export-to-csv-my-watchlist', {}, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      tap(),
      catchError(this.handleError('exportToCsvMyWatchlist', []))
    );
  }

  exportToCsvMyAlerts(csv: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/export-to-csv-my-alerts', {}, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      tap(),
      catchError(this.handleError('exportToCsvMyAlerts', []))
    );
  }

  exportToCsvMyEmailList(csv: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/export-to-csv-my-email-list', {}, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      tap(),
      catchError(this.handleError('exportToCsvMyEmailList', []))
    );
  }

  /**
* Handle Http operation that failed.
* Let the app continue.
* @param operation - name of the operation that failed
* @param result - optional value to return as the observable result
*/
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      //console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
