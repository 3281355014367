import { Component, OnInit } from '@angular/core';
import { PagesService } from '../../../services/admin/pages.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-notifiy-heading',
  templateUrl: './notifiy-heading.component.html',
  styleUrls: ['./notifiy-heading.component.css']
})
export class NotifiyHeadingComponent implements OnInit {

  dividend_stocks: string[] = [];
  toRankedUsa: FormGroup;
  runnerUpsUsa: FormGroup;
  toRankedCad: FormGroup;
  runnerUpsCad: FormGroup;
  deepValuedUsa: FormGroup;
  deepValuedCad: FormGroup;
  submitted = false;

  constructor(
    private router: Router,
    private pagesService: PagesService,
    public fb: FormBuilder,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.toRankedUsa = this.fb.group({
      content: ['', [Validators.required]],
    });
    this.runnerUpsUsa = this.fb.group({
      content: ['', [Validators.required]],
    });
    this.toRankedCad = this.fb.group({
      content: ['', [Validators.required]],
    });
    this.runnerUpsCad = this.fb.group({
      content: ['', [Validators.required]],
    });
    this.deepValuedUsa = this.fb.group({
      content: ['', [Validators.required]],
    });
    this.deepValuedCad = this.fb.group({
      content: ['', [Validators.required]],
    });
  }

  get f() {
    return this.toRankedUsa.controls;
  }
  get f1() {
    return this.toRankedCad.controls;
  }
  get f2() {
    return this.runnerUpsUsa.controls;
  }
  get f3() {
    return this.runnerUpsCad.controls;
  }
  get f4() {
    return this.deepValuedUsa.controls;
  }
  get f5() {
    return this.deepValuedCad.controls;
  }

  submitFormTopRankUsa() {
    var data = this.toRankedUsa.controls['content'].value;
    if (data) {
      this.submitted = true;
      this.pagesService.savedNotifyContent(this.toRankedUsa.value, "topRankedUsa")
        .subscribe(data => {
          if (data.success) {
            this.toastr.success(data.message, 'Top Ranked Usa');
            this.toRankedUsa.reset();
          } else {
            this.toastr.success(data.message, 'Top Ranked Usa');
          }
        },
          error => {
            console.log(error);
          });
    } else {
      this.submitted = false;
    }

  }

  submitFormRunerCad() {
    var data = this.runnerUpsCad.controls['content'].value;
    if (data) {
      this.pagesService.savedNotifyContent(this.runnerUpsCad.value, "runnerUpsCad")
        .subscribe(data => {
          if (data.success) {
            this.toastr.success(data.message, 'Runner Ups Cad');
            this.runnerUpsCad.reset();
          } else {
            this.toastr.success(data.message, 'Runner Ups Cad');
          }
        },
          error => {
            console.log(error);
          });
    } else {
      this.submitted = false;
    }
  }

  submitFormTopRankCad() {
    var data = this.toRankedCad.controls['content'].value;
    if (data) {
      this.pagesService.savedNotifyContent(this.toRankedCad.value, "toRankedCad")
        .subscribe(data => {
          if (data.success) {
            this.toastr.success(data.message, 'Top Ranked Cad');
            this.toRankedCad.reset();
          } else {
            this.toastr.success(data.message, 'Top Ranked Cad');
          }
        },
          error => {
            console.log(error);
          });
    } else {
      this.submitted = false;
    }
  }

  submitFormDeepUsa() {
    var data = this.deepValuedUsa.controls['content'].value;
    if (data) {
      this.pagesService.savedNotifyContent(this.deepValuedUsa.value, "deepValuedUsa")
        .subscribe(data => {
          if (data.success) {
            this.toastr.success(data.message, 'Deep Valued Usa');
            this.deepValuedUsa.reset();
          } else {
            this.toastr.success(data.message, 'Deep Valued Usa');
          }
        },
          error => {
            console.log(error);
          });
    } else {
      this.submitted = false;
    }
  }

  submitFormRunerUsa() {
    var data = this.runnerUpsUsa.controls['content'].value;
    if (data) {
      this.pagesService.savedNotifyContent(this.runnerUpsUsa.value, "runnerUpsUsa")
        .subscribe(data => {
          if (data.success) {
            this.toastr.success(data.message, 'Runner Ups Usa');
            this.runnerUpsUsa.reset();
          } else {
            this.toastr.success(data.message, 'Runner Ups Usa');
          }
        },
          error => {
            console.log(error);
          });
    } else {
      this.submitted = false;
    }
  }

  submitFormDeepCad() {
    var data = this.deepValuedCad.controls['content'].value;
    if (data) {
      this.pagesService.savedNotifyContent(this.deepValuedCad.value, "deepValuedCad")
        .subscribe(data => {
          if (data.success) {
            this.toastr.success(data.message, 'Deep Valued Cad');
            this.deepValuedCad.reset();
          } else {
            this.toastr.success(data.message, 'Deep Valued Cad');
          }
        },
          error => {
            console.log(error);
          });
    } else {
      this.submitted = false;
    }
  }

  clearTheMessage(formName) {
    this.pagesService.clearNotifyContent(formName)
      .subscribe(data => {
        if (data.success) {
          this.toastr.success(data.message, 'Notification Heading');
          this.deepValuedCad.reset();
        } else {
          this.toastr.error(data.message, 'Notification Heading');
        }
      },
        error => {
          console.log(error);
        });
  }

}
