import { Component, ViewChild, OnInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { HttpErrorResponse } from '@angular/common/http';
import { PageColumnsService } from '../../../services/admin/page-columns.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-dividend-trap-settings',
  templateUrl: './dividend-trap-settings.component.html',
  styleUrls: ['./dividend-trap-settings.component.css']
})
export class DividendTrapSettingsComponent implements OnInit {

  stockForm: FormGroup;
  fields: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public fb: FormBuilder,
    private toastr: ToastrService,
    private pageColumnsService: PageColumnsService,
  ) { }

  get f() { return this.stockForm.controls; }

  ngOnInit(): void {
    this.stockForm = this.fb.group({
      avg_div_us: '',
      max_div_us: '',
      mul_fac_us: ['', [Validators.required]],
      avg_div_cad: '',
      max_div_cad: '',
      mul_fac_cad: ['', [Validators.required]],
    });
    this.getDividendTrapSettings()
  }

  getDividendTrapSettings() {
    this.pageColumnsService.getDividendTrapSettings()
      .subscribe((res: any) => {
        if (res != null) {
          this.fields = res.data.records;
          this.stockForm.setValue(this.fields)
        } else {
          console.log("");
        }
      },
        (err: HttpErrorResponse) => {
          this.toastr.error('Error in updating Column');
        });
  }

  submitForm() {
    if (this.stockForm.invalid) {
      return;
    }
    console.log(this.stockForm.value);

    this.pageColumnsService.updateDividendTrapSettings(this.stockForm.value)
      .subscribe((data: any) => {
        if (data.success) {
          this.toastr.success(data.message);
        } else {
          this.toastr.error(data.message);
        }
      },
        (err: HttpErrorResponse) => {
          alert("Server Update Job Sub Category Error");
        });

  }

}
