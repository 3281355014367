import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PagesService } from '../../../services/admin/pages.service';
import { ToastrService } from 'ngx-toastr';
import { HttpEventType, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { StocksService } from '../../../services/admin/stocks.service';

@Component({
  selector: 'app-dow30',
  templateUrl: './dow30.component.html',
  styleUrls: ['./dow30.component.css']
})
export class Dow30Component implements OnInit {
  stockForm: FormGroup;
  filterData: object;
  pageList: object[] = [];
  stocksList = [];
  search_item = ''
  slug = "dow30-stocks";
  constructor(
    public fb: FormBuilder,
    private router: Router,
    public pagesService: PagesService,
    private stocksService: StocksService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.stockForm = this.fb.group({
      stockMultiFilterCtrl: ['', [Validators.required]],
      excluded_stocks: ['', [Validators.required]],
    });
    this.getStocks();
    this.getDow30Stocks();
  }
  get f() { return this.stockForm.controls; }

  submitForm() {
    this.stocksService.saveDow30Stocks(this.slug, this.stockForm.value)
      .subscribe((data: any) => {
        if (data.success) {
          this.toastr.success(data.message);
          this.ngOnInit();
        } else {
          this.toastr.error(data.message);
        }
      });
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');

    this.scrollTo(firstElementWithError);
  }

  getDow30Stocks() {
    this.stocksService.getDow30Stocks(this.slug)
      .subscribe((data: any) => {
        if (data.success == true) {
          this.pageList = data.data;
          this.toastr.success(data.message);
        } else {
          this.toastr.error(data.message);
        }
      },
        (err: HttpErrorResponse) => {
          alert("Server Skills Error");
        });
  }

  // pageChanged(event) {
  //   this.current_page = event;
  //   // this.getPages();
  // }

  delete(id: any) {
    if (window.confirm('Are sure you want to delete this Stock ?')) {
      this.stocksService.deleteDow30Stock(id)
        .subscribe((data: any) => {
          if (data.success) {
            this.toastr.success(data.message);
            this.getDow30Stocks();
          }
        },
          (err: HttpErrorResponse) => {
            alert("Refresh the page");
          });
      // }
    }
  }


  private filterStocksMulti(event: Event) {
    console.log(event);

    if (!this.stocksList) {
      return;
    }
    let search = (event.target as HTMLInputElement).value;
    if (search != "") {
      this.getStocks('symbolstring', 'asc', 0, 200, search);
    } else {
      this.getStocks('symbolstring', 'asc', 0, 200, '');
    }
  }

  getStocks(symbolstring = "symbolstring", order = "asc", page = 0, limit = 200, filter = "") {
    this.stocksService.getAllStocks(symbolstring, order, page, limit, filter).subscribe((data: any) => {
      if (data.success == true) {
        this.stocksList = data.data

      } else {
        this.toastr.error(data.message, 'Column');
      }
    },
      (err: HttpErrorResponse) => {
        this.toastr.error('Error in fetching Stocks');
      });
  }


}
