import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-logout-pop',
  templateUrl: './logout-pop.component.html',
  styleUrls: ['./logout-pop.component.css']
})
export class LogoutPopComponent implements OnInit {

  constructor(
    private router: Router,
    private activeModal: NgbActiveModal,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
  }

  redirectNow() {
    // this.userService.logout();
    localStorage.clear();
    this.activeModal.close();
    // this.router.navigate(['/login']);
  }

}
