import { Component, OnInit } from '@angular/core';
import { FaqService } from '../../../services/admin/faq.service';
import {HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-user-faq',
  templateUrl: './user-faq.component.html',
  styleUrls: ['./user-faq.component.scss']
})
export class UserFaqComponent implements OnInit {

  constructor(public faqService: FaqService)
  { }

  faqList = [];
  ngOnInit(): void {
    this.getFaqs();
  }

  getFaqs(){

    this.faqService.getAllFaqs()
    .subscribe((data: any) => {
      if (data.success == true) {
        
        this.faqList = data.data;


        //this.jobMessage = data.message;
      } else {
        //this.showError(data.message, 'skills');
      }
    },
    (err: HttpErrorResponse) => {
      alert("Server Skills Error");
    });
  }

}
