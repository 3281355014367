import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user/user.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { ModalService } from '../../../services/user/modal.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    public userService: UserService,
    private modalService: NgbModal,
    private router: Router,
    private modelService: ModalService,) { }

  status: boolean = false;
  menuStatus: boolean = false;
  course_user: boolean = false;
  messages: string = "";
  closeResult: string;
  user_name: string = "";
  name: string = "";
  videoID: string = "";
  videoHeading: string = "";

  clickEvent() {
    if (this.userService.user_type == "Free_platform") {
      this.status = false;
    } else {
      this.status = !this.status;
    }
  }

  clickMenuEvent() {
    this.menuStatus = !this.menuStatus;
  }

  ngOnInit(): void {
    var token = this.userService.getAccessToken();
    if (token) {
      var decoded = JSON.parse(atob(token.split('.')[1]));
      this.userService.user_type = decoded.type;
      this.userService.user_id = decoded.userId;
      this.userService.user_name = decoded.name;
    } else {
      this.userService.user_type = null;
      this.userService.user_id = null;
    }
    if (this.userService.user_type == "course_platform") {
      this.course_user = true;
    }
    this.name = this.userService.user_name;
    this.user_name = this.name.slice(0, 24);
    this.getVideoID();
  }

  logout() {
    this.userService.logout();
  }

  checkUserTag() {
    if (this.userService.user_type == "course_platform") {
      return "greyOut";
    }
  }

  freeandcourse(content, type: string) {
    if (type == "top_ranked") {
      this.messages = "Top Ranked are stocks that receive a 10 out of 10 grade in the SI Criteria.<br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; This feature is only available to SI Report & Platform members.";
    }
    if (type == "runner_up") {
      this.messages = "Runners-Up are stocks that receive a 9 out of 10 grade in the SI Criteria.<br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; This feature is only available to SI Report & Platform members.";
    }
    if (type == "build_port") {
      this.messages = "This feature is only available to SI Report & Platform members.";
    }
    this.modalService.open(content, { size: 'md', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  upgradeNow() {
    window.location.href = "https://www.simplyinvesting.com/invest";
  }

  redirectHomePage() {
    if (this.userService.user_type == "course_platform") {
      this.router.navigate(['/user/pages/course']);
    } else {
      this.router.navigate(['/user/dashboard']);
    }
  }

  openModal() {
    console.log("modal open");
  }

  getVideoID() {
    var slugs = "overview-video";
    this.userService.getVideoID(slugs)
      .subscribe((data: any) => {
        data = data.data;
        this.videoID = data.videoID;
        this.videoHeading = data.heading;
      });
  }

  openVideoPop() {
    if (this.videoID !== undefined && this.videoHeading !== undefined) {
      this.modelService.videoPop(this.videoID, this.videoHeading).pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    } else {
      this.videoHeading = "Video is not available on this page.";
      this.videoID = "";
      this.modelService.videoPop(this.videoID, this.videoHeading).pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    }
  }

}
