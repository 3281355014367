import { Component, ViewChild, AfterViewInit, OnInit, ElementRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { StocksService } from '../../../services/user/stocks.service';
import { UserService } from '../../../services/user/user.service';
import { FilterService } from '../../../services/user/filter.service';
import { ColumnsService } from '../../../services/admin/columns.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PagesService } from '../../../services/admin/pages.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { ModalService } from '../../../services/user/modal.service';
import { UserSideMenuComponent } from '../user-side-menu/user-side-menu.component';

@Component({
  selector: 'app-my-watchlist',
  templateUrl: './my-watchlist.component.html',
  styleUrls: ['./my-watchlist.component.scss']
})
export class MyWatchlistComponent implements OnInit, AfterViewInit {

  stocksList = [];
  stocksListData: [];
  stockForm: FormGroup;
  selectedstocks = [];
  selected_filter;
  displayedColumns: string[] = [];
  header = {};
  pagesize = environment.pagesize;
  resultsLength = 0;
  falsedata = true;
  company_name = "";
  stock_symbol = "";
  previousIndex: number;
  initialColumns = [];
  columnList = [];
  slug = 'my-watchlist';
  user_name = "";
  videoSource: any;
  closeResult: string;
  content = "";
  status: boolean = false;
  selected_stocks_ids = [];
  selected_watchlist_ids = [];
  selected_alert_ids = [];
  user_type = "";
  free_user = false;
  tooltip = "tooltip";
  allToolTips: any;
  disaplayToolTip: string;
  custome_filter: string = "Default Filter";
  modalReference: any;
  hidden: boolean = false;
  custom_user: string;
  videoID: string = "";
  videoHeading: string = "";

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  @ViewChild(UserSideMenuComponent) userSideMenuCom: UserSideMenuComponent;

  constructor(private stocksService: StocksService,
    private userService: UserService,
    private filterService: FilterService,
    private columnsService: ColumnsService,
    private toastr: ToastrService,
    public fb: FormBuilder,
    private modalService: NgbModal,
    public pagesService: PagesService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private modelService: ModalService,
  ) { }

  searchForm = new FormGroup({
    column: new FormControl('', [Validators.required]),
    column_value: new FormControl('', [Validators.required]),
    sortFilterCtrl: new FormControl(''),
    column_op: new FormControl('', [Validators.required]),
  });


  stockForm1 = new FormGroup({
    symbol: new FormControl('', [Validators.required]),
    quantity: new FormControl('', [Validators.required]),
    stock_id: new FormControl('', [Validators.required]),
    account: new FormControl(''),
  });

  operatorList = [

    { name: 'Equal to and less than', value: '<=' },
    { name: 'Equal to and greater than', value: '>=' },
    { name: 'Less than', value: '<' },
    { name: 'Greater than', value: '>' },
    { name: 'Equal To', value: '==' }
  ];

  ngOnInit(): void {
    var token = this.userService.getAccessToken();
    if (token) {
      var decoded = JSON.parse(atob(token.split('.')[1]));
      this.userService.user_type = decoded.type;
      this.userService.user_id = decoded.userId;
      this.userService.user_name = decoded.name;
      this.userService.user_email = decoded.email;
    } else {
      this.userService.user_type = null;
      this.userService.user_id = null;
    }
    this.user_name = this.userService.user_name;
    var user_type = this.userService.user_type;
    if (this.userService.user_type == "Free_platform") {
      this.router.navigate(['/user/dashboard']);
    } else if (this.userService.user_type == "course_platform" || this.userService.user_type == "Free_platformCourse_platform") {
      this.router.navigate(['/user/pages/course']);
    }
    this.user_type = this.userService.user_type
    if (this.userService.user_type == "US_and_CAD" || this.userService.user_type == "US_onlyCourse_platform" || this.userService.user_type == "CAD_onlyCourse_platform" || this.userService.user_type == "US_and_CADCourse_platform") {
      this.user_type = "US_and_CAD"
    }
    this.stockForm = this.fb.group({
      stocks: ['', [Validators.required]],
      stockMultiFilterCtrl: '',
      sortFilterCtrl: '',
    });
    this.custome_filter = this.filterService.getFilterName(this.slug);
    if (this.custome_filter == null) {
      this.custome_filter = "Default filter";
    }
    this.company_name = environment.compnay_name_column_id;
    this.stock_symbol = environment.symbol_column_id;
    this.selected_filter = this.filterService.getItem(this.slug);
    this.user_name = this.userService.user_name;
    var sideMenuStatus = this.filterService.getSideMenuStaus();
    if (sideMenuStatus !== null) {
      this.status = !sideMenuStatus;
    }
    this.getToolTips();
    this.getColumns();
    this.getPageContent();
    this.getUserSavedWatchListIds();
    this.getUserSavedStocksIds();
    this.getUserSavedAlertIds();
    this.clearStockForm();
    this.getHidden();
    this.getVideoID();
    // var checkSpinner = this.filterService.getCompareStockCountry(this.slug + "watchlist");
    // if (checkSpinner == null) {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 1500);
    // }
    this.getStocks();
  }

  clickEvent() {
    this.status = !this.status;
    this.filterService.setSideMenuStaus(!this.status);
  }

  changeSideIcon() {
    if (this.status == false) {
      return "fa fa-caret-left fa-2x";
    } else if (this.status == true) {
      return "fa fa-caret-right fa-2x";
    }
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.stocksService!.getAllWatchlistStocks(this.searchForm.controls['column'].value, this.searchForm.controls['column_value'].value, this.searchForm.controls['column_op'].value, this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize, this.userService.user_id, this.selected_filter);
        }),
        map(data => {
          return data.data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe((data: any) => {
        this.displayedColumns = ['1', this.company_name, this.stock_symbol];
        this.stocksListData = data.stocks;
        this.resultsLength = data.count;
        var ids_data = data.ids;
        if (this.resultsLength == 0) {
          this.falsedata = true;
        }
        ids_data.sort(function (a, b) {
          return a.column_order - b.column_order;
        });
        if (this.resultsLength > 0) {
          this.falsedata = false;
          for (let v in ids_data) {
            this.displayedColumns.push(ids_data[v]['column_id']);
            this.initialColumns.push(ids_data[v]['column_id']);
            this.header[ids_data[v]['column_id']] = ids_data[v]['column_name'];
          }
        }
        if (this.selected_filter == null) {
          var filter_obj = { 'columns': data.columns };
          this.filterService.setItem(this.slug, filter_obj);
          this.selected_filter = this.filterService.getItem(this.slug);
        }
        // if (this.filterService.getCompareStockCountry(this.slug + "watchlist") != null) {
        //   localStorage.removeItem(this.slug + "watchlist");
        //   this.filterService.setCompareStockCountry(this.slug + "watchlist", true);
        // } else {
        //   this.filterService.setCompareStockCountry(this.slug + "watchlist", true);
        // }
      });
  }

  getStocks(symbolstring = "symbolstring", order = "asc", page = 0, limit = 200, filter = "") {
    if (this.userService.user_type == "US_onlyCourse_platform" || this.userService.user_type == "US_only") {
      this.custom_user = 'US_only';
    } else if (this.userService.user_type == "CAD_onlyCourse_platform" || this.userService.user_type == "CAD_only") {
      this.custom_user = 'CAD_only';
    } else if (this.userService.user_type == "Free_platform" || this.userService.user_type == "Free_platformCourse_platform") {
      this.custom_user = "Free_platform";
    } else if (this.userService.user_type == "US_and_CADCourse_platform" || this.userService.user_type == "US_and_CAD") {
      this.custom_user = 'US_and_CAD';
    }
    this.stocksService.getAllStocks(symbolstring, order, page, limit, filter, this.selectedstocks, this.custom_user).subscribe((data: any) => {
      if (data.success == true) {
        this.stocksList = null;
        this.stocksList = data.data;
        this.stocksList.map(stocks => stocks.symbolstring = ` ${stocks.companyName} (${stocks.symbolstring})`);
      } else if (data.success == false) {
        this.toastr.error(data.message);
      }
    },
      (error) => {
        throw error;
      });
  }

  get f() { return this.stockForm.controls; }
  get s() { return this.searchForm.controls; }
  get st() { return this.stockForm1.controls; }

  submitForm() {
    let checkStock = this.stockForm.controls['stocks'].value;
    if (checkStock !== "" && checkStock !== null) {
      this.selectedstocks = this.stockForm.controls['stocks'].value;
      this.stocksService.saveWatchlistStocks(this.selectedstocks, this.userService.user_id)
        .subscribe((data: any) => {
          if (data.success) {
            this.toastr.success(data.message);
            this.getUserSavedWatchListIds();
            this.ngAfterViewInit();
            this.ngOnInit();
          } else if (data.success == false) {
            this.toastr.error(data.message);
          }
        },
          (error) => {
            throw error;
          });
    } else {
      this.toastr.error("Please Select the Stock Symbol");
    }
  }

  getUserSavedStocks(filter_column = "", filter = "", filter_op = "", sort = '', order = "asc", page = 0, limit = this.pagesize) {
    this.stocksService.getAllWatchlistStocks(filter_column, filter, filter_op, sort, order, page, limit, this.userService.user_id, this.selected_filter).subscribe((data: any) => {
      if (data.success == true) {
        var data = data.data;
        this.displayedColumns = ['1', this.company_name, this.stock_symbol];
        this.stocksListData = data.stocks;
        this.resultsLength = data.count;
        var ids_data = data.ids;
        if (this.resultsLength == 0) {
          this.falsedata = true;
        }
        ids_data.sort(function (a, b) {
          return a.column_order - b.column_order;
        });
        if (this.resultsLength > 0) {
          this.falsedata = false;
          for (let v in ids_data) {
            this.displayedColumns.push(ids_data[v]['column_id']);
            this.initialColumns.push(ids_data[v]['column_id']);
            this.header[ids_data[v]['column_id']] = ids_data[v]['column_name'];
          }
        }
        if (this.selected_filter == null) {
          var filter_obj = { 'columns': data.columns };
          this.filterService.setItem(this.slug, filter_obj);
          this.selected_filter = this.filterService.getItem(this.slug);
        }
      } else if (data.success == false) {
        console.log(data.message);
      }
    },
      (error) => {
        throw error;
      });
  }

  getColCss(column_id, stock_data) {
    let dummy_column_index5 = stock_data.values_data.findIndex(x => x.column_id == "60781c759008e551ad66d40c");
    let dummy_column_index10 = stock_data.values_data.findIndex(x => x.column_id == "60791e199008d379355a36f9");
    let dummy_column_index15 = stock_data.values_data.findIndex(x => x.column_id == "60791e219008d379355a36fb");
    let dummy_column_index20 = stock_data.values_data.findIndex(x => x.column_id == "60791e299008d379355a36fd");
    let dummy_column_dy = stock_data.values_data.findIndex(x => x.column_id == "63c9355b2265834d3487fcf2");

    if (dummy_column_dy != -1) {
      var values = stock_data.values_data[dummy_column_dy]['column_value'];
      for (let val in values) {
        if (values[val] == column_id) {
          return "highlightDY";
        }
      }
    }
    if (dummy_column_index5 != -1) {
      var values = stock_data.values_data[dummy_column_index5]['column_value'];
      for (let val in values) {
        if (values[val] == column_id) {
          return "highlight5";
        }
      }
    }
    if (dummy_column_index10 != -1) {
      var values = stock_data.values_data[dummy_column_index10]['column_value'];
      for (
        let val in values) {
        if (values[val] == column_id) {
          return "highlight10";
        }
      }
    }
    if (dummy_column_index15 != -1) {
      var values = stock_data.values_data[dummy_column_index15]['column_value'];
      for (let val in values) {
        if (values[val] == column_id) {
          return "highlight15";
        }
      }
    }
    if (dummy_column_index20 != -1) {
      var values = stock_data.values_data[dummy_column_index20]['column_value'];
      for (let val in values) {
        if (values[val] == column_id) {
          return "highlight20";
        }
      }
    }
  }

  getColVal(column_id, stock_data) {
    let index = stock_data.values_data.findIndex(x => x.column_id == column_id);

    var val = "";
    if (index != -1) {
      if (typeof stock_data.values_data[index]['column_value'] === "number") {
        if (Number.isInteger(stock_data.values_data[index]['column_value'])) {

          val = stock_data.values_data[index]['column_value'];

          if (this.header[column_id] == "Dividends Paid Since" || this.header[column_id] == "Annual Forward Dividend vs Previous Year, Rule #9") {
            if (this.header[column_id] == "Annual Forward Dividend vs Previous Year, Rule #9") {
              if (stock_data.values_data[index]['column_value'] == 0) {
                return Number(val) + "%";
              } else {
                return Number(val).toFixed(2) + "%";
              }
            }
            return val;
          } else {
            val = val.toLocaleString();
          }
          if (this.header[column_id] == "Consecutive Years of Dividend Increases" || this.header[column_id] == "Consecutive Years of EPS Increases") {
            return val;
          }
          if (this.header[column_id] == "Annual Forward Dividend") {
            return "$" + val;
          }
          if (stock_data.values_data[index]['column_value'] == 0) {
            val = "N/A";
          }
        } else {
          if (this.header[column_id] == "Annual Forward Dividend") {
            return "$" + parseFloat(stock_data.values_data[index]['column_value']).toFixed(2);
          }
          if (this.header[column_id] == "Annual Forward Dividend vs Previous Year, Rule #9") {
            val = parseFloat(stock_data.values_data[index]['column_value']).toFixed(2) + "%";
          } else {
            val = parseFloat(stock_data.values_data[index]['column_value']).toFixed(2);
          }
        }
      } else {
        if (stock_data.values_data[index]['column_value'] == "") {
          return val = "N/A";
        } else {
          val = stock_data.values_data[index]['column_value'];
        }
        if (stock_data.values_data[index]['column_value'] == null) {
          val = "N/A";
        } else {
          val = stock_data.values_data[index]['column_value'];
        }
        // val = stock_data.values_data[index]['column_value'];
      }
    } else {
      val = "N/A";
    }
    var percent_arr = ['%difference between Graham Price vs Share Price',
      'Average Dividend Growth (5-year), Rule #6', 'Average Dividend Growth (10-year), Rule #6',
      'Average Dividend Growth (15-year), Rule #6', 'Average Dividend Growth (20-year), Rule #6',
      'Average Dividend Yield (5-year)', 'Average Dividend Yield (10-year)',
      'Average Dividend Yield (15-year)', 'Average Dividend Yield (20-year)',
      'Average EPS Growth (5-year), Rule #5a', 'Average EPS Growth (10-year), Rule #5a',
      'Average EPS Growth (15-year), Rule #5a',
      'Average EPS Growth (20-year), Rule #5a',
      'Average High Dividend Yield (5-year)',
      'Average High Dividend Yield (10-year)',
      'Average High Dividend Yield (15-year)',
      'Average High Dividend Yield (20-year)',
      'Average Low Dividend Yield (5-year)',
      'Average Low Dividend Yield (10-year)',
      'Average Low Dividend Yield (15-year)',
      'Average Low Dividend Yield (20-year)',
      'Current Dividend Yield',
      'Difference Between Current Dividend Yield and Average Dividend Yield (5-year)',
      'Difference Between Current Dividend Yield and Average Dividend Yield (10-year)',
      'Difference Between Current Dividend Yield and Average Dividend Yield (15-year)',
      'Difference Between Current Dividend Yield and Average Dividend Yield (20-year)',
      'Profit Margin (Total Operating)',
      'Total Debt to Equity Ratio',
      'Payout Ratio, Rule #7',
      'Long-term Debt/Equity Ratio, Rule #8',
      // 'Annual Forward Dividend vs Previous Year, Rule #9'
    ]
    if (percent_arr.indexOf(this.header[column_id]) !== -1) {
      if (val != "N/A" && val != null && val != "") {
        if (val != "0") {
          val = val + "%";
        } else {
          val = "N/A";
        }
      }
    }
    let dummy_column_dy = stock_data.values_data.findIndex(x => x.column_id == "63c9355b2265834d3487fcf2");
      if (this.header[column_id] == "Current Dividend Yield" && dummy_column_dy != -1) {
        var values = stock_data.values_data[dummy_column_dy]['column_value'];
        for (let vale in values) {
          if (values[vale] == column_id) {
            return val + ' <a target="_blank" href="user/pages/dividend-trap">DT</a>';
          }
        }
      }
    return val;
  }

  dropListDropped(event: CdkDragDrop<string[]>) {
    this.displayedColumns.splice(0, 1);
    this.displayedColumns.splice(0, 1);
    this.displayedColumns.splice(0, 1);

    var element_id = this.displayedColumns[event.previousIndex];
    this.displayedColumns.splice(event.previousIndex, 1);
    this.displayedColumns.splice(event.currentIndex, 0, element_id);
    for (let i = 0; i < this.displayedColumns.length; i++) {
      let index = this.selected_filter.columns.findIndex(x => x._id == this.displayedColumns[i]);
      if (index != -1) {
        this.selected_filter.columns[index].column_order = i;
      }
    }
    this.displayedColumns.splice(0, 0, '1');
    this.displayedColumns.splice(1, 0, '2');
    this.displayedColumns.splice(2, 0, '3');

    this.filterService.setItem(this.slug, this.selected_filter);
    this.custome_filter = "Custom filter applied";
    this.checkFilterName();
  }

  removeColumn(index) {
    let sel_index = this.selected_filter.columns.findIndex(x => x._id == this.displayedColumns[index]);
    if (sel_index != -1) {
      this.selected_filter.columns[sel_index].show_column = 0;
    }
    this.displayedColumns.splice(index, 1);
    this.filterService.setItem(this.slug, this.selected_filter);
    this.custome_filter = "Custom filter applied";
    this.checkFilterName();
  }

  private filterSortColumn(event: Event) {
    if (!this.stocksList) {
      return;
    }
    // get the search keyword
    let search = (event.target as HTMLInputElement).value;
    if (search != "") {
      this.columnList = this.columnList.filter(x => x.column_name.toLowerCase().indexOf(search.toLowerCase()) > -1);
    } else {
      this.getColumns();
    }
  }

  private filterStocksMulti(event: Event) {
    if (!this.stocksList) {
      return;
    }
    this.selectedstocks = this.stockForm.controls['stocks'].value;
    // get the search keyword
    let search = (event.target as HTMLInputElement).value;
    if (search != "") {
      this.getStocks('symbolstring', 'asc', 0, 200, search);
    } else {
      this.getStocks('symbolstring', 'asc', 0, 200, '');
    }

  }

  getColumns() {
    this.columnsService.getColumns('column_order', 'asc', 0, 150).subscribe((data: any) => {
      if (data.success == true) {
        this.columnList = data.data
      } else if (data.success == false) {
        console.log(data.message);
      }
    },
      (error) => {
        this.loginPopUpNow();
        throw error;
      });
  }

  searchStocks(event: Event) {
    this.getUserSavedStocks(this.searchForm.controls['column'].value, this.searchForm.controls['column_value'].value, this.searchForm.controls['column_op'].value, '', "asc", 0, this.pagesize);
  }

  scrollLeft() {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
  }

  scrollRight() {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
  }

  open(content) {
    this.modalService.open(content, { size: 'xl', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  opendialog(content, stock) {
    if (this.selected_stocks_ids.indexOf(stock.stock_id) !== -1) {
      this.router.navigate(['/user/my-stocks']);
      // this.addToList("stocks", stock);
    } else {
      let newStockName = (stock.longname) + "  " + ('(' + stock.symbolstring + ')');

      this.stockForm1.controls['symbol'].setValue(newStockName);
      this.stockForm1.controls['stock_id'].setValue(stock.stock_id);
      this.modalReference = this.modalService.open(content, { size: 'md', ariaLabelledBy: 'modal-basic-title' })
      this.modalReference.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  addStocksToList() {
    var selectedstocks = this.stockForm1.controls['stock_id'].value;
    var quantity = this.stockForm1.controls['quantity'].value;
    var account = this.stockForm1.controls['account'].value;
    this.stocksService.saveUserStocks(selectedstocks, quantity, account, this.userService.user_id)
      .subscribe((data: any) => {
        if (data != null) {
          if (this.selected_stocks_ids.indexOf(data.data.stock_id) != -1) {
            this.selected_stocks_ids.splice(this.selected_stocks_ids.indexOf(data.data.stock_id), 1);
          }
        }
        if (data.success) {
          this.toastr.success(data.message);
          this.modalReference.close();
          this.getUserSavedStocksIds();
        } else if (data.success == false) {
          this.toastr.error(data.message);
        }
      },
        (error) => {
          throw error;
        });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getPageContent() {
    this.pagesService.getPageContent(this.slug)
      .subscribe((data: any) => {
        if (data.success) {
          this.content = data.data.content;
          // if (data.data.video_path) {
          //   var path = data.data.video_path;
          //   this.videoSource = path.replace("src/", "");
          // }
        } else if (data.success == false) {
          this.toastr.error(data.message);
        }
      },
        (error) => {
          this.loginPopUpNow();
          throw error;
        });
  }

  toggleVideo(event: any) {
    this.videoplayer.nativeElement.play();
  }

  addToList(type, stock) {

    if (type == 'watchlist') {

      if (this.selected_watchlist_ids.indexOf(stock.stock_id) !== -1) {
        this.removeFromList(type, stock);
      } else {
        this.saveToList(type, stock);
      }
    } else if (type == 'stocks') {
      if (this.selected_stocks_ids.indexOf(stock.stock_id) !== -1) {
        this.removeFromList(type, stock);
      }
      //  else {
      //   this.saveToList(type, stock);
      // }
    }


  }

  saveToList(type, stock) {
    this.stocksService.addToList(type, stock.stock_id, this.userService.user_id).subscribe((data: any) => {
      if (data.success == true) {
        if (type == 'watchlist') {
          this.selected_watchlist_ids.push(stock.stock_id);
          this.toastr.success(data.message);
        } else if (type == 'stocks') {
          this.selected_stocks_ids.push(stock.stock_id);
          this.toastr.success(data.message);
        } else {
          // this.toastr.error(data.message, 'Error in fetching stocks ids');
        }
      } else if (data.success == false) {
        this.toastr.error(data.message);
      }
    },
      (error) => {
        throw error;
      });
  }

  removeFromList(type, stock) {
    this.stocksService.addToList(type, stock.stock_id, this.userService.user_id).subscribe((data: any) => {
      if (type == "watchlist") {
        if (this.selected_watchlist_ids.indexOf(data.data.stock_id) != -1) {
          this.selected_watchlist_ids.splice(this.selected_stocks_ids.indexOf(data.data.stock_id), 1);
        }
      } else if (type == "stocks") {
        if (this.selected_stocks_ids.indexOf(data.data.stock_id) != -1) {
          this.selected_stocks_ids.splice(this.selected_stocks_ids.indexOf(data.data.stock_id), 1);
        }
      }
      if (data.success == true) {
        this.toastr.success(data.message);
        this.getUserSavedWatchListIds();
        this.getUserSavedStocksIds();
        this.ngAfterViewInit();
      } else if (data.success == false) {
        this.toastr.error(data.message);
        this.getUserSavedWatchListIds();
        this.getUserSavedStocksIds();
        this.ngAfterViewInit();
      }
    },
      (error) => {
        throw error;
      });
  }

  getIcon(icon_type: string, stock) {

    if (icon_type == 'watchlist') {

      if (this.selected_watchlist_ids.indexOf(stock.stock_id) !== -1) {
        return "assets/images/marketing-yellow.png";
      } else {
        return "assets/images/marketing.png";
      }
    } else if (icon_type == 'stocks') {
      //console.log(this.selected_stocks_ids.indexOf(stock.stock_id));
      if (this.selected_stocks_ids.indexOf(stock.stock_id) !== -1) {
        return "assets/images/stock-icon2.png";
      } else {
        return "assets/images/growth.png";
      }
    } else if (icon_type == 'alerts') {
      if (this.selected_alert_ids != undefined) {
        if (this.selected_alert_ids.indexOf(stock.stock_id) !== -1) {
          return "assets/images/alerts-blue.png";
        } else {
          return "assets/images/alerts.png";
        }
      } else {
        return "assets/images/alerts.png";
      }
    }


  }

  getTitle(icon_type: string, stock) {
    if (icon_type == 'watchlist') {

      if (this.selected_watchlist_ids.indexOf(stock.stock_id) !== -1) {
        return "Remove From My Watchlist ";
      } else {
        return "Add to My Watchlist";
      }
    } else if (icon_type == 'stocks') {

      if (this.selected_stocks_ids.indexOf(stock.stock_id) !== -1) {
        return "Remove From My Stocks";
      } else {
        return "Add to My Stocks";
      }
    } else if (icon_type == 'alerts') {
      if (this.selected_alert_ids != undefined) {
        if (this.selected_alert_ids.indexOf(stock.stock_id) !== -1) {
          return "View My Alerts";
        } else {
          return "Add to My Alerts";
        }
      } else {
        return "Add to My Alerts";
      }
    }
  }

  getUserSavedWatchListIds() {
    this.stocksService.getAllWatchlistStockIds(this.userService.user_id).subscribe((data: any) => {
      if (data.success == true) {
        this.selected_watchlist_ids = data.data.ids;
      } else if (data.success == false) {
        console.log(data.message);
      }
    },
      (error) => {
        this.loginPopUpNow();
        throw error;
      });
  }

  getUserSavedStocksIds() {
    this.stocksService.getAllSavedStockIds(this.userService.user_id).subscribe((data: any) => {
      if (data.success == true) {
        this.selected_stocks_ids = data.data.ids;
      } else if (data.success == false) {
        console.log(data.message);
      }
    },
      (error) => {
        throw error;
      });
  }

  getUserSavedAlertIds() {
    this.stocksService.getAllUserAlerts(this.userService.user_id).subscribe((data: any) => {
      if (data.success == true) {
        this.selected_alert_ids = data.data;
      } else if (data.success == false) {
        console.log(data.message);
      }
    },
      (error) => {
        throw error;
      });
  }

  getToolTips() {
    this.pagesService.getToolTips(this.tooltip)
      .subscribe(data => {
        if (data.success) {
          this.allToolTips = data.data;
        }
      },
        (error) => {
          this.loginPopUpNow();
          throw error;
        });
  }

  showToolTipData(column_id) {
    this.disaplayToolTip = "";
    let index = this.allToolTips.findIndex(x => x.column_name == column_id);
    if (index != -1) {
      this.disaplayToolTip = this.allToolTips[index].tool_tips;
      return true;
    } else {
      return false;
    }
  }

  checkFilterName() {
    this.filterService.removeFilterName(this.slug);
    this.filterService.setFilterName(this.slug, this.custome_filter);
  }

  clearStockForm() {
    localStorage.removeItem('active-form');
    localStorage.removeItem("search-stock2");
    localStorage.removeItem("search-stock-con");
    localStorage.removeItem("checkMarket");
  }

  openFilterpop() {
    if (this.userService.user_type == "Free_platform" || this.userService.user_type == "course_platform" || this.userService.user_type == "Free_platformCourse_platform") {
      this.modelService.confirmMessage().pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    } else {
      this.modelService.confirm().pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    }
  }

  restore() {
    if (this.userService.user_type == "course_platform") {
      this.modelService.confirmMessage().pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    } else {
      this.userSideMenuCom.restore();
    }
  }

  columnDefination() {
    if (this.userService.user_type == "course_platform") {
      this.modelService.confirmMessage().pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    } else {
      this.router.navigate([]).then(result => {
        window.open('/user/pages/column-definitions', '_blank');
      });
    }
  }

  clickHidde() {
    this.hidden = !this.hidden;
    this.userService.saveDetailSection(this.slug, this.hidden, this.userService.user_id)
      .subscribe((data: any) => { })
  }

  getHidden() {
    this.userService.getDetailSection(this.slug, this.userService.user_id)
      .subscribe((data: any) => {
        this.hidden = data.data;
      })
  }

  checkHidden() {
    if (this.hidden == false) {
      return "fas fa-caret-up fa-2x";
    } else if (this.hidden == true) {
      return "fas fa-caret-down fa-2x";
    }
  }

  onChangeSearch(event: Event) {
    let search = (event.target as HTMLInputElement).value;
    var symbolstring = "symbolstring", order = "asc", page = 0, limit = 200, filter = search;
    this.selectedstocks = [];
    var userType = "";
    if (this.userService.user_type == "US_onlyCourse_platform" || this.userService.user_type == "US_only") {
      this.custom_user = 'US_only';
    } else if (this.userService.user_type == "CAD_onlyCourse_platform" || this.userService.user_type == "CAD_only") {
      this.custom_user = 'CAD_only';
    } else if (this.userService.user_type == "Free_platform" || this.userService.user_type == "Free_platformCourse_platform") {
      this.custom_user = "Free_platform";
    } else if (this.userService.user_type == "US_and_CADCourse_platform" || this.userService.user_type == "US_and_CAD") {
      this.custom_user = 'US_and_CAD';
    }
    this.stocksService.getAllStocks(symbolstring, order, page, limit, filter, this.selectedstocks, this.custom_user).subscribe((data: any) => {
      if (data.success == true) {
        this.stocksList = data.data;
        this.stocksList.map(stocks => stocks.symbolstring = ` ${stocks.companyName} (${stocks.symbolstring})`);
      }
    },
      (err: HttpErrorResponse) => {
        this.toastr.error('Error in fetching Stocks');
      });
  }

  navigetToalert(data: any) {
    this.router.navigate([]).then((result) => {
      let encoID = btoa(data.symbolstring);
      window.open('/user/my-alert/' + encoID, '_blank');
    });
  }

  loginPopUpNow() {
    this.modelService.logoutPop().pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => { });
  }

  getVideoID() {
    var slugs = "my-watchlist";
    this.userService.getVideoID(slugs)
      .subscribe((data: any) => {
        data = data.data;
        this.videoID = data.videoID;
        this.videoHeading = data.heading;
      });
  }

  openVideoPop() {
    if (this.videoID !== undefined && this.videoHeading !== undefined) {
      this.modelService.videoPop(this.videoID, this.videoHeading).pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    } else {
      this.videoHeading = "Video is not available on this page.";
      this.videoID = "";
      this.modelService.videoPop(this.videoID, this.videoHeading).pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    }
  }

}

