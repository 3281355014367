import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-adv-matching-stock',
  templateUrl: './adv-matching-stock.component.html',
  styleUrls: ['./adv-matching-stock.component.scss']
})
export class AdvMatchingStockComponent implements OnInit {

  constructor() { }
  
  status: boolean = false;
  
  clickEvent(){
      this.status = !this.status;       
  }
  ngOnInit(): void {
  }

}
