import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { PageColumnsService } from '../../../services/admin/page-columns.service';
import { ToastrService } from 'ngx-toastr';

enum Group5 { PUSH, RUN, NONE };
// enum Group7 { PUSH, RUN, NONE };
enum Group8 { PUSH, RUN, NONE };
enum Group22 { PUSH, RUN, NONE };
enum Group23 { PUSH, RUN, NONE };

@Component({
  selector: 'app-corn-job',
  templateUrl: './corn-job.component.html',
  styleUrls: ['./corn-job.component.css']
})
export class CornJobComponent implements OnInit {

  constructor(private pageColumnsService: PageColumnsService,
    private toastr: ToastrService,
  ) { }
  check_box_group5 = Group5;
  currentlyGroup5: Group5;

  // check_box_group7 = Group7;
  // currentlyGroup7: Group7;

  check_box_group8 = Group8;
  currentlyGroup8: Group8;

  check_box_group22 = Group22;
  currentlyGroup22: Group22;

  check_box_group23 = Group23;
  currentlyGroup23: Group23;

  ngOnInit(): void {
    this.getCronJobsStatus();
  }


  selectGroup5(targetType: Group5) {
    if (this.currentlyGroup5 === targetType) {
      this.currentlyGroup5 = Group5.NONE;
      return;
    }
    var cronName = "Group5";
    this.currentlyGroup5 = targetType;
    this.pageColumnsService.checkFunction(this.currentlyGroup5, cronName)
      .subscribe((data: any) => {
        if (data.success) {
          this.toastr.success(data.message);
        } else {
          this.toastr.error(data.message);
        }
      })
  }

  // selectGroup7(targetType: Group7) {
  //   if (this.currentlyGroup7 === targetType) {
  //     this.currentlyGroup7 = Group7.NONE;
  //     return;
  //   }
  //   var cronName = "Group7";
  //   this.currentlyGroup7 = targetType;
  //   this.pageColumnsService.checkFunction(this.currentlyGroup7, cronName)
  //     .subscribe((data: any) => {
  //       if (data.success) {
  //         this.toastr.success(data.message);
  //       } else {
  //         this.toastr.error(data.message);
  //       }
  //     })
  // }

  selectGroup8(targetType: Group8) {
    if (this.currentlyGroup8 === targetType) {
      this.currentlyGroup8 = Group8.NONE;
      return;
    }
    this.currentlyGroup8 = targetType;
    var cronName = "Group8";
    this.pageColumnsService.checkFunction(this.currentlyGroup8, cronName)
      .subscribe((data: any) => {
        if (data.success) {
          this.toastr.success(data.message);
        } else {
          this.toastr.error(data.message);
        }
      })
  }

  selectGroup22(targetType: Group22) {
    if (this.currentlyGroup22 === targetType) {
      this.currentlyGroup22 = Group22.NONE;
      return;
    }
    this.currentlyGroup22 = targetType;
    var cronName = "Group22";
    this.pageColumnsService.checkFunction(this.currentlyGroup22, cronName)
      .subscribe((data: any) => {
        if (data.success) {
          this.toastr.success(data.message);
        } else {
          this.toastr.error(data.message);
        }
      })
  }

  selectGroup23(targetType: Group23) {
    if (this.currentlyGroup23 === targetType) {
      this.currentlyGroup23 = Group23.NONE;
      return;
    }
    this.currentlyGroup23 = targetType;
    var cronName = "Group23";
    this.pageColumnsService.checkFunction(this.currentlyGroup23, cronName)
      .subscribe((data: any) => {
        if (data.success) {
          this.toastr.success(data.message);
        } else {
          this.toastr.error(data.message);
        }
      })
  }

  getCronJobsStatus() {
    var temp = "cron_job";
    this.pageColumnsService.getCronJobsStatus(temp)
      .subscribe((data: any) => {
        if (data.success) {
          for (let i = 0; i < (data.data).length; i++) {
            if (data.data[i].cron_name == "Group5") {
              if (data.data[i].cron_status) {
                this.currentlyGroup5 = Group5.RUN;
              } else {
                this.currentlyGroup5 = Group5.PUSH;
              }
            }

            // if (data.data[i].cron_name == "Group7") {
            //   if (data.data[i].cron_status) {
            //     this.currentlyGroup7 = Group7.RUN;
            //   } else {
            //     this.currentlyGroup7 = Group7.PUSH;
            //   }
            // }

            if (data.data[i].cron_name == "Group8") {
              if (data.data[i].cron_status) {
                this.currentlyGroup8 = Group8.RUN;
              } else {
                this.currentlyGroup8 = Group8.PUSH;
              }
            }

            if (data.data[i].cron_name == "Group22") {
              if (data.data[i].cron_status) {
                this.currentlyGroup22 = Group22.RUN;
              } else {
                this.currentlyGroup22 = Group22.PUSH;
              }
            }

            if (data.data[i].cron_name == "Group23") {
              if (data.data[i].cron_status) {
                this.currentlyGroup23 = Group23.RUN;
              } else {
                this.currentlyGroup23 = Group23.PUSH;
              }
            }
          }
        } else {
          this.toastr.error(data.message);
        }
      })
  }

}
