import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class StocksService {

  constructor(private httpClient: HttpClient, public router: Router) { }

  getAllStocks(sort: string, order: string, page: number, pagesize: number, filter: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/all-stocks' + '/' + sort + '/' + order + '/' + page + '/' + pagesize, { 'term': filter })
      .pipe(
        tap(),
        catchError(this.handleError('getFilterComponentList', []))
      );
  }

  getStocksHistory(filter: string, sort: string, order: string, page: number, pagesize: number): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/get-stocks-history', { 'sort': sort, 'order': order, 'page': page, 'pagesize': pagesize, 'filter': filter })
      .pipe(
        tap(),
        catchError(this.handleError('updatePageDetails', []))
      );
  }

  getStocksByCriteria(filter: string, sort: string, order: string, page: number, pagesize: number): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/get-stocks-list', { 'sort': sort, 'order': order, 'page': page, 'pagesize': pagesize, 'filter': filter })
      .pipe(
        tap(),
        catchError(this.handleError('updatePageDetails', []))
      );
  }

  updateFreeStock(symbolstring: string, checked: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/update-free-stock ', { 'checked': checked, 'symbolstring': symbolstring })
      .pipe(
        tap(),
        catchError(this.handleError('updateJobCategory', []))
      );
  }

  getYearHistory(symbolstring: string, slug: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/get-year-history ', { 'symbolstring': symbolstring, 'slug': slug })
      .pipe(
        tap(),
        catchError(this.handleError('updateJobCategory', []))
      );
  }

  editStockHistory(symbolstring: string, slug: string, formdata: object): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/edit-year-history ', { 'symbolstring': symbolstring, 'slug': slug, 'formdata': formdata })
      .pipe(
        tap(),
        catchError(this.handleError('updateJobCategory', []))
      );
  }

  saveFreeUserStocks(slug: string, formData: object): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/edit-free-user-stocks', { 'slug': slug, 'formdata': formData })
      .pipe(
        tap(),
        catchError(this.handleError('updateJobCategory', []))
      );
  }

  saveTsx60Stocks(slug: string, formData: object): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/edit-tsx60-stocks', { 'slug': slug, 'formdata': formData })
      .pipe(
        tap(),
        catchError(this.handleError('updateJobCategory', []))
      );
  }

  saveDow30Stocks(slug: string, formData: object): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/edit-dow30-stocks', { 'slug': slug, 'formdata': formData })
      .pipe(
        tap(),
        catchError(this.handleError('updateJobCategory', []))
      );
  }

  getFreeUserStocks(slug: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/get-free-user-stocks', { 'slug': slug })
      .pipe(
        tap(),
        catchError(this.handleError('updateJobCategory', []))
      );
  }

  getDow30Stocks(slug: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/get-dow30-stocks', { 'slug': slug })
      .pipe(
        tap(),
        catchError(this.handleError('updateJobCategory', []))
      );
  }

  getTsx60Stocks(slug: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/get-tsx60-stocks', { 'slug': slug })
      .pipe(
        tap(),
        catchError(this.handleError('updateJobCategory', []))
      );
  }

  deleteFreeUserStock(id: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/delete-free-user-stocks', { 'id': id })
      .pipe(
        tap(),
        catchError(this.handleError('updateJobCategory', []))
      );
  }
  deleteTsx60Stock(id: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/delete-tsx60-stocks', { 'id': id })
      .pipe(
        tap(),
        catchError(this.handleError('updateJobCategory', []))
      );
  }

  deleteDow30Stock(id: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/delete-dow30-stocks', { 'id': id })
      .pipe(
        tap(),
        catchError(this.handleError('updateJobCategory', []))
      );
  }

  exportStocksCsv(slug: string, selectedColumns: object, exchange: object): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/generatStockCSV-stocks', { 'slug': slug, 'selectedColumns': selectedColumns, 'exchange': exchange })
      .pipe(
        tap(),
        catchError(this.handleError('updateJobCategory', []))
      );
  }

  exportStocksHistoryCsv(slug: string, selectedColumns: object, exchange: object): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/generatStockHistoryCSV-stocks', { 'slug': slug, 'selectedColumns': selectedColumns, 'exchange': exchange })
      .pipe(
        tap(),
        catchError(this.handleError('updateJobCategory', []))
      );
  }

  deleteExportFile(slug: string, fileType: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/deleteExportStocks', { 'slug': slug, "fileType": fileType })
      .pipe(
        tap(),
        catchError(this.handleError('deleteExportFile', []))
      );
  }

  deleteDividendStock(stockId: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/delete-dividend-stock', { 'stockId': stockId })
      .pipe(
        tap(),
        catchError(this.handleError('access_log', []))
      );
  }

  saveNewDividendStock(symbol: string, exchange: string, currency: string, frequency: string, payment_date: string, record_date: string, amount: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/saveNewDividendStocks', { "symbol": symbol, "exchange": exchange, "currency": currency, "frequency": frequency, "payment_date": payment_date, "record_date": record_date, "amount": amount })
      .pipe(
        tap(),
        catchError(this.handleError('savenewdividend', []))
      );
  }

  getAllStocksList(filter: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/get-allstocks-list', { 'filter': filter })
      .pipe(
        tap(),
        catchError(this.handleError('updatePageDetails', []))
      );
  }

  editStockInfo(formData: object): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/update-stocks-info', { 'formData': formData })
      .pipe(
        tap(),
        catchError(this.handleError('updatePageDetails', []))
      );
  }

  addNewStock(formData: object): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/add-new-stock', { 'formData': formData })
      .pipe(
        tap(),
        catchError(this.handleError('updatePageDetails', []))
      );
  }

  saveStockHistoryData(symboldetails: any, formdata: any, divid: any, low: any, high: any, eps: any): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/saveStock-history-data', { 'symboldetails': symboldetails, 'formdata': formdata, 'divid': divid, 'low': low, 'high': high, 'eps': eps })
      .pipe(
        tap(),
        catchError(this.handleError('saveStockHistoryData', []))
      );
  }
  /**
* Handle Http operation that failed.
* Let the app continue.
* @param operation - name of the operation that failed
* @param result - optional value to return as the observable result
*/
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      //console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */

}
