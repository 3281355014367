import {Component, ViewChild, AfterViewInit, OnInit} from '@angular/core';
import { MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router , ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { PageColumnsService } from '../../../services/admin/page-columns.service';
import { ColumnsService } from '../../../services/admin/columns.service';
import { StocksService } from '../../../services/admin/stocks.service';
import { ToastrService } from 'ngx-toastr';
import {ReplaySubject,Subject,merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-runner-ups-list',
  templateUrl: './runner-ups-list.component.html',
  styleUrls: ['./runner-ups-list.component.css']
})
export class RunnerUpsListComponent implements OnInit, AfterViewInit {


  slug = "runner-ups";
  market = 'usa';
  show_ids = [];
  stocksList: object[] = [];
  displayedColumns: string[] = [];
  header = {};
  pagesize = 2;
  resultsLength = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor( public fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private pageColumnsService: PageColumnsService,
    private toastr: ToastrService)
    {}

  ngOnInit(): void {

    this.getTopRankedList()
    this.market=this.route.snapshot.paramMap.get("slug");
  }

  ngAfterViewInit() {

    
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
    .pipe(
      startWith({}),
      switchMap(() => {
        return  this.pageColumnsService!.getStocksList(this.slug,this.market,this.show_ids,
          this.sort.active, this.sort.direction, this.paginator.pageIndex,this.paginator.pageSize);
      }),
      map(data => {

       
        //this.isLoadingResults = false;
        //this.isRateLimitReached = false;
        //this.resultsLength = data.count;

        return data.data;
      }),
      catchError(() => {
        //this.isLoadingResults = false;
        // Catch if the GitHub API has reached its rate limit. Return empty data.
        //this.isRateLimitReached = true;
        return observableOf([]);
      })
    ).subscribe((data: any) => {

        this.displayedColumns = [];

        this.stocksList = data.stocks;
        this.resultsLength = data.count;
        var ids_data = data.ids;
        ids_data.sort((a,b) => console.log(a));
        ids_data.sort(function (a, b) {
          return a.column_order - b.column_order;
        });
        for( let v in ids_data){
            this.displayedColumns.push(ids_data[v]['column_id']);
            this.header[ids_data[v]['column_id']] = ids_data[v]['column_name'];
           
        }
      });
  }


  getTopRankedList() {
    

    
    this.pageColumnsService.getStocksList(this.slug,this.market,this.show_ids,"","",0,this.pagesize)
      .subscribe((data: any) => {
        if (data.success) {
          this.toastr.success(data.message, 'Page');
          this.displayedColumns = [];

          
          this.stocksList = data.data.stocks;
          this.resultsLength = data.data.count;
          var ids_data = data.data.ids;
          ids_data.sort((a,b) => console.log(a));
          ids_data.sort(function (a, b) {
            return a.column_order - b.column_order;
          });
          for( let v in ids_data){
              this.displayedColumns.push(ids_data[v]['column_id']);
              this.header[ids_data[v]['column_id']] = ids_data[v]['column_name'];
             
          }
        } else {
          this.toastr.error(data.message, 'Page');
        }
      },
      (err: HttpErrorResponse) => {
        alert("Server Update Job Sub Category Error");
      });
  }


  getColVal(column_id,stock_data){
    let index= stock_data.values_data.findIndex(x => x.column_id == column_id);
    var val ="";
    if(index != -1){
      if(isNaN(parseFloat(stock_data.values_data[index]['column_value']))){
        val = stock_data.values_data[index]['column_value'];
      }else{
        val = parseFloat(stock_data.values_data[index]['column_value']).toFixed(2);
      }
      
    }else{
      val = "N/A"
    }
    return val;

  }

}
