import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ColumnsService } from '../../../services/admin/columns.service';
import { StocksService } from '../../../services/user/stocks.service';
import { UserService } from '../../../services/user/user.service';
import { PageColumnsService } from '../../../services/user/page-columns.service';
import { FilterService } from '../../../services/user/filter.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { PagesService } from '../../../services/admin/pages.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { ModalService } from '../../../services/user/modal.service';
import { UserSideMenuComponent } from '../user-side-menu/user-side-menu.component';
import { Sort } from '@angular/material/sort';


@Component({
  selector: 'app-my-alert',
  templateUrl: './my-alert.component.html',
  styleUrls: ['./my-alert.component.css']
})
export class MyAlertComponent implements OnInit {
  slug = "my-alert";
  market = 'usa';
  type = 'dividend';
  stocksList = [];
  header = {};
  pagesize = environment.pagesize;
  resultsLength = 0;
  company_name = "";
  stock_symbol = "";
  previousIndex: number;
  initialColumns = [];
  columnList = [];
  selected_filter;
  selected_exchange = 'us';
  us_stocks = false;
  canada_stocks = false;
  selected_stocks_ids = [];
  selected_watchlist_ids = [];
  selected_alert_ids = [];
  stockForm: FormGroup;
  alert_list: any = [];
  stocksList2 = [];
  selectedstocks = [];
  addMoreStatus: boolean = true;
  removeMoreStatus: boolean = false;
  stockConditionForm: FormGroup;
  user_name = "";
  videoSource: any;
  closeResult: string;
  user_type: string;
  content = "";
  displayedColumns: string[] = ['Actions', 'Symbol', 'Condition'];
  status: boolean = false;
  custome_filter: string = "Default Filter";
  hidden: boolean = false;
  decoId = "";
  custom_user: string;
  modalReference: any;
  videoID: string = "";
  videoHeading: string = "";
  buyback: boolean = false;
  undervalued: boolean = false;
  optionCheck: boolean = false;

  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  @ViewChild(UserSideMenuComponent) userSideMenuCom: UserSideMenuComponent;

  constructor(private columnsService: ColumnsService,
    private toastr: ToastrService,
    private stocksService: StocksService,
    private userService: UserService,
    private pageColumnsService: PageColumnsService,
    private filterService: FilterService,
    public pagesService: PagesService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private modelService: ModalService,
    private router: Router,
    private route: ActivatedRoute) {
    this.stockConditionForm = this.fb.group({
      stock_id: ['', [Validators.required]],
      conditions: this.fb.array([]),
    });
  }

  public entityFilterCtrl: FormControl = new FormControl();

  ngOnInit(): void {
    var token = this.userService.getAccessToken();
    if (token) {
      var decoded = JSON.parse(atob(token.split('.')[1]));
      this.userService.user_type = decoded.type;
      this.userService.user_id = decoded.userId;
      this.userService.user_name = decoded.name;
      this.userService.user_email = decoded.email;
    } else {
      this.userService.user_type = null;
      this.userService.user_id = null;
    }
    this.user_name = this.userService.user_name;
    if (this.userService.user_type == "course_platform" || this.userService.user_type == "Free_platformCourse_platform") {
      this.router.navigate(['/user/pages/course']);
    } else if (this.userService.user_type == "Free_platform") {
      this.router.navigate(['/user/dashboard']);
    }
    this.user_type = this.userService.user_type;
    this.custome_filter = this.filterService.getFilterName(this.slug);
    if (this.custome_filter == null) {
      this.custome_filter = "Default filter";
    }
    this.stockForm = this.fb.group({
      stocks: ['', [Validators.required]],
      stockMultiFilterCtrl: '',
      sortFilterCtrl: '',
    });
    if (this.route.snapshot.paramMap.get("symbols")) {
      this.decoId = atob(this.route.snapshot.paramMap.get("symbols"));
    }
    var sideMenuStatus = this.filterService.getSideMenuStaus();
    if (sideMenuStatus !== null) {
      this.status = !sideMenuStatus;
    }
    this.getColumns();
    this.getUserAlerts();
    this.getPageContent();
    this.getUserSavedWatchListIds();
    this.getUserSavedStocksIds();
    this.getUserSavedAlertIds();
    this.clearStockForm();
    this.getHidden();
    this.getStocks();
    this.getVideoID();
    this.conditions().push(this.newQuantity());
    // var checkSpinner = this.filterService.getCompareStockCountry(this.slug + "alerts");
    // if (checkSpinner == null) {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 1500);
    // }
    // this.conditions().push(this.newQuantity());
    //this.conditions().push(this.newQuantity());
  }

  get s() { return this.stockConditionForm.controls; }
  get st() { return this.stockForm1.controls; }

  //Add more
  conditions(): FormArray {
    return this.stockConditionForm.get("conditions") as FormArray
  }

  newQuantity(): FormGroup {
    return this.fb.group({
      column_name: ['', [Validators.required]],
      column_op: ['', [Validators.required]],
      column_value: ['', [Validators.required]],
    })
  }

  addQuantity() {
    this.getColumns();
    this.entityFilterCtrl = new FormControl();
    this.conditions().push(this.newQuantity());
    if (this.conditions().length > 9) {
      this.addMoreStatus = false
    } else {
      this.addMoreStatus = true
      this.removeMoreStatus = true
    }
  }

  removeQuantity(i: number) {
    this.conditions().removeAt(i);
    if (this.conditions().length > 11) {
      this.addMoreStatus = false
    } else {
      this.addMoreStatus = true
      if (this.conditions().length < 2) {
        this.removeMoreStatus = false
      } else {
        this.removeMoreStatus = true
      }
    }
  }


  onSubmit() {
    if (this.stockConditionForm.controls['stock_id'].value == "" || this.stockConditionForm.controls.conditions.status == "INVALID") {
      if (this.stockConditionForm.controls['stock_id'].value == "" && this.stockConditionForm.controls.conditions.status == "INVALID") {
        this.toastr.error("Please set stock.");
        this.toastr.error("Please fill in all fields, and ensure values are correct, invalid characters are not permitted.");
        return false;
      }
      else if (this.stockConditionForm.controls['stock_id'].value == "") {
        this.toastr.error("Please set stock.");
        return false;

      } else {
        this.toastr.error("Please fill in all fields, and ensure values are correct, invalid characters are not permitted.");
        return false;
      }
    }
    let stockConstions = this.stockConditionForm.value;
    stockConstions.user_id = this.userService.user_id;

    this.userService.saveUserStockConditions(stockConstions, this.userService.user_id, this.userService.user_email)
      .subscribe((data: any) => {
        if (data.success) {
          this.toastr.success(data.message);
          this.getUserAlerts();
          this.getUserSavedAlertIds();
        } else if (data.success == false) {
          this.toastr.error(data.message);
        }
      },
        (error) => {
          throw error;
        });
  }
  //End add more

  searchForm = new FormGroup({
    column: new FormControl('', [Validators.required]),
    column_value: new FormControl('', [Validators.required]),
    sortFilterCtrl: new FormControl(''),
    //us_stocks :  new FormControl(true),
    canada_stocks: new FormControl(''),
    column_op: new FormControl('', [Validators.required]),
  });

  stockForm1 = new FormGroup({
    symbol: new FormControl('', [Validators.required]),
    quantity: new FormControl('', [Validators.required]),
    stock_id: new FormControl('', [Validators.required]),
    account: new FormControl(''),
  });

  operatorList = [
    { name: 'Less than or equal to', value: '<=' },
    { name: 'Greater than or equal to', value: '>=' },
    { name: 'Less than', value: '<' },
    { name: 'Greater than', value: '>' },
    { name: 'Equal to', value: '==' },
    { name: 'Not equal to', value: '!=' }
  ];

  private filterSortColumn(event: Event) {
    if (!this.stocksList) {
      return;
    }
    // get the search keyword
    let search = (event.target as HTMLInputElement).value;
    if (search != "") {
      this.columnList = this.columnList.filter(x => x.column_name.toLowerCase().indexOf(search.toLowerCase()) > -1);
      if (this.columnList.length == 0) {
        this.getColumns();
      }
    } else {
      this.getColumns();
    }
  }

  getColumns() {
    this.columnsService.getColumns('column_order', 'asc', 0, 150).subscribe((data: any) => {
      if (data.success == true) {
        var ids_data = data.data;
        ids_data.sort(function (a, b) {
          return (a.column_name).localeCompare(b.column_name); //using String.prototype.localCompare()
        });
        this.columnList = ids_data;
      } else {
        this.getColumns();
        return;
      }
    },
      (error) => {
        this.loginPopUpNow();
        throw error;
      });
  }

  resetColumnList() {
    this.getColumns();
  }

  checkColumnsIds(quantity: any) {
    if (quantity == "5f3bd442cd95fa3b5815b226") {
      this.buyback = true;
      this.undervalued = false;
      return true;
    } else if (quantity == "5f3bd442cd95fa3b5815b239" || quantity == "5f3bd442cd95fa3b5815b23a" || quantity == "5f3bd442cd95fa3b5815b23b" || quantity == "5f3bd442cd95fa3b5815b238") {
      this.undervalued = true;
      this.buyback = false;
      return true;
    } else {
      return false;
    }
  }

  checkOperator(quantity: any, opratorName: any) {
    if (quantity == "5f3bd442cd95fa3b5815b226") {
      if (opratorName == "Equal to" || opratorName == "Not equal to") {
        return true;
      } else {
        return false;
      }
    } else if (quantity == "5f3bd442cd95fa3b5815b239" || quantity == "5f3bd442cd95fa3b5815b23a" || quantity == "5f3bd442cd95fa3b5815b23b" || quantity == "5f3bd442cd95fa3b5815b238") {
      if (opratorName == "Equal to" || opratorName == "Not equal to") {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  searchStocks(event: Event) {
    //if(this.column.value != '' && this.column_value.value != '' && this.column_op.value != '')
    //

    // this.getValuedList();

  }

  private filterStocksMulti(event: Event) {
    if (!this.stocksList) {
      return;
    }
    this.selectedstocks = this.stockForm.controls['stocks'].value;
    // get the search keyword
    let search = (event.target as HTMLInputElement).value;
    if (search != "") {
      this.getStocks('symbolstring', 'asc', 0, 200, search);
    } else {
      this.getStocks('symbolstring', 'asc', 0, 200, '');
    }

  }

  getStocks(symbolstring = "symbolstring", order = "asc", page = 0, limit = 200, filter = "") {
    if (this.userService.user_type == "US_onlyCourse_platform" || this.userService.user_type == "US_only") {
      this.custom_user = 'US_only';
    } else if (this.userService.user_type == "CAD_onlyCourse_platform" || this.userService.user_type == "CAD_only") {
      this.custom_user = 'CAD_only';
    } else if (this.userService.user_type == "Free_platform" || this.userService.user_type == "Free_platformCourse_platform") {
      this.custom_user = "Free_platform";
    } else if (this.userService.user_type == "US_and_CADCourse_platform" || this.userService.user_type == "US_and_CAD") {
      this.custom_user = 'US_and_CAD';
    }
    if (this.decoId !== null) {
      filter = this.decoId;
    }
    this.stocksService.getAllStocks(symbolstring, order, page, limit, filter, this.selectedstocks, this.custom_user).subscribe((data: any) => {
      if (data.success == true) {
        this.stocksList = data.data;
        this.stocksList.map(stocks => stocks.symbolstring = ` ${stocks.companyName} (${stocks.symbolstring})`);
        if (this.decoId !== null) {
          let index = this.stocksList.findIndex(stocks => stocks.symbol == this.decoId);
          if (index != -1) {
            this.stockConditionForm.controls['stock_id'].setValue(this.stocksList[index].stockID);
          }
        }
      } else if (data.success == false) {
        this.toastr.error(data.message, 'Column');
      }
    },
      (error) => {
        throw error;
      });
  }

  getUserAlerts() {
    //get_user_alerts
    this.userService.getAllUserAlerts(this.userService.user_id).subscribe((data: any) => {
      if (data.success == true) {
        this.alert_list = data.data;
        // if (this.filterService.getCompareStockCountry(this.slug + "alerts") != null) {
        //   localStorage.removeItem(this.slug + "alerts");
        //   this.filterService.setCompareStockCountry(this.slug + "alerts", true);
        // } else {
        //   this.filterService.setCompareStockCountry(this.slug + "alerts", true);
        // }
      } else if (data.success == false) {
        console.log(data.message);
      }
    },
      (error) => {
        this.loginPopUpNow();
        throw error;
      });
  }

  getSymbol(stock_id) {
    return stock_id;
  }

  getColVal(column, alert) {

    if (column == "Symbol") {
      return alert._id;
    }
    if (column == "Condition") {
      var string = alert.string.replace(/AND\s*$/, " ");
      let search = "AND";

      if (search === undefined) {
        search = 'highlight';
      }

      if (string != undefined) {
        const pattern = search
          .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&")
          .split(' ')
          .filter(t => t.length > 0)
          .join('|');
        const regex = new RegExp(pattern, 'gi');
        return search ? string.replace(regex, match => "<b>" + match + "</b>") : string;
      }
    }
  }

  removeAlert(id) {
    this.userService.deleteAlert(this.userService.user_id, id).subscribe((data: any) => {
      if (data.success == true) {
        this.toastr.success(data.message);
        this.getUserAlerts();
        this.getUserSavedAlertIds();
      } else if (data.success == false) {
        console.log(data.message);
      }
    },
      (error) => {
        throw error;
      });
  }

  clickEvent() {
    this.status = !this.status;
    this.filterService.setSideMenuStaus(!this.status);
  }

  changeSideIcon() {
    if (this.status == false) {
      return "fa fa-caret-left fa-2x";
    } else if (this.status == true) {
      return "fa fa-caret-right fa-2x";
    }
  }

  getPageContent() {
    this.pagesService.getPageContent(this.slug)
      .subscribe((data: any) => {
        if (data.success) {
          this.content = data.data.content;
          // if (data.data.video_path) {
          //   var path = data.data.video_path;
          //   this.videoSource = path.replace("src/", "");
          // }
        } else if (data.success == false) {
          this.toastr.error(data.message);
        }
      },
        (error) => {
          this.loginPopUpNow();
          throw error;
        });
  }

  toggleVideo(event: any) {
    this.videoplayer.nativeElement.play();
  }

  open(content) {
    this.modalService.open(content, { size: 'xl', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  addStocksToList() {

    var selectedstocks = this.stockForm1.controls['stock_id'].value;
    var quantity = this.stockForm1.controls['quantity'].value;
    var account = this.stockForm1.controls['account'].value;
    this.stocksService.saveUserStocks(selectedstocks, quantity, account, this.userService.user_id)
      .subscribe((data: any) => {
        if (data != null) {
          if (this.selected_stocks_ids.indexOf(data.data.stock_id) != -1) {
            this.selected_stocks_ids.splice(this.selected_stocks_ids.indexOf(data.data.stock_id), 1);
          }
        }
        if (data.success) {
          this.toastr.success(data.message);
          this.modalReference.close();
          this.getUserSavedStocksIds();
        } else if (data.success == false) {
          this.toastr.error(data.message);
        }
      },
        (error) => {
          throw error;
        });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getIcon(icon_type: string, stock) {

    if (icon_type == 'watchlist') {

      if (this.selected_watchlist_ids.indexOf(stock.stock_id) !== -1) {
        return "assets/images/marketing-yellow.png";
      } else {
        return "assets/images/marketing.png";
      }
    } else if (icon_type == 'stocks') {
      //console.log(this.selected_stocks_ids.indexOf(stock.stock_id));
      if (this.selected_stocks_ids.indexOf(stock.stock_id) !== -1) {
        return "assets/images/stock-icon2.png";
      } else {
        return "assets/images/growth.png";
      }
    } else if (icon_type == 'alerts') {
      if (this.selected_alert_ids != undefined) {
        if (this.selected_alert_ids.indexOf(stock.stock_id) !== -1) {
          return "assets/images/alerts-blue.png";
        } else {
          return "assets/images/alerts.png";
        }
      } else {
        return "assets/images/alerts.png";
      }
    }


  }

  getTitle(icon_type: string, stock) {
    if (icon_type == 'watchlist') {

      if (this.selected_watchlist_ids.indexOf(stock.stock_id) !== -1) {
        return "Remove From My Watchlist ";
      } else {
        return "Add to My Watchlist";
      }
    } else if (icon_type == 'stocks') {

      if (this.selected_stocks_ids.indexOf(stock.stock_id) !== -1) {
        return "Remove From My Stocks";
      } else {
        return "Add to My Stocks";
      }
    } else if (icon_type == 'alerts') {
      if (this.selected_alert_ids != undefined) {
        if (this.selected_alert_ids.indexOf(stock.stock_id) !== -1) {
          return "Remove From My Alerts";
        } else {
          return "Add to My Alerts";
        }
      } else {
        return "Add to My Alerts";
      }
    }
  }

  opendialog(content, stock) {
    if (this.selected_stocks_ids.indexOf(stock.stock_id) !== -1) {
      this.addToList("stocks", stock);
    } else {
      let newStockName = (stock.longname) + "  " + ('(' + stock._id + ')');
      // this.stockForm1.controls['symbol'].setValue(stock._id);
      this.stockForm1.controls['symbol'].setValue(newStockName);
      this.stockForm1.controls['stock_id'].setValue(stock.stock_id);

      this.modalReference = this.modalService.open(content, { size: 'md', ariaLabelledBy: 'modal-basic-title' });
      this.modalReference.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
        this.getColumns();
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  addToList(type, stock) {

    if (type == 'watchlist') {
      if (this.selected_watchlist_ids.indexOf(stock.stock_id) !== -1) {
        this.removeFromList(type, stock);
      } else {
        this.saveToList(type, stock);
      }
    } else if (type == 'stocks') {
      if (this.selected_stocks_ids.indexOf(stock.stock_id) !== -1) {
        this.removeFromList(type, stock);
      }
      // else {
      //   this.saveToList(type, stock);
      // }
    }


  }

  saveToList(type, stock) {
    this.stocksService.addToList(type, stock.stock_id, this.userService.user_id).subscribe((data: any) => {
      if (data.success == true) {
        if (type == 'watchlist') {
          this.selected_watchlist_ids.push(stock.stock_id);
          this.toastr.success(data.message);
        } else if (type == 'stocks') {
          this.selected_stocks_ids.push(stock.stock_id);
          this.toastr.success(data.message);
        } else {
          // this.toastr.error(data.message, 'Error in fetching stocks ids');
        }
      } else if (data.success == false) {
        this.toastr.error(data.message);
      }
    },
      (error) => {
        throw error;
      });
  }

  removeFromList(type, stock) {
    this.stocksService.addToList(type, stock.stock_id, this.userService.user_id).subscribe((data: any) => {
      if (type == "watchlist") {
        if (this.selected_watchlist_ids.indexOf(data.data.stock_id) != -1) {
          this.selected_watchlist_ids.splice(this.selected_stocks_ids.indexOf(data.data.stock_id), 1);
        }
      } else if (type == "stocks") {
        if (this.selected_stocks_ids.indexOf(data.data.stock_id) != -1) {
          this.selected_stocks_ids.splice(this.selected_stocks_ids.indexOf(data.data.stock_id), 1);
        }
      }

      if (data.success == true) {
        this.toastr.success(data.message);
        this.getUserSavedWatchListIds();
        this.getUserSavedStocksIds();
      } else if (data.success == false) {
        this.toastr.error(data.message);
        this.getUserSavedWatchListIds();
        this.getUserSavedStocksIds();
      }
    },
      (error) => {
        throw error;
      });
  }

  getUserSavedWatchListIds() {
    this.stocksService.getAllWatchlistStockIds(this.userService.user_id).subscribe((data: any) => {
      if (data.success == true) {
        this.selected_watchlist_ids = data.data.ids;
      } else if (data.success == false) {
        // console.log(data.message);
      }
    },
      (error) => {
        this.loginPopUpNow();
        throw error;
      });
  }

  getUserSavedStocksIds() {
    this.stocksService.getAllSavedStockIds(this.userService.user_id).subscribe((data: any) => {
      if (data.success == true) {
        this.selected_stocks_ids = data.data.ids;
      } else if (data.success == false) {
        console.log(data.message);
      }
    },
      (error) => {
        throw error;
      });
  }

  getUserSavedAlertIds() {
    this.stocksService.getAllUserAlerts(this.userService.user_id).subscribe((data: any) => {
      if (data.success == true) {
        this.selected_alert_ids = data.data;
      } else if (data.success == false) {
        console.log(data.message);
      }
    },
      (error) => {
        this.loginPopUpNow();
        throw error;
      });
  }

  displayColumn(columnName) {
    var array = [
      "Dummy Column 5-years", "Dummy Column 10-years", "Dummy Column 15-years", "Dummy Column 20-years",
      "Annual Forward Dividend vs Previous Year, Rule #9", "Stock Symbol", "Company Name",
      "Company Description", "Delisted Stock", "Dividend Currency", "Dividend Frequency",
      "Dividend Payment Date", "Dividend Record Date", "Ex-Dividend Date", "Exchange", "Industry",
      "Market", "Sector", "Share Price Date", "Fiscal Year-End Date", "Website", "Company Address1",
      "Company Address2", "Company Address, City", "Company Address, State", "Company Address, Country"
    ];
    let index = array.findIndex(x => x == columnName);
    if (index == -1) {
      return true;
    } else {
      return false;
    }
  }

  clearStockForm() {
    localStorage.removeItem('active-form');
    localStorage.removeItem("search-stock2");
    localStorage.removeItem("search-stock-con");
    localStorage.removeItem("checkMarket");
  }

  openFilterpop() {
    if (this.userService.user_type == "Free_platform" || this.userService.user_type == "course_platform" || this.userService.user_type == "Free_platformCourse_platform") {
      this.modelService.confirmMessage().pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    } else {
      this.modelService.confirm().pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    }
  }

  restore() {
    if (this.userService.user_type == "Free_platform" || this.userService.user_type == "course_platform" || this.userService.user_type == "Free_platformCourse_platform") {
      this.modelService.confirmMessage().pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    } else {
      this.userSideMenuCom.restore();
    }
  }

  clickHidde() {
    this.hidden = !this.hidden;
    this.userService.saveDetailSection(this.slug, this.hidden, this.userService.user_id)
      .subscribe((data: any) => { })
  }

  getHidden() {
    this.userService.getDetailSection(this.slug, this.userService.user_id)
      .subscribe((data: any) => {
        this.hidden = data.data;
      })
  }

  checkHidden() {
    if (this.hidden == false) {
      return "fas fa-caret-up fa-2x";
    } else if (this.hidden == true) {
      return "fas fa-caret-down fa-2x";
    }
  }

  columnDefination() {
    if (this.userService.user_type == "Free_platform" || this.userService.user_type == "course_platform" || this.userService.user_type == "Free_platformCourse_platform") {
      this.modelService.confirmMessage().pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    } else {
      this.router.navigate(['/user/pages/column-definitions']);
    }
  }

  onChangeSearch(event: Event) {
    let search = (event.target as HTMLInputElement).value;
    var symbolstring = "symbolstring", order = "asc", page = 0, limit = 200, filter = search;
    this.selectedstocks = [];
    var userType = "";
    if (this.userService.user_type == "US_onlyCourse_platform" || this.userService.user_type == "US_only") {
      this.custom_user = 'US_only';
    } else if (this.userService.user_type == "CAD_onlyCourse_platform" || this.userService.user_type == "CAD_only") {
      this.custom_user = 'CAD_only';
    } else if (this.userService.user_type == "Free_platform" || this.userService.user_type == "Free_platformCourse_platform") {
      this.custom_user = "Free_platform";
    } else if (this.userService.user_type == "US_and_CADCourse_platform" || this.userService.user_type == "US_and_CAD") {
      this.custom_user = 'US_and_CAD';
    }
    this.stocksService.getAllStocks(symbolstring, order, page, limit, filter, this.selectedstocks, this.custom_user).subscribe((data: any) => {
      if (data.success == true) {
        this.stocksList = data.data;
        this.stocksList.map(stocks => stocks.symbolstring = ` ${stocks.companyName} (${stocks.symbolstring})`);
      }
    },
      (error) => {
        throw error;
      });
  }

  loginPopUpNow() {
    this.modelService.logoutPop().pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => { });
  }

  sortData(sort: Sort) {
    const data = this.alert_list.slice();
    if (!sort.active || sort.direction === '') {
      this.alert_list = data;
      return;
    }
    this.alert_list = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Symbol': return this.compare(a._id, b._id, isAsc);
        default: return 0;
      }
    });
  }

  compare(a: string, b: string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getVideoID() {
    var slugs = "my-alert";
    this.userService.getVideoID(slugs)
      .subscribe((data: any) => {
        data = data.data;
        this.videoID = data.videoID;
        this.videoHeading = data.heading;
      });
  }

  openVideoPop() {
    if (this.videoID !== undefined && this.videoHeading !== undefined) {
      this.modelService.videoPop(this.videoID, this.videoHeading).pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    } else {
      this.videoHeading = "Video is not available on this page.";
      this.videoID = "";
      this.modelService.videoPop(this.videoID, this.videoHeading).pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    }
  }

}
