import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { StocksService } from '../../../services/admin/stocks.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-stocks-value-edit',
  templateUrl: './stocks-value-edit.component.html',
  styleUrls: ['./stocks-value-edit.component.css']
})
export class StocksValueEditComponent implements OnInit {
  slug = "";
  symbol = "";
  fields: any;
  editForm: FormGroup = new FormGroup({});


  constructor(
    public fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private stocksService: StocksService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService) {
    this.slug = this.route.snapshot.paramMap.get('slug');
    this.symbol = this.route.snapshot.paramMap.get('symbol');
  }

  ngOnInit(): void {
    this.slug = this.route.snapshot.paramMap.get('slug');
    this.symbol = this.route.snapshot.paramMap.get('symbol');
    this.getYearsforSymbol()
  }

  getYearsforSymbol() {
    this.stocksService.getYearHistory(this.symbol, this.slug)
      .subscribe((res: any) => {
        if (res != null) {
          this.fields = res.data.records;
          this.fields.forEach(x => {
            this.editForm.addControl(x.year, new FormControl(x.value, Validators.required))
          });
          // this.toastr.success(data.message, 'Column');
        } else {
          console.log("");
        }
      },
        (err: HttpErrorResponse) => {
          this.toastr.error('Error in updating Column');
        });
  }

  submitForm() {
    if (this.editForm.invalid) {
      return;
    }
    console.log(this.editForm.value);
    this.stocksService.editStockHistory(this.symbol, this.slug, this.editForm.value)
      .subscribe(data => {
        if (data.success) {
          this.toastr.success(data.message, 'Page');
          this.router.navigateByUrl('/admin/stocks-history');
        } else {
          console.log(data);
        }
      },
        error => {
          console.log(error);
        });
  }
}
