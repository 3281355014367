import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PagesService } from '../../../services/admin/pages.service';
import { UserService } from '../../../services/admin/user.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ColumnsService } from '../../../services/admin/columns.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-tool-tips',
  templateUrl: './tool-tips.component.html',
  styleUrls: ['./tool-tips.component.css'],
})
export class ToolTipsComponent implements OnInit {
  questionForm: FormGroup;
  submitted = false;
  tooltip = "tooltip";
  allToolTips: any;
  closeResult: string;
  columnList = [];


  constructor(
    public fb: FormBuilder,
    private columnsService: ColumnsService,
    public pagesService: PagesService,
    private toastr: ToastrService,
    private userService: UserService,
    private modalService: NgbModal,
  ) { }

  stockForm = new FormGroup({
    column_name: new FormControl('', [Validators.required]),
    tooltip: new FormControl('', [Validators.required]),
    tooltip_id: new FormControl('', [Validators.required]),
  });

  get st() { return this.stockForm.controls; }

  ngOnInit(): void {
    this.questionForm = this.fb.group({
      question: ['', [Validators.required]],
      answer: ['', [Validators.required]],
      sortFilterCtrl: '',
    });
    this.getToolTips();
    this.getColumns();
  }

  submitForm() {
    var question = this.questionForm.controls['question'].value;
    var answer = this.questionForm.controls['answer'].value;
    if (question && answer) {
      this.submitted = true;
      this.pagesService.savedToolTips(this.questionForm.value)
        .subscribe(data => {
          if (data.success) {
            this.toastr.success(data.message, 'Tool-Tip');
            this.questionForm.reset();
          } else {
            this.toastr.success(data.message, 'Tool-Tip');
          }
          this.ngOnInit();
        },
          error => {
            console.log(error);
          });
    } else {
      this.submitted = false;
    }
  }

  getToolTips() {
    this.pagesService.getToolTips(this.tooltip)
      .subscribe(data => {
        if (data.success) {
          this.toastr.success(data.message);
          this.allToolTips = data.data;
        } else {
          this.toastr.success(data.message);
        }
      },
        error => {
          console.log(error);
        });
  }

  opendialog(content, tool_tips, column_name, id) {
    this.stockForm.controls['column_name'].setValue(column_name);
    this.stockForm.controls['tooltip'].setValue(tool_tips);
    this.stockForm.controls['tooltip_id'].setValue(id);
    this.modalService.open(content, { size: 'md', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  editToolTips() {
    var tooltipid = this.stockForm.controls['tooltip_id'].value;
    this.pagesService.editToolTips(tooltipid, this.stockForm.value)
      .subscribe(data => {
        if (data.success) {
          this.toastr.success(data.message);
          this.getToolTips();
        } else {
          this.toastr.success(data.message);
        }
      },
        error => {
          console.log(error);
        });
  }

  getColumns() {
    this.columnsService.getColumns('column_order', 'asc', 0, 150).subscribe((data: any) => {
      if (data.success == true) {
        this.columnList = data.data
      } else {
        this.toastr.error(data.message, 'Error in fetching column data');
      }
    },
      (err: HttpErrorResponse) => {
        this.toastr.error('Error in fetching columns');
      });
  }

  private filterSortColumn(event: Event) {
    let search = (event.target as HTMLInputElement).value;
    this.columnList = this.columnList.filter(x => x.column_name.toLowerCase().indexOf(search.toLowerCase()) > -1);
  }

  displayColumn(columnName) {
    var array = ["Dummy Column 5-years", "Dummy Column 10-years", "Dummy Column 15-years", "Dummy Column 20-years", "Annual Forward Dividend vs Previous Year, Rule #91"];
    let index = array.findIndex(x => x == columnName);
    if (index == -1) {
      return true;
    } else {
      return false;
    }
  }

  removeToolTip(column_name, id) {
    this.pagesService.removeToolTip(column_name, id)
      .subscribe(data => {
        if (data.success) {
          this.toastr.success(data.message);
          this.ngOnInit();
        } else {
          this.toastr.error(data.message);
        }
      },
        error => {
          console.log(error);
        });
  }

}
