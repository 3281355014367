import { Component, HostListener, Renderer2, Inject, PLATFORM_ID } from '@angular/core';
import { decode } from 'punycode';
import { isPlatformBrowser, isPlatformServer, DOCUMENT } from '@angular/common';
import { UserService } from './services/user/user.service';
import { ScrolltopService } from './services/user/scrolltop.service';

var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'simply-investing';

  constructor(private userService: UserService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private scrollTopService: ScrolltopService,
    private renderer: Renderer2) { }

  ngOnInit(): void {

    var token = this.userService.getAccessToken();

    if (token) {
      var decoded = JSON.parse(atob(token.split('.')[1]));
      this.userService.user_type = decoded.type;
      this.userService.user_id = decoded.userId;
      this.userService.user_name = decoded.name;
      this.userService.user_email = decoded.email;
    } else {
      this.userService.user_type = null;
      this.userService.user_id = null;
    }
    this.scrollTopService.setScrollTop();
  }
  // @HostListener('scroll', ['$event']) // for scroll events of the current element
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    let scrollPosition: number = this.document.documentElement.scrollTop || this.document.body.scrollTop;
    this.showHideClass(scrollPosition);
  }

  showHideClass(scrollPosition: number) {
    if (scrollPosition < 50) {
      this.renderer.removeClass(this.document.body, 'scroll_class');
    } else {
      this.renderer.addClass(this.document.body, 'scroll_class');
    }
  }
}
