import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserService } from '../../../services/user/user.service';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class UserLoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  error = '';
  loginBtn: boolean = true;
  status: boolean;
  message: string;

  constructor(
    public formBuilder: FormBuilder,
    public userService: UserService,
    public router: Router,
    private sanitizer: DomSanitizer,
  ) {

  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', Validators.required]
    })
    this.getLoginPageMess();
  }

  get f() { return this.loginForm.controls; }

  loginUser() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.userService.login(this.loginForm.value)
      .pipe(first())
      .subscribe(data => {
        // console.log(data);
        if (data.success) {
          localStorage.setItem('access_token', data.token)
          if (data.user !== "course_platform" || data.user !== "Free_platformCourse_platform") {
            this.router.navigate(['user/dashboard']);
          } else {
            this.router.navigate(['/user/pages/course']);
          }
        } else {
          if (data.data == undefined) {
            if (data.message == "Please Login After 10min.") {
              this.loginBtn = false;
              setTimeout(() => {
                this.loginBtn = true;
              }, 600000);
            } else if (data.message == "access") {
              this.loginUser();
            } else {
              this.error = data.message;
            }
          } else {
            this.error = data.message;
          }
        }
      },
        (error) => {
          /* Swal.fire("", "Error in login...Please check you credentials or your subscription date", "error");*/
          // this.commonService.errorMessage(error.error.message)
          throw error;
          // this.loading = false;
        });
  }

  getLoginPageMess() {
    var tempdata = "loginMess"
    this.userService.getLoginPageMess(tempdata).subscribe(data => {
      data = data.data;
      this.status = data.status;
      this.message = data.content;
    }, (error) => {
      throw error;
    })
  }

  sameAsHtml(html_content) {
    return this.sanitizer.bypassSecurityTrustHtml(html_content);
  }

}
