import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError,of, BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ColumnsService {

  constructor(private httpClient: HttpClient,public router: Router) { }

  // getColumnsCount(data): Observable<any> {
  //   return this.httpClient.post(environment.apiBaseUrl+'/admin/pages/count', data)
  //     .pipe(
  //     tap(),
  //     catchError(this.handleError('getJobsList', []))
  //     );
  // }

  // getColumns(courseId:number, filter = '', sortOrder = 'asc',pageNumber = 0, pageSize = 3):  Observable<any> {

  //   return this.httpClient.get('/api/lessons', {
  //       params: new HttpParams()
  //           .set('courseId', courseId.toString())
  //           .set('filter', filter)
  //           .set('sortOrder', sortOrder)
  //           .set('pageNumber', pageNumber.toString())
  //           .set('pageSize', pageSize.toString())
  //   }).pipe(
  //       map(res =>  res["payload"])
  //   );
  // }
  getColumns(sort: string, order: string, page: number,pagesize:number): Observable<any> {
    //const href = environment.apiBaseUrl+'/admin/get-columns-list';
    //const requestUrl =  `${href}/sort=${sort}&order=${order}&page=${page + 1}`;

    return this.httpClient.post(environment.apiBaseUrl+'/admin/get-columns-list/'+sort+'/'+order+'/'+page+'/'+pagesize,'');
  }

  getAllColumns(filter): Observable<any> {
    //const href = environment.apiBaseUrl+'/admin/get-columns-list';
    //const requestUrl =  `${href}/sort=${sort}&order=${order}&page=${page + 1}`;

    return this.httpClient.post(environment.apiBaseUrl+'/user/get-all-columns-list',{'filter':filter});
  }

  updateColumn(column_id:string,update_column:string,is_checked:boolean): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl+'/admin/update-column',{'column_id':column_id,'update_column':update_column,'is_checked':is_checked})
      .pipe(
      tap(),
      catchError(this.handleError('updateJobCategory', []))
      );
  }


  updateOrder(data): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl+'/admin/update-column-order',{'data':data})
      .pipe(
      tap(),
      catchError(this.handleError('updateJobCategory', []))
      );
  }

  addFilters(data): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl+'/user/add-filters',{data:data})
      .pipe(
      tap(),
      catchError(this.handleError('Add filters', []))
      );
  }

      /**
* Handle Http operation that failed.
* Let the app continue.
* @param operation - name of the operation that failed
* @param result - optional value to return as the observable result
*/
private handleError<T>(operation = 'operation', result?: T) {
  return (error: any): Observable<T> => {

    // TODO: send the error to remote logging infrastructure
    console.error(error); // log to console instead

    // TODO: better job of transforming error for user consumption
    //console.log(`${operation} failed: ${error.message}`);

    // Let the app keep running by returning an empty result.
    return of(result as T);
  };
}



}
