import { Component, ViewChild, AfterViewInit, OnInit, ElementRef } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { UserService } from '../../../services/user/user.service';
import { StocksService } from '../../../services/user/stocks.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PagesService } from '../../../services/admin/pages.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ReplaySubject, Subject, merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { ModalService } from '../../../services/user/modal.service';
import { take } from 'rxjs/operators';
import { FilterService } from '../../../services/user/filter.service';

@Component({
  selector: 'app-displaytsx60',
  templateUrl: './displaytsx60.component.html',
  styleUrls: ['./displaytsx60.component.css']
})
export class Displaytsx60Component implements OnInit {
  status: boolean = false;
  content = "";
  slug = "display-tsx60";
  market = 'usa';
  stocksList: object[] = [];
  displayedColumns: string[] = [];
  header = {};
  pagesize = 20;
  //pagesize = 1;
  resultsLength = 0;
  company_name = "";
  stock_symbol = "";
  previousIndex: number;
  initialColumns = [];
  columnList = [];
  selected_filter;
  selected_exchange = '';
  us_stocks = false;
  canada_stocks = false;
  selected_stocks_ids = [];
  selected_watchlist_ids = [];
  selected_alert_ids = [];
  user_name = "";
  videoSource: any;
  title = "Top Ranked Stocks";
  user_type = "";
  free_user: boolean = false;
  messages: string;
  modalReference: any;
  videoID: string = "";
  videoHeading: string = "";

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  constructor(
    public fb: FormBuilder,
    private userService: UserService,
    private stocksService: StocksService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    public pagesService: PagesService,
    private modelService: ModalService,
    private router: Router,
    private filterService: FilterService,
  ) { }

  searchForm = new FormGroup({
    column: new FormControl('', [Validators.required]),
    column_value: new FormControl('', [Validators.required]),
    sortFilterCtrl: new FormControl(''),
    canada_stocks: new FormControl(''),
    column_op: new FormControl('', [Validators.required]),
  });

  stockForm = new FormGroup({
    symbol: new FormControl('', [Validators.required]),
    quantity: new FormControl('', [Validators.required]),
    stock_id: new FormControl('', [Validators.required]),
    account: new FormControl(''),
  });

  get s() { return this.searchForm.controls; }
  get st() { return this.stockForm.controls; }
  closeResult: string;

  ngOnInit(): void {
    var token = this.userService.getAccessToken();
    if (token) {
      var decoded = JSON.parse(atob(token.split('.')[1]));
      this.userService.user_type = decoded.type;
      this.userService.user_id = decoded.userId;
      this.userService.user_name = decoded.name;
    } else {
      this.userService.user_type = null;
      this.userService.user_id = null;
    }
    if (this.userService.user_type == "course_platform" || this.userService.user_type == "Free_platformCourse_platform") {
      this.free_user = true;
    } else {
      this.free_user = false;
    }
    if (this.userService.user_type == "Free_platform") {
      this.router.navigate(['/user/dashboard']);
    }
    this.user_name = this.userService.user_name;
    this.user_type = this.userService.user_type;
    this.company_name = environment.compnay_name_column_id;
    this.stock_symbol = environment.symbol_column_id;
    // this.getTsx60StocksList();
    var sideMenuStatus = this.filterService.getSideMenuStaus();
    if (sideMenuStatus !== null) {
      this.status = !sideMenuStatus;
    }
    this.getUserSavedWatchListIds();
    this.getUserSavedStocksIds();
    this.getUserSavedAlertIds();
    this.getPageContent();
    this.clearStockForm();
    this.getVideoID();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.stocksService.getTsx60StocksList(this.slug, this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
        }),
        map(data => {
          return data.data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe((data: any) => {
        this.displayedColumns = ['1'];
        this.stocksList = data.stocks;
        this.resultsLength = data.count;
        var ids_data = data.ids;
        for (let v in ids_data) {
          this.displayedColumns.push(ids_data[v]['column_id']);
          this.initialColumns.push(ids_data[v]['column_id']);
          this.header[ids_data[v]['column_id']] = ids_data[v]['column_name'];
        }
      });
  }

  open(content) {
    this.modalService.open(content, { size: 'xl', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  opendialog(content, stock) {
    if (this.selected_stocks_ids.indexOf(stock.stock_id) !== -1) {
      this.router.navigate(['/user/my-stocks']);
      // this.addToList("stocks", stock);
    } else {
      let newStockName = (stock.longname) + "  " + ('(' + stock.symbolstring + ')');

      this.stockForm.controls['symbol'].setValue(newStockName);
      this.stockForm.controls['stock_id'].setValue(stock.stock_id);
      this.stockForm.controls['quantity'].setValue('');
      this.modalReference = this.modalService.open(content, { size: 'md', ariaLabelledBy: 'modal-basic-title' });
      this.modalReference.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }


  addStocksToList() {
    var selectedstocks = this.stockForm.controls['stock_id'].value;
    var quantity = this.stockForm.controls['quantity'].value;
    var account = this.stockForm.controls['account'].value;
    this.stocksService.saveUserStocks(selectedstocks, quantity, account, this.userService.user_id)
      .subscribe((data: any) => {
        if (data != null) {
          if (this.selected_stocks_ids.indexOf(data.data.stock_id) != -1) {
            this.selected_stocks_ids.splice(this.selected_stocks_ids.indexOf(data.data.stock_id), 1);
          }
        }
        if (data.success) {
          this.toastr.success(data.message);
          this.modalReference.close();
          this.getUserSavedStocksIds();
        } else if (data.success == false) {
          this.toastr.error(data.message);
        }
      },
        (error) => {
          throw error;
        });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  clickEvent() {
    this.status = !this.status;
    this.filterService.setSideMenuStaus(!this.status);
  }

  getTsx60StocksList() {
    this.stocksService.getTsx60StocksList(this.slug, this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize)
      .subscribe((data: any) => {
        if (data.success) {
          data = data.data;
          this.displayedColumns = ['1'];
          this.stocksList = data.stocks;
          this.resultsLength = data.count;
          var ids_data = data.ids;
          for (let v in ids_data) {
            this.displayedColumns.push(ids_data[v]['column_id']);
            this.initialColumns.push(ids_data[v]['column_id']);
            this.header[ids_data[v]['column_id']] = ids_data[v]['column_name'];
          }
        } else if (data.success == false) {
          this.toastr.error(data.message);
        }
      },
        (error) => {
          throw error;
        });
  }

  getColVal(column_id, stock_data) {
    let index = stock_data.values_data.findIndex(x => x.column_id == column_id);
    var val = "";
    if (index != -1) {
      if (isNaN(stock_data.values_data[index]['column_value'])) {
        val = stock_data.values_data[index]['column_value'];
      } else {
        val = parseFloat(stock_data.values_data[index]['column_value']).toFixed(3) + "%";
      }
    } else {
      val = "N/A"
    }
    return val;
  }

  getPageContent() {
    this.pagesService.getPageContent(this.slug)
      .subscribe((data: any) => {
        if (data.success) {
          this.content = data.data.content;
          // if (data.data.video_path) {
          //   var path = data.data.video_path.replace("src/", "");
          //   this.videoSource = path;
          // }
        } else if (data.success == false) {
          this.toastr.error(data.message);
        }
      },
        (error) => {
          throw error;
        });
  }

  toggleVideo(event: any) {
    this.videoplayer.nativeElement.play();
  }

  public isSticky(col) {
    if (col == this.stock_symbol || col == this.company_name) {
      return true;
    } else {
      return false;
    }
  }

  addToList(type, stock) {

    if (type == 'watchlist') {
      if (this.selected_watchlist_ids.indexOf(stock.stock_id) !== -1) {
        this.removeFromList(type, stock);
      } else {
        this.saveToList(type, stock);
      }
    } else if (type == 'stocks') {
      if (this.selected_stocks_ids.indexOf(stock.stock_id) !== -1) {
        this.removeFromList(type, stock);
      }
    }
  }

  saveToList(type, stock) {
    this.stocksService.addToList(type, stock.stock_id, this.userService.user_id).subscribe((data: any) => {
      if (data.success == true) {
        if (type == 'watchlist') {
          this.selected_watchlist_ids.push(stock.stock_id);
          this.toastr.success(data.message);
        } else if (type == 'stocks') {
          this.selected_stocks_ids.push(stock.stock_id);
          this.toastr.success(data.message);
        }
      } else if (data.success == false) {
        this.toastr.error(data.message);
      }
    },
      (error) => {
        throw error;
      });
  }

  removeFromList(type, stock) {
    this.stocksService.addToList(type, stock.stock_id, this.userService.user_id).subscribe((data: any) => {
      if (type == "watchlist") {
        if (this.selected_watchlist_ids.indexOf(data.data.stock_id) != -1) {
          this.selected_watchlist_ids.splice(this.selected_stocks_ids.indexOf(data.data.stock_id), 1);
        }
      } else if (type == "stocks") {
        if (this.selected_stocks_ids.indexOf(data.data.stock_id) != -1) {
          this.selected_stocks_ids.splice(this.selected_stocks_ids.indexOf(data.data.stock_id), 1);
        }
      }
      if (data.success == true) {
        this.toastr.success(data.message);
        this.getUserSavedWatchListIds();
        this.getUserSavedStocksIds();
      } else if (data.success == false) {
        this.toastr.error(data.message);
        this.getUserSavedWatchListIds();
        this.getUserSavedStocksIds();
      }
    },
      (error) => {
        throw error;
      });
  }

  getIcon(icon_type: string, stock) {

    if (icon_type == 'watchlist') {

      if (this.selected_watchlist_ids.indexOf(stock.stock_id) !== -1) {
        return "assets/images/marketing-yellow.png";
      } else {
        return "assets/images/marketing.png";
      }
    } else if (icon_type == 'stocks') {
      if (this.selected_stocks_ids.indexOf(stock.stock_id) !== -1) {
        return "assets/images/stock-icon2.png";
      } else {
        return "assets/images/growth.png";
      }
    } else if (icon_type == 'alerts') {
      if (this.selected_alert_ids != undefined) {
        if (this.selected_alert_ids.indexOf(stock.stock_id) !== -1) {
          return "assets/images/alerts-blue.png";
        } else {
          return "assets/images/alerts.png";
        }
      } else {
        return "assets/images/alerts.png";
      }
    }


  }

  getTitle(icon_type: string, stock) {
    if (icon_type == 'watchlist') {

      if (this.selected_watchlist_ids.indexOf(stock.stock_id) !== -1) {
        return "Remove From My Watchlist ";
      } else {
        return "Add to My Watchlist";
      }
    } else if (icon_type == 'stocks') {

      if (this.selected_stocks_ids.indexOf(stock.stock_id) !== -1) {
        return "Remove From My Stocks";
      } else {
        return "Add to My Stocks";
      }
    } else if (icon_type == 'alerts') {
      if (this.selected_alert_ids != undefined) {
        if (this.selected_alert_ids.indexOf(stock.stock_id) !== -1) {
          return "View My Alerts";
        } else {
          return "Add to My Alerts";
        }
      } else {
        return "Add to My Alerts";
      }
    }
  }

  getUserSavedWatchListIds() {
    this.stocksService.getAllWatchlistStockIds(this.userService.user_id).subscribe((data: any) => {
      if (data.success == true) {
        this.selected_watchlist_ids = data.data.ids;
      } else if (data.success == false) {
        this.toastr.error(data.message, 'Error in fetching stocks ids');
      }
    },
      (error) => {
        this.loginPopUpNow();
        throw error
      });
  }

  getUserSavedStocksIds() {
    this.stocksService.getAllSavedStockIds(this.userService.user_id).subscribe((data: any) => {
      if (data.success == true) {
        this.selected_stocks_ids = data.data.ids;
      } else if (data.success == false) {
        this.toastr.error(data.message, 'Error in fetching stocks ids');
      }
    },
      (error) => {
        this.loginPopUpNow();
        throw error;
      });
  }

  getUserSavedAlertIds() {
    this.stocksService.getAllUserAlerts(this.userService.user_id).subscribe((data: any) => {
      if (data.success == true) {
        this.selected_alert_ids = data.data;
      } else if (data.success == false) {
        this.toastr.error(data.message, 'Error in fetching stocks ids');
      }
    },
      (error) => {
        this.loginPopUpNow();
        throw error;
      });
  }

  upgradeNow() {
    window.location.href = "https://www.simplyinvesting.com/invest";
  }

  freeandcourse(content, type: string) {
    if (type == "tsxdow") {
      this.messages = "This feature is not available in the Free Account or Course Platform. <br> This feature is only available to SI Report & Platform members.";
    }
    if (type == "stocks") {
      this.messages = "Your account is not permitted to add this stock to your list of My Stocks. <br> This feature is only available to SI Report & Platform members.";
    }
    if (type == "watchlist") {
      this.messages = "Your account is not permitted to add this stock to your Watchlist. <br> This feature is only available to SI Report & Platform members.";
    }
    if (type == "alerts") {
      this.messages = "Your account is not permitted to add this stock to your Alerts. <br> This feature is only available to SI Report & Platform members.";
    }
    this.modalService.open(content, { size: 'md', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  clearStockForm() {
    localStorage.removeItem('active-form');
    localStorage.removeItem("search-stock2");
    localStorage.removeItem("search-stock-con");
    localStorage.removeItem("checkMarket");
  }

  navigetToalert(data: any) {
    this.router.navigate([]).then((result) => {
      let encoID = btoa(data.symbolstring);
      window.open('/user/my-alert/' + encoID, '_blank');
    });
  }

  loginPopUpNow() {
    this.modelService.logoutPop().pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => { });
  }

  changeSideIcon() {
    if (this.status == false) {
      return "fa fa-caret-left fa-2x";
    } else if (this.status == true) {
      return "fa fa-caret-right fa-2x";
    }
  }

  getVideoID() {
    var slugs = "display-tsx60";
    this.userService.getVideoID(slugs)
      .subscribe((data: any) => {
        data = data.data;
        this.videoID = data.videoID;
        this.videoHeading = data.heading;
      });
  }

  openVideoPop() {
    if (this.videoID !== undefined && this.videoHeading !== undefined) {
      this.modelService.videoPop(this.videoID, this.videoHeading).pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    } else {
      this.videoHeading = "Video is not available on this page.";
      this.videoID = "";
      this.modelService.videoPop(this.videoID, this.videoHeading).pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    }
  }

}
