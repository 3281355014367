import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { User } from '../../models/user.model';



@Injectable({
  providedIn: 'root'
})
export class UserService {

  private currentUserSubject: BehaviorSubject<User>;

  headers = new HttpHeaders().set('Content-Type', 'application/json');
  selectedUser: User = {
    fullName: '',
    email: '',
    password: ''
  };
  user_type = "";
  user_id = "";
  user_name = "";
  user_email = "";


  public currentUser: Observable<User>;

  constructor(private httpClient: HttpClient, public router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  postUser(user: User): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/register', user);
  }

  login(user: User) {
    return this.httpClient.post<any>(environment.apiBaseUrl + '/login', user)
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.accessToken) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          user.language = 'en';
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
        }

        return user;
      }));
    // .subscribe((res: any) => {
    //   localStorage.setItem('access_token', res.token)
    //   this.getUserProfile(res._id).subscribe((res) => {
    //     this.selectedUser = res;
    //     this.router.navigate(['admin/dashboard/']);
    //   })
    // })
  }

  getAccessToken() {
    return localStorage.getItem('access_token');
  }

  isLoggedIn(): boolean {
    let authToken = localStorage.getItem('access_token');
    return (authToken !== null) ? true : false;
  }

  logout() {
    if (localStorage.removeItem('access_token') == null) {
      localStorage.clear();
      this.router.navigate(['/login']);
    }
  }

  restore(slug) {
    if (localStorage.removeItem(slug) == null) {
      //this.router.navigate(['/user/dashboard']);
    }
  }

  getUserProfile(id): Observable<any> {
    return this.httpClient.get(environment.apiBaseUrl + `/admin/profile/${id}`, { headers: this.headers }).pipe(
      map((res: Response) => {
        return res || {}
      }),
      catchError(this.handleError)
    )
  }

  requestReset(body): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/req-reset-password', body);
  }

  newPassword(body): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/new-password', body);
  }

  ValidPasswordToken(body): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/valid-password-token', body);
  }

  saveUserStockConditions(stockConstions: Array<any>, user_id: string, user_email: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/save_user_stock_conditions', { 'stock_constions': stockConstions, 'user_id': user_id, 'user_email': user_email })
  }

  saveNotification(notificationData, user_id: string, user_email: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/save_notification', { 'notification_data': notificationData, 'user_id': user_id, 'user_email': user_email })
  }

  getNotificationDetails(user_id: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/get-notification', { 'user_id': user_id })
  }

  getAllUserAlerts(user_id: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/get_user_alerts', { 'user_id': user_id })
  }

  deleteAlert(user_id: string, alert_id: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/delete_user_alert', { 'user_id': user_id, 'alert_id': alert_id })
  }

  getFreeAccMessage(): Observable<any> {
    return this.httpClient.get(environment.apiBaseUrl + '/user/get_free_acc_message');
  }

  getDefaultColumns(slug: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/get-defaultColumns-list', { 'slug': slug });
  }

  unsubscribeNotify(notifyType: object, user_email: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/update-single-notify', { 'notifyType': notifyType, 'user_email': user_email })
  }

  getLoginPageMess(tempData: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/getLoginPageMess', { 'tempData': tempData });
  }

  saveDetailSection(slug: string, status: any, user_id: any) {
    return this.httpClient.post(environment.apiBaseUrl + '/user/saveDetailSection', { 'slug': slug, 'status': status, 'user_id': user_id });
  }


  getDetailSection(slug: string, user_id: any) {
    return this.httpClient.post(environment.apiBaseUrl + '/user/getDetailSection', { 'slug': slug, 'user_id': user_id });
  }

  getExchangeRate(slug: string) {
    return this.httpClient.post(environment.apiBaseUrl + '/user/getExchangeRate', { 'slug': slug });
  }

  getVideoID(slug: string) {
    return this.httpClient.post(environment.apiBaseUrl + '/user/getVideoID', { 'slug': slug });
  }

  handleError(error: HttpErrorResponse) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(msg);
  }


}
