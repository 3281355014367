import { Component, Renderer2, OnInit } from '@angular/core';
 
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  constructor(private renderer: Renderer2) {
    //this.renderer.removeClass(document.body, 'login-page');
    //this.renderer.addClass(document.body, 'sidebar-mini layout-fixed');
   }

  ngOnInit(): void {

    
  }

}
