import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {Component, ViewChild, AfterViewInit} from '@angular/core';
import { MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import { ColumnsService } from '../../../services/admin/columns.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';



/**
 * @title Basic use of `<table mat-table>`
 */
@Component({
  selector: "columns-root",
  styleUrls: ["columns.component.css"],
  templateUrl: "columns.component.html"
})
export class ColumnsComponent implements AfterViewInit  {

  columnList: object[] = [];
  displayedColumns: string[] = ["column_order","column_name", "column_filter", "show_column", "created_date"];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {

    
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
    .pipe(
      startWith({}),
      switchMap(() => {
        this.isLoadingResults = true;
        return  this.columnsService!.getColumns(
          this.sort.active, this.sort.direction, this.paginator.pageIndex,this.paginator.pageSize);
      }),
      map(data => {

        console.log(data);
        // Flip flag to show that loading has finished.
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
        this.resultsLength = 128;

        return data.data;
      }),
      catchError(() => {
        this.isLoadingResults = false;
        // Catch if the GitHub API has reached its rate limit. Return empty data.
        this.isRateLimitReached = true;
        return observableOf([]);
      })
    ).subscribe(data => this.columnList = data);
  }

 
  constructor(
    private router: Router,
    public columnsService: ColumnsService,
    private toastr: ToastrService
  ){ }

 
  

  drop(event: CdkDragDrop<any>) {
    moveItemInArray(this.columnList, event.previousIndex, event.currentIndex);
    console.log(event.container.data);
    this.columnsService.updateOrder(event.container.data)
    .subscribe((data: any) => {
      if (data.success == true) {
        this.getColumns();
        this.toastr.success(data.message, 'Column');
      } else {
        this.toastr.error(data.message, 'Column');
      }
    },
    (err: HttpErrorResponse) => {
      this.toastr.error('Error in updating Column');
    });
    //
  }

  changeShowColumn(row,event){

    this.columnsService.updateColumn(row._id,'show_column',event.checked)
      .subscribe((data: any) => {
        if (data.success == true) {
          this.toastr.success(data.message, 'Column');
        } else {
          this.toastr.error(data.message, 'Column');
        }
      },
      (err: HttpErrorResponse) => {
        this.toastr.error('Error in updating Column');
      });
   
  }

  changeFilterColumn(row,event){
    this.columnsService.updateColumn(row._id,'column_filter',event.checked)
    .subscribe((data: any) => {
      if (data.success == true) {
        this.toastr.success(data.message, 'Column');
      } else {
        this.toastr.error(data.message, 'Column');
      }
    },
    (err: HttpErrorResponse) => {
      this.toastr.error('Error in updating Column');
    });
  }


  getColumns(){
    this.columnsService.getColumns(
      'column_order','asc', 0,150).subscribe((data: any) => {
        if (data.success == true) {
          this.columnList = data.data
        } else {
          this.toastr.error(data.message, 'Column');
        }
      },
      (err: HttpErrorResponse) => {
        this.toastr.error('Error in updating Column');
      });
  }
  
}
