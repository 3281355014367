import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { PageColumnsService } from '../../../services/admin/page-columns.service';
import { ColumnsService } from '../../../services/admin/columns.service';
import { StocksService } from '../../../services/admin/stocks.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-update-split',
  templateUrl: './update-split.component.html',
  styleUrls: ['./update-split.component.css']
})
export class UpdateSplitComponent implements OnInit {
  submitted = false;
  error = '';
  page_id: string;
  stockDetail: object = {};
  stockForm: FormGroup;
  columnList = [];
  stocksList: [];
  pageDetail: [];
  pageColumnsList: object[] = [];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  slug = "export-stocks";
  market = "";
  display_slug = "";
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    public fb: FormBuilder,
    private pageColumnsService: PageColumnsService,
    private columnsService: ColumnsService,
    private stocksService: StocksService,
    private toastr: ToastrService,
  ) { }

  marketList = [
    { name: 'NSD', value: 'NSD' },
    { name: 'NYE', value: 'NYE' },
    { name: 'TSX', value: 'TSX' },
  ];

  get f() { return this.stockForm.controls; }

  ngOnInit(): void {
    this.stockForm = this.fb.group({
      select_stock: ['', [Validators.required]],
      column_value: ['', [Validators.required]],
      exchange: ['', [Validators.required]],
      stockMultiFilterCtrl: '',
    });
    this.getStocks();
  }

  getStocks(symbolstring = "symbolstring", order = "asc", page = 0, limit = 200, filter = "") {
    this.stocksService.getAllStocks(symbolstring, order, page, limit, filter).subscribe((data: any) => {
      if (data.success == true) {
        this.stocksList = data.data
      } else {
        this.toastr.error(data.message, 'Column');
      }
    },
      (err: HttpErrorResponse) => {
        this.toastr.error('Error in fetching Stocks');
      });
  }
  private filterStocksMulti(event: Event) {
    if (!this.stocksList) {
      return;
    }
    // get the search keyword
    let search = (event.target as HTMLInputElement).value;
    this.getStocks('symbolstring', 'asc', 0, 200, search);
  }

  submitForm() {
    if (this.stockForm.invalid) {
      return;
    }
    this.pageColumnsService.updateStockSplitDetails(this.stockForm.value)
      .subscribe((data: any) => {
        if (data.success) {
          this.toastr.success(data.message);
        } else {
          this.toastr.error(data.message);
        }
      },
        (err: HttpErrorResponse) => {
          alert("Server Update Job Sub Category Error");
        });
  }
}
