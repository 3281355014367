import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PageColumnsService {

  constructor(private httpClient: HttpClient, public router: Router) { }

  getPageColumnsBySlug(slug: string): Observable<any> {
    return this.httpClient.get(environment.apiBaseUrl + '/admin/get-page-columns/' + slug);
  }

  getOnlyColumns(sort: string, order: string, page: number, pagesize: number, slug: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/get-page-columns-list/' + sort + '/' + order + '/' + page + '/' + pagesize + '/' + slug, '');
  }

  updateColumn(slug: string, column_id: string, update_column: string, is_checked: boolean): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/update-page-column', { 'column_id': column_id, 'update_column': update_column, 'is_checked': is_checked, 'slug': slug })
      .pipe(
        tap(),
        catchError(this.handleError('updateColumn', []))
      );
  }

  updateAllColumn(slug: string, update_column: string, column_id: object, is_checked: boolean): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/update-allpage-column', { 'update_column': update_column, 'slug': slug, 'column_id': column_id, 'is_checked': is_checked })
      .pipe(
        tap(),
        catchError(this.handleError('updateAllColumn', []))
      );
  }

  updateColumnOrder(slug: string, data): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/update-page-column-order', { 'data': data, 'slug': slug })
      .pipe(
        tap(),
        catchError(this.handleError('updateColumnOrder', []))
      );
  }


  updatePageDetails(slug: string, page): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/update-page-column-details/' + slug, page)
      .pipe(
        tap(),
        catchError(this.handleError('updatePageDetails', []))
      );
  }

  updateStockDetails(slug: string, formData): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/update-stock-column-details', { 'slug': slug, 'formData': formData })
      .pipe(
        tap(),
        catchError(this.handleError('updateStockDetails', []))
      );
  }

  getStocksList(slug: string, market: string, ids: Array<any>, sort: string, order: string, page: number, pagesize: number): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/get-stocks-list-by-criteria', { 'slug': slug, 'market': market, 'ids': ids, 'sort': sort, 'order': order, 'page': page, 'pagesize': pagesize })
      .pipe(
        tap(),
        catchError(this.handleError('updatePageDetails', []))
      );
  }

  getValuedStocksList(slug: string, market: string, dividend_type: string, ids: Array<any>, sort: string, order: string, page: number, pagesize: number): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/get-admin-valued-stocks-list-by-criteria', { 'slug': slug, 'market': market, 'ids': ids, 'sort': sort, 'order': order, 'page': page, 'pagesize': pagesize, 'dividend_type': dividend_type })
      .pipe(
        tap(),
        catchError(this.handleError('updatePageDetails', []))
      );
  }


  getDeepValuedStocksList(slug: string, market: string, type: string, ids: Array<any>, sort: string, order: string, page: number, pagesize: number): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/get-deep-value-stocks-list-by-criteria', { 'slug': slug, 'market': market, 'ids': ids, 'sort': sort, 'order': order, 'page': page, 'pagesize': pagesize, 'type': type })
      .pipe(
        tap(),
        catchError(this.handleError('updatePageDetails', []))
      );
  }

  getUserPagesStocksList(slug: string, market: string, ids: Array<any>, sort: string, order: string, page: number, pagesize: number): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/get-user-stocks-list-by-criteria', { 'slug': slug, 'market': market, 'ids': ids, 'sort': sort, 'order': order, 'page': page, 'pagesize': pagesize })
      .pipe(
        tap(),
        catchError(this.handleError('updatePageDetails', []))
      );
  }

  updateAllTableColumn(selectedSlug: string) {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/updateAllTableColumns', { 'selectedSlug': selectedSlug })
      .pipe(
        tap(),
        catchError(this.handleError('updateAllTableColumn', []))
      );
  }

  updateStockSplitDetails(formData): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/update-stock-split-details', { 'formData': formData })
      .pipe(
        tap(),
        catchError(this.handleError('updateStockSplitDetails', []))
      );
  }

  checkFunction(data: any, cron_name: any) {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/check-script', { 'data': data, 'cron_name': cron_name })
      .pipe(
        tap(),
        catchError(this.handleError('checkFunction', []))
      );
  }

  getCronJobsStatus(temp: any) {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/check-cron-status', { 'temp': temp })
      .pipe(
        tap(),
        catchError(this.handleError('getCronJobsStatus', []))
      );
  }

  updateDividendTrapSettings(formData): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/update-dividend-trap-settings', { 'formData': formData })
      .pipe(
        tap(),
        catchError(this.handleError('updateDividendTrapSettings', []))
      );
  }

  getDividendTrapSettings(): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/get-dividend-trap-settings', { })
      .pipe(
        tap(),
        catchError(this.handleError('getDividendTrapSettings', []))
      );
  }
  
  /**
* Handle Http operation that failed.
* Let the app continue.
* @param operation - name of the operation that failed
* @param result - optional value to return as the observable result
*/
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      //console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
