import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FaqService } from '../../../../services/admin/faq.service';
import { ToastrService } from 'ngx-toastr';
import { HttpEventType, HttpResponse, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-list-faq',
  templateUrl: './list-faq.component.html',
  styleUrls: ['./list-faq.component.css']
})
export class ListFaqComponent implements OnInit {

  searchForm: FormGroup;
  filterData: object;
  pageList: object[]=[];
  search_item = ''
  current_page: number;
  total_items: number;
  items_per_page: number;
  categoryList = [];

  constructor(
    public fb: FormBuilder,
    private router: Router,
    public faqService: FaqService,
    private toastr: ToastrService
  ){ }

  ngOnInit(): void {
    this.current_page = 1;
    this.total_items = 0;
    this.items_per_page = 5;

    this.createSearchForm();
  }

  createSearchForm() {
    this.searchForm = this.fb.group({
      searchterm: ['', [Validators.pattern(/^(?:[1-9]\d*|0)?(?:\.\d+)?$/)]],
      sortorder: '',
      sorttype: ''
    });
    this.searchForm.controls['sortorder'].setValue('name');
    this.searchForm.controls['sorttype'].setValue(1);
    this.getFaqs();
  }


  getFaqs() {
    var key = this.searchForm.controls['sortorder'].value;
    var obj = {};
    obj[key] = this.searchForm.controls['sorttype'].value;

    this.filterData = {
      "search": {
        "term": this.searchForm.controls['searchterm'].value,
        "page": this.current_page,
        "limit": this.items_per_page,
        "sort": obj
      }
    };

    this.getPageCounts();
    this.getPageLists();
  }

  getPageCounts(): void {
    this.faqService.getCount(this.filterData)
      .subscribe((data: any) => {
        if (data.success == true) {
          this.total_items = data.count;
        } else {
          //this.showError(data.message, 'skills');
        }
      },
      (err: HttpErrorResponse) => {
        alert("Server Skills Error");
      });
  }

 


  //Gell Components list
  getPageLists(): void {
    this.faqService.getFaqs(this.filterData)
      .subscribe((data: any) => {
        if (data.success == true) {
          
          this.pageList = data.data;
          //this.jobMessage = data.message;
        } else {
          //this.showError(data.message, 'skills');
        }
      },
      (err: HttpErrorResponse) => {
        alert("Server Skills Error");
      });
  }

  pageChanged(event) {
    this.current_page = event;
    this.getFaqs();
  }

  setSortOrder(data: any, field) {
    if (field == 'sortorder') {
      this.searchForm.controls['sortorder'].setValue(data);
    } else {
      this.searchForm.controls['sorttype'].setValue(data);
    }
    this.getFaqs();
  }



  delete(id: any) {
    // if (window.confirm('Are sure you want to delete this Job Category ?')) {
      this.faqService.deleteFaq(id)
        .subscribe((data: any) => {
          if (data.success) {
            this.current_page = 1;
            this.toastr.success(data.message, 'Page');
            this.getPageLists();
          } else {
            this.toastr.error(data.message, 'Page');
          }
        },
        (err: HttpErrorResponse) => {
          alert("Server delete Page Error");
        });
    // }
  }

  getCategoryVal(id){
    var cat_val = "";
    if(id == 1){  
      cat_val = "General Investing Questions";
    }else if(id == 2){
      cat_val = "About Simply Investing";
    }else if(id == 3){
      cat_val = "About Simply Investing Report";
    }else if(id == 4){
      cat_val = "Customer Service Question";
    }

    return cat_val;
  }

}
