import { Component, ViewChild, AfterViewInit, OnInit, ElementRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { StocksService } from '../../../services/user/stocks.service';
import { UserService } from '../../../services/user/user.service';
import { FilterService } from '../../../services/user/filter.service';
import { ColumnsService } from '../../../services/admin/columns.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';
import { PagesService } from '../../../services/admin/pages.service';
import { UserSideMenuComponent } from '../user-side-menu/user-side-menu.component';
import { take } from 'rxjs/operators';
import { ModalService } from '../../../services/user/modal.service';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-search-stock',
  templateUrl: './search-stock.component.html',
  styleUrls: ['./search-stock.component.css']
})
export class SearchStockComponent implements OnInit, AfterViewInit {

  stockForm: FormGroup;
  // operatorForm: FormGroup;

  stocksList = [];
  companyList = [];
  industryList = [];
  sectorList = [];
  consecutive_Years = [
    { 'key': '10--14', 'value': '10 to 14 years (Achievers)' },
    { 'key': '15--24', 'value': '15 to 24 years (Contenders)' },
    { 'key': '25--49', 'value': '25 to 49 years (Aristocrats/Champions)' },
    { 'key': '50', 'value': '50 years or more (Kings)' },
    { 'key': 'custome', 'value': 'Enter your own value' },
  ];

  operatorList = [
    { 'name': 'Less than or equal to ', 'value': '<=' },
    { 'name': 'Greater than or equal to ', 'value': '>=' },
    { 'name': 'Less than ', 'value': '<' },
    { 'name': 'Greater than ', 'value': '>' },
    { 'name': 'Equal to ', 'value': '==' },
    { 'name': 'Not equal to ', 'value': '!=' },
    { 'name': 'Range between x and y', 'value': 'range' }
  ];


  si_criteriaList = [{ 'key': '0', 'value': '0/10' }, { 'key': '1', 'value': '1/10' }, { 'key': '2', 'value': '2/10' },
  { 'key': '3', 'value': '3/10' }, { 'key': '4', 'value': '4/10' }, { 'key': '5', 'value': '5/10' }, {
    'key': '6', 'value': '6/10'
  }, { 'key': '7', 'value': '7/10' }, { 'key': '8', 'value': '8/10' }, { 'key': '9', 'value': '9/10' }, { 'key': '10', 'value': '10/10' }];
  valuedList = [{ 'key': 'undervalued', 'value': 'Undervalued' }, { 'key': 'overvalued', 'value': 'Overvalued' }]
  selectedstocks = [];
  selectedcompanies = [];
  selectedindustries = [];
  selectedsectors = [];
  marketList = [{ 'name': 'US', 'value': 'us' }, { 'name': 'Canada', 'value': 'canada' }];
  selected_filter;
  displayedColumns: string[] = [];
  header = {};
  pagesize = environment.pagesize;
  resultsLength = 0;
  company_name = "";
  stock_symbol = "";
  previousIndex: number;
  initialColumns = [];
  columnList = [];
  stocksListData = [];
  slug = 'search-stocks';
  selected_stocks_ids = [];
  selected_watchlist_ids = [];
  selected_alert_ids = [];
  user_name = "";
  status: boolean = false;
  free_user: boolean = false;
  paid_user: boolean = false;
  paid_user_usa: boolean = false;
  paid_user_cad: boolean = false;
  messages: string = "";
  selectedmarket = "";
  selectedstockMulti = [];
  selectedcompanyMulti = [];
  selectedindustryMulti = [];
  selectedsectorMulti = [];
  selectedcriteria_5 = "";
  selectedcriteria_10 = "";
  selectedcriteria_15 = "";
  selectedcriteria_20 = "";
  selectedvalued_5 = "";
  selectedvalued_10 = "";
  selectedvalued_15 = "";
  selectedvalued_20 = "";
  selectConsecutiveData = "";
  both_user: boolean = false;
  searchField: boolean = true;
  tooltip = "tooltip";
  allToolTips: any;
  disaplayToolTip: string;
  user_type = ""
  custome_filter: string = "Default Filter";
  videoSource: any;
  content: any;
  selectedField: any;
  us_stocks = false;
  canada_stocks = false;
  market = 'usa';
  modalReference: any;
  showUSA: boolean = false;
  showCAD: boolean = false;
  compareStockCountry: any;
  checkMarket: boolean = false;
  keyword = 'symbolstring';
  basicSearch: boolean = true;
  advanceSearch: boolean = false;
  basicStock: string = "";
  stocks_radio = "us_stocks";
  checkMarketOption: boolean = false;
  videoID: string = "";
  videoHeading: string = "";
  visibleInputField: boolean = false;
  hideInputField: boolean = false;
  dividendIncreaseValue: any;


  selectedOperator: any;
  valueOfX: any;
  valueOfY: any;
  valueOfZ: any;


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  @ViewChild(UserSideMenuComponent) userSideMenuCom: UserSideMenuComponent;


  constructor(private stocksService: StocksService,
    private userService: UserService,
    private filterService: FilterService,
    private columnsService: ColumnsService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    public fb: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    public pagesService: PagesService,
    private modelService: ModalService
  ) { }

  searchForm = new FormGroup({
    column: new FormControl('', [Validators.required]),
    column_value: new FormControl('', [Validators.required]),
    sortFilterCtrl: new FormControl(''),
    us_stocks: new FormControl(''),
    canada_stocks: new FormControl(''),
    column_op: new FormControl('', [Validators.required]),
  });

  stockForms = new FormGroup({
    symbol: new FormControl('', [Validators.required]),
    quantity: new FormControl('', [Validators.required]),
    stock_id: new FormControl('', [Validators.required]),
    account: new FormControl(''),
  });


  operatorForm = new FormGroup({
    selectOperator: new FormControl('', [Validators.required]),
    enterValue: new FormControl('', [Validators.required]),
    enterValueOfx: new FormControl('', [Validators.required]),
    enterValueOfy: new FormControl('', [Validators.required]),
  });

  get st() { return this.stockForms.controls; }
  get modalForm() { return this.operatorForm.controls; }


  closeResult: string;

  open(content) {
    this.modalService.open(content, { size: 'xl', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  ngOnInit(): void {

    var token = this.userService.getAccessToken();
    if (token) {
      var decoded = JSON.parse(atob(token.split('.')[1]));
      this.userService.user_type = decoded.type;
      this.userService.user_id = decoded.userId;
      this.userService.user_name = decoded.name;
    } else {
      this.userService.user_type = null;
      this.userService.user_id = null;
    }
    this.stockForm = this.fb.group({
      industry: '',
      sector: '',
      market: '',
      stockMultiFilterCtrl: '',
      companyMultiFilterCtrl: '',
      industryMultiFilterCtrl: '',
      sectorMultiFilterCtrl: '',
      criteria_5: '',
      criteria_10: '',
      criteria_15: '',
      criteria_20: '',
      valued_5: '',
      valued_10: '',
      valued_15: '',
      valued_20: '',
      consecutiveData: '',
    });

    // this.operatorForm = this.fb.group({
    //   selectOperator: '',
    //   enterValue: '',
    //   enterValueOfx: '',
    //   enterValueOfy: '',
    // });
    // this.slug = "top-ranked";
    this.custome_filter = this.filterService.getFilterName(this.slug);
    if (this.custome_filter == null) {
      this.custome_filter = "Default filter";
    }
    this.company_name = environment.compnay_name_column_id;
    this.stock_symbol = environment.symbol_column_id;
    this.selected_filter = this.filterService.getItem(this.slug);
    this.user_name = this.userService.user_name;
    this.user_name = this.userService.user_name;
    if (this.userService.user_type == "course_platform") {
      this.user_type = "course_platform";
      this.paid_user = true;
      this.paid_user_usa = false;
      this.paid_user_cad = false;
      this.both_user = true;
      this.searchField = false;
    } else if (this.userService.user_type == "Free_platform") {
      this.user_type = "Free_platform";
      this.free_user = true;
      this.both_user = true;
      this.checkMarket = true;
    } else if (this.userService.user_type == "CAD_only") {
      this.user_type = "CAD_only";
      this.paid_user = true;
      this.paid_user_usa = true;
      this.paid_user_cad = false;
    } else if (this.userService.user_type == "US_only") {
      this.user_type = "US_only";
      this.paid_user = true;
      this.paid_user_cad = true;
      this.paid_user_usa = false;
    } else if (this.userService.user_type == "US_and_CAD" || this.userService.user_type == "US_and_CADCourse_platform") {
      this.user_type = "US_and_CAD";
      this.paid_user = true;
      this.paid_user_usa = false;
      this.paid_user_cad = false;
      this.us_stocks = true;
      this.checkMarket = true;
    } else if (this.userService.user_type == "US_onlyCourse_platform") {
      this.user_type = "US_only";
      this.paid_user = true;
      this.paid_user_cad = false;
      this.paid_user_usa = false;
    } else if (this.userService.user_type == "CAD_onlyCourse_platform") {
      this.user_type = "CAD_only";
      this.paid_user = true;
      this.paid_user_cad = false;
      this.paid_user_usa = false;
    } else if (this.userService.user_type == "Free_platformCourse_platform") {
      this.user_type = "Free_platform";
      this.paid_user = true;
      this.paid_user_cad = false;
      this.paid_user_usa = false;
    }
    this.getToolTips();


    let activeForm = this.filterService.getCompareStockCountry('active-form');
    if (activeForm) {
      this.advanceSearch = true;
      this.basicSearch = false;
    } else {
      this.advanceSearch = false;
      this.basicSearch = true;
    }
    if (this.selected_filter !== null) {
      if (this.selected_filter.show_tsx_exchange !== undefined) {
        if (this.selected_filter.show_tsx_exchange) {
          this.canada_stocks = true;
          this.market = 'canada';
          this.us_stocks = false;
        }
      }
    }
    this.compareStockCountry = this.filterService.getCompareStockCountry('search-stock-con');
    if (this.compareStockCountry) {
      this.market = this.compareStockCountry;
      if (this.compareStockCountry == 'usa') {
        this.us_stocks = true;
        this.canada_stocks = false;
      } else if (this.compareStockCountry == 'canada') {
        this.canada_stocks = true;
        this.us_stocks = false;
      }
    }
    let checkmarket = this.filterService.getCompareStockCountry('checkMarket');
    if (!checkmarket) {
      this.checkMarket = false;
      this.checkMarketOption = true;
    }
    var formData = this.filterService.getFormValue("search-stock");
    if (formData !== null) {
      this.displayTable(formData);
    } else {
      formData = null;
    }
    var sideMenuStatus = this.filterService.getSideMenuStaus();
    if (sideMenuStatus !== null) {
      this.status = !sideMenuStatus;
    }
    this.checkUsersTag();
    this.checkUsersTagCheck();
    this.getStocks();
    // this.getCompanyNames();
    this.getIndustries();
    this.getSectors();
    this.getUserSavedWatchListIds();
    this.getUserSavedStocksIds();
    this.getUserSavedAlertIds();
    this.getPageContent();
    this.getVideoID();
  }

  ngAfterViewInit() {
    if (this.stocksListData.length > 0) {
      var formData = this.filterService.getFormValue("search-stock");
      if (formData !== null) {
        this.setFormData(formData);
      }
      this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
      merge(this.sort.sortChange, this.paginator.page)
        .pipe(
          startWith({}),
          switchMap(() => {
            return this.stocksService.getSearchedStocks(this.searchForm.controls['column'].value,
              this.searchForm.controls['column_value'].value, this.searchForm.controls['column_op'].value,
              this.sort.active, this.sort.direction, this.paginator.pageIndex,
              this.paginator.pageSize, this.selected_filter, this.stockForm.value,
              this.user_type, this.market);
          }),
          map(data => {
            return data;
          }),
          catchError(() => {
            return observableOf([]);
          })
        ).subscribe((data: any) => {
          if (data.success == true) {
            data = data.data;
            this.displayedColumns = ['1', this.company_name, this.stock_symbol];
            this.stocksListData = data.stocks;
            this.resultsLength = data.count;
            var ids_data = data.ids;
            ids_data.sort(function (a, b) {
              return a.column_order - b.column_order;
            });
            for (let v in ids_data) {
              this.displayedColumns.push(ids_data[v]['column_id']);
              this.initialColumns.push(ids_data[v]['column_id']);
              this.header[ids_data[v]['column_id']] = ids_data[v]['column_name'];
            }
            if (this.selected_filter == null) {
              var filter_obj = { 'columns': data.columns };
              this.filterService.setItem(this.slug, filter_obj);
              this.selected_filter = this.filterService.getItem(this.slug);
            }
          }
        });
    }
  }

  checkUsersTagCheck() {
    if (this.userService.user_type == "US_onlyCourse_platform" || this.userService.user_type == "US_only") {
      this.showUSA = true;
    } else if (this.userService.user_type == "CAD_onlyCourse_platform" || this.userService.user_type == "CAD_only") {
      this.showCAD = true;
    } else {
      this.showUSA = true;
      this.showCAD = true;
    }
  }

  checkUsersTag() {
    if (this.userService.user_type == "US_onlyCourse_platform" || this.userService.user_type == "US_only") {
      this.marketList = [{ 'name': 'US', 'value': 'us' }];
    } else if (this.userService.user_type == "CAD_onlyCourse_platform" || this.userService.user_type == "CAD_only") {
      this.marketList = [{ 'name': 'Canada', 'value': 'canada' }];
    }
  }

  submitBasicForm() {
    if (this.basicStock) {
      this.router.navigate(['/user/stock-quote', this.basicStock]);
    }
  }

  getStocks(symbolstring = "symbolstring", order = "asc", page = 0, limit = 350, filter = "") {
    var userType = "";
    this.selectedstocks = [];
    if (this.userService.user_type == "US_onlyCourse_platform" || this.userService.user_type == "CAD_onlyCourse_platform" || this.userService.user_type == "US_and_CAD" || this.userService.user_type == "US_and_CADCourse_platform") {
      userType = "US_and_CAD";
    } else {
      if (this.basicSearch == true && this.advanceSearch == false && this.userService.user_type == "Free_platformCourse_platform") {
        userType = "course_platform";
      } else {
        userType = this.user_type;
      }
    }
    this.stocksService.getAllStocks(symbolstring, order, page, limit, filter, this.selectedstocks, userType).subscribe((data: any) => {
      if (data.success == true) {
        this.stocksList = data.data;
        this.stocksList.map(stocks => stocks.symbolstring = ` ${stocks.companyName} (${stocks.symbolstring})`);
      }
    },
      (error) => {
        this.loginPopUpNow();
        throw error;
      });
  }

  selectEvent(item: any) {
    this.basicStock = item.symbol;
  }

  onChangeSearch(search: string) {
    var symbolstring = "symbolstring", order = "asc", page = 0, limit = 200, filter = search;
    this.selectedstocks = [];
    var userType = "";
    if (this.userService.user_type == "US_onlyCourse_platform" || this.userService.user_type == "CAD_onlyCourse_platform" || this.userService.user_type == "US_and_CAD" || this.userService.user_type == "US_and_CADCourse_platform") {
      userType = "US_and_CAD";
    } else {
      if (this.basicSearch == true && this.advanceSearch == false && this.userService.user_type == "Free_platformCourse_platform") {
        userType = "course_platform";
      } else {
        userType = this.user_type;
      }
    }
    this.stocksService.getAllStocks(symbolstring, order, page, limit, filter, this.selectedstocks, userType).subscribe((data: any) => {
      if (data.success == true) {
        this.stocksList = data.data;
        this.stocksList.map(stocks => stocks.symbolstring = ` ${stocks.companyName} (${stocks.symbolstring})`);
      }
    },
      (error) => {
        throw error;
      });
  }

  onFocused(e) {
    // do something
  }

  changeSideIcon() {
    if (this.status == false) {
      return "fa fa-caret-left fa-2x";
    } else if (this.status == true) {
      return "fa fa-caret-right fa-2x";
    }
  }

  // getCompanyNames(symbolstring = "symbol", order = "asc", page = 0, limit = 200, filter = "") {
  //   var userType = "";
  //   if (this.userService.user_type == "US_onlyCourse_platform" || this.userService.user_type == "CAD_onlyCourse_platform" || this.userService.user_type == "US_and_CAD" || this.userService.user_type == "US_and_CADCourse_platform") {
  //     userType = "US_and_CAD";
  //   } else {
  //     userType = this.user_type;
  //   }
  //   this.stocksService.getCompanyNames(symbolstring, order, page, limit, filter, this.selectedcompanies, userType).subscribe((data: any) => {
  //     if (data.success == true) {
  //       this.companyList = data.data;
  //       this.stockForm.controls['company_name'].setValue(this.selectedcompanies);
  //     } else {
  //       this.toastr.error(data.message, 'Column');
  //     }
  //   },
  //     (err: HttpErrorResponse) => {
  //       this.toastr.error('Error in fetching Stocks');
  //     });
  // }

  getIndustries(symbolstring = "symbol", order = "asc", page = 0, limit = 200, filter = "") {

    var industry = ["Apparel & Luxury", "Containers & Packaging", "Home and Homeware", "Hotels, Lodging & Leisure", "Personal Services", "Residential Construction", "Restaurants & Bars", "Retail - Discretionary", "Vehicles", "Beverages - Alcoholic", "Beverages - Non-Alcoholic", "Consumer Products - Foods", "Consumer Products - Household & Personal", "Education", "Retailers - Staples", "Tobacco", "Fossil Fuels", "Other Energy Sources", "Renewable Energy Producers", "Asset Management Services", "Banking", "Capital Markets", "Credit Finance", "Diversified Financial Services", "Insurance", "Biotechnology", "Healthcare Providers & Services", "Medical Diagnostics & Screening", "Medical Distributors", "Medical Equipment & Supplies", "Medical Service Plans", "Pharmaceuticals", "Aerospace & Defense", "Construction", "Corporate Services", "Industrial Conglomerates", "Industrial Equipment Distributors", "Industrial Goods", "Industrial Machinery", "Transportation", "Waste Management", "Agriculture", "Chemicals", "Construction Materials", "Forestry", "Mining", "Steel", "Interactive Multi-Media", "Telecommunications", "Traditional Media", "REITs", "Real Estate", "Hardware & Equipment", "Semiconductors and Equipment", "Software", "Independent Power Producers", "Regulated Utilities"];
    industry.sort(function (a, b) {
      return (a).localeCompare(b); //using String.prototype.localCompare()
    });
    this.industryList = industry;
    this.stockForm.controls['industry'].setValue(this.selectedindustries);
    
    // this.stocksService.getIndustries(symbolstring, order, page, limit, filter, this.selectedindustries, this.user_type).subscribe((data: any) => {
    //   if (data.success == true) {
    //     var industry = data.data;
    //     industry.sort(function (a, b) {
    //       return (a).localeCompare(b); //using String.prototype.localCompare()
    //     });
    //     this.industryList = industry;
    //     this.stockForm.controls['industry'].setValue(this.selectedindustries);
    //   }
    // },
    //   (error) => {
    //     this.loginPopUpNow();
    //     throw error;
    //   });
  }

  getSectors(symbolstring = "symbol", order = "asc", page = 0, limit = 200, filter = "") {

    var sector = ['Consumer Discretionary', 'Consumer Staples', 'Energy', 'Finance', 'Healthcare', 'Industrials', 'Materials', 'Media', 'Real Estate', 'Technology', 'Utilities'];
    sector.sort(function (a, b) {
      return (a).localeCompare(b); //using String.prototype.localCompare()
    });
    this.sectorList = sector;
    this.stockForm.controls['sector'].setValue(this.selectedsectors);

    // this.stocksService.getSectors(symbolstring, order, page, limit, filter, this.selectedsectors, this.user_type).subscribe((data: any) => {
    //   if (data.success == true) {
    //     var sector = data.data;
    //     sector.sort(function (a, b) {
    //       return (a).localeCompare(b); //using String.prototype.localCompare()
    //     });
    //     this.sectorList = sector;
    //     this.stockForm.controls['sector'].setValue(this.selectedsectors);
    //   }
    // },
    //   (error) => {
    //     this.loginPopUpNow();
    //     throw error;
    //   });
  }


  // private filterStocksMulti(event: Event) {

  //   this.selectedstocks = this.stockForm.controls['stocks'].value;
  //   // get the search keyword
  //   let search = (event.target as HTMLInputElement).value;
  //   if (search != "") {
  //     this.getStocks('symbolstring', 'asc', 0, 200, search);
  //   } else {
  //     this.getStocks('symbolstring', 'asc', 0, 200, '');
  //   }

  // }

  // private filterCompanyMulti(event: Event) {

  //   this.selectedcompanies = this.stockForm.controls['company_name'].value;
  //   // get the search keyword
  //   let search = (event.target as HTMLInputElement).value;
  //   if (search != "") {
  //     this.getCompanyNames('symbolstring', 'asc', 0, 200, search);
  //   } else {
  //     this.getCompanyNames('symbolstring', 'asc', 0, 200, '');
  //   }

  // }

  selectChangeHandler(event: any, content: any) {
    if (event == 'custome') {
      this.modalReference = this.modalService.open(content, { size: 'md', ariaLabelledBy: 'modal-basic-title' });
      this.modalReference.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }else {
      const consecutiveFromValue = this.stockForm.controls['consecutiveData'].value;
      const consecutiveIndex = this.consecutive_Years.findIndex((data) => data.key == consecutiveFromValue);
      if (consecutiveIndex !== -1) {
        this.selectedOperator = undefined; 
     }
    }
  }

  selectOperatorChange(event: any) {
    if (event == 'range') {
      // this.operatorForm.controls['selectOperator'].setValue(event);
      this.operatorForm.controls['enterValue'].setValue("");
      this.visibleInputField = true;
      this.hideInputField = false;
    } else {
      // this.operatorForm.controls['selectOperator'].setValue(event);
      this.operatorForm.controls['enterValueOfx'].setValue("");
      this.operatorForm.controls['enterValueOfy'].setValue("");
      this.visibleInputField = false;
      this.hideInputField = true;
    }
  }

  private filterIndustryMulti(event: Event) {

    this.selectedindustries = this.stockForm.controls['industry'].value;
    // get the search keyword
    let search = (event.target as HTMLInputElement).value;
    if (search != "") {
      this.getIndustries('symbolstring', 'asc', 0, 200, search);
    } else {
      this.getIndustries('symbolstring', 'asc', 0, 200, '');
    }

  }

  private filterSectorMulti(event: Event) {

    this.selectedsectors = this.stockForm.controls['sector'].value;
    // get the search keyword
    let search = (event.target as HTMLInputElement).value;
    if (search != "") {
      this.getSectors('symbolstring', 'asc', 0, 200, search);
    } else {
      this.getSectors('symbolstring', 'asc', 0, 200, '');
    }

  }

  displayTable(formData) {
    if (formData !== null) {
      this.stockForm.controls['industry'].setValue(formData.industry);
      this.stockForm.controls['sector'].setValue(formData.sector);
      this.stockForm.controls['market'].setValue(formData.market);
      this.stockForm.controls['stockMultiFilterCtrl'].setValue(formData.stockMultiFilterCtrl);
      this.stockForm.controls['companyMultiFilterCtrl'].setValue(formData.companyMultiFilterCtrl);
      this.stockForm.controls['industryMultiFilterCtrl'].setValue(formData.industryMultiFilterCtrl);
      this.stockForm.controls['sectorMultiFilterCtrl'].setValue(formData.sectorMultiFilterCtrl);
      this.stockForm.controls['criteria_5'].setValue(formData.criteria_5);
      this.stockForm.controls['criteria_10'].setValue(formData.criteria_10);
      this.stockForm.controls['criteria_15'].setValue(formData.criteria_15);
      this.stockForm.controls['criteria_20'].setValue(formData.criteria_20);
      this.stockForm.controls['valued_5'].setValue(formData.valued_5);
      this.stockForm.controls['valued_10'].setValue(formData.valued_10);
      this.stockForm.controls['valued_15'].setValue(formData.valued_15);
      this.stockForm.controls['valued_20'].setValue(formData.valued_20);
      this.stockForm.controls['consecutiveData'].setValue(formData.consecutiveData);

      this.getUserSavedStocks();
      setTimeout(() => {
        this.ngAfterViewInit();
      }, 4000);
    }
  }

  setFormData(formData) {
    if (formData !== null) {
      this.stockForm.controls['industry'].setValue(formData.industry);
      this.stockForm.controls['sector'].setValue(formData.sector);
      this.stockForm.controls['market'].setValue(formData.market);
      this.stockForm.controls['stockMultiFilterCtrl'].setValue(formData.stockMultiFilterCtrl);
      this.stockForm.controls['companyMultiFilterCtrl'].setValue(formData.companyMultiFilterCtrl);
      this.stockForm.controls['industryMultiFilterCtrl'].setValue(formData.industryMultiFilterCtrl);
      this.stockForm.controls['sectorMultiFilterCtrl'].setValue(formData.sectorMultiFilterCtrl);
      this.stockForm.controls['criteria_5'].setValue(formData.criteria_5);
      this.stockForm.controls['criteria_10'].setValue(formData.criteria_10);
      this.stockForm.controls['criteria_15'].setValue(formData.criteria_15);
      this.stockForm.controls['criteria_20'].setValue(formData.criteria_20);
      this.stockForm.controls['valued_5'].setValue(formData.valued_5);
      this.stockForm.controls['valued_10'].setValue(formData.valued_10);
      this.stockForm.controls['valued_15'].setValue(formData.valued_15);
      this.stockForm.controls['valued_20'].setValue(formData.valued_20);
      this.stockForm.controls['consecutiveData'].setValue(formData.consecutiveData);

    }
  }

  submitForm() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 2500);
    
    // Set modal form value
    if (this.selectedOperator && this.selectedOperator == 'range') {
      this.stockForm.controls['consecutiveData'].setValue(this.valueOfX + "--" + this.valueOfY);
    } else if (this.selectedOperator && this.selectedOperator != 'range') {
      this.stockForm.controls['consecutiveData'].setValue(this.valueOfZ + "***" + this.selectedOperator);
    }
    this.selectedindustries = this.stockForm.controls['industry'].value;
    this.selectedsectors = this.stockForm.controls['sector'].value;
    this.selectedmarket = this.stockForm.controls['market'].value;
    this.selectedstockMulti = this.stockForm.controls['stockMultiFilterCtrl'].value;
    this.selectedcompanyMulti = this.stockForm.controls['companyMultiFilterCtrl'].value;
    this.selectedindustryMulti = this.stockForm.controls['industryMultiFilterCtrl'].value;
    this.selectedsectorMulti = this.stockForm.controls['sectorMultiFilterCtrl'].value;
    this.selectedcriteria_5 = this.stockForm.controls['criteria_5'].value;
    this.selectedcriteria_10 = this.stockForm.controls['criteria_10'].value;
    this.selectedcriteria_15 = this.stockForm.controls['criteria_15'].value;
    this.selectedcriteria_20 = this.stockForm.controls['criteria_20'].value;
    this.selectedvalued_5 = this.stockForm.controls['valued_5'].value;
    this.selectedvalued_10 = this.stockForm.controls['valued_10'].value;
    this.selectedvalued_15 = this.stockForm.controls['valued_15'].value;
    this.selectedvalued_20 = this.stockForm.controls['valued_20'].value;
    this.selectConsecutiveData = this.stockForm.controls['consecutiveData'].value;

    if (this.selectedmarket !== "" && typeof (this.stockForm.controls['market'].value) !== "object") {
      this.checkMarket = false;
      this.checkMarketOption = true;
      if (this.filterService.getCompareStockCountry('checkMarket') != null) {
        localStorage.removeItem('checkMarket');
        this.filterService.setCompareStockCountry('checkMarket', this.checkMarket);
      } else {
        this.filterService.setCompareStockCountry('checkMarket', this.checkMarket);
      }
    } else if (this.selectedmarket == "") {
      this.checkMarket = true;
      this.checkMarketOption = false;
      if (this.filterService.getCompareStockCountry('checkMarket') != null) {
        localStorage.removeItem('checkMarket');
        this.filterService.setCompareStockCountry('checkMarket', this.checkMarket);
      } else {
        this.filterService.setCompareStockCountry('checkMarket', this.checkMarket);
      }
    }

    if (this.filterService.getCompareStockCountry('active-form') != null) {
      localStorage.removeItem('active-form');
      this.filterService.setCompareStockCountry('active-form', this.advanceSearch);
    } else {
      this.filterService.setCompareStockCountry('active-form', this.advanceSearch);
    }

    this.selectedField = this.stockForm.value;
    var formData = this.filterService.getFormValue("search-stock");
    if (formData == null) {
      this.filterService.setFormValue("search-stock", this.selectedField);
    } else if (formData !== null) {
      localStorage.removeItem("search-stock2");
      this.filterService.setFormValue("search-stock", this.selectedField);
    }
    this.getUserSavedStocks();
    setTimeout(() => {
      this.ngAfterViewInit();
    }, 5000);

  }

  submitModalForm() {
    this.selectedOperator = this.operatorForm.controls['selectOperator'].value;
    this.valueOfZ = this.operatorForm.controls['enterValue'].value;
    this.valueOfX = this.operatorForm.controls['enterValueOfx'].value;
    this.valueOfY = this.operatorForm.controls['enterValueOfy'].value;

    if (this.selectedOperator == 'range') {
      if (this.valueOfX && this.valueOfY && Number.isInteger(this.valueOfX) && Number.isInteger(this.valueOfY) && this.valueOfX <= 1000 && this.valueOfY <= 1000) {
        const consecutiveIndex = this.consecutive_Years.findIndex((data) => data.key == 'custome');
        const previousKey = this.consecutive_Years[consecutiveIndex].key;

        this.dividendIncreaseValue = "Range between " + this.valueOfX + " and " + this.valueOfY;
        this.consecutive_Years[consecutiveIndex].value = this.dividendIncreaseValue;
        this.consecutive_Years[consecutiveIndex].key = previousKey;
        
        this.modalReference.close();
        this.operatorForm.controls['selectOperator'].setValue("");
        this.operatorForm.controls['enterValue'].setValue("");
        this.operatorForm.controls['enterValueOfx'].setValue("");
        this.operatorForm.controls['enterValueOfy'].setValue("");
      } else {
        this.toastr.success("“Only positive whole numbers are permitted from 0 to 1000”");
      }
    } else if (this.valueOfZ && Number.isInteger(this.valueOfZ) && this.valueOfZ <= 1000) {
      const OperatorListIndex = this.operatorList.findIndex((x) => x.value == this.selectedOperator);
      const consecutiveIndex = this.consecutive_Years.findIndex((data) => data.key == 'custome');
      const previousKey = this.consecutive_Years[consecutiveIndex].key;

      this.dividendIncreaseValue = this.operatorList[OperatorListIndex].name + this.valueOfZ;
      this.consecutive_Years[consecutiveIndex].value = this.dividendIncreaseValue;
      this.consecutive_Years[consecutiveIndex].key = previousKey;

      this.modalReference.close();
      this.operatorForm.controls['selectOperator'].setValue("");
      this.operatorForm.controls['enterValue'].setValue("");
      this.operatorForm.controls['enterValueOfx'].setValue("");
      this.operatorForm.controls['enterValueOfy'].setValue("");
    } else {
      this.toastr.success("“Only positive whole numbers are permitted from 0 to 1000”");
      return true;
    }
  }


  getUserSavedStocks() {
    var userType = "";
    if (this.userService.user_type == "US_onlyCourse_platform" || this.userService.user_type == "US_only") {
      userType = 'US_only';
    } else if (this.userService.user_type == "CAD_onlyCourse_platform" || this.userService.user_type == "CAD_only") {
      userType = 'CAD_only';
    } else if (this.userService.user_type == "Free_platform" || this.userService.user_type == "Free_platformCourse_platform") {
      userType = "Free_platform";
    } else if (this.userService.user_type == "US_and_CADCourse_platform" || this.userService.user_type == "US_and_CAD") {
      userType = 'US_and_CAD';
    }
    this.stocksService.getSearchedStocks(this.searchForm.controls['column'].value,
      this.searchForm.controls['column_value'].value, this.searchForm.controls['column_op'].value, "", "", 0,
      this.pagesize, this.selected_filter, this.stockForm.value, userType, this.market).subscribe((data: any) => {
        if (data.success == true) {
          var data = data.data;
          this.displayedColumns = ['1', this.company_name, this.stock_symbol];
          this.stocksListData = data.stocks;
          this.resultsLength = data.count;
          var ids_data = data.ids;
          ids_data.sort(function (a, b) {
            return a.column_order - b.column_order;
          });
          for (let v in ids_data) {
            this.displayedColumns.push(ids_data[v]['column_id']);
            this.initialColumns.push(ids_data[v]['column_id']);
            this.header[ids_data[v]['column_id']] = ids_data[v]['column_name'];
          }
          if (this.selected_filter == null) {
            var filter_obj = { 'columns': data.columns };
            this.filterService.setItem(this.slug, filter_obj);
            this.selected_filter = this.filterService.getItem(this.slug);
          }

        } else {
          if (data.message == "NoStock") {
            this.toastr.success("No stocks match your search criteria. Click on the Reset button and try again.");
          }
        }
      },
        (error) => {
          throw error;
        });
  }

  checkFormData() {
    if (this.selectedcompanies.length == 0 &&
      this.selectedstocks.length == 1 &&
      this.selectedindustries.length == 0 &&
      this.selectedsectors.length == 0 &&
      this.selectedmarket.length == 0 &&
      this.selectedstockMulti.length == 0 &&
      this.selectedcompanyMulti.length == 0 &&
      this.selectedindustryMulti.length == 0 &&
      this.selectedsectorMulti.length == 0 &&
      this.selectedcriteria_5 == "" &&
      this.selectedcriteria_10 == "" &&
      this.selectedcriteria_15 == "" &&
      this.selectedcriteria_20 == "" &&
      this.selectedvalued_5 == "" &&
      this.selectedvalued_10 == "" &&
      this.selectedvalued_15 == "" &&
      this.selectedvalued_20 == "" &&
      this.selectConsecutiveData == "") {
      return true;
    } else if (this.selectedcompanies.length == 1 &&
      this.selectedstocks.length == 0 &&
      this.selectedindustries.length == 0 &&
      this.selectedsectors.length == 0 &&
      this.selectedmarket.length == 0 &&
      this.selectedstockMulti.length == 0 &&
      this.selectedcompanyMulti.length == 0 &&
      this.selectedindustryMulti.length == 0 &&
      this.selectedsectorMulti.length == 0 &&
      this.selectedcriteria_5 == "" &&
      this.selectedcriteria_10 == "" &&
      this.selectedcriteria_15 == "" &&
      this.selectedcriteria_20 == "" &&
      this.selectedvalued_5 == "" &&
      this.selectedvalued_10 == "" &&
      this.selectedvalued_15 == "" &&
      this.selectedvalued_20 == "" &&
      this.selectConsecutiveData == "") {
      return true;
    } else {
      return false;
    }
  }

  scrollLeft() {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
  }

  scrollRight() {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
  }


  addToList(type, stock) {
    // this.stocksService.addToList(type, stock.stock_id, this.userService.user_id).subscribe((data: any) => {
      if (type == 'watchlist') {
        if (this.selected_watchlist_ids.indexOf(stock.stock_id) !== -1) {
          this.removeFromList(type, stock);
        } else {
          this.saveToList(type, stock);
        }
      } else if (type == 'stocks') {
        if (this.selected_stocks_ids.indexOf(stock.stock_id) !== -1) {
          this.removeFromList(type, stock);
        }
      }
    // },
    //   (error) => {
    //     throw error;
    //   });

  }

  addStocksToList() {
    var selectedstocks = this.stockForms.controls['stock_id'].value;
    var quantity = this.stockForms.controls['quantity'].value;
    var account = this.stockForms.controls['account'].value;
    this.stocksService.saveUserStocks(selectedstocks, quantity, account, this.userService.user_id)
      .subscribe((data: any) => {
        if (data != null) {
          if (this.selected_stocks_ids.indexOf(data.data.stock_id) != -1) {
            this.selected_stocks_ids.splice(this.selected_stocks_ids.indexOf(data.data.stock_id), 1);
          }
        }
        if (data.success) {
          this.toastr.success(data.message);
          this.modalReference.close();
          this.getUserSavedStocksIds();
        } else if (data.success == false) {
          this.toastr.error(data.message);
        }
      },
        (error) => {
          throw error;
        });
  }

  saveToList(type, stock) {
    this.stocksService.addToList(type, stock.stock_id, this.userService.user_id).subscribe((data: any) => {
      if (data.success == true) {
        if (type == 'watchlist') {
          this.selected_watchlist_ids.push(stock.stock_id);
          this.toastr.success(data.message);
        } else if (type == 'stocks') {
          this.selected_stocks_ids.push(stock.stock_id);
          this.toastr.success(data.message);
        }
      } else if (data.success == false) {
        this.toastr.error(data.message);
      }
    },
      (error) => {
        throw error;
      });
  }

  removeFromList(type, stock) {
    this.stocksService.addToList(type, stock.stock_id, this.userService.user_id).subscribe((data: any) => {
      if (type == "watchlist") {
        if (this.selected_watchlist_ids.indexOf(data.data.stock_id) != -1) {
          this.selected_watchlist_ids.splice(this.selected_stocks_ids.indexOf(data.data.stock_id), 1);
        }
      } else if (type == "stocks") {
        if (this.selected_stocks_ids.indexOf(data.data.stock_id) != -1) {
          this.selected_stocks_ids.splice(this.selected_stocks_ids.indexOf(data.data.stock_id), 1);
        }
      }
      if (data.success == true) {
        this.toastr.success(data.message);
        this.getUserSavedWatchListIds();
        this.getUserSavedStocksIds();
        this.ngAfterViewInit();
      } else if (data.success == false) {
        this.toastr.error(data.message);
        this.getUserSavedWatchListIds();
        this.getUserSavedStocksIds();
        this.ngAfterViewInit();
      }
    },
      (error) => {
        throw error;
      });
  }

  getIcon(icon_type: string, stock) {

    if (icon_type == 'watchlist') {

      if (this.selected_watchlist_ids.indexOf(stock.stock_id) !== -1) {
        return "assets/images/marketing-yellow.png";
      } else {
        return "assets/images/marketing.png";
      }
    } else if (icon_type == 'stocks') {

      if (this.selected_stocks_ids.indexOf(stock.stock_id) !== -1) {
        return "assets/images/stock-icon2.png";
      } else {
        return "assets/images/growth.png";
      }
    } else if (icon_type == 'alerts') {
      if (this.selected_alert_ids != undefined) {
        if (this.selected_alert_ids.indexOf(stock.stock_id) !== -1) {
          return "assets/images/alerts-blue.png";
        } else {
          return "assets/images/alerts.png";
        }
      } else {
        return "assets/images/alerts.png";
      }
    } else {

    }


  }

  getTitle(icon_type: string, stock) {
    if (icon_type == 'watchlist') {

      if (this.selected_watchlist_ids.indexOf(stock.stock_id) !== -1) {
        return "Remove From My Watchlist ";
      } else {
        return "Add to My Watchlist";
      }
    } else if (icon_type == 'stocks') {

      if (this.selected_stocks_ids.indexOf(stock.stock_id) !== -1) {
        return "Remove From My Stocks";
      } else {
        return "Add to My Stocks";
      }
    } else if (icon_type == 'alerts') {
      if (this.selected_alert_ids != undefined) {
        if (this.selected_alert_ids.indexOf(stock.stock_id) !== -1) {
          return "View My Alerts";
        } else {
          return "Add to My Alerts";
        }
      } else {
        return "Add to My Alerts";
      }
    }
  }

  opendialog(content, stock) {
    if (this.selected_stocks_ids.indexOf(stock.stock_id) !== -1) {
      this.router.navigate(['/user/my-stocks']);
      // this.addToList("stocks", stock);
    } else {
      let newStockName = (stock.longname) + "  " + ('(' + stock.symbolstring + ')');

      this.stockForms.controls['symbol'].setValue(newStockName);
      this.stockForms.controls['stock_id'].setValue(stock.stock_id);
      this.modalReference = this.modalService.open(content, { size: 'md', ariaLabelledBy: 'modal-basic-title' });
      this.modalReference.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }


  opendialogDropwodn(content) {

    // this.stockForms.controls['symbol'].setValue(newStockName);
    // this.stockForms.controls['stock_id'].setValue(stock.stock_id);
    this.modalReference = this.modalService.open(content, { size: 'md', ariaLabelledBy: 'modal-basic-title' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getUserSavedWatchListIds() {
    this.stocksService.getAllWatchlistStockIds(this.userService.user_id).subscribe((data: any) => {
      if (data.success == true) {
        this.selected_watchlist_ids = data.data.ids;
      } else if (data.success == false) {
        // console.log(data.message);
      }
    },
      (error) => {
        throw error;
      });
  }


  getUserSavedStocksIds() {
    this.stocksService.getAllSavedStockIds(this.userService.user_id).subscribe((data: any) => {
      if (data.success == true) {
        this.selected_stocks_ids = data.data.ids;
      } else if (data.success == false) {
        // console.log(data.message);
      }
    },
      (error) => {
        throw error;
      });
  }

  getUserSavedAlertIds() {
    this.stocksService.getAllUserAlerts(this.userService.user_id).subscribe((data: any) => {
      if (data.success == true) {
        this.selected_alert_ids = data.data;
      } else if (data.success == false) {

        // console.log(data.message);
      }
    },
      (error) => {
        throw error;
      });
  }

  getColCss(column_id, stock_data) {
    let dummy_column_index5 = stock_data.values_data.findIndex(x => x.column_id == "60781c759008e551ad66d40c");
    let dummy_column_index10 = stock_data.values_data.findIndex(x => x.column_id == "60791e199008d379355a36f9");
    let dummy_column_index15 = stock_data.values_data.findIndex(x => x.column_id == "60791e219008d379355a36fb");
    let dummy_column_index20 = stock_data.values_data.findIndex(x => x.column_id == "60791e299008d379355a36fd");
    let dummy_column_dy = stock_data.values_data.findIndex(x => x.column_id == "63c9355b2265834d3487fcf2");

    if (dummy_column_dy != -1) {
      var values = stock_data.values_data[dummy_column_dy]['column_value'];
      for (let val in values) {
        if (values[val] == column_id) {
          return "highlightDY";
        }
      }
    }
    if (dummy_column_index5 != -1) {
      var values = stock_data.values_data[dummy_column_index5]['column_value'];
      for (let val in values) {
        if (values[val] == column_id) {
          return "highlight5";
        }
      }
    }
    if (dummy_column_index10 != -1) {
      var values = stock_data.values_data[dummy_column_index10]['column_value'];
      for (
        let val in values) {
        if (values[val] == column_id) {
          return "highlight10";
        }
      }
    }
    if (dummy_column_index15 != -1) {
      var values = stock_data.values_data[dummy_column_index15]['column_value'];
      for (let val in values) {
        if (values[val] == column_id) {
          return "highlight15";
        }
      }
    }
    if (dummy_column_index20 != -1) {
      var values = stock_data.values_data[dummy_column_index20]['column_value'];
      for (let val in values) {
        if (values[val] == column_id) {
          return "highlight20";
        }
      }
    }
  }

  getColVal(column_id, stock_data) {
    let index = stock_data.values_data.findIndex(x => x.column_id == column_id);

    var val = "";
    if (index != -1) {
      if (typeof stock_data.values_data[index]['column_value'] === "number") {
        if (Number.isInteger(stock_data.values_data[index]['column_value'])) {
          val = stock_data.values_data[index]['column_value'];
          if (this.header[column_id] == "Dividends Paid Since" || this.header[column_id] == "Annual Forward Dividend vs Previous Year, Rule #9") {
            if (this.header[column_id] == "Annual Forward Dividend vs Previous Year, Rule #9") {
              if (stock_data.values_data[index]['column_value'] == 0) {
                return Number(val) + "%";
              } else {
                return Number(val).toFixed(2) + "%";
              }
            }
            return val;
          } else {
            val = val.toLocaleString();
          }
          if (this.header[column_id] == "SI Criteria (out of 10) using 20-year data") {
            if (stock_data.values_data[index]['column_value'] == 0) {
              return Number(val);
            }
          }
          if (this.header[column_id] == "Consecutive Years of Dividend Increases" || this.header[column_id] == "Consecutive Years of EPS Increases") {
            return val;
          }
          if (this.header[column_id] == "Annual Forward Dividend") {
            return "$" + val;
          }
          if (stock_data.values_data[index]['column_value'] == 0) {
            val = "N/A";
          }
        } else {
          if (this.header[column_id] == "Annual Forward Dividend") {
            return "$" + parseFloat(stock_data.values_data[index]['column_value']).toFixed(2);
          }
          if (this.header[column_id] == "Annual Forward Dividend vs Previous Year, Rule #9") {
            val = parseFloat(stock_data.values_data[index]['column_value']).toFixed(2) + "%";
          } else {
            val = parseFloat(stock_data.values_data[index]['column_value']).toFixed(2);
          }
        }
      } else {
        if (stock_data.values_data[index]['column_value'] == "") {
          return val = "N/A";
        } else {
          val = stock_data.values_data[index]['column_value'];
        }
        if (stock_data.values_data[index]['column_value'] == null) {
          val = "N/A";
        } else {
          val = stock_data.values_data[index]['column_value'];
        }
        // val = stock_data.values_data[index]['column_value'];
      }
    } else {
      val = "N/A";
    }
    var percent_arr = ['%difference between Graham Price vs Share Price',
      'Average Dividend Growth (5-year), Rule #6', 'Average Dividend Growth (10-year), Rule #6',
      'Average Dividend Growth (15-year), Rule #6', 'Average Dividend Growth (20-year), Rule #6',
      'Average Dividend Yield (5-year)', 'Average Dividend Yield (10-year)',
      'Average Dividend Yield (15-year)', 'Average Dividend Yield (20-year)',
      'Average EPS Growth (5-year), Rule #5a', 'Average EPS Growth (10-year), Rule #5a',
      'Average EPS Growth (15-year), Rule #5a',
      'Average EPS Growth (20-year), Rule #5a',
      'Average High Dividend Yield (5-year)',
      'Average High Dividend Yield (10-year)',
      'Average High Dividend Yield (15-year)',
      'Average High Dividend Yield (20-year)',
      'Average Low Dividend Yield (5-year)',
      'Average Low Dividend Yield (10-year)',
      'Average Low Dividend Yield (15-year)',
      'Average Low Dividend Yield (20-year)',
      'Current Dividend Yield',
      'Difference Between Current Dividend Yield and Average Dividend Yield (5-year)',
      'Difference Between Current Dividend Yield and Average Dividend Yield (10-year)',
      'Difference Between Current Dividend Yield and Average Dividend Yield (15-year)',
      'Difference Between Current Dividend Yield and Average Dividend Yield (20-year)',
      'Profit Margin (Total Operating)',
      'Total Debt to Equity Ratio',
      'Payout Ratio, Rule #7',
      'Long-term Debt/Equity Ratio, Rule #8',
      // 'Annual Forward Dividend vs Previous Year, Rule #9'
    ]
    if (percent_arr.indexOf(this.header[column_id]) !== -1) {
      if (val != "N/A" && val != null && val != "") {
        if (val != "0") {
          val = val + "%";
        } else {
          val = "N/A";
        }
      }
    }
    let dummy_column_dy = stock_data.values_data.findIndex(x => x.column_id == "63c9355b2265834d3487fcf2");
      if (this.header[column_id] == "Current Dividend Yield" && dummy_column_dy != -1) {
        var values = stock_data.values_data[dummy_column_dy]['column_value'];
        for (let vale in values) {
          if (values[vale] == column_id) {
            return val + ' <a target="_blank" href="user/pages/dividend-trap">DT</a>';
          }
        }
      }
    return val;
  }

  dropListDropped(event: CdkDragDrop<string[]>) {

    this.displayedColumns.splice(0, 1);
    this.displayedColumns.splice(0, 1);
    this.displayedColumns.splice(0, 1);

    var element_id = this.displayedColumns[event.previousIndex];
    this.displayedColumns.splice(event.previousIndex, 1);
    this.displayedColumns.splice(event.currentIndex, 0, element_id);
    for (let i = 0; i < this.displayedColumns.length; i++) {
      let index = this.selected_filter.columns.findIndex(x => x._id == this.displayedColumns[i]);
      if (index != -1) {
        this.selected_filter.columns[index].column_order = i;
      }
    }
    this.displayedColumns.splice(0, 0, '1');
    this.displayedColumns.splice(1, 0, '2');
    this.displayedColumns.splice(2, 0, '3');
    this.filterService.setItem(this.slug, this.selected_filter);
  }

  removeColumn(index) {
    let sel_index = this.selected_filter.columns.findIndex(x => x._id == this.displayedColumns[index]);
    if (sel_index != -1) {
      this.selected_filter.columns[sel_index].show_column = 0;
    }
    this.displayedColumns.splice(index, 1);
    this.filterService.setItem(this.slug, this.selected_filter);
  }

  clearStockForm() {
    this.stocksListData = [];
    localStorage.removeItem('active-form');
    localStorage.removeItem("search-stock2");
    localStorage.removeItem("search-stock-con");
    localStorage.removeItem("checkMarket");
    this.dividendIncreaseValue = "";
    this.clearForm();
    this.getIndustries('symbolstring', 'asc', 0, 200, '');
    this.getSectors('symbolstring', 'asc', 0, 200, '');
    this.getUserSavedStocks();

  }

  clearForm() {
    this.stockForm.controls['industry'].setValue([]);
    this.stockForm.controls['sector'].setValue([]);
    this.stockForm.controls['market'].setValue([]);
    this.stockForm.controls['stockMultiFilterCtrl'].setValue("");
    this.stockForm.controls['companyMultiFilterCtrl'].setValue("");
    this.stockForm.controls['industryMultiFilterCtrl'].setValue("");
    this.stockForm.controls['sectorMultiFilterCtrl'].setValue("");
    this.stockForm.controls['criteria_5'].setValue("");
    this.stockForm.controls['criteria_10'].setValue("");
    this.stockForm.controls['criteria_15'].setValue("");
    this.stockForm.controls['criteria_20'].setValue("");
    this.stockForm.controls['valued_5'].setValue("");
    this.stockForm.controls['valued_10'].setValue("");
    this.stockForm.controls['valued_15'].setValue("");
    this.stockForm.controls['valued_20'].setValue("");

    this.stockForm.controls['consecutiveData'].setValue("");

    const consecutiveIndex = this.consecutive_Years.findIndex((data) => data.key == 'custome');
    const previousKey = this.consecutive_Years[consecutiveIndex].key;
    this.dividendIncreaseValue = "Enter your own value";
    this.consecutive_Years[consecutiveIndex].value = this.dividendIncreaseValue;
    this.consecutive_Years[consecutiveIndex].key = previousKey;

    this.selected_filter = null;
    this.selectedindustries = [];
    this.selectedsectors = [];
    this.selectedmarket = "";
    this.custome_filter = "Default filter";

  }

  clickEvent() {
    this.status = !this.status;
    this.filterService.setSideMenuStaus(!this.status);
  }

  upgradeNow() {
    window.location.href = "https://www.simplyinvesting.com/invest";
  }

  freeandcourse(content, type: string) {
    if (type == "tsxdow") {
      this.messages = "This feature is not available in the Free Account. <br>";
    }
    if (type == "stocks") {
      this.messages = "Your account is not permitted to add this stock to your list of My Stocks. <br> This feature is only available to SI Report & Platform members.";
    }
    if (type == "watchlist") {
      this.messages = "Your account is not permitted to add this stock to your Watchlist. <br> This feature is only available to SI Report & Platform members.";
    }
    if (type == "alerts") {
      this.messages = "Your account is not permitted to add this stock to your Alerts. <br> This feature is only available to SI Report & Platform members.";
    }
    this.modalService.open(content, { size: 'md', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  getToolTips() {
    this.pagesService.getToolTips(this.tooltip)
      .subscribe(data => {
        if (data.success) {
          this.allToolTips = data.data;
        }
      },
        (error) => {
          throw error;
        });
  }

  showToolTipData(column_id) {
    this.disaplayToolTip = "";
    let index = this.allToolTips.findIndex(x => x.column_name == column_id);
    if (index != -1) {
      this.disaplayToolTip = this.allToolTips[index].tool_tips;
      return true;
    } else {
      return false;
    }
  }

  getPageContent() {
    var slugs = "search-stock";
    this.pagesService.getPageContent(slugs)
      .subscribe((data: any) => {
        if (data.success) {
          this.content = data.data.content;
          // if (data.data.video_path) {
          //   var path = data.data.video_path;
          //   this.videoSource = path.replace("src/", "");
          // }
        } else if (data.success == false) {
          this.toastr.error(data.message);
        }
      },
        (error) => {
          throw error;
        });
  }

  toggleVideo(event: any) {
    this.videoplayer.nativeElement.play();
  }

  onCheckboxChangeUs(event: MatRadioChange) {
    if (this.stockForm.controls['market'].value !== "canada" || this.stockForm.controls['market'].value == "") {
      this.selected_filter = null;
      this.canada_stocks = false;
      this.market = 'usa';
      this.paginator.pageIndex = 0;
      if (this.filterService.getCompareStockCountry('search-stock-con') != null) {
        localStorage.removeItem('search-stock-con');
        this.filterService.setCompareStockCountry('search-stock-con', this.market);
      } else {
        this.filterService.setCompareStockCountry('search-stock-con', this.market);
      }
      var formData = this.filterService.getFormValue("search-stock");
      if (formData !== null) {
        this.displayTable(formData);
        this.ngAfterViewInit();
      } else {
        formData = null;
      }
      this.custome_filter = "Default filter";
      this.stocks_radio = "us_stocks";
      this.userSideMenuCom.closeModal();
    } else {
      this.toastr.success("* This Option is not working because Canadian market is selected *")
    }
  }

  onCheckboxChangeTsx(event: MatRadioChange) {
    if (this.stockForm.controls['market'].value !== "us" || this.stockForm.controls['market'].value == "") {
      this.selected_filter = null;
      this.us_stocks = false;
      this.market = 'canada';
      if (this.filterService.getCompareStockCountry('search-stock-con') != null) {
        localStorage.removeItem('search-stock-con');
        this.filterService.setCompareStockCountry('search-stock-con', this.market);
      } else {
        this.filterService.setCompareStockCountry('search-stock-con', this.market);
      }
      this.paginator.pageIndex = 0;
      var formData = this.filterService.getFormValue("search-stock");
      if (formData !== null) {
        this.displayTable(formData);
        this.ngAfterViewInit();
      } else {
        formData = null;
      }
      this.custome_filter = "Default filter";
      this.stocks_radio = "canada_stocks";
      this.userSideMenuCom.closeModal();
    } else {
      this.toastr.success("* This Option is not working because US market is selected *")
    }
  }

  clearFormFieldss() {
    localStorage.removeItem("search-stock2");
  }

  changeSearchCard(searchForm: string) {
    if (searchForm == 'basic') {
      this.basicSearch = true;
      this.advanceSearch = false;
    } else if (searchForm == 'advance') {
      this.advanceSearch = true;
      this.basicSearch = false;
    } else {
      this.basicSearch = false;
      this.advanceSearch = false;
    }
    this.changeTagColor(searchForm);
  }

  changeTagColor(searchForm: string) {
    if (searchForm == 'basic') {
      if (this.basicSearch) {
        return "activebcards";
      } else {
        return "bcards";
      }
    } else if (searchForm == 'advance') {
      if (this.advanceSearch) {
        return "activebcards";
      } else {
        return "bcards";
      }
    }
  }

  openFilterpop() {
    if (this.userService.user_type == "Free_platform" || this.userService.user_type == "course_platform" || this.userService.user_type == "Free_platformCourse_platform") {
      this.modelService.confirmMessage().pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    } else {
      this.modelService.confirm().pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    }
  }

  restore() {
    if (this.userService.user_type == "course_platform") {
      this.modelService.confirmMessage().pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    } else {
      this.userSideMenuCom.restore();
    }
  }

  columnDefination() {
    if (this.userService.user_type == "course_platform") {
      this.modelService.confirmMessage().pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    } else {
      this.router.navigate([]).then(result => {
        window.open('/user/pages/column-definitions', '_blank');
      })
    }
  }

  navigetToalert(data: any) {
    this.router.navigate([]).then((result) => {
      let encoID = btoa(data.symbolstring);
      window.open('/user/my-alert/' + encoID, '_blank');
    });
  }

  loginPopUpNow() {
    this.modelService.logoutPop().pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => { });
  }

  getVideoID() {
    var slugs = "search-stock";
    this.userService.getVideoID(slugs)
      .subscribe((data: any) => {
        data = data.data;
        this.videoID = data.videoID;
        this.videoHeading = data.heading;
      });
  }

  openVideoPop() {
    if (this.videoID !== undefined && this.videoHeading !== undefined) {
      this.modelService.videoPop(this.videoID, this.videoHeading).pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    } else {
      this.videoHeading = "Video is not available on this page.";
      this.videoID = "";
      this.modelService.videoPop(this.videoID, this.videoHeading).pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    }
  }
}
