import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserService } from '../../../services/admin/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  userId: string;
  error = '';
  constructor(
    public formBuilder: FormBuilder,
    public userService: UserService,
    private route: ActivatedRoute,
    public router: Router,
    private toastr: ToastrService
  ) {
    this.route.params.subscribe(params => {
      this.userId = params.id;
      this.getQuestion();
    });
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      question: ['', [Validators.required]],
      answer: ['', Validators.required]
    });
  }

  get f() { return this.loginForm.controls; }

  loginUser() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.userService.loginPart(this.loginForm.value, this.userId)
      .pipe(first())
      .subscribe((data: any) => {
        if (data.success) {
          if (data.message == "Auth Successful") {
            localStorage.setItem('access_token', data.token);
            window.location.href = 'https://platform.simplyinvesting.com/admin/pages';
            // this.router.navigate(['admin/pages']);
          }
        } else {
          this.toastr.error("Error in login...Please check you credentials");
          setTimeout(() => {
            this.router.navigate(['admks/login']);
          }, 2000);
        }
        // localStorage.setItem('access_token', data.token);
        // // window.location.href = 'http://platform.simplyinvesting.com/admin/pages';
        // this.router.navigate(['admin/pages']);

      },
        (err: HttpErrorResponse) => {
          this.toastr.error("Error in login...Please check you credentials");
        });
  }

  getQuestion() {
    this.userService.getQuestion(this.userId)
      .subscribe((data: any) => {
        if (data.success == true) {
          this.loginForm.controls['question'].setValue(data.data);
        }
      },
        (err: HttpErrorResponse) => {
          // this.toastr.error('Error in updating Column');
        });
  }

}
