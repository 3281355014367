import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class StocksService {

  constructor(private httpClient: HttpClient, public router: Router) { }

  getAllStocks(sort: string, order: string, page: number, pagesize: number, filter: string, selected_stocks: Array<any>, user_type: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/all-stocks' + '/' + sort + '/' + order + '/' + page + '/' + pagesize, { 'term': filter, 'selected_ids': selected_stocks, 'user_type': user_type })
      .pipe(
        tap(),
        catchError(this.handleError('getFilterComponentList', []))
      );
  }

  getAllUserSavedStocks(filter_column: string, filter: string, filter_op: string, sort: string, order: string, page: number, pagesize: number, user_id: string, selected_filter: object, stock_types: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/get-user-stocks-list', { 'sort': sort, 'order': order, 'page': page, 'pagesize': pagesize, 'filter': filter, 'filter_column': filter_column, 'filter_op': filter_op, 'user_id': user_id, 'selected_filter': selected_filter, 'stock_types': stock_types })
      .pipe(
        tap(),
        catchError(this.handleError('updatePageDetails', []))
      );
  }

  getUserStocksHistory(filter: string, sort: string, order: string, page: number, pagesize: number): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/get-stocks-history', { 'sort': sort, 'order': order, 'page': page, 'pagesize': pagesize, 'filter': filter })
      .pipe(
        tap(),
        catchError(this.handleError('updatePageDetails', []))
      );
  }



  saveUserStocks(selected_stocks: Array<any>, quantity: number, account: string, user_id: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/save_user_stocks', { 'selected_stocks': selected_stocks, 'user_id': user_id, 'account': account, 'quantity': quantity })
      .pipe(
        tap(),
        catchError(this.handleError('updateJobCategory', []))
      );
  }

  saveUserStocksQuantity(selected_stocks: Array<any>, quantity: number, account: string, user_id: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/save_user_stocks_quantity', { 'selected_stocks': selected_stocks, 'user_id': user_id, 'account': account, 'quantity': quantity })
      .pipe(
        tap(),
        catchError(this.handleError('saveUserStocksQuantity', []))
      );
  }

  getAllWatchlistStocks(filter_column: string, filter: string, filter_op: string, sort: string, order: string, page: number, pagesize: number, user_id: string, selected_filter: object): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/get-user-watchlist-list', { 'sort': sort, 'order': order, 'page': page, 'pagesize': pagesize, 'filter': filter, 'filter_column': filter_column, 'filter_op': filter_op, 'user_id': user_id, 'selected_filter': selected_filter })
      .pipe(
        tap(),
        catchError(this.handleError('updatePageDetails', []))
      );
  }

  saveWatchlistStocks(selected_stocks: Array<any>, user_id: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/save-user-watchlist-stocks', { 'selected_stocks': selected_stocks, 'user_id': user_id })
      .pipe(
        tap(),
        catchError(this.handleError('updateJobCategory', []))
      );
  }

  getAllWatchlistStockIds(user_id: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/get-user-watchlist-stocks-ids', { 'user_id': user_id })
      .pipe(
        tap(),
        catchError(this.handleError('updatePageDetails', []))
      );
  }

  getAllSavedStockIds(user_id: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/get-user-saved-stocks-ids', { 'user_id': user_id })
      .pipe(
        tap(),
        catchError(this.handleError('updateJobCategory', []))
      );
  }

  addToList(type: string, stock_id: any, user_id: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/save-to-list', { 'user_id': user_id, 'type': type, 'stock_id': stock_id })
      .pipe(
        tap(),
        catchError(this.handleError('updateJobCategory', []))
      );
  }

  getComparedStocks(filter_column: string, filter: string, filter_op: string, sort: string, order: string, page: number, pagesize: number, selected_stocks: Array<any>, selected_filter: object, market: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/get-compared-stocks-list', { 'sort': sort, 'order': order, 'page': page, 'pagesize': pagesize, 'filter': filter, 'filter_column': filter_column, 'filter_op': filter_op, 'selected_stocks': selected_stocks, 'selected_filter': selected_filter, 'market': market })
      .pipe(
        tap(),
        catchError(this.handleError('updatePageDetails', []))
      );
  }

  getCompanyNames(sort: string, order: string, page: number, pagesize: number, filter: string, selected_names: Array<any>, user_type: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/all-company-names' + '/' + sort + '/' + order + '/' + page + '/' + pagesize, { 'term': filter, 'selected_ids': selected_names, 'user_type': user_type })
      .pipe(
        tap(),
        catchError(this.handleError('getFilterComponentList', []))
      );
  }

  getSectors(sort: string, order: string, page: number, pagesize: number, filter: string, selected_names: Array<any>, user_type: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/all-sector-names' + '/' + sort + '/' + order + '/' + page + '/' + pagesize, { 'term': filter, 'selected_ids': selected_names, 'user_type': user_type })
      .pipe(
        tap(),
        catchError(this.handleError('getFilterComponentList', []))
      );
  }

  getIndustries(sort: string, order: string, page: number, pagesize: number, filter: string, selected_names: Array<any>, user_type: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/all-industry-names' + '/' + sort + '/' + order + '/' + page + '/' + pagesize, { 'term': filter, 'selected_ids': selected_names, 'user_type': user_type })
      .pipe(
        tap(),
        catchError(this.handleError('getFilterComponentList', []))
      );
  }

  getSearchedStocks(filter_column: string, filter: string, filter_op: string, sort: string, order: string, page: number, pagesize: number, selected_filter: object, form_data: object, user_type: string, market: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/get-searched-stocks-list', { 'filter_column': filter_column, 'filter_op': filter_op, 'filter': filter, 'sort': sort, 'order': order, 'page': page, 'pagesize': pagesize, 'selected_filter': selected_filter, 'form_data': form_data, 'user_type': user_type, 'market': market })
      .pipe(
        tap(),
        catchError(this.handleError('updatePageDetails', []))
      );
  }

  getStockDetails(symbol: string, selected_filter: object): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/get-stock-details', { 'symbol': symbol, 'selected_filter': selected_filter })
      .pipe(
        tap(),
        catchError(this.handleError('updatePageDetails', []))
      );
  }

  getAllUserAlerts(user_id: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/get-alerts-list', { 'user_id': user_id })
      .pipe(
        tap(),
        catchError(this.handleError('updateJobCategory', []))
      );
  }

  getGraphData(form_data: object): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/get-graph-data', { 'form_data': form_data })
      .pipe(
        tap(),
        catchError(this.handleError('updateJobCategory', []))
      );
  }

  getTsx60StocksList(slug: string, sort: string, order: string, page: number, pagesize: number): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/get-tsx60-data', { 'slug': slug, 'sort': sort, 'order': order, 'page': page, 'pagesize': pagesize })
      .pipe(
        tap(),
        catchError(this.handleError('updateJobCategory', []))
      );
  }

  getDow30StocksList(slug: string, sort: string, order: string, page: number, pagesize: number): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/get-dow30-data', { 'slug': slug, 'sort': sort, 'order': order, 'page': page, 'pagesize': pagesize })
      .pipe(
        tap(),
        catchError(this.handleError('updateJobCategory', []))
      );
  }

  getSelectedStocks(stocks_ids: any): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/get-selectedStocks-data', { 'stocks_ids': stocks_ids })
      .pipe(
        tap(),
        catchError(this.handleError('updateJobCategory', []))
      );
  }

  /**
* Handle Http operation that failed.
* Let the app continue.
* @param operation - name of the operation that failed
* @param result - optional value to return as the observable result
*/
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      //console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */

}
