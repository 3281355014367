import { Component, OnInit } from '@angular/core';
import { PagesService } from '../../../services/admin/pages.service';
import { ToastrService } from 'ngx-toastr';
import { Router , ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-archives',
  templateUrl: './archives.component.html',
  styleUrls: ['./archives.component.css']
})
export class ArchivesComponent implements OnInit {
  pageNames: string[] = [];
  years: string[] = [];
  months: string[] = [];
  tableData: any[] = [];
  header = {};
  displayName: string[] = [];

  month = new Date().getMonth()+1;
  ys=new Date().getFullYear();
  year = [2021,2022,2023,2024,2025,2026,2027,2028,2029,2030,2031,2032,2033,2034,
         2035,2036,2037,2038,2039,2040,2041,2042,2043,2044,2045,2046,2047,2048,2049,2050,2051,2052,2053,2054,2055];
 
  monthnum = [
    { name: 'January', id: 1 },
    { name: 'February', id: 2 },
    { name: 'March', id: 3 },
    { name: 'April', id: 4 },
    { name: 'May', id: 5 },
    { name: 'June', id: 6 },
    { name: 'July', id: 7 },
    { name: 'August', id: 8 },
    { name: 'September', id: 9 },
    { name: 'October', id: 10 },
    { name: 'November', id: 11 },
    { name: 'December', id: 12 },
  ]

  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private pagesService: PagesService,
    private toastr: ToastrService
    ) { }
    
    ngOnInit(): void {
      // this.getAllStockList();
    }
    
    // getAllStockList() {
      //   this.pagesService.getAllMonthStockList()
  //     .subscribe((response: any) => {
  //       this.displayName = response.data;
        
  //     },
  //       (err: HttpErrorResponse) => {
  //         this.toastr.error('Error in updating Column');
  //       });
  // }

}
