import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { PagesService } from '../../../services/admin/pages.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { StocksService } from '../../../services/admin/stocks.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-dividend-stock',
  templateUrl: './dividend-stock.component.html',
  styleUrls: ['./dividend-stock.component.css']
})
export class DividendStockComponent implements AfterViewInit {
  dividend_stocks: string[] = [];
  closeResult: string;
  recordList: object[] = [];
  displayedColumns: string[] = ["symbolstring", "exchange", "dividend_currency", "dividend_frequency", "dividend_payment_date", "dividend_record_date", "dividend_amount", "action"];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  filterValue = "";
  modalReference: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('fileInput') fileInput: ElementRef;
  constructor(
    private router: Router,
    private pagesService: PagesService,
    private stocksService: StocksService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    public fb: FormBuilder,
  ) { }

  stockForm = new FormGroup({
    symbol: new FormControl('', [Validators.required]),
    exchange: new FormControl('', [Validators.required]),
    currency: new FormControl('', [Validators.required]),
    frequency: new FormControl('', [Validators.required]),
    payment_date: new FormControl('', [Validators.required]),
    amount: new FormControl('', [Validators.required]),
    record_date: new FormControl('', [Validators.required]),
  });

  get st() { return this.stockForm.controls; }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.pagesService!.getDividendStocks(this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize, this.filterValue);
        }),
        map((data: any) => {
          this.resultsLength = data.count;
          return data.data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe((data: any) => {
        // console.log(data);

        this.recordList = data
        console.log(this.recordList);

      });
  }


  getDividendStocks() {
    this.pagesService.getDividendStocks('symbolstring', 'asc', 0, 10, this.filterValue)
      .subscribe((data: any) => {
        if (data.success == true) {
          this.recordList = data.data
          this.resultsLength = data.count;
        } else {
          this.toastr.error(data.message, 'Column');
        }
      },
        (err: HttpErrorResponse) => {
          this.toastr.error('Error in updating Column');
        });
  }

  deleteDividendStock(id: any) {
    if (window.confirm('Are sure you want to delete this Stock ?')) {
      this.stocksService.deleteDividendStock(id)
        .subscribe((data: any) => {
          if (data.success) {
            this.toastr.success(data.message);
          }
          this.ngAfterViewInit();
        },
          (err: HttpErrorResponse) => {
            alert("Refresh the page");
          });
    }
  }

  opendialog(content) {
    this.modalReference = this.modalService.open(content, { size: 'md', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  addStocksToList() {
    var symbol = this.stockForm.controls['symbol'].value;
    var exchange = this.stockForm.controls['exchange'].value;
    var currency = this.stockForm.controls['currency'].value;
    var frequency = this.stockForm.controls['frequency'].value;
    var payment_date = this.stockForm.controls['payment_date'].value;
    var amount = this.stockForm.controls['amount'].value;
    var record_date = this.stockForm.controls['record_date'].value;
    this.stocksService.saveNewDividendStock(symbol, exchange, currency, frequency, payment_date, record_date, amount)
      .subscribe((data: any) => {
        if (data.success) {
          this.toastr.success(data.message);
          this.modalReference.close();
          this.getDividendStocks();
        } else {
          this.toastr.error(data.message);
        }
      },
        (err: HttpErrorResponse) => {
          alert("Server Not Connected");
        });
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.getDividendStocks();
  }

}
