import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { PageColumnsService } from '../../../services/admin/page-columns.service';
import { ColumnsService } from '../../../services/admin/columns.service';
import { StocksService } from '../../../services/admin/stocks.service';
import { ToastrService } from 'ngx-toastr';
import { ReplaySubject, Subject, merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-user-pages',
  templateUrl: './user-pages.component.html',
  styleUrls: ['./user-pages.component.css']
})
export class UserPagesComponent implements OnInit, AfterViewInit {

  submitted = false;
  error = '';
  page_id: string;
  stockDetail: object = {};
  stockForm: FormGroup;
  columnList = [];
  stocksList: [];
  pageDetail: [];
  public filteredStocksMulti: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _onDestroy = new Subject<void>();

  pageColumnsList: object[] = [];
  displayedColumns: string[] = ["column_order", "column_name", "column_filter", "show_column", "created_date"];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  slug = "";
  market = "";
  type = "";
  display_text = "";

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;



  constructor(public fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private pageColumnsService: PageColumnsService,
    private columnsService: ColumnsService,
    private stocksService: StocksService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.slug = this.route.snapshot.paramMap.get("slug");


    this.route.params.subscribe(data => {
      this.slug = data.slug;
      if (this.slug == 'compare-stocks') {
        this.display_text = "This page is for Admin to set Default Columns and All Columns to be displayed on Compare Stocks Page in User Site.";
      } else if (this.slug == 'quote') {
        this.display_text = "This page is for Admin to set Default Columns and All Columns to be displayed on Stock Quote Page in User Site.";
      } else if (this.slug == 'my-stocks') {
        this.display_text = "This page is for Admin to set Default Columns and All Columns to be displayed on My Stocks Page in User Site.";
      } else if (this.slug == 'my-stocks') {
        this.display_text = "This page is for Admin to set Default Columns and All Columns to be displayed on My Stocks Page in User Site.";
      } else if (this.slug == 'my-stocks') {
        this.display_text = "This page is for Admin to set Default Columns and All Columns to be displayed on My Stocks Page in User Site.";
      } else if (this.slug == 'my-watchlist') {
        this.display_text = "This page is for Admin to set Default Columns and All Columns to be displayed on My Watchlist Page in User Site.";
      } else if (this.slug == 'my-alerts') {
        this.display_text = "This page is for Admin to set Default Columns and All Columns to be displayed on My Alerts Page in User Site.";
      }
      this.getStocks();
      this.getColumns();
      this.getPageDetails();
    });

    this.getStocks();

    this.stockForm = this.fb.group({
      sort_column: ['', [Validators.required]],
      excluded_stocks: [''],
      stockMultiFilterCtrl: '',
      sortFilterCtrl: '',
    });
    this.getColumns();
    this.getPageDetails();

  }

  ngAfterViewInit() {


    //this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.sort.sortChange
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.pageColumnsService!.getOnlyColumns(
            this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize, this.slug);
        }),
        map(data => {


          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.count;

          return data.data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.pageColumnsList = data);
  }


  get f() { return this.stockForm.controls; }

  private filterStocksMulti(event: Event) {
    if (!this.stocksList) {
      return;
    }
    // get the search keyword
    let search = (event.target as HTMLInputElement).value;
    this.getStocks('symbolstring', 'asc', 0, 200, search);

  }

  private filterSortColumn(event: Event) {
    if (!this.stocksList) {
      return;
    }
    // get the search keyword
    let search = (event.target as HTMLInputElement).value;
    this.columnList = this.columnList.filter(x => x.column_name.toLowerCase().indexOf(search.toLowerCase()) > -1);
  }

  submitForm() {
    this.submitted = true;
    if (this.stockForm.invalid) {
      this.scrollToError();
      return;
    }


    this.pageColumnsService.updatePageDetails(this.slug, this.stockForm.value)
      .subscribe((data: any) => {
        if (data.success) {
          this.toastr.success(data.message);
        } else {
          this.toastr.error(data.message);
        }
      },
        (err: HttpErrorResponse) => {
          alert("Please refresh the page");
        });
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');

    this.scrollTo(firstElementWithError);
  }

  getColumns() {
    this.columnsService.getColumns('column_order', 'asc', 0, 150).subscribe((data: any) => {
      if (data.success == true) {
        this.columnList = data.data

      } else {
        this.toastr.error(data.message, 'Error in fetching column data');
      }
    },
      (err: HttpErrorResponse) => {
        this.toastr.error('Error in fetching columns');
      });
  }

  getStocks(symbolstring = "symbolstring", order = "asc", page = 0, limit = 200, filter = "") {
    this.stocksService.getAllStocks(symbolstring, order, page, limit, filter).subscribe((data: any) => {
      if (data.success == true) {
        this.stocksList = data.data

      } else {
        this.toastr.error(data.message, 'Column');
      }
    },
      (err: HttpErrorResponse) => {
        this.toastr.error('Error in fetching Stocks');
      });
  }

  getPageDetails(): void {
    //this.action_type = 'Update';
    this.pageColumnsService.getPageColumnsBySlug(this.slug)
      .subscribe((data: any) => {
        if (data.success) {
          this.pageDetail = data.data;
          this.stockForm.controls['sort_column'].setValue(this.pageDetail['sort_column']);
          this.stockForm.controls['excluded_stocks'].setValue(this.pageDetail['excluded_stocks']);
          this.pageColumnsList = this.pageDetail['column_ids']
        } else {
          this.toastr.error(data.message, 'Page Not Found');
        }
      },
        (err: HttpErrorResponse) => {
          alert("Server Get Job Page Detail Error");
        });
  }


  drop(event: CdkDragDrop<any>) {
    moveItemInArray(this.pageColumnsList, event.previousIndex, event.currentIndex);
    console.log(event.container.data);
    this.pageColumnsService.updateColumnOrder(this.slug, event.container.data)
      .subscribe((data: any) => {
        if (data.success == true) {
          this.getPageDetails();
          this.toastr.success(data.message);
        } else {
          this.toastr.error(data.message);
        }
      },
        (err: HttpErrorResponse) => {
          this.toastr.error('Error in updating Column');
        });
    //
  }

  changeShowColumn(row, event) {

    this.pageColumnsService.updateColumn(this.slug, row._id, 'show_column', event.checked)
      .subscribe((data: any) => {
        if (data.success == true) {
          this.toastr.success(data.message);
        } else {
          this.toastr.error(data.message);
        }
      },
        (err: HttpErrorResponse) => {
          this.toastr.error('Error in updating Column');
        });

  }

  changeFilterColumn(row, event) {
    this.pageColumnsService.updateColumn(this.slug, row._id, 'column_filter', event.checked)
      .subscribe((data: any) => {
        if (data.success == true) {
          this.toastr.success(data.message);
        } else {
          this.toastr.error(data.message);
        }
      },
        (err: HttpErrorResponse) => {
          this.toastr.error('Error in updating Column');
        });
  }

  changeAllPages(type, event) {
    if (event.checked) {
      this.pageColumnsService.updateAllTableColumn(this.slug)
        .subscribe((data: any) => {
          if (data.success == true) {
            this.toastr.success(data.message, 'Column');
            this.ngOnInit();
          } else {
            this.toastr.error(data.message, 'Column');
          }
        },
          (err: HttpErrorResponse) => {
            this.toastr.error('Error in updating Column');
          });
    } else {
      console.log("nahi chal na");
    }
  }

}

