import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { StocksService } from '../../../services/admin/stocks.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-stock',
  templateUrl: './add-stock.component.html',
  styleUrls: ['./add-stock.component.css']
})
export class AddStockComponent implements OnInit {

  filter = "";
  show_ids = [];
  stocksList: any[] = [];
  pagesize = 50;
  resultsLength = 0;
  closeResult: string;
  questionForm: FormGroup;
  constructor(
    private stocksService: StocksService,
    private toastr: ToastrService,
    public fb: FormBuilder,
    private modalService: NgbModal,
  ) { }

  stockForm = new FormGroup({
    stock_id: new FormControl('', [Validators.required]),
    stock_symbol: new FormControl('', [Validators.required]),
    comp_name: new FormControl('', [Validators.required]),
    exchange: new FormControl('', [Validators.required]),
  });

  get st() { return this.stockForm.controls; }

  ngOnInit(): void {
    this.questionForm = this.fb.group({
      question: ['', [Validators.required]],
      answer: ['', [Validators.required]],
    });
    this.getAllStocksList();
  }

  getAllStocksList() {
    this.stocksService.getAllStocksList(this.filter)
      .subscribe((data: any) => {
        if (data.success) {
          this.toastr.success(data.message);
          this.stocksList = data.data;
        } else {
          this.toastr.error(data.message);
        }
      },
        (err: HttpErrorResponse) => {
          alert("Server not conncted");
        });
  }

  applyFilter(event: Event) {
    this.filter = (<HTMLInputElement>document.getElementById("myValue")).value;
    this.getAllStocksList();
  }

  opendialog(content, symbol: string, company: string, id: string, exchange: string) {
    this.stockForm.controls['stock_id'].setValue(id);
    this.stockForm.controls['stock_symbol'].setValue(symbol);
    this.stockForm.controls['comp_name'].setValue(company);
    this.stockForm.controls['exchange'].setValue(exchange);

    this.modalService.open(content, { size: 'md', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  editStockInfo() {
    var stock_id = this.stockForm.controls['stock_id'].value;
    var stock_symbol = this.stockForm.controls['stock_symbol'].value;
    var comp_name = this.stockForm.controls['comp_name'].value;
    var exchange = this.stockForm.controls['exchange'].value;
    if (stock_id && stock_symbol && comp_name && exchange) {
      this.stocksService.editStockInfo(this.stockForm.value)
        .subscribe(data => {
          if (data.success) {
            this.toastr.success(data.message);
          } else {
            this.toastr.success(data.message);
          }
        },
          error => {
            console.log(error);
          });
    } else {
      this.toastr.success("Don't empty any field");
    }
  }

  submitForm() {
    var symbol = this.questionForm.controls['question'].value;
    var exchange = this.questionForm.controls['answer'].value;
    if (symbol && exchange) {
      this.stocksService.addNewStock(this.questionForm.value)
        .subscribe(data => {
          if (data.success) {
            this.toastr.success(data.message);
          } else {
            this.toastr.success(data.message);
          }
        },
          error => {
            console.log(error);
          });
    } else {
      this.toastr.success("Don't empty any field");
    }
  }

}
