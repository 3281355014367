import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaselineImportService {

  constructor(private httpClient: HttpClient, public router: Router) { }


  uploadFile(files: any, name: any): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/upload-dividend-baseline', { "fileData": files, "name": name }, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      tap(),
      catchError(this.handleError('uploadFile', []))
    );
  }

  getBaselineDividendsPaidRecords(sort: string, order: string, page: number, pagesize: number, term: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/get-baseline-dividend-paid-since-list/' + sort + '/' + order + '/' + page + '/' + pagesize, { 'term': term });
  }

  uploadConsFile(files: any, name: any): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/upload-consecutive-dividend-baseline', { "fileData": files, "name": name })
      .pipe(
        tap(),
        catchError(this.handleError('uploadConsFile', []))
      );
  }

  getBaselineDividendsConsRecords(sort: string, order: string, page: number, pagesize: number, term: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/get-baseline-dividend-consecutive-list/' + sort + '/' + order + '/' + page + '/' + pagesize, { 'term': term });
  }


  uploadConsEpsFile(files: any, name: any): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/upload-consecutive-eps-baseline', { "fileData": files, "name": name })
      .pipe(
        tap(),
        catchError(this.handleError('uploadConsEpsFile', []))
      );
  }

  getBaselineEpsConsRecords(sort: string, order: string, page: number, pagesize: number, term: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/get-baseline-eps-consecutive-list/' + sort + '/' + order + '/' + page + '/' + pagesize, { 'term': term });
  }

  uploadHighLowFile(files: any, name: any): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/upload-highlow-baseline', { "fileData": files, "name": name }, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      tap(),
      catchError(this.handleError('uploadHighLowFile', []))
    );
  }


  getBaselineHighLowRecords(sort: string, order: string, page: number, pagesize: number, term: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/get-baseline-highlow-list/' + sort + '/' + order + '/' + page + '/' + pagesize, { 'term': term });
  }


  updateConsData(name: any): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/upload-consecutive-dividend-baseline-update', { "name": name })
      .pipe(
        tap(),
        catchError(this.handleError('updateJobCategory', []))
      );
  }

  /**
  * Handle Http operation that failed.
  * Let the app continue.
  * @param operation - name of the operation that failed
  * @param result - optional value to return as the observable result
  */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      //console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}

