import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PagesService } from '../../../services/admin/pages.service';
import { UserService } from '../../../services/admin/user.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-security-question',
  templateUrl: './security-question.component.html',
  styleUrls: ['./security-question.component.css']
})
export class SecurityQuestionComponent implements OnInit {
  questionForm: FormGroup;
  submitted = false;
  userId: string;
  constructor(
    public fb: FormBuilder,
    private router: Router,
    public pagesService: PagesService,
    private toastr: ToastrService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    var token = this.userService.getAccessToken();
    if (token) {
      var decoded = JSON.parse(atob(token.split('.')[1]));
      this.userId = decoded.userId;
    }
    this.questionForm = this.fb.group({
      question: ['', [Validators.required]],
      answer: ['', [Validators.required]],
    });
  }

  submitForm() {
    // this.userService.user_id
    var question = this.questionForm.controls['question'].value;
    var answer = this.questionForm.controls['answer'].value;
    if (question && answer) {
      this.submitted = true;
      this.pagesService.savedQuestionAnswer(this.questionForm.value, this.userId)
        .subscribe(data => {
          if (data.success) {
            this.toastr.success(data.message);
            this.questionForm.reset();
          } else {
            this.toastr.success(data.message);
          }
        },
          error => {
            console.log(error);
          });
    } else {
      this.submitted = false;
    }

  }

}
