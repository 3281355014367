import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../services/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PagesService } from '../../../services/admin/pages.service';
import { ModalService } from '../../../services/user/modal.service';
import { take } from 'rxjs/operators';
import { FilterService } from '../../../services/user/filter.service';


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  status: boolean = false;
  user_name = "";
  finalArray: any[] = [];
  checkValue: any[] = [];
  topShowButn: boolean = false;
  runShowButn: boolean = false;
  deepShowButn: boolean = false;
  topRankCheck: any;
  runnerUpCheck: any;
  deepValueCheck: any;
  default = 1;
  unsubscribe = 0;
  modalReference: any;
  closeResult: string;
  modaHeading: string = "";
  imageSource: string = "";
  content = "";
  videoSource: any;
  slug = "my-email-lists";
  videoID: string = "";
  videoHeading: string = "";

  toprankPlans = [
    {
      typeName: "Top Ranked", value: "1", frequencyList: [
        { frequencyCode: "3", frequencyName: "daily" },
        { frequencyCode: "2", frequencyName: "weekly" },
        { frequencyCode: "1", frequencyName: "monthly" }],
      selected: 0,
    },
  ];

  runnerupPlans = [
    {
      typeName: "Runners-Up", value: "2", frequencyList: [
        { frequencyCode: "3", frequencyName: "daily" },
        { frequencyCode: "2", frequencyName: "weekly" },
        { frequencyCode: "1", frequencyName: "monthly" }],
      selected: 0,
    },
  ];

  deepvaluePlans = [
    {
      typeName: "Deep Valued Dividend", value: "3", frequencyList: [
        { frequencyCode: "3", frequencyName: "daily" },
        { frequencyCode: "2", frequencyName: "weekly" },
        { frequencyCode: "1", frequencyName: "monthly" }],
      selected: 0,
    },
  ];


  constructor(private fb: FormBuilder,
    private userService: UserService,
    private toastr: ToastrService,
    private router: Router,
    public pagesService: PagesService,
    private modalService: NgbModal,
    private modelService: ModalService,
    private filterService: FilterService,
  ) { }


  ngOnInit() {
    var token = this.userService.getAccessToken();
    if (token) {
      var decoded = JSON.parse(atob(token.split('.')[1]));
      this.userService.user_type = decoded.type;
      this.userService.user_id = decoded.userId;
      this.userService.user_name = decoded.name;
      this.userService.user_email = decoded.email;
    } else {
      this.userService.user_type = null;
      this.userService.user_id = null;
    }
    if (this.userService.user_type == "course_platform" || this.userService.user_type == "Free_platformCourse_platform") {
      this.router.navigate(['/user/pages/course']);
    }
    if (this.userService.user_type == "Free_platform") {
      this.router.navigate(['/user/dashboard']);
    }

    var sideMenuStatus = this.filterService.getSideMenuStaus();
    if (sideMenuStatus !== null) {
      this.status = !sideMenuStatus;
    }
    this.clearStockForm();
    this.getNotificationDetails();
    this.getVideoID();
  }

  runnerUpChange(event, data) {

    var plan = data.value;
    var selected = event.value;
    this.finalArray = [];
    this.finalArray.push({ "value": plan, "selected": selected });

    this.userService.saveNotification(this.finalArray, this.userService.user_id, this.userService.user_email)
      .subscribe((data: any) => {
        if (data.success) {
          this.toastr.success(data.message);
          this.getNotificationDetails();
        } else if (data.success == false) {
          this.toastr.error(data.message);
        }
      },
        (error) => {
          throw error;
        });
  }

  topRankChange(event, data) {

    var plan = data.value;
    var selected = event.value;
    this.finalArray = [];
    this.finalArray.push({ "value": plan, "selected": selected });
    this.userService.saveNotification(this.finalArray, this.userService.user_id, this.userService.user_email)
      .subscribe((data: any) => {
        if (data.success) {
          this.toastr.success(data.message);
          this.getNotificationDetails();
        } else if (data.success == false) {
          this.toastr.error(data.message);
        }
      },
        (error) => {
          throw error;
        });
  }

  deepChange(event, data) {

    var plan = data.value;
    var selected = event.value;
    this.finalArray = [];
    this.finalArray.push({ "value": plan, "selected": selected });
    this.userService.saveNotification(this.finalArray, this.userService.user_id, this.userService.user_email)
      .subscribe((data: any) => {
        if (data.success) {
          this.toastr.success(data.message);
          this.getNotificationDetails();
        } else if (data.success == false) {
          this.toastr.error(data.message);
        }
      },
        (error) => {
          throw error;
        });
  }

  getNotificationDetails() {
    this.userService.getNotificationDetails(this.userService.user_id)
      .subscribe((data: any) => {
        if (data.success) {
          if (!data.status && data.status != null) {
            this.topRankCheck = this.unsubscribe.toString();
            this.runnerUpCheck = this.unsubscribe.toString();
            this.deepValueCheck = this.unsubscribe.toString();
          } else if ((data.data).length > 0) {
            this.checkValue = [];
            this.topRankCheck = "";
            this.runnerUpCheck = "";
            this.deepValueCheck = "";
            this.topShowButn = false;
            this.runShowButn = false;
            this.deepShowButn = false;
            this.checkValue.push(data.data);
            if (this.checkValue[0].length > 0) {
              for (let i = 0; i < this.checkValue[0].length; i++) {
                if (this.checkValue[0][i].plan == 1) {
                  this.topRankCheck = this.checkValue[0][i].type;
                  this.topShowButn = true;
                }
                if (this.checkValue[0][i].plan == 2) {
                  this.runnerUpCheck = this.checkValue[0][i].type;
                  this.runShowButn = true;
                }
                if (this.checkValue[0][i].plan == 3) {
                  this.deepValueCheck = this.checkValue[0][i].type;
                  this.deepShowButn = true;
                }
              }
            }
          }
        } else {
          this.checkValue = [];
          this.topRankCheck = "";
          this.runnerUpCheck = "";
          this.deepValueCheck = "";
          this.topShowButn = false;
          this.runShowButn = false;
          this.deepShowButn = false;
        }
      },
        (error) => {
          this.loginPopUpNow();
          throw error;
        });
  }

  clickEvent() {
    this.status = !this.status;
    this.filterService.setSideMenuStaus(!this.status);
  }

  changeSideIcon() {
    if (this.status == false) {
      return "fa fa-caret-left fa-2x";
    } else if (this.status == true) {
      return "fa fa-caret-right fa-2x";
    }
  }

  unsubscribeNotify(type, data) {
    var value: any;
    var message: any;
    (type == "topRanked") ? value = '1' : (type == "runnerUp") ? value = '2' : (type == "deepValued") ? value = '3' : null;
    (type == "topRanked") ? message = 'Top-Ranked' : (type == "runnerUp") ? message = 'Runners-Up' : (type == "deepValued") ? message = 'Deep Valued Dividend' : null;
    var notifydata = { "value": value, "selected": data };
    this.userService.unsubscribeNotify(notifydata, this.userService.user_email)
      .subscribe((data: any) => {
        if (data.success) {
          this.toastr.success(data.message, message);
        } else if (data.success == false) {
          this.toastr.error(data.message);
        }
        this.getNotificationDetails();
      },
        (error) => {
          throw error;
        });
  }

  opendialog(content, modalname, name) {

    this.modaHeading = name;

    if (modalname == "topRanked") {
      this.imageSource = "assets/images/Top_Ranked.png";
    } else if (modalname == "runnerUps") {
      this.imageSource = "assets/images/Runners_Up.png";
    } else if (modalname == "deepValued") {
      this.imageSource = "assets/images/Deep_Valued.png";
    }
    this.modalReference = this.modalService.open(content, { size: 'xl', ariaLabelledBy: 'modal-basic-title' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getPageContent() {
    this.pagesService.getPageContent(this.slug)
      .subscribe((data: any) => {
        if (data.success) {
          this.content = data.data.content;
          if (data.data.video_path) {
            var path = data.data.video_path.replace("src/", "");
            this.videoSource = path;
          }
        } else if (data.success == false) {
          this.toastr.error(data.message);
        }
      },
        (error) => {
          throw error;
        });
  }

  open(content) {
    this.modalService.open(content, { size: 'xl', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  clearStockForm() {
    localStorage.removeItem('active-form');
    localStorage.removeItem("search-stock2");
    localStorage.removeItem("search-stock-con");
    localStorage.removeItem("checkMarket");
  }

  loginPopUpNow() {
    this.modelService.logoutPop().pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => { });
  }

  getVideoID() {
    var slugs = "my-email-lists";
    this.userService.getVideoID(slugs)
      .subscribe((data: any) => {
        data = data.data;
        this.videoID = data.videoID;
        this.videoHeading = data.heading;
      });
  }

  openVideoPop() {
    if (this.videoID !== undefined && this.videoHeading !== undefined) {
      this.modelService.videoPop(this.videoID, this.videoHeading).pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    } else {
      this.videoHeading = "Video is not available on this page.";
      this.videoID = "";
      this.modelService.videoPop(this.videoID, this.videoHeading).pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    }
  }

}



