import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { HttpErrorResponse } from '@angular/common/http';
import { PageColumnsService } from '../../../services/admin/page-columns.service';
import { ColumnsService } from '../../../services/admin/columns.service';
import { StocksService } from '../../../services/admin/stocks.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-history-data',
  templateUrl: './edit-history-data.component.html',
  styleUrls: ['./edit-history-data.component.css']
})
export class EditHistoryDataComponent implements OnInit {

  submitted = false;
  error = '';
  page_id: string;
  stockDetail: object = {};
  stockForm: FormGroup;
  columnList = [];
  stocksList: [];
  pageDetail: [];
  pageColumnsList: object[] = [];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  slug = "export-stocks";
  market = "";
  display_slug = "";
  fieldsDivid: any[] = [];
  fieldsLow: any[] = [];
  fieldsHigh: any[] = [];
  fieldsEps: any[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public fb: FormBuilder,
    private pageColumnsService: PageColumnsService,
    private columnsService: ColumnsService,
    private stocksService: StocksService,
    private toastr: ToastrService,
  ) { }

  marketList = [
    { name: 'NSD', value: 'NSD' },
    { name: 'NYE', value: 'NYE' },
    { name: 'TSX', value: 'TSX' },
  ];

  get f() { return this.stockForm.controls; }
  editForm: FormGroup = new FormGroup({});

  ngOnInit(): void {
    this.stockForm = this.fb.group({
      select_stock: ['', [Validators.required]],
      exchange: ['', [Validators.required]],
      stockMultiFilterCtrl: '',
    });

    this.getStocks();
    // this.getPageDetails();
    this.setFormValue();
  }

  getStocks(symbolstring = "symbolstring", order = "asc", page = 0, limit = 200, filter = "") {
    this.stocksService.getAllStocks(symbolstring, order, page, limit, filter).subscribe((data: any) => {
      if (data.success == true) {
        this.stocksList = data.data
      } else {
        this.toastr.error(data.message, 'Column');
      }
    },
      (err: HttpErrorResponse) => {
        this.toastr.error('Error in fetching Stocks');
      });
  }

  setFormValue() {
    let year = new Date().getFullYear();
    for (let index = 1999; index <= year; index++) {
      this.fieldsDivid.push({ 'year': index, 'value': 0, 'name': index + 'divid' });
      this.fieldsLow.push({ 'year': index, 'value': 0, 'name': index + 'low' });
      this.fieldsHigh.push({ 'year': index, 'value': 0, 'name': index + 'high' });
      this.fieldsEps.push({ 'year': index, 'value': 0, 'name': index + 'eps' });
    }
    this.fieldsDivid.forEach(x => {
      this.editForm.addControl(x.name, new FormControl(x.value, Validators.required))
    });
    this.fieldsLow.forEach(x => {
      this.editForm.addControl(x.name, new FormControl(x.value, Validators.required))
    });
    this.fieldsHigh.forEach(x => {
      this.editForm.addControl(x.name, new FormControl(x.value, Validators.required))
    });
    this.fieldsEps.forEach(x => {
      this.editForm.addControl(x.name, new FormControl(x.value, Validators.required))
    });
  }

  getPageDetails(): void {
    //this.action_type = 'Update';
    this.pageColumnsService.getPageColumnsBySlug(this.slug)
      .subscribe((data: any) => {
        if (data.success) {
          this.pageColumnsList = data.data.column_ids;
        } else {
          this.toastr.error(data.message, 'Page Not Found');
        }
      },
        (err: HttpErrorResponse) => {
          alert("Server Get Job Page Detail Error");
        });
  }

  private filterStocksMulti(event: Event) {
    if (!this.stocksList) {
      return;
    }
    // get the search keyword
    let search = (event.target as HTMLInputElement).value;
    this.getStocks('symbolstring', 'asc', 0, 200, search);
  }

  submitForm() {
    if (this.stockForm.controls['select_stock'].value !== "" && this.stockForm.controls['exchange'].value !== "") {
      let symboldetails = { "stock_id": this.stockForm.controls['select_stock'].value, "exchange": this.stockForm.controls['exchange'].value }
      this.stocksService.saveStockHistoryData(symboldetails, this.editForm.value, this.fieldsDivid, this.fieldsLow, this.fieldsHigh, this.fieldsEps)
        .subscribe(data => {
          if (data.success) {
            this.toastr.success(data.message, 'Important');
          } else {
            this.toastr.error(data.message, 'Important');
          }
        },
          error => {
            console.log(error);
          });
    } else {
      this.toastr.error("plese select stock and exchange from dropdown");
    }
  }
}
