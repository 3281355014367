import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { User } from '../../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  selected_filter = '';

  private currentUserSubject: BehaviorSubject<User>;

  headers = new HttpHeaders().set('Content-Type', 'application/json');

  public currentUser: Observable<User>;

  constructor(private httpClient: HttpClient, public router: Router) {
  }

  setFilterName(slug: string, data) {
    localStorage.setItem(slug + "1", JSON.stringify(data));
  }

  getFilterName(slug: string) {
    return JSON.parse(localStorage.getItem(slug + "1"));
  }

  removeFilterName(slug) {
    localStorage.removeItem(slug + 1);
  }

  setItem(slug: string, data) {
    localStorage.setItem(slug, JSON.stringify(data));
  }

  getItem(slug: string) {
    return JSON.parse(localStorage.getItem(slug));
  }

  addFilter(form_data: object, selected_filter: object, user_id: string, slug: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/save-filter', { 'user_id': user_id, 'form_data': form_data, 'selected_filter': selected_filter, 'slug': slug });
  }

  getFilters(user_id: string, slug: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/get-filters', { 'user_id': user_id, 'slug': slug })
  }

  removeFilter(user_id: string, filter: object, slug: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/remove-filter', { 'user_id': user_id, 'filter': filter, 'slug': slug })
  }

  getFilter(user_id: string, filter: object, slug: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/user/get-filter', { 'user_id': user_id, 'filter': filter, 'slug': slug })
  }

  setFormValue(slug, data) {
    localStorage.setItem(slug + "2", JSON.stringify(data));
  }

  getFormValue(slug: string) {
    return JSON.parse(localStorage.getItem(slug + "2"));
  }

  setCompareStock(slug, data) {
    localStorage.setItem(slug, JSON.stringify(data));
  }

  getCompareStock(slug) {
    return JSON.parse(localStorage.getItem(slug));
  }

  setCompareStockCountry(slug, data) {
    localStorage.setItem(slug, JSON.stringify(data));
  }

  getCompareStockCountry(slug) {
    return JSON.parse(localStorage.getItem(slug));
  }

  setSideMenuStaus(status) {
    localStorage.setItem("sideMenuStatus", status);
  }

  getSideMenuStaus() {
    return JSON.parse(localStorage.getItem("sideMenuStatus"));
  }

  handleError(error: HttpErrorResponse) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(msg);
  }
}