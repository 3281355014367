import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SiCriteriaService {


  constructor(private httpClient: HttpClient,public router: Router) { }


  getCriteriaDetails(slug: string): Observable<any> {
    return this.httpClient.get(environment.apiBaseUrl+'/admin/get-criteria-details/'+slug);
  }

  updateCriteria(slug:string,data): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl+'/admin/update-criteria',{'data':data,'slug':slug})
      .pipe(
      tap(),
      catchError(this.handleError('updateColumnOrder', []))
      );
  }




      /**
* Handle Http operation that failed.
* Let the app continue.
* @param operation - name of the operation that failed
* @param result - optional value to return as the observable result
*/
private handleError<T>(operation = 'operation', result?: T) {
  return (error: any): Observable<T> => {

    // TODO: send the error to remote logging infrastructure
    console.error(error); // log to console instead

    // TODO: better job of transforming error for user consumption
    //console.log(`${operation} failed: ${error.message}`);

    // Let the app keep running by returning an empty result.
    return of(result as T);
  };
}
}
