import { GenerateListService } from './../../../services/admin/generate-list.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-undervalued-stocks-generate-list',
  templateUrl: './undervalued-stocks-generate-list.component.html',
  styleUrls: ['./undervalued-stocks-generate-list.component.css']
})
export class UndervaluedStocksGenerateListComponent implements OnInit {

  selectedFile: any;
  selectedName: any;

  @ViewChild('fileInput') fileInput: ElementRef;
  fileAttr = 'Choose File';

  constructor(
    private genearteListService: GenerateListService,
    private toastr: ToastrService) {
  }

  ngOnInit(): void {
  }

  uploadFileEvt(imgFile: any) {
    if (imgFile.target.files && imgFile.target.files[0]) {
      this.fileAttr = '';
      Array.from(imgFile.target.files).forEach((file: File) => {
        this.fileAttr += file.name + ' - ';
      });

      // HTML5 FileReader API
      let reader = new FileReader();
      reader.onload = (e: any) => {
        let image = new Image();
        image.src = e.target.result;
        this.selectedFile = image.src;
      };
      this.selectedName = imgFile.target.files[0].name;
      reader.readAsDataURL(imgFile.target.files[0]);
      // Reset if duplicate image uploaded again
      this.fileInput.nativeElement.value = "";
    } else {
      this.fileAttr = 'Choose File';
      this.toastr.error(this.fileAttr);
    }
  }

  onUpload() {
    if (this.selectedFile !== undefined && this.selectedName !== undefined) {
      this.toastr.warning('Please wait generating output file');
      this.genearteListService.uploadFile(this.selectedFile, this.selectedName)
        .subscribe((event: any) => {
          if (event.body && event.body.success == true) {
            this.downloaStocksCsv(event.body.data);
            this.toastr.success(event.body.message);
          }
        })
    } else {
      this.toastr.error('Select the correct file');
    }
  }

  downloaStocksCsv(data) {
    console.log('data =>' + data);
    let blob = null;
    blob = new Blob([data], { type: 'text/csv' });
    let url = null;
    url = window.URL.createObjectURL(blob);
    let a = null;
    a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    var time = new Date().getTime();
    a.setAttribute('download', 'stocks_' + time + '.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

}
function ngOnInit() {
  throw new Error('Function not implemented.');
}

