import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/admin/user.service';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.css']
})
export class TopMenuComponent implements OnInit {
  public token:any;
  constructor(public userService: UserService) { }

  ngOnInit(): void {
    this.token = localStorage.getItem('access_token');
  }
 
  logout(){
    this.userService.logout();
  }

}
