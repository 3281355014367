import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import { Pages } from '../../models/pages.model';
import { environment } from '../../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class PagesService {

  constructor(private httpClient: HttpClient, public router: Router) { }

  // addPage(page: Pages): Observable<any> {
  //   return this.httpClient.post(environment.apiBaseUrl+'/add-page',page);
  // }

  addPage(page: Pages): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/add-page', page)
      .pipe(
        tap(),
        catchError(this.handleError('postJob', []))
      );
  }
  getPagesCount(data): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/pages/count', data)
      .pipe(
        tap(),
        catchError(this.handleError('getJobsList', []))
      );
  }

  getPages(data): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/pages', data)
      .pipe(
        tap(),
        catchError(this.handleError('getFilterComponentList', []))
      );
  }

  updatePage(id, page: Pages): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/update-page/' + id, page)
      .pipe(
        tap(),
        catchError(this.handleError('updateJobCategory', []))
      );
  }

  // public updatePage(data, id) {
  //   console.log(data,"servicesss 111 ")
  //   let uploadURL = `${environment.apiBaseUrl }/admin/update-page/${id}`;

  //   return this.httpClient.post<any>(uploadURL, data, {
  //     reportProgress: true,
  //     observe: 'events'
  //   }).pipe(map((event) => {

  //     switch (event.type) {

  //       case HttpEventType.UploadProgress:
  //         const progress = Math.round(100 * event.loaded / event.total);
  //         return { status: 'progress', message: progress };

  //       case HttpEventType.Response:
  //         return event.body;
  //       default:
  //         return `Unhandled event: ${event.type}`;
  //     }
  //   })
  //   );
  // }

  getPageDetails(id): Observable<any> {
    return this.httpClient.get(environment.apiBaseUrl + '/admin/get-page-details/' + id)
      .pipe(
        tap(),
        catchError(this.handleError('updateJobCategory', []))
      );
  }

  deletePage(id): Observable<any> {
    return this.httpClient.get(environment.apiBaseUrl + '/admin/delete-page/' + id)
      .pipe(
        tap(),
        catchError(this.handleError('deleteJobCategory', []))
      );
  }

  getPageContent(slug): Observable<any> {

    return this.httpClient.post(environment.apiBaseUrl + '/user/get-page-content/', { 'slug': slug })
      .pipe(
        tap(),
        catchError(this.handleError('deleteJobCategory', []))
      );
  }


  getMessage(setting: any): Observable<any> {

    return this.httpClient.post(environment.apiBaseUrl + '/admin/message', setting)
      .pipe(
        tap(),
        catchError(this.handleError('message', []))
      );
  }

  getUserLoginLogs() {
    return this.httpClient.get(environment.apiBaseUrl + '/admin/access-log')
      .pipe(
        tap(),
        catchError(this.handleError('access_log', []))
      );
  }

  getUserLogsFile() {
    return this.httpClient.get(environment.apiBaseUrl + '/admin/user-log-file')
      .pipe(
        tap(),
        catchError(this.handleError('userLogsFile', []))
      );
  }

  getAllMonthStockList(year: string, pagename: string, month: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/archives-table', { 'year': year, 'pagename': pagename, 'month': month })
      .pipe(
        tap(),
        catchError(this.handleError('access_log', []))
      );
  }

  getUserAlertsNotify() {
    return this.httpClient.get(environment.apiBaseUrl + '/admin/alert-notify')
      .pipe(
        tap(),
        catchError(this.handleError('access_log', []))
      );
  }

  getUserNotifyAlerts() {
    return this.httpClient.get(environment.apiBaseUrl + '/admin/notify-alert')
      .pipe(
        tap(),
        catchError(this.handleError('access_log', []))
      );
  }

  updateUserAlert(userId: string, status: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/update-user-alert', { 'userId': userId, 'status': status })
      .pipe(
        tap(),
        catchError(this.handleError('access_log', []))
      );
  }

  updateUserNotification(userId: string, status: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/update-user-notify', { 'userId': userId, 'status': status })
      .pipe(
        tap(),
        catchError(this.handleError('access_log', []))
      );
  }

  getDividendStocks(sort: string, order: string, page: number, pagesize: number, term: string) {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/dividend-stocks/' + sort + '/' + order + '/' + page + '/' + pagesize, { 'term': term });
  }

  savedNotifyContent(content: any, pageName: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/notifyContent', { 'content': content, 'pageName': pageName })
      .pipe(
        tap(),
        catchError(this.handleError('message', []))
      );
  }

  clearNotifyContent(pageName: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/clearNotifyContent', { 'pageName': pageName })
      .pipe(
        tap(),
        catchError(this.handleError('message', []))
      );
  }

  savedQuestionAnswer(queAns: any, userId: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/questionAnswer', { 'queAns': queAns, 'userId': userId })
      .pipe(
        tap(),
        catchError(this.handleError('queAns', []))
      );
  }

  savedToolTips(tooltips: any): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/saveToolTips', { 'tooltips': tooltips })
      .pipe(
        tap(),
        catchError(this.handleError('queAns', []))
      );
  }

  getToolTips(tooltips: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/getToolTips', { 'tooltips': tooltips })
      .pipe(
        tap(),
        catchError(this.handleError('queAns', []))
      );
  }

  editToolTips(tooltips: string, formData: object): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/editToolTips', { 'tooltips': tooltips, 'formData': formData })
      .pipe(
        tap(),
        catchError(this.handleError('queAns', []))
      );
  }

  removeToolTip(tooltips: string, id: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/removeToolTips', { 'tooltips': tooltips, 'id': id })
      .pipe(
        tap(),
        catchError(this.handleError('removeToolTip', []))
      );
  }

  saveUserLoginMess(formData: any): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/saveUserLoginMess', { 'formData': formData })
      .pipe(
        tap(),
        catchError(this.handleError('saveUserLoginMess', []))
      );
  }

  getLoginPageMess(tempData: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/getLoginPageMess', { 'tempData': tempData })
      .pipe(
        tap(),
        catchError(this.handleError('getLoginPageMess', []))
      );
  }

  updateLoginMessStatus(status: any): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/updateLoginMessStatus', { 'status': status })
      .pipe(
        tap(),
        catchError(this.handleError('updateLoginMessStatus', []))
      );
  }

  getDataBackUpFile() {
    return this.httpClient.get(environment.apiBaseUrl + '/admin/backup-list')
      .pipe(
        tap(),
        catchError(this.handleError('getDataBackUpFile', []))
      );
  }

  getPagesDetails(data): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/pagesDetails', data)
      .pipe(
        tap(),
        catchError(this.handleError('getFilterComponentList', []))
      );
  }

  saveVideoDetails(data): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin/saveVideoDetails', data)
      .pipe(
        tap(),
        catchError(this.handleError('getFilterComponentList', []))
      );
  }
  /**
* Handle Http operation that failed.
* Let the app continue.
* @param operation - name of the operation that failed
* @param result - optional value to return as the observable result
*/
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      //console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */




}
