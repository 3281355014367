import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from "@angular/common/http";
import { UserService } from "./services/admin/user.service";
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError,of, BehaviorSubject } from 'rxjs';
import * as JWT from 'jwt-decode';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: UserService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const accessToken = this.authService.getAccessToken();
        req = req.clone({
            setHeaders: {
                Authorization: `JWT ${accessToken}` 
            }
        });
        return next.handle(req).pipe(
           
            catchError(err => {
            // onError
            var decoded = JSON.parse(atob(accessToken.split('.')[1])); 
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    if(decoded['role'] == 'admin'){
                         window.location.href = "/admin/login";
                    }else{
                         window.location.href = "/login";
                    }
                   
                }
            }
            return throwError(err);
        })) as any;
    }
}