import { GenerateListService } from './../../../../services/admin/generate-list.service';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-my-stocks',
  templateUrl: './my-stocks.component.html',
  styleUrls: ['./my-stocks.component.css']
})
export class MyStocksComponent implements OnInit {

  constructor(
    private genearteListService: GenerateListService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
  }

  download(exporttocsv: string) {
    this.toastr.warning('Please wait generating output file');
    this.genearteListService.exportToCsvMyStocks(exporttocsv)
      .subscribe((event: any) => {
        if (event.body && event.body.success == true) {
          this.downloaStocksCsv(event.body.data);
          this.toastr.success(event.body.message);
        } else if (event.body && event.body.success == false) {
          this.toastr.error(event.body.message);
        }
      })
  }

  downloaStocksCsv(data) {
    console.log('data =>' + data);
    let blob = null;
    blob = new Blob([data], { type: 'text/csv' });
    let url = null;
    url = window.URL.createObjectURL(blob);
    let a = null;
    a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    var time = new Date().getTime();
    a.setAttribute('download', 'my-stocks_' + time + '.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

}

