import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user/user.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  course_user: boolean = false;
  closeResult: string = "";
  messages: string = "";

  constructor(
    private userService: UserService,
    private modalService: NgbModal,) { }

  ngOnInit(): void {
    var token = this.userService.getAccessToken();
    if (token) {
      var decoded = JSON.parse(atob(token.split('.')[1]));
      this.userService.user_type = decoded.type;
      this.userService.user_id = decoded.userId;
      this.userService.user_name = decoded.name;
    } else {
      this.userService.user_type = null;
      this.userService.user_id = null;
    }
    if (this.userService.user_type == "course_platform") {
      this.course_user = true;
    }
  }

  freeandcourse(content, type: string) {
    if (type == "top_ranked") {
      this.messages = "Top Ranked are stocks that receive a 10 out of 10 grade in the SI Criteria.<br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; This feature is only available to SI Report & Platform members.";
    }
    if (type == "runner_up") {
      this.messages = "Runners-Up are stocks that receive a 9 out of 10 grade in the SI Criteria.<br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; This feature is only available to SI Report & Platform members.";
    }
    if (type == "build_port") {
      this.messages = "This feature is only available to SI Report & Platform members.";
    }
    this.modalService.open(content, { size: 'md', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  upgradeNow() {
    window.location.href = "https://www.simplyinvesting.com/invest";
  }
  
  checkUserTag(){
    if(this.userService.user_type == "course_platform"){
      return "greyOut";
    }
  }

}
