import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router , ActivatedRoute } from '@angular/router';
import { HttpEventType, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { FaqService } from '../../../../services/admin/faq.service';
import { ToastrService } from 'ngx-toastr';
//import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-add-faq',
  templateUrl: './add-faq.component.html',
  styleUrls: ['./add-faq.component.css']
})
export class AddFaqComponent implements OnInit {

 
  action_type: string = 'Add';
  submitted = false;
  error = '';
  page_id : string;
  pageDetail: object = {};
 
  pageForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public faqService: FaqService,
    private toastr: ToastrService
  ){ }



  ngOnInit() {

    this.page_id = this.route.snapshot.params['id'] 
    this.pageForm = this.fb.group({
      question: ['',[Validators.required]],
      answer: ['',[Validators.required]],
      category_id: ['',[Validators.required]],
      status: ['',[Validators.required]],
    });
    if (this.page_id != undefined) {
      this.getPageDetails();
    }

  }

  get f() { return this.pageForm.controls; }

  add() {
    this.faqService.add(this.pageForm.value)
    .subscribe(data => {
      if (data.success) {
        this.toastr.success(data.message, 'Page');
        this.router.navigateByUrl('/admin/faqs');
      }else {
       console.log(data);
      }
    },
      error => {
        //console.log(error);
      });


      
    
    // this.pagesService.create(this.pageForm.value).subscribe(res => {
    //   this.router.navigateByUrl('/admin/pages/list/'))
    // }
  }

  update(): void {
    this.faqService.update(this.page_id, this.pageForm.value)
      .subscribe((data: any) => {
        if (data.success) {
          this.toastr.success(data.message);
          this.router.navigateByUrl('/admin/pages');
        } else {
          this.toastr.error(data.message, 'Page');
        }
      },
      (err: HttpErrorResponse) => {
        alert("Server  Error");
      });
  }


  submitForm() {
    this.submitted = true;
    if (this.pageForm.invalid) {
      return;
    }
    if (this.action_type == 'Add') {
      this.add();
    } else {
      this.update();
    }
  }




  getPageDetails(): void {
    this.action_type = 'Update';
    this.faqService.getDetails(this.page_id)
      .subscribe((data: any) => {
        if (data.success) {
          this.pageDetail = data.data;
          this.pageForm.controls['question'].setValue(this.pageDetail['question']);
          this.pageForm.controls['answer'].setValue(this.pageDetail['answer']);
          this.pageForm.controls['category_id'].setValue(this.pageDetail['category_id']);
          this.pageForm.controls['status'].setValue(this.pageDetail['status']);
        } else {
          this.toastr.error('Error in getting details');
        }
      },
      (err: HttpErrorResponse) => {
        alert("Server  Error");
      });
  }


}

