import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { BaselineImportService } from '../../../services/admin/baseline-import.service';
import { ToastrService } from 'ngx-toastr';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-baseline-consecutive-div-import',
  templateUrl: './baseline-consecutive-div-import.component.html',
  styleUrls: ['./baseline-consecutive-div-import.component.css']
})
export class BaselineConsecutiveDivImportComponent implements AfterViewInit {
  stockForm: FormGroup;
  filesToUpload: Array<File>;
  recordList: object[] = [];
  displayedColumns: string[] = ["symbolstring", "consecutive_years_inc", "market", "date"];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  filterValue = "";
  selectedFile: any;
  selectedName: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('fileInput') fileInput: ElementRef;
  fileAttr = 'Choose File';
  selected = "sumeet";

  constructor(
    private baselineImportService: BaselineImportService,
    private toastr: ToastrService,
    public fb: FormBuilder) {
    this.filesToUpload = [];

  }

  get f() { return this.stockForm.controls; }

  ngOnInit(): void {
    this.stockForm = this.fb.group({
      csvfile: ['', [Validators.required]],
      basicfile: ['', [Validators.required]],
    });
  }

  ngAfterViewInit() {


    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.baselineImportService!.getBaselineDividendsConsRecords(
            this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize, this.filterValue);
        }),
        map(data => {
          // console.log(data);
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.count;
          return data.data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.recordList = data);
  }

  // fileUploadListener($event: any): void {
  //   var filesToUpload = $event.target.files;
  //   let file = new Blob([""], { type: "application/csv" });
  //   let formData = new FormData();
  //   formData.append("files", filesToUpload[0], filesToUpload[0].name);
  //   console.log(filesToUpload[0].name);
  //   // let userData = {
  //   //     firstName : "ABC",
  //   //     lastName : "DEF",
  //   //     //userFormData: formData
  //   // }
  //   //formData.append("userData", JSON.stringify(userData));

  //   this.baselineImportService.uploadConsFile(formData)
  //     .subscribe((data: any) => {
  //       if (data.success == true) {
  //         this.getAllRecords();
  //         this.toastr.success(data.message, 'Column');
  //       } else {
  //         this.toastr.error(data.message, 'Column');
  //       }
  //     })

  // }

  uploadFileEvt(imgFile: any) {
    if (imgFile.target.files && imgFile.target.files[0]) {
      this.fileAttr = '';
      Array.from(imgFile.target.files).forEach((file: File) => {
        this.fileAttr += file.name + ' - ';
      });

      // HTML5 FileReader API
      let reader = new FileReader();
      reader.onload = (e: any) => {
        let image = new Image();
        image.src = e.target.result;
        this.selectedFile = image.src;
      };
      this.selectedName = imgFile.target.files[0].name;
      reader.readAsDataURL(imgFile.target.files[0]);
      // Reset if duplicate image uploaded again
      this.fileInput.nativeElement.value = "";
    } else {
      this.fileAttr = 'Choose File';
      this.toastr.error(this.fileAttr);
    }
  }

  onUpload() {
    if (this.selectedFile !== undefined && this.selectedName !== undefined) {
      this.baselineImportService.uploadConsFile(this.selectedFile, this.selectedName)
        .subscribe((event: any) => {
          if (event.success == true) {
            this.getAllRecords();
            this.toastr.success(event.message);
          } else {
            this.toastr.error(event.message, 'Somthing went wrong');
          }
        })
    } else {
      this.toastr.error('Select the correct file');
    }
  }

  getAllRecords() {
    this.baselineImportService.getBaselineDividendsConsRecords(
      'symbolstring', 'asc', 0, 10, this.filterValue).subscribe((data: any) => {
        if (data.success == true) {
          this.recordList = data.data
          this.resultsLength = data.count;
        } else {
          this.toastr.error(data.message, 'Column');
        }
      },
        (err: HttpErrorResponse) => {
          this.toastr.error('Error in updating Column');
        });
  }

  upDateData() {
    this.baselineImportService.updateConsData(this.selected)
      .subscribe((event: any) => {
        // console.log(event);
        if (event.success == true) {
          this.getAllRecords();
          this.toastr.success(event.message, 'Column');
        } else {
          this.toastr.error(event.message, 'Column');
        }
      })
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.getAllRecords();
  }
}
