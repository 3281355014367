import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';
import { HttpEventType, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { PagesService } from '../../../../services/admin/pages.service';
import { FaqService } from '../../../../services/admin/faq.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../environments/environment';

const URL = environment.apiBaseUrl + '/admin/upload-page-video'

@Component({
  selector: 'app-add-page',
  templateUrl: './add-page.component.html',
  styleUrls: ['./add-page.component.css']
})

export class AddPageComponent implements OnInit {
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  public uploader: FileUploader = new FileUploader({ url: URL, itemAlias: 'file' });
  action_type: string = 'Add';
  submitted = false;
  error = '';
  page_id: string;
  pageDetail: object = {};
  files: any
  pageForm: FormGroup;
  videoSource: any;
  uploadResponse = { status: '', message: '', filePath: '' };

  constructor(
    public fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public pagesService: PagesService,
    public faqService: FaqService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {

    this.page_id = this.route.snapshot.params['id']
    this.pageForm = this.fb.group({
      name: ['', [Validators.required]],
      slug: ['', [Validators.required]],
      content: ['', [Validators.required]],
      status: ['', [Validators.required]],
    });
    if (this.page_id != undefined) {
      this.getPageDetails();
    }

    //override the onAfterAddingfile property of the uploader so it doesn't authenticate with //credentials.
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    //overide the onCompleteItem property of the uploader so we are 
    //able to deal with the server response.
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      console.log("ImageUpload:uploaded:", item, status, response);
    };

  }

  get f() { return this.pageForm.controls; }

  add() {
    this.pagesService.addPage(this.pageForm.value)
      .subscribe(data => {
        if (data.success) {
          this.toastr.success(data.message, 'Page');
          this.router.navigateByUrl('/admin/pages');
        } else {
          console.log(data);
        }
      },
        error => {
          //console.log(error);
        });

  }

  update(): void {

    if (this.uploader) {
      console.log("videosss....", this.uploader)
      this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
        form.append('page_id', this.page_id);
      };
      this.uploader.uploadAll();
    }


    this.pagesService.updatePage(this.page_id, this.pageForm.value)
      .subscribe((data: any) => {
        if (data.success) {
          this.toastr.success(data.message, 'Page');
          this.router.navigateByUrl('/admin/pages');
        } else {
          this.toastr.error(data.message, 'Page');
        }
      },
        (err: HttpErrorResponse) => {
          alert("Server Update Job Sub Category Error");
        });
  }


  submitForm() {
    this.submitted = true;
    if (this.pageForm.invalid) {
      return;
    }
    if (this.action_type == 'Add') {
      this.add();
    } else {
      this.update();
    }
  }




  getPageDetails(): void {
    this.action_type = 'Update';
    this.pagesService.getPageDetails(this.page_id)
      .subscribe((data: any) => {
        if (data.success) {
          this.pageDetail = data.data;
          this.videoSource = this.pageDetail['video_path'];
          this.pageForm.controls['name'].setValue(this.pageDetail['page_name']);
          this.pageForm.controls['slug'].setValue(this.pageDetail['slug']);
          this.pageForm.controls['content'].setValue(this.pageDetail['content']);
          this.pageForm.controls['status'].setValue(this.pageDetail['status']);
        } else {
          this.toastr.error('Error in getting page details', 'Page');
        }
      },
        (err: HttpErrorResponse) => {
          alert("Server Get Job Sub Category Detail Error");
        });
  }

  toggleVideo(event: any) {
    this.videoplayer.nativeElement.play();
  }


}
