import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { User } from '../../models/user.model';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  private currentUserSubject: BehaviorSubject<User>;

  headers = new HttpHeaders().set('Content-Type', 'application/json');
  selectedUser: User = {
    fullName: '',
    email: '',
    password: ''
  };


  public currentUser: Observable<User>;

  constructor(private httpClient: HttpClient, public router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  postUser(user: User): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/register', user);
  }

  login(user: User) {
    return this.httpClient.post<any>(environment.apiBaseUrl + '/admin/login', user)
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.accessToken) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          user.language = 'en';
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
        }

        return user;
      }));
    // .subscribe((res: any) => {
    //   localStorage.setItem('access_token', res.token)
    //   this.getUserProfile(res._id).subscribe((res) => {
    //     this.selectedUser = res;
    //     this.router.navigate(['admin/dashboard/']);
    //   })
    // })
  }

  getAccessToken() {
    return localStorage.getItem('access_token');
  }

  isLoggedIn(): boolean {
    let authToken = localStorage.getItem('access_token');
    return (authToken !== null) ? true : false;
  }

  logout() {
    if (localStorage.removeItem('access_token') == null) {
      localStorage.clear();
      this.router.navigate(['/admks/login']);
    }
  }

  getUserProfile(id): Observable<any> {
    return this.httpClient.get(environment.apiBaseUrl + `/admin/profile/${id}`, { headers: this.headers }).pipe(
      map((res: Response) => {
        return res || {}
      }),
      catchError(this.handleError)
    )
  }

  requestReset(body): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/req-reset-password', body);
  }

  newPassword(password: any, userId: any): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/new-password', { 'password': password, 'userId': userId });
  }

  ValidPasswordToken(token: any, id: any): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/valid-password-token', { 'token': token, 'id': id });
  }

  getQuestion(userId: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin-question-answer', { 'userId': userId });
  }

  loginPart(formData: object, userId: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/admin-loginPart', { 'formData': formData, 'userId': userId });
  }
  
  handleError(error: HttpErrorResponse) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(msg);
  }



}



