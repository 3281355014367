import { Component, ViewChild, AfterViewInit, OnInit, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { PageColumnsService } from '../../../services/admin/page-columns.service';
import { ColumnsService } from '../../../services/admin/columns.service';
import { StocksService } from '../../../services/admin/stocks.service';
import { ToastrService } from 'ngx-toastr';
import { ReplaySubject, Subject, merge, Observable, of as observableOf } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatSort } from '@angular/material/sort';
import { FilterService } from '../../../services/user/filter.service';
import { DownloadService } from '../../../services/admin/download.service';
import { NgxSpinnerService } from "ngx-spinner";
import * as XLSX from "xlsx";

declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-export-stocks',
  templateUrl: './export-stocks.component.html',
  styleUrls: ['./export-stocks.component.css']
})
export class ExportStocksComponent implements OnInit {
  name = 'Angular';
  isCategorySel: boolean = false;
  isExchangeSel: boolean = false;
  checkedCategoryList = [];
  checkedExchangeList = [];
  submitted = false;
  error = '';
  page_id: string;
  stockDetail: object = {};
  stockForm: FormGroup;
  columnList = [];
  stocksList: [];
  pageDetail: [];
  public filteredStocksMulti: ReplaySubject<any> = new ReplaySubject<any>(1);
  pageColumnsList: object[] = [];
  displayedColumns: string[] = ["column_order", "column_name", "show_column", "created_date"];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  slug = "export-stocks";
  market = "";
  display_slug = "";
  closeResult: string;
  selected_filter: any;
  getSelectedColumns = [];
  filterType = [];
  selecetAll: boolean = false;
  checkedcateList: any;
  checkedList: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private pageColumnsService: PageColumnsService,
    private columnsService: ColumnsService,
    private modalService: NgbModal,
    private stocksService: StocksService,
    private filterService: FilterService,
    private toastr: ToastrService,
    private downloads: DownloadService,
    private spinner: NgxSpinnerService,
  ) {
  }

  categoryList = [
    { id: 1, value: 'dividend_forward_amount', name: 'Dividend per share', isSelected: false },
    { id: 2, value: 'high_price', name: 'High Stock Price for the Year', isSelected: false },
    { id: 3, value: 'low_price', name: 'Low Stock Price for the Year', isSelected: false },
    { id: 4, value: 'avg_price', name: 'Average Stock Price', isSelected: false },
    { id: 5, value: 'avg_dividend_yield', name: 'Average Dividend Yield per Year', isSelected: false },
    { id: 6, value: 'low_dividend_yield', name: 'Low Dividend Yield per Year', isSelected: false },
    { id: 7, value: 'high_dividend_yield', name: 'High Dividend Yield per Year', isSelected: false },
    { id: 8, value: 'eps', name: 'EPS', isSelected: false },
    { id: 9, value: 'avg_pe_ratio', name: 'Average PE Ratio per Year', isSelected: false },
    { id: 10, value: 'low_pe_ratio', name: 'Low PE Ratio per Year', isSelected: false },
    { id: 11, value: 'high_pe_ratio', name: 'High PE Ratio per Year', isSelected: false },
    { id: 12, value: 'year_over_growth', name: 'Year-Over-Year Dividend Growth', isSelected: false },
    { id: 13, value: 'year_over_eps_growth', name: 'Year-Over-Year EPS Growth', isSelected: false },
  ];


  exchangeList = [
    { id: 1, value: 'NSD', name: 'NSD', isSelected: false },
    { id: 2, value: 'NYE', name: 'NYE', isSelected: false },
    { id: 3, value: 'TSX', name: 'TSX', isSelected: false },
  ];


  ngOnInit(): void {
    this.market = 'USA';
    this.slug = 'export-stocks';

    this.getColumns();
    this.getPageDetails();
    // this.getCheckedItemList();
    // this.getExchnageCheckedItemList();
  }

  opendialog(content, stock) {

    this.modalService.open(content, { size: 'xl', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  private filterSortColumn(event: Event) {
    if (!this.stocksList) {
      return;
    }
    // get the search keyword
    let search = (event.target as HTMLInputElement).value;
    this.columnList = this.columnList.filter(x => x.column_name.toLowerCase().indexOf(search.toLowerCase()) > -1);
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');

    this.scrollTo(firstElementWithError);
  }

  getColumns() {
    this.columnsService.getColumns('column_order', 'asc', 0, 150).subscribe((data: any) => {
      if (data.success == true) {
        this.columnList = data.data

      } else {
        this.toastr.error(data.message, 'Error in fetching column data');
      }
    },
      (err: HttpErrorResponse) => {
        this.toastr.error('Error in fetching columns');
      });
  }

  getPageDetails(): void {
    this.pageColumnsService.getPageColumnsBySlug(this.slug)
      .subscribe((data: any) => {
        if (data.success) {
          this.pageDetail = data.data;
          this.pageColumnsList = this.pageDetail['column_ids'];
          var filter_obj = { 'columns': data.data.column_ids };
          this.filterService.setItem(this.slug, filter_obj);
          this.selected_filter = this.filterService.getItem(this.slug);
        } else {
          this.toastr.error(data.message, 'Page Not Found');
        }
      },
        (err: HttpErrorResponse) => {
          alert("Server Get Job Page Detail Error");
        });
  }

  drop(event: CdkDragDrop<any>) {
    moveItemInArray(this.pageColumnsList, event.previousIndex, event.currentIndex);
    console.log(event.container.data);
    this.pageColumnsService.updateColumnOrder(this.slug, event.container.data)
      .subscribe((data: any) => {
        if (data.success == true) {
          this.getPageDetails();
          this.toastr.success(data.message, 'Column');
        } else {
          this.toastr.error(data.message, 'Column');
        }
      },
        (err: HttpErrorResponse) => {
          this.toastr.error('Error in updating Column');
        });
    //
  }

  changeShowColumn(row, event) {

    this.pageColumnsService.updateColumn(this.slug, row._id, 'show_column', event.checked)
      .subscribe((data: any) => {
        if (data.success == true) {
          this.toastr.success(data.message, 'Column');
          this.getPageDetails();
        } else {
          this.toastr.error(data.message, 'Column');
        }
      },
        (err: HttpErrorResponse) => {
          this.toastr.error('Error in updating Column');
        });

  }

  categoryCheckUncheckAll() {
    for (var i = 0; i < this.categoryList.length; i++) {
      this.categoryList[i].isSelected = this.isCategorySel;
    }
    this.getCheckedItemList();
  }

  isCategoryAllSelected() {
    this.isCategorySel = this.categoryList.every(function (item: any) {
      return item.isSelected == true;
    })
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.checkedCategoryList = [];
    for (var i = 0; i < this.categoryList.length; i++) {
      if (this.categoryList[i].isSelected)
        this.checkedCategoryList.push(this.categoryList[i]);
    }
  }

  exchangeCheckUncheckAll() {
    for (var i = 0; i < this.exchangeList.length; i++) {
      this.exchangeList[i].isSelected = this.isExchangeSel;
    }
    this.getExchnageCheckedItemList();
  }

  isExchangeAllSelected() {
    this.isExchangeSel = this.exchangeList.every(function (item: any) {
      return item.isSelected == true;
    })
    this.getExchnageCheckedItemList();
  }

  getExchnageCheckedItemList() {
    this.checkedExchangeList = [];
    for (var i = 0; i < this.exchangeList.length; i++) {
      if (this.exchangeList[i].isSelected)
        this.checkedExchangeList.push(this.exchangeList[i]);
    }
  }

  columnCheckUncheckAll(event, value) {
    if (value == "all") {
      if (event.target.checked) {
        for (var i = 0; i < this.selected_filter.columns.length; i++) {
          this.selected_filter.columns[i].show_column = true;
        }
        this.selecetAll = true;
      } else {
        for (var i = 0; i < this.selected_filter.columns.length; i++) {
          this.selected_filter.columns[i].show_column = false;
        }
        this.selecetAll = false;
      }
    }
    this.pageColumnsService.updateAllColumn(this.slug, 'show_column', this.selected_filter.columns, event.target.checked)
      .subscribe((data: any) => {
        if (data.success == true) {
          this.toastr.success(data.message, 'Column');
          this.getPageDetails();
        } else {
          this.toastr.error(data.message, 'Column');
        }
      },
        (err: HttpErrorResponse) => {
          this.toastr.error('Error in updating Column');
        });
    // console.log(this.selected_filter);
  }

  generatStocksCSV() {
    this.getSelectedColumns = [];
    this.filterType = [];
    for (var i = 0; i < this.selected_filter.columns.length; i++) {
      if (this.selected_filter.columns[i].show_column == true) {
        this.getSelectedColumns.push({ 'column_id': this.selected_filter.columns[i]._id, 'column_name': this.selected_filter.columns[i].column_name });
      }
    }
    for (let j = 0; j < this.checkedExchangeList.length; j++) {
      this.filterType.push(this.checkedExchangeList[j].value)
    }
    this.spinner.show();
    this.stocksService.exportStocksCsv(this.slug, this.getSelectedColumns, this.filterType)
      .subscribe((data: any) => {
        if (data.success) {
          this.downloaStocksCsv(data.data);

          this.toastr.success("Stock CSV generated successfully");
        } else {
          this.toastr.success(data.message, "Stock CSV not generated");
        }
      },
        (err: HttpErrorResponse) => {
          alert("refresh the page");
        });
    setTimeout(() => {
      this.spinner.hide();
    }, 2500);
  }

  generatHistoryCSV() {
    this.getSelectedColumns = [];
    this.filterType = [];

    for (var i = 0; i < this.checkedCategoryList.length; i++) {
      if (this.checkedCategoryList[i].isSelected == true) {
        this.getSelectedColumns.push({ 'column_name': this.checkedCategoryList[i].name, 'value': this.checkedCategoryList[i].value });
      }
    }
    for (let j = 0; j < this.checkedExchangeList.length; j++) {
      this.filterType.push(this.checkedExchangeList[j].value)
    }
    this.spinner.show();
    this.stocksService.exportStocksHistoryCsv(this.slug, this.getSelectedColumns, this.filterType)
      .subscribe((data: any) => {
        if (data.success) {
          this.displayedColumns = [];
          this.stocksList = data.data.stocks;
          this.resultsLength = data.data.count;
          for (let v in data.data.years) {
            this.displayedColumns.push("" + data.data.years[v]);
          }
          setTimeout(() => {
            this.exportTableToExcel("ExampleNormalTable");
          }, 4500);
          this.toastr.success("Stock History CSV generated successfully Please wait 5 sec.");
        } else {
          this.toastr.success(data.message, "Stock CSV not generated");
        }
      },
        (err: HttpErrorResponse) => {
          alert("Refresh the page");
        });
    setTimeout(() => {
      this.spinner.hide();
    }, 2500);
  }

  getDividendColVal(column_id: any, stock_data: any, column_name: any) {
    let arr = stock_data.data;
    let index = arr.findIndex(x => x.year == column_id);
    if (index != -1) {
      if (parseFloat(stock_data.data[index][column_name]).toFixed(2) == '0.00') {
        return "N/A";
      } else {
        if (isNaN(parseFloat(stock_data.data[index][column_name]))) {
          return "N/A";
        } else {
          if (column_name == "avg_dividend_yield" || column_name == "low_dividend_yield" || column_name == "high_dividend_yield" || column_name == "year_over_growth" || column_name == "year_over_eps_growth") {
            var val = parseFloat(stock_data.data[index][column_name]).toFixed(4);
            return val + "%";
          } else {
            return parseFloat(stock_data.data[index][column_name]).toFixed(4);
          }
        }
      }
    }
  }

  // exportNormalTable() {
  //   TableUtil.exportTableToExcel("ExampleNormalTable", "stockshistory");
  // }



  getFileName(name: string) {
    let timeSpan = new Date().toISOString();
    let sheetName = name || "ExportResult";
    let fileName = `${sheetName}-${timeSpan}`;
    return {
      sheetName,
      fileName
    };
  };

  exportTableToExcel(tableId: string, name?: string) {
    let { sheetName, fileName } = this.getFileName(name);
    let targetTableElm = document.getElementById(tableId);

    let wb = XLSX.utils.table_to_book(targetTableElm, <XLSX.Table2SheetOpts>{
      sheet: sheetName
    });
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }


  downloadHistoryCsv(data) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'stockshistory.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  downloaStocksCsv(data) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'stocks.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

}

