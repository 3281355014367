import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FilterService } from '../../../services/user/filter.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css']
})
export class MyAccountComponent implements OnInit {

  user_type = "";
  user_email = "";
  user_name = "";
  status: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private filterService: FilterService,
    private userService: UserService,

  ) { }

  ngOnInit(): void {
    var token = this.userService.getAccessToken();
    if (token) {
      var decoded = JSON.parse(atob(token.split('.')[1]));
      this.userService.user_type = decoded.type;
      this.userService.user_id = decoded.userId;
      this.userService.user_name = decoded.name;
      this.userService.user_email = decoded.email;
    } else {
      this.userService.user_type = null;
      this.userService.user_id = null;
    }
    this.user_name = this.userService.user_name;
    var user_type = this.userService.user_type;
    if (user_type == "US_only") {
      this.user_type = "US stocks only";
    } else if (user_type == "CAD_only") {
      this.user_type = "Canadian stocks only";
    } else if (user_type == "US_and_CAD") {
      this.user_type = "US and Canadian stocks";
    } else if (user_type == "Free_platform") {
      this.user_type = "Free access";
    } else if (user_type == "course_platform") {
      this.user_type = "Course Access";
    } else if (user_type == "US_onlyCourse_platform") {
      this.user_type = "US stocks only, and Course Access";
    } else if (user_type == "CAD_onlyCourse_platform") {
      this.user_type = "Canadian stocks only, and Course Access";
    } else if (user_type == "US_and_CADCourse_platform") {
      this.user_type = "US and Canadian stocks, and Course Access";
    } else if (user_type == "Free_platformCourse_platform") {
      this.user_type = "Free access, and Course Access";
    }
    this.user_email = this.userService.user_email;
    var sideMenuStatus = this.filterService.getSideMenuStaus();
    if (sideMenuStatus !== null) {
      this.status = !sideMenuStatus;
    }
    this.clearStockForm();
  }

  clearStockForm() {
    localStorage.removeItem('active-form');
    localStorage.removeItem("search-stock2");
    localStorage.removeItem("search-stock-con");
    localStorage.removeItem("checkMarket");
  }

  clickEvent() {
    this.status = !this.status;
    this.filterService.setSideMenuStaus(!this.status);
  }

  changeSideIcon() {
    if (this.status == false) {
      return "fa fa-caret-left fa-2x";
    } else if (this.status == true) {
      return "fa fa-caret-right fa-2x";
    }
  }

}
