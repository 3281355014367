import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { BaselineImportService } from '../../../services/admin/baseline-import.service';
import { ToastrService } from 'ngx-toastr';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-baseline-highlow-import',
  templateUrl: './baseline-highlow-import.component.html',
  styleUrls: ['./baseline-highlow-import.component.css']
})
export class BaselineHighlowImportComponent implements OnInit {
  stockForm: FormGroup;
  filesToUpload: Array<File>;
  recordList: object[] = [];
  displayedColumns: string[] = ["symbolstring", "year", "high_price", "low_price"];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  filterValue = "";
  selectedFile: any;
  selectedName: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('fileInput') fileInput: ElementRef;
  fileAttr = 'Choose File';

  constructor(
    private baselineImportService: BaselineImportService,
    private toastr: ToastrService,
    private http: HttpClient,
    public fb: FormBuilder) {
    this.filesToUpload = [];

  }

  get f() { return this.stockForm.controls; }

  ngOnInit(): void {
    this.stockForm = this.fb.group({
      csvfile: ['', [Validators.required]],
      basicfile: ['', [Validators.required]],
    });
  }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.baselineImportService!.getBaselineHighLowRecords(
            this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize, this.filterValue);
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.count;
          return data.data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.recordList = data);
  }

  uploadFileEvt(imgFile: any) {
    if (imgFile.target.files && imgFile.target.files[0]) {
      this.fileAttr = '';
      Array.from(imgFile.target.files).forEach((file: File) => {
        this.fileAttr += file.name + ' - ';
      });

      // HTML5 FileReader API
      let reader = new FileReader();
      reader.onload = (e: any) => {
        let image = new Image();
        image.src = e.target.result;
        this.selectedFile = image.src;
      };
      this.selectedName = imgFile.target.files[0].name;
      reader.readAsDataURL(imgFile.target.files[0]);
      // Reset if duplicate image uploaded again
      this.fileInput.nativeElement.value = "";
    } else {
      this.fileAttr = 'Choose File';
      this.toastr.error(this.fileAttr);
    }
  }

  onUpload() {
    if (this.selectedFile !== undefined && this.selectedName !== undefined) {
      this.baselineImportService.uploadHighLowFile(this.selectedFile, this.selectedName)
        .subscribe((event: any) => {
          if (event.success == true) {
            this.getAllRecords();
            this.toastr.success(event.message);
          } else {
            this.toastr.error(event.message, 'Something went wrong');
          }
        })
    } else {
      this.toastr.error('Select the correct file');
    }
  }

  getAllRecords() {
    this.baselineImportService.getBaselineHighLowRecords('symbolstring', 'asc', 0, 10, this.filterValue).subscribe((data: any) => {
      if (data.success == true) {
        this.recordList = data.data
        this.resultsLength = data.count;
      } else {
        this.toastr.error(data.message, 'Column');
      }
    },
      (err: HttpErrorResponse) => {
        this.toastr.error('Error in updating Column');
      });
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.getAllRecords();
  }

}
