import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router , ActivatedRoute } from '@angular/router';
import { PagesService } from '../../../../services/admin/pages.service';
import { ToastrService } from 'ngx-toastr';
// import { exit } from 'process';

// import { CKEditorModule } from 'ckeditor4-angular';
@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {
  
  action_type: string = 'Message';
  submitted = false;
  error = '';
  // page_id : string;
  // pageDetail: object = {};
  messageForm: FormGroup;

  constructor(
    public fb: FormBuilder,
    private router: Router,
    public pagesService: PagesService,
    private toastr: ToastrService

  ){ }

  ngOnInit(): void {
    this.messageForm = this.fb.group({
      // slug: ['',[Validators.required]],
      content: ['',[Validators.required]],
    });
  }

  get f() {
    return this.messageForm.controls;
  }

    add() {
      this.pagesService.getMessage(this.messageForm.value)
      .subscribe(data => {
        if (data.success) {
          this.toastr.success(data.message, 'message');
          this.router.navigateByUrl('/admin/message');
        }else {
          console.log(data);
        }
      },
      error => {
      console.log(error);
      });
    }
      submitForm() {
        this.submitted = true;
        if (this.messageForm.invalid) {
          return;
        }
        if (this.action_type == 'Message') {
          this.add();
        }
      }
}
