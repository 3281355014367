import { Routes } from '@angular/router';
import { SignUpComponent } from './components/admin/sign-up/sign-up.component';
import { LoginComponent } from './components/admin/login/login.component';
import { DashboardComponent } from './components/admin/dashboard/dashboard.component';
import { LayoutComponent } from './components/admin/layout/layout.component';
import { AddPageComponent } from './components/admin/pages/add-page/add-page.component';
import { ListPageComponent } from './components/admin/pages/list-page/list-page.component';
import { ColumnsComponent } from './components/admin/columns/columns.component';
import { BaselineImportComponent } from './components/admin/baseline-import/baseline-import.component';
import { BaselineConsecutiveDivImportComponent } from './components/admin/baseline-consecutive-div-import/baseline-consecutive-div-import.component';
import { BaselineConsecutiveEpsImportComponent } from './components/admin/baseline-consecutive-eps-import/baseline-consecutive-eps-import.component';
import { BaselineHighlowImportComponent } from './components/admin/baseline-highlow-import/baseline-highlow-import.component';
import { SiCriteriaFiveYearsComponent } from './components/admin/si-criteria-five-years/si-criteria-five-years.component';
import { SiCriteriaTenYearsComponent } from './components/admin/si-criteria-ten-years/si-criteria-ten-years.component';
import { SiCriteriaFifteenYearsComponent } from './components/admin/si-criteria-fifteen-years/si-criteria-fifteen-years.component';
import { SiCriteriaTwentyYearsComponent } from './components/admin/si-criteria-twenty-years/si-criteria-twenty-years.component';
import { TopRankedStocksComponent } from './components/admin/top-ranked-stocks/top-ranked-stocks.component';
import { TopRankedStocksListComponent } from './components/admin/top-ranked-stocks-list/top-ranked-stocks-list.component';
import { RunnerUpComponent } from './components/admin/runner-up/runner-up.component';
import { RunnerUpsListComponent } from './components/admin/runner-ups-list/runner-ups-list.component';
import { ValuedComponent } from './components/admin/valued/valued.component';
import { ValuedListComponent } from './components/admin/valued-list/valued-list.component';
import { RequestResetComponent } from './components/admin/request-reset/request-reset.component';
import { ResponseResetComponent } from './components/admin/response-reset/response-reset.component';
import { DeepValueComponent } from './components/admin/deep-value/deep-value.component';
import { DeepValuedListComponent } from './components/admin/deep-valued-list/deep-valued-list.component';
import { NondividendDeepValueComponent } from './components/admin/nondividend-deep-value/nondividend-deep-value.component';
import { UserPagesComponent } from './components/admin/user-pages/user-pages.component';
import { UserPagesListComponent } from './components/admin/user-pages-list/user-pages-list.component';
import { StocksHistoryComponent } from './components/admin/stocks-history/stocks-history.component';
import { StocksComponent } from './components/admin/stocks/stocks.component';
import { AddFaqComponent } from './components/admin/faq/add-faq/add-faq.component';
import { ListFaqComponent } from './components/admin/faq/list-faq/list-faq.component';
import { MessageComponent } from './components/admin/pages/message/message.component';
import { AccessLogComponent } from './components/admin/access-log/access-log.component';
import { ArchivesComponent } from './components/admin/archives/archives.component';
import { ArchivesTableNewComponent } from './components/admin/archives-table-new/archives-table-new.component';
import { FreeAccountColumnsComponent } from './components/admin/free-account-columns/free-account-columns.component';
import { FreeAccountStocksComponent } from './components/admin/free-account-stocks/free-account-stocks.component';


//user components
import { UserLayoutComponent } from './components/user/user-layout/user-layout.component';
import { UserLoginComponent } from './components/user/login/login.component';
import { UserDashboardComponent } from './components/user/dashboard/dashboard.component';
import { MyStocksComponent } from './components/user/my-stocks/my-stocks.component';
import { MyWatchlistComponent } from './components/user/my-watchlist/my-watchlist.component';
import { MyAlertComponent } from './components/user/my-alert/my-alert.component';
import { UserValuedComponent } from './components/user/user-valued/user-valued.component';
import { CompareStockComponent } from './components/user/compare-stock/compare-stock.component';
import { SearchStockComponent } from './components/user/search-stock/search-stock.component';
import { NotificationComponent } from './components/user/notification/notification.component';
import { StockQuoteComponent } from './components/user/stock-quote/stock-quote.component';
import { UserFaqComponent } from './components/user/user-faq/user-faq.component';
import { PagesComponent } from './components/user/pages/pages.component';
import { HistoricalStockComponent } from './components/user/historical-stock/historical-stock.component';
import { StocksHistoryEditComponent } from './components/admin/stocks-history-edit/stocks-history-edit.component';
import { MyAccountComponent } from './components/user/my-account/my-account.component';
import { StocksValueEditComponent } from './components/admin/stocks-value-edit/stocks-value-edit.component';
import { Dow30Component } from './components/admin/dow30/dow30.component';
import { Tsx60Component } from './components/admin/tsx60/tsx60.component';
import { Displaydow30Component } from './components/user/displaydow30/displaydow30.component';
import { Displaytsx60Component } from './components/user/displaytsx60/displaytsx60.component';
import { ExportStocksComponent } from './components/admin/export-stocks/export-stocks.component';
import { EditStockValueComponent } from './components/admin/edit-stock-value/edit-stock-value.component';
import { UnsubscribeNotifyComponent } from './components/admin/unsubscribe-notify/unsubscribe-notify.component';
import { CourseComponent } from './components/user/course/course.component';
import { DividendStockComponent } from './components/admin/dividend-stock/dividend-stock.component';
import { NotifiyHeadingComponent } from './components/admin/notifiy-heading/notifiy-heading.component';
import { SecurityQuestionComponent } from './components/admin/security-question/security-question.component';
import { AdminLoginComponent } from './components/admin/admin-login/admin-login.component';
import { ToolTipsComponent } from './components/admin/tool-tips/tool-tips.component';
import { AddStockComponent } from './components/admin/add-stock/add-stock.component';
import { UpdateSplitComponent } from './components/admin/update-split/update-split.component';
import { EditPageComponent } from './components/admin/edit-page/edit-page.component';
import { CornJobComponent } from './components/admin/corn-job/corn-job.component';
import { LoginPageMessageComponent } from './components/admin/login-page-message/login-page-message.component';
import { BackupListComponent } from './components/admin/backup-list/backup-list.component';
import { EditHistoryDataComponent } from './components/admin/edit-history-data/edit-history-data.component';
import { UploadVideoComponent } from './components/admin/upload-video/upload-video.component';
import { UserNonValuedComponent } from './components/user/user-non-valued/user-non-valued.component';
import { DividendTrapSettingsComponent } from './components/admin/dividend-trap-settings/dividend-trap-settings.component';
import { UndervaluedStocksGenerateListComponent } from './components/admin/undervalued-stocks-generate-list/undervalued-stocks-generate-list.component';
import { MyStocksComponent as ExportToCsvMyStocksComponent } from './components/admin/export-to-csv/my-stocks/my-stocks.component';
import { MyWatchlistComponent as ExportToCsvMyWatchlistComponent } from './components/admin/export-to-csv/my-watchlist/my-watchlist.component';
import { MyAlertsComponent as ExportToCsvMyAlertsComponent } from './components/admin/export-to-csv/my-alerts/my-alerts.component';
import { MyEmailListComponent as ExportToCsvMyEmailListComponent } from './components/admin/export-to-csv/my-email-list/my-email-list.component';

import { AuthGuard } from "./auth.guard";

export const appRoutes: Routes = [
    {
        path: 'admin/signup', component: SignUpComponent,
        //children: [{ path: '', component: SignUpComponent }]
    },
    {
        path: 'admks/login', component: LoginComponent,
        //children: [{ path: '', component: SignUpComponent }]
    },
    {
        path: 'admin/request-reset-password',
        component: RequestResetComponent,
    },
    {
        path: 'admin/response-reset-password/:id/:token',
        component: ResponseResetComponent
    },
    {
        path: 'admks/admin-login/:id', component: AdminLoginComponent,
    },
    {
        //path: 'admin/dashboard', component: DashboardComponent, canActivate: [AuthGuard] 
        path: 'admin', component: LayoutComponent, canActivate: [AuthGuard], data: { role: 'Admin' },
        children: [
            // { path: '', redirectTo: 'employee', pathMatch: 'full' },
            { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'add-page', component: AddPageComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'edit-page/:id', component: EditPageComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'pages', component: ListPageComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'add-faq', component: AddFaqComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'edit-faq/:id', component: AddFaqComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'faqs', component: ListFaqComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'columns', component: ColumnsComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'baseline-dividend-paid-import', component: BaselineImportComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'baseline-consective-dividend-import', component: BaselineConsecutiveDivImportComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'baseline-consective-eps-import', component: BaselineConsecutiveEpsImportComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'si-criteria-5-year', component: SiCriteriaFiveYearsComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'si-criteria-10-year', component: SiCriteriaTenYearsComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'si-criteria-15-year', component: SiCriteriaFifteenYearsComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'si-criteria-20-year', component: SiCriteriaTwentyYearsComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'top-ranked/:slug', component: TopRankedStocksComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'top-ranked-list/:slug', component: TopRankedStocksListComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'runner-up/:slug', component: RunnerUpComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'runner-up-list/:slug', component: RunnerUpsListComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'valued/:type/:div-type/:slug', component: ValuedComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'valued-list/:type/:div-type/:slug', component: ValuedListComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'deep-valued/dividend/:slug', component: DeepValueComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'deep-valued-list/:type/:slug', component: DeepValuedListComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'deep-valued/nondividend/:slug', component: NondividendDeepValueComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'deep-valued-list/:type/:slug', component: DeepValuedListComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'user-pages/:slug', component: UserPagesComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'user-pages-list/:slug', component: UserPagesListComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'stocks-history', component: StocksHistoryComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'stocks', component: StocksComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            // { path: 'edit-stock-history/:slug/:symbol',component:StocksHistoryEditComponent },
            //{ path: 'employee/employee', component: EmployeeComponent },
            { path: 'edit-stock-history/:slug/:symbol', component: StocksValueEditComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'message', component: MessageComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'access-log', component: AccessLogComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'archives', component: ArchivesComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'archives-table-new/:year/:month/:pagename', component: ArchivesTableNewComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'free-account-columns', component: FreeAccountColumnsComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'free-account-stocks', component: FreeAccountStocksComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'dow30-stocks', component: Dow30Component, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'tsx30-stocks', component: Tsx60Component, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'export-stocks', component: ExportStocksComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'edit-stocks-value', component: EditStockValueComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'unsubscribe-notify', component: UnsubscribeNotifyComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'dividend-stocks', component: DividendStockComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'notify-heading', component: NotifiyHeadingComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'security-question', component: SecurityQuestionComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'tool-tips', component: ToolTipsComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'add-stock', component: AddStockComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'update-split', component: UpdateSplitComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'cron-jobs', component: CornJobComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'login-page-message', component: LoginPageMessageComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'baseline-highlow-import', component: BaselineHighlowImportComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'backup-list', component: BackupListComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'edit-history', component: EditHistoryDataComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'update-video', component: UploadVideoComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'dividend-trap-settings', component: DividendTrapSettingsComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'undervalued-stocks-generate-list', component: UndervaluedStocksGenerateListComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'export-to-csv-my-stocks', component: ExportToCsvMyStocksComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'export-to-csv-my-watchlist', component: ExportToCsvMyWatchlistComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'export-to-csv-my-alerts', component: ExportToCsvMyAlertsComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
            { path: 'export-to-csv-my-email-list', component: ExportToCsvMyEmailListComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
        ]
    },
    {
        path: '', redirectTo: '/login', pathMatch: 'full'
    },

    {
        path: 'login', component: UserLoginComponent,
        //children: [{ path: '', component: SignUpComponent }]
    },
    {
        //path: 'admin/dashboard', component: DashboardComponent, canActivate: [AuthGuard] 
        path: 'user', component: UserLayoutComponent, canActivate: [AuthGuard], data: { role: 'User' },
        children: [
            // { path: '', redirectTo: 'employee', pathMatch: 'full' },
            { path: 'dashboard', component: UserDashboardComponent, canActivate: [AuthGuard], data: { role: 'User' } },
            { path: 'top-ranked', component: UserDashboardComponent, canActivate: [AuthGuard], data: { role: 'User' } },
            { path: 'runner-up', component: UserDashboardComponent, canActivate: [AuthGuard], data: { role: 'User' } },
            { path: 'my-stocks', component: MyStocksComponent, canActivate: [AuthGuard], data: { role: 'User' } },
            { path: 'my-watchlist', component: MyWatchlistComponent, canActivate: [AuthGuard], data: { role: 'User' } },
            { path: 'my-alert', component: MyAlertComponent, canActivate: [AuthGuard], data: { role: 'User' } },
            { path: 'my-alert/:symbols', component: MyAlertComponent, canActivate: [AuthGuard], data: { role: 'User' } },
            { path: 'valued/:type/:slug', component: UserValuedComponent, canActivate: [AuthGuard], data: { role: 'User' } },
            { path: 'non-valued/:type/:slug', component: UserNonValuedComponent, canActivate: [AuthGuard], data: { role: 'User' } },
            { path: 'compare-stocks', component: CompareStockComponent, canActivate: [AuthGuard], data: { role: 'User' } },
            { path: 'search-stocks', component: SearchStockComponent, canActivate: [AuthGuard], data: { role: 'User' } },
            { path: 'my-email-lists', component: NotificationComponent, canActivate: [AuthGuard], data: { role: 'User' } },
            { path: 'stock-quote/:symbol', component: StockQuoteComponent, canActivate: [AuthGuard], data: { role: 'User' } },
            { path: 'faqs', component: UserFaqComponent, canActivate: [AuthGuard], data: { role: 'User' } },
            { path: 'pages/:slug', component: PagesComponent, canActivate: [AuthGuard], data: { role: 'User' } },
            { path: 'pages/:slug/:id', component: PagesComponent, canActivate: [AuthGuard], data: { role: 'User' } },
            { path: 'historical-stocks', component: HistoricalStockComponent, canActivate: [AuthGuard], data: { role: 'User' } },
            { path: 'my-account', component: MyAccountComponent, canActivate: [AuthGuard], data: { role: 'User' } },
            { path: 'display-dow30', component: Displaydow30Component, canActivate: [AuthGuard], data: { role: 'User' } },
            { path: 'display-tsx60', component: Displaytsx60Component, canActivate: [AuthGuard], data: { role: 'User' } },
            { path: 'course', component: CourseComponent, canActivate: [AuthGuard], data: { role: 'User' } },

            //{ path: 'employee/e,mployee', component: EmployeeComponent },
        ]
    },
];