import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ColumnsService } from '../../../services/admin/columns.service';
import { SiCriteriaService } from '../../../services/admin/si-criteria.service';

@Component({
  selector: 'app-si-criteria-five-years',
  templateUrl: './si-criteria-five-years.component.html',
  styleUrls: ['./si-criteria-five-years.component.css']
})
export class SiCriteriaFiveYearsComponent implements OnInit {

  criteriaForm: FormGroup;
  slug = 'SI Criteria using 5-year data';
  pageDetail = [];
  constructor(
    public fb: FormBuilder,
    private columnsService: ColumnsService,
    private toastr: ToastrService,
    private criteriaService: SiCriteriaService,
  ) { }
  columnList = [];
  operatorList = [
    { name: 'Less than or equal to', value: '<=' },
    { name: 'Greater than or equal to', value: '>=' },
    { name: 'Less than', value: '<' },
    { name: 'Greater than', value: '>' },
    { name: 'Equal to', value: '==' },
    { name: 'Not equal to', value: '!=' }
  ];
  optionList = [
    { name: 'Yes', value: 'Yes' },
    { name: 'No', value: 'No' },
  ];

  valueList = [
    { name: 'Undervalued', value: 'undervalued' },
    { name: 'Overvalued', value: 'overvalued' },
  ];
  option
  ngOnInit(): void {

    this.criteriaForm = this.fb.group({
      average_eps_growth: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      average_dividend_growth: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      payout_ratio: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      long_term_debt_equity_ratio: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      eps_increase: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      share_buypack: ['', [Validators.required]],
      pe_ratio_ttm: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      undervalued_or_overvalued: ['', [Validators.required]],
      pb_ratio: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      average_eps_growth_op: ['', [Validators.required]],
      average_dividend_growth_op: ['', [Validators.required]],
      payout_ratio_op: ['', [Validators.required]],
      long_term_debt_equity_ratio_op: ['', [Validators.required]],
      eps_increase_op: ['', [Validators.required]],
      share_buypack_op: ['', [Validators.required]],
      pe_ratio_ttm_op: ['', [Validators.required]],
      undervalued_or_overvalued_op: ['', [Validators.required]],
      pb_ratio_op: ['', [Validators.required]],
      annual_forward_vs_previous_year: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      annual_forward_vs_previous_year_op: ['', Validators.required],

    });
    this.getColumns();
    this.getDetails();
  }

  get f() { return this.criteriaForm.controls; }

  getColumns() {
    this.columnsService.getColumns('column_order', 'asc', 0, 150).subscribe((data: any) => {
      if (data.success == true) {
        this.columnList = data.data

      } else {
        this.toastr.error(data.message, 'Error in fetching column data');
      }
    },
      (err: HttpErrorResponse) => {
        this.toastr.error('Error in fetching columns');
      });
  }

  submitForm() {
    if (this.criteriaForm.invalid) {
      this.scrollToError();
      return;
    }

    this.criteriaService.updateCriteria(this.slug, this.criteriaForm.value)
      .subscribe((data: any) => {
        if (data.success) {
          this.toastr.success(data.message);
        } else {
          this.toastr.error(data.message, 'Page');
        }
      },
        (err: HttpErrorResponse) => {
          alert("Server Update Job Sub Category Error");
        });
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    this.scrollTo(firstElementWithError);
  }

  getDetails() {
    this.criteriaService.getCriteriaDetails(this.slug)
      .subscribe((data: any) => {
        if (data.success) {
          this.pageDetail = data.data;
          this.criteriaForm.controls['average_eps_growth'].setValue(this.pageDetail['average_eps_growth']);
          this.criteriaForm.controls['average_eps_growth_op'].setValue(this.pageDetail['average_eps_growth_op']);
          this.criteriaForm.controls['average_dividend_growth'].setValue(this.pageDetail['average_dividend_growth']);
          this.criteriaForm.controls['average_dividend_growth_op'].setValue(this.pageDetail['average_dividend_growth_op']);
          this.criteriaForm.controls['payout_ratio'].setValue(this.pageDetail['payout_ratio']);
          this.criteriaForm.controls['payout_ratio_op'].setValue(this.pageDetail['payout_ratio_op']);
          this.criteriaForm.controls['long_term_debt_equity_ratio'].setValue(this.pageDetail['long_term_debt_equity_ratio']);
          this.criteriaForm.controls['long_term_debt_equity_ratio_op'].setValue(this.pageDetail['long_term_debt_equity_ratio_op']);
          this.criteriaForm.controls['eps_increase'].setValue(this.pageDetail['eps_increase']);
          this.criteriaForm.controls['eps_increase_op'].setValue(this.pageDetail['eps_increase_op']);
          this.criteriaForm.controls['share_buypack'].setValue(this.pageDetail['share_buypack']);
          this.criteriaForm.controls['share_buypack_op'].setValue(this.pageDetail['share_buypack_op']);
          this.criteriaForm.controls['pe_ratio_ttm'].setValue(this.pageDetail['pe_ratio_ttm']);
          this.criteriaForm.controls['pe_ratio_ttm_op'].setValue(this.pageDetail['pe_ratio_ttm_op']);
          this.criteriaForm.controls['undervalued_or_overvalued'].setValue(this.pageDetail['undervalued_or_overvalued']);
          this.criteriaForm.controls['undervalued_or_overvalued_op'].setValue(this.pageDetail['undervalued_or_overvalued_op']);
          this.criteriaForm.controls['pb_ratio'].setValue(this.pageDetail['pb_ratio']);
          this.criteriaForm.controls['pb_ratio_op'].setValue(this.pageDetail['pb_ratio_op']);
          this.criteriaForm.controls['annual_forward_vs_previous_year'].setValue(this.pageDetail['annual_forward_vs_previous_year']);
          this.criteriaForm.controls['annual_forward_vs_previous_year_op'].setValue(this.pageDetail['annual_forward_vs_previous_year_op']);
        }
      },
        (err: HttpErrorResponse) => {
          alert("Server Update Job Sub Category Error");
        });
  }


}
