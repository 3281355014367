import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { StocksService } from '../../../services/user/stocks.service';
import { PagesService } from '../../../services/admin/pages.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-historical-stock',
  templateUrl: './historical-stock.component.html',
  styleUrls: ['./historical-stock.component.css']
})
export class HistoricalStockComponent implements OnInit {

  stocksList = [];
  stocksListData:[] = [];
  stockForm: FormGroup;
  selectedstocks = [];
  show_ids = [];
  displayedColumns: string[] = [];
  header = {};
  pagesize = 50;
  resultsLength = 0;
  filter = "";
  page = 0;
  slug = 'historical-stocks';
  title = "Historical Data";
  videoSource:any;
  content = "";
  closeResult: string;

  constructor(private stocksService: StocksService,
    private userService:UserService,
    private toastr: ToastrService,
    public fb: FormBuilder,
    public pagesService: PagesService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    )
  { }

  ngOnInit(): void {
    this.stockForm = this.fb.group({
      stocks: ['',[Validators.required]],
      stockMultiFilterCtrl:'',
      sortFilterCtrl:'',
    });
    this.getStocks();
  }

  getStocks(symbolstring="symbolstring",order="asc",page=0,limit=200,filter=""){
    this.stocksService.getAllStocks(symbolstring,order,page,limit,filter,this.selectedstocks,this.userService.user_type).subscribe((data: any) => {
        if (data.success == true) {
          this.stocksList = data.data;
          this.stockForm.controls['stocks'].setValue(this.selectedstocks);
        } else {
          this.toastr.error(data.message, 'Column');
        }
      },
      (err: HttpErrorResponse) => {
        this.toastr.error('Error in fetching Stocks');
      });
  }

  submitForm() {
  
       // alert();
       this.spinner.show();
       setTimeout(() => {
         this.spinner.hide();
       }, 3000);
     
    var stock_val = this.stockForm.controls['stocks'].value;
    let index= this.stocksList.findIndex(x => x._id == stock_val);

    if(index != -1){
    this.filter = this.stocksList[index]['symbolstring'];
    }

    this.getTopRankedList();
  }

  getTopRankedList() {
    this.stocksService.getUserStocksHistory(this.filter,"","",this.page,this.pagesize)
      .subscribe((data: any) => {
        if (data.success) {
          
          this.toastr.success(data.message);
          this.displayedColumns = [];

          this.stocksListData = data.data.stocks;
          this.resultsLength = data.data.count;
       
          for( let v in data.data.years){
              this.displayedColumns.push(""+data.data.years[v]);
             
          }
        } else {
          this.toastr.error(data.message);
        }
      },
      (err: HttpErrorResponse) => {
        alert("Server not conncted");
      });
  }


  getDividendColVal(column_id,stock_data,column_name){
    let arr = stock_data.data;
    let index= arr.findIndex(x => x.year == column_id);
  
    if(index != -1){
      if(parseFloat(stock_data.data[index][column_name]).toFixed(2) == '0.00'){
        return "N/A";
      }else{
        if(isNaN(parseFloat(stock_data.data[index][column_name]))){
          return "N/A";
        }else{
          if(column_name=="avg_dividend_yield" || column_name == "low_dividend_yield" || column_name=="high_dividend_yield" || column_name =="year_over_growth" || column_name== "year_over_eps_growth"){
              var val = parseFloat(stock_data.data[index][column_name]).toFixed(4);
              return val+"%";
          }else{
            return parseFloat(stock_data.data[index][column_name]).toFixed(4);
          }
        }
      }
    }
  }

  getStockSymbol(column_id,stock_data){
    return stock_data.symbolstring;
  }


  private filterStocksMulti(event: Event) {
    if (!this.stocksList) {
      return;
    }
    this.selectedstocks = this.stockForm.controls['stocks'].value;
    // get the search keyword
    let search =  (event.target as HTMLInputElement).value;
    if(search != ""){
        this.getStocks('symbolstring','asc',0,200,search);
    }else{
      this.getStocks('symbolstring','asc',0,200,'');
    }
  
  }
  getPageContent(){
    this.pagesService.getPageContent(this.slug)
    .subscribe((data: any) => {
      if (data.success) {
       this.content = data.data.content;
       this.videoSource = data.data.video_path
      } else {
        this.toastr.error(data.message);
      }
    },
    (err: HttpErrorResponse) => {
      alert("Server not conncted");
    });
  
   
  }
  
  open(content) {
    this.modalService.open(content, {size: 'xl', ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

}
