import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { UserService } from '../../../services/user/user.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ColumnsService } from '../../../services/admin/columns.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FilterService } from '../../../services/user/filter.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { PageColumnsService } from '../../../services/user/page-columns.service';
import { SymbolService } from 'src/app/services/user/symbol.service';

// import { threadId } from 'worker_threads';
@Component({
  selector: 'app-user-side-menu',
  templateUrl: './user-side-menu.component.html',
  styleUrls: ['./user-side-menu.component.css']
})
export class UserSideMenuComponent implements OnInit {

  conditionFlag: boolean = false;
  columnList: any = [];
  filter: any;
  tableFilter: any = [];
  filterValue: any;
  filterForm: FormGroup;
  filter_name: any = '';
  filter_country_id: any = '';
  filter_exchange_id: any = '';
  filter_column_id: any = '';
  columnIds: any = [];
  selected_filter: any;
  slug = "top-ranked";
  columnForm: FormGroup;
  filterList: any = [];
  tableTitle = "Top Ranked";
  navigate_url = "";
  modalReference: any;
  user_type = "";
  messageContent: any;
  chek_si = 0;
  gradout = true;
  menuout = false;
  defaultColumns: any = [];
  company_name = "";
  stock_symbol = "";
  free_course: boolean = false;
  messages: string = "";
  custfilter: boolean = false;
  customefilter: string;
  count = 0;
  countcheck = 0;
  symbolstring: string = "";
  customclick: boolean = false;
  customefiltername: string;
  selected_value: string = "";
  free_not_course: string = "";


  constructor(public userService: UserService,
    private modalService: NgbModal,
    private columnsService: ColumnsService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private filterService: FilterService,
    private symbolService: SymbolService,
    private pageColumnsService: PageColumnsService,) { }

  status: boolean = false;
  all_checked = false;
  default_checked = false;
  usaField: boolean = false;
  canadaField: boolean = false;

  // operatorList = [
  //   { name: 'Less than or equal to', value: '<=' },
  //   { name: 'Greater than or equal to', value: '>=' },
  //   { name: 'Less than', value: '<' },
  //   { name: 'Greater than', value: '>' },
  //   { name: 'Equal to', value: '==' },
  //   { name: 'Not equal to', value: '!=' }
  // ];

  operatorList: OperatorList[] = [
    { value: 'Less than or equal to', viewValue: '<=' },
    { value: 'Greater than or equal to', viewValue: '>=' },
    { value: 'Less than', viewValue: '<' },
    { value: 'Greater than', viewValue: '>' },
    { value: 'Equal to', viewValue: '==' },
    { value: 'Not equal to', viewValue: '!=' }
  ];

  ngOnInit(): void {
    var token = this.userService.getAccessToken();
    if (token) {
      var decoded = JSON.parse(atob(token.split('.')[1]));
      this.userService.user_type = decoded.type;
      this.userService.user_id = decoded.userId;
      this.userService.user_name = decoded.name;
    } else {
      this.userService.user_type = null;
      this.userService.user_id = null;
    }
    if (this.userService.user_type == "Free_platform" || this.userService.user_type == "course_platform" || this.userService.user_type == "Free_platformCourse_platform") {
      if (this.userService.user_type == "Free_platform" || this.userService.user_type == "Free_platformCourse_platform") {
        this.free_not_course = "Free_platform";
      } else {
        this.free_not_course = "course_platform";
      }
      this.free_course = false;
    } else {
      this.free_course = true;
    }
    this.user_type = this.userService.user_type;
    this.symbolstring = this.route.snapshot.paramMap.get("symbol");
    this.symbolService.symbolstring = this.symbolstring;
    if (this.router.url == '/user/my-stocks') {
      this.slug = 'my-stocks';
    }
    this.selected_filter = this.filterService.getItem(this.slug);
    this.filterForm = this.fb.group({
      filter_name: ['', [Validators.required]],
    });

    // if (this.router.url == '/user/compare-stocks') {
    //   this.menuout = true;
    // }
    // if (this.router.url == '/user/my-stocks') {
    //   this.menuout = true;
    // }
    // if (this.router.url == '/user/my-watchlist') {
    //   this.menuout = true;
    // }
    // if (this.router.url == '/user/search-stocks') {
    //   this.menuout = true;
    // }
    this.columnForm = this.fb.group({
      column_op: [''],
      column_value: [''],
    });
    this.detailsFunction();
    // this.getColumns();
    // this.getFilters();
    this.getFreeAccMessage();
    this.checkUser();
    // this.getUserTagValue();
    this.company_name = environment.compnay_name_column_id;
    this.stock_symbol = environment.symbol_column_id;
  }

  // getUserTagValue() {
  //   this.pageColumnsService.getUserTagName(this.userService.user_email).subscribe((data: any) => {
  //     if (!data.success && data.message == "logout") {
  //       this.userService.logout();
  //     }
  //   },
  //     (err: HttpErrorResponse) => {
  //       console.log("error");
  //       // this.toastr.error('');
  //     });
  // }

  detailsFunction() {
    if (this.symbolstring != null) {
      this.tableTitle = "Stock Data";
      if (this.router.url == '/user/stock-quote/' + this.symbolstring) {
        this.slug = 'quote';
        this.navigate_url = '/user/stock-quote/' + this.symbolstring;
        this.getDefaultColumns(this.slug);
      }
    } else if (this.router.url == '/user/runner-up') {
      this.slug = 'runner-ups';
      this.tableTitle = "Runners-Up Stocks";
      this.navigate_url = '/user/runner-up';
      this.getDefaultColumns(this.slug);

    } else if (this.router.url == '/user/valued/dividend/undervalued') {
      this.slug = 'dividend-undervalued';
      this.tableTitle = "Undervalued Dividend Stocks";
      this.navigate_url = '/user/valued/dividend/undervalued';
      this.getDefaultColumns(this.slug);

    } else if (this.router.url == '/user/valued/dividend/overvalued') {
      this.slug = 'dividend-overvalued';
      this.tableTitle = "Overvalued Dividend Stocks";
      this.navigate_url = '/user/valued/dividend/overvalued';
      this.getDefaultColumns(this.slug);

    } else if (this.router.url == '/user/valued/dividend/deep-valued') {
      this.slug = 'dividend-deep-valued';
      this.tableTitle = "Deep Value Dividend Stocks";
      this.navigate_url = '/user/valued/dividend/deep-valued';
      this.getDefaultColumns(this.slug);

    } else if (this.router.url == '/user/non-valued/non-dividend/undervalued') {
      this.slug = 'non-dividend-undervalued';
      this.tableTitle = "Undervalued Non-Dividend Stocks";
      this.navigate_url = '/user/non-valued/non-dividend/undervalued';
      this.getDefaultColumns(this.slug);

    } else if (this.router.url == '/user/non-valued/non-dividend/overvalued') {
      this.slug = 'non-dividend-overvalued';
      this.tableTitle = "Overvalued Non-Dividend Stocks";
      this.navigate_url = '/user/non-valued/non-dividend/overvalued';
      this.getDefaultColumns(this.slug);

    } else if (this.router.url == '/user/non-valued/non-dividend/deep-valued') {
      this.slug = 'non-dividend-deep-valued';
      this.tableTitle = "Deep Value Non-Dividend Stocks";
      this.navigate_url = '/user/non-valued/non-dividend/deep-valued';
      this.getDefaultColumns(this.slug);

    } else if (this.router.url == '/user/my-stocks') {
      this.slug = "my-stocks";
      this.tableTitle = "My Stocks";
      this.navigate_url = '/user/my-stocks';
      this.getDefaultColumns(this.slug);

    } else if (this.router.url == '/user/my-watchlist') {
      this.slug = "my-watchlist";
      this.tableTitle = "My Watchlist";
      this.navigate_url = '/user/my-watchlist';
      this.getDefaultColumns(this.slug);

    } else if (this.router.url == '/user/dashboard') {
      this.slug = "top-ranked";
      this.tableTitle = "Top Ranked Stocks";
      this.navigate_url = '/user/dashboard';
      this.getDefaultColumns(this.slug);

    } else if (this.router.url == '/user/search-stocks') {
      this.tableTitle = "Search Stocks";
      this.slug = 'search-stocks';
      this.getDefaultColumns(this.slug);

    } else if (this.router.url == '/user/compare-stocks') {
      this.tableTitle = "Compare Stocks";
      this.slug = 'compare-stocks';
      this.getDefaultColumns(this.slug);
    }
  }

  checkUser() {
    if (this.user_type == "US_onlyCourse_platform" || this.user_type == "US_only") {
      this.usaField = true;
      this.canadaField = false;
    } else if (this.user_type == "CAD_onlyCourse_platform" || this.user_type == "CAD_only") {
      this.usaField = false;
      this.canadaField = true;
    } else if (this.user_type == "US_and_CAD" || this.user_type == "US_and_CADCourse_platform") {
      this.usaField = true;
      this.canadaField = true;
    }
  }

  checkUserTag() {
    if (this.user_type == "course_platform") {
      return "greyOut";
    }
  }

  clickEvent() {
    if (this.userService.user_type == "Free_platform") {
      this.status = false;
    } else {
      this.status = !this.status;
    }
  }

  logout() {
    this.userService.logout();
  }

  restore() {
    if (this.router.url == '/user/search-stocks') {
      this.customefilter = "Default filter";
      this.filterService.removeFilterName(this.slug);
      this.filterService.setFilterName(this.slug, this.customefilter);
      this.userService.restore(this.slug);
      this.router.navigate(['/user/search-stocks']);
      window.location.reload();
    } else if (this.router.url == '/user/notifications') {
      this.router.navigate(['/user/notifications']);
    } else {
      if (this.symbolstring != null) {
        if (this.router.url == '/user/stock-quote/' + this.symbolstring) {
          this.navigate_url = '/user/stock-quote/' + this.symbolstring;
        }
      }
      this.navigate_url = this.router.url;
      this.customefilter = "Default filter";
      this.filterService.removeFilterName(this.slug);
      this.filterService.setFilterName(this.slug, this.customefilter);
      this.userService.restore(this.slug);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate([this.navigate_url]));
    }
  }

  closeResult: string;

  open(content) {
    this.ngOnInit();
    this.selected_filter = this.filterService.getItem(this.slug);
    this.modalReference = this.modalService.open(content, { size: 'xl', ariaLabelledBy: 'modal-basic-title' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.getColumns();
      this.getFilters();
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  closeModal() {
    if (this.modalReference !== undefined) {
      this.modalReference.close();
    }
  }

  getColumns() {
    this.columnsService.getAllColumns(this.filter).subscribe((data: any) => {
      if (data.success == true) {
        this.columnList = data.data;
      } else if (data.success == false) {
        this.toastr.error(data.message, 'Column');
      }
    },
      (error) => {
        throw error;
      });
  }

  getFilters() {
    this.filterService.getFilters(this.userService.user_id, this.slug).subscribe((data: any) => {
      if (data.success == true) {
        if (data != "") {
          this.filterList = data.data;
        } else {
          this.filterList = [];
        }
      } else if (data.success == false) {
        this.toastr.error(data.message, 'Column');
      }
    },
      (error) => {
        throw error;
      });

  }

  applyFilter(value) {
    this.filter = value;
    this.getColumns();
  }

  drop(event: CdkDragDrop<any>) {
    moveItemInArray(this.columnList, event.previousIndex, event.currentIndex);
  }

  setFilterValue(value) {
    this.filterValue = value;
  }

  saveUpdateFilter() {
    for (var i = 0; i < this.selected_filter.columns.length; i++) {
      if (this.selected_filter.columns[i]._id == "5f3bd442cd95fa3b5815b218" || this.selected_filter.columns[i]._id == "5f3bd442cd95fa3b5815b237") {
        this.selected_filter.columns[i].show_column = false;
      }
    }
    this.filterService.setItem(this.slug, this.selected_filter);
    if (this.customclick) {
      this.customefilter = "Custom filter applied";
    } else if (this.custfilter) {
      this.customefilter = this.customefiltername + " filter applied";
    } else {
      this.customefilter = "Default filter";
    }
    this.navigate_url = this.router.url;
    this.filterService.removeFilterName(this.slug);
    this.filterService.setFilterName(this.slug, this.customefilter);
    if (this.router.url != '/user/search-stocks') {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate([this.navigate_url]));
    }
    this.modalReference.close();
    if (this.router.url == '/user/search-stocks') {
      window.location.reload();
    }
    // window.location.reload();
  }

  chnageColumn(event, value) {

    this.selected_filter.show_columns = "";
    // this.default_checked = false;
    // this.all_checked = false;

    if (value == "all") {
      if (event.target.checked) {
        this.selected_filter.show_columns = "all";
        for (var i = 0; i < this.selected_filter.columns.length; i++) {
          if (this.selected_filter.columns[i]._id != this.company_name && this.selected_filter.columns[i]._id != this.stock_symbol) {
            this.selected_filter.columns[i].show_column = true;
          } else {
            this.selected_filter.columns[i].show_column = false;
          }
        }
      } else {
        for (var i = 0; i < this.selected_filter.columns.length; i++) {
          this.selected_filter.columns[i].show_column = false;
        }
      }
    }
  }

  chnageColumnSi5(event, value) {

    if (value == "si_5") {
      for (var i = 0; i < this.selected_filter.columns.length; i++) {
        this.selected_filter.columns[i].show_column = false;
      }
      if (event.target.checked) {
        this.selected_filter.show_columns = "si_5";
        var si5Array = [
          { id: 1, text: "Average EPS Growth (5-year), Rule #5a" },
          { id: 2, text: "Number of EPS increases in the last 5-years, Rule #5b" },
          { id: 3, text: "Average Dividend Growth (5-year), Rule #6" },
          { id: 4, text: "Payout Ratio, Rule #7" },
          { id: 5, text: "Long-term Debt/Equity Ratio, Rule #8" },
          { id: 6, text: "Share Buyback?, Rule #10" },
          { id: 7, text: "P/E Ratio (TTM), Rule #11.a" },
          { id: 8, text: "Undervalued or Overvalued (based on 5-year data), Rule #11.b" },
          { id: 9, text: "P/B Ratio, Rule #11.c" },
          { id: 10, text: "SI Criteria (out of 10) using 5-year data" },
          { id: 11, text: "Annual Forward Dividend vs Previous Year, Rule #9" },
        ];
        for (let i = 0; i < this.selected_filter.columns.length; i++) {
          for (let j = 0; j < si5Array.length; j++) {
            if (this.selected_filter.columns[i].column_name == si5Array[j].text) {
              this.selected_filter.columns[i].show_column = true;
            }
          }
        }
        // console.log(this.selected_filter.columns);
      } else {
        for (var i = 0; i < this.selected_filter.columns.length; i++) {
          this.selected_filter.columns[i].show_column = false;
        }
      }
    }
  }

  chnageColumnSi10(event, value) {

    if (value == "si_10") {
      for (var i = 0; i < this.selected_filter.columns.length; i++) {
        this.selected_filter.columns[i].show_column = false;
      }
      if (event.target.checked) {
        this.selected_filter.show_columns = "si_10";
        var si5Array = [
          { id: 1, text: "Average EPS Growth (10-year), Rule #5a" },
          { id: 2, text: "Number of EPS increases in the last 10-years, Rule #5b" },
          { id: 3, text: "Average Dividend Growth (10-year), Rule #6" },
          { id: 4, text: "Payout Ratio, Rule #7" },
          { id: 5, text: "Long-term Debt/Equity Ratio, Rule #8" },
          { id: 6, text: "Share Buyback?, Rule #10" },
          { id: 7, text: "P/E Ratio (TTM), Rule #11.a" },
          { id: 8, text: "Undervalued or Overvalued (based on 10-year data), Rule #11.b" },
          { id: 9, text: "P/B Ratio, Rule #11.c" },
          { id: 10, text: "SI Criteria (out of 10) using 10-year data" },
          { id: 11, text: "Annual Forward Dividend vs Previous Year, Rule #9" },
        ];
        for (let i = 0; i < this.selected_filter.columns.length; i++) {
          for (let j = 0; j < si5Array.length; j++) {
            if (this.selected_filter.columns[i].column_name == si5Array[j].text) {
              this.selected_filter.columns[i].show_column = true;
            }
          }
        }
        // console.log(this.selected_filter.columns);
      } else {
        for (var i = 0; i < this.selected_filter.columns.length; i++) {
          this.selected_filter.columns[i].show_column = false;
        }
      }
    }
  }

  chnageColumnSi15(event, value) {

    if (value == "si_15") {
      for (var i = 0; i < this.selected_filter.columns.length; i++) {
        this.selected_filter.columns[i].show_column = false;
      }
      if (event.target.checked) {
        this.selected_filter.show_columns = "si_15";
        var si5Array = [
          { id: 1, text: "Average EPS Growth (15-year), Rule #5a" },
          { id: 2, text: "Number of EPS increases in the last 15-years, Rule #5b" },
          { id: 3, text: "Average Dividend Growth (15-year), Rule #6" },
          { id: 4, text: "Payout Ratio, Rule #7" },
          { id: 5, text: "Long-term Debt/Equity Ratio, Rule #8" },
          { id: 6, text: "Share Buyback?, Rule #10" },
          { id: 7, text: "P/E Ratio (TTM), Rule #11.a" },
          { id: 8, text: "Undervalued or Overvalued (based on 15-year data), Rule #11.b" },
          { id: 9, text: "P/B Ratio, Rule #11.c" },
          { id: 10, text: "SI Criteria (out of 10) using 15-year data" },
          { id: 11, text: "Annual Forward Dividend vs Previous Year, Rule #9" },
        ];
        for (let i = 0; i < this.selected_filter.columns.length; i++) {
          for (let j = 0; j < si5Array.length; j++) {
            if (this.selected_filter.columns[i].column_name == si5Array[j].text) {
              this.selected_filter.columns[i].show_column = true;
            }
          }
        }
        // console.log(this.selected_filter.columns);
      } else {
        for (var i = 0; i < this.selected_filter.columns.length; i++) {
          this.selected_filter.columns[i].show_column = false;
        }
      }
    }
  }

  chnageColumnSi20(event, value) {

    if (value == "si_20") {
      for (var i = 0; i < this.selected_filter.columns.length; i++) {
        this.selected_filter.columns[i].show_column = false;
      }
      if (event.target.checked) {
        this.selected_filter.show_columns = "si_20";
        var si5Array = [
          { id: 1, text: "Average EPS Growth (20-year), Rule #5a" },
          { id: 2, text: "Number of EPS increases in the last 20-years, Rule #5b" },
          { id: 3, text: "Average Dividend Growth (20-year), Rule #6" },
          { id: 4, text: "Payout Ratio, Rule #7" },
          { id: 5, text: "Long-term Debt/Equity Ratio, Rule #8" },
          { id: 6, text: "Share Buyback?, Rule #10" },
          { id: 7, text: "P/E Ratio (TTM), Rule #11.a" },
          { id: 8, text: "Undervalued or Overvalued (based on 20-year data), Rule #11.b" },
          { id: 9, text: "P/B Ratio, Rule #11.c" },
          { id: 10, text: "SI Criteria (out of 10) using 20-year data" },
          { id: 11, text: "Annual Forward Dividend vs Previous Year, Rule #9" },
        ];
        for (let i = 0; i < this.selected_filter.columns.length; i++) {
          for (let j = 0; j < si5Array.length; j++) {
            if (this.selected_filter.columns[i].column_name == si5Array[j].text) {
              this.selected_filter.columns[i].show_column = true;
            }
          }
        }
        // console.log(this.selected_filter.columns);
      } else {
        for (var i = 0; i < this.selected_filter.columns.length; i++) {
          this.selected_filter.columns[i].show_column = false;
        }
      }
    }
  }

  chnageColumnDefault(event, value) {

    if (value == "default") {
      for (var i = 0; i < this.selected_filter.columns.length; i++) {
        this.selected_filter.columns[i].show_column = false;
      }
      if (event.target.checked) {
        this.selected_filter.show_columns = "default";
        for (let i = 0; i < this.selected_filter.columns.length; i++) {
          for (let j = 0; j < this.defaultColumns.length; j++) {
            if (this.selected_filter.columns[i].column_name == this.defaultColumns[j]) {
              this.selected_filter.columns[i].show_column = true;
            }
          }
        }
      } else {
        for (var i = 0; i < this.selected_filter.columns.length; i++) {
          this.selected_filter.columns[i].show_column = false;
        }
      }
    }
  }

  checkColumn(val) {
    if (this.selected_filter.show_columns != undefined && this.selected_filter.show_columns !== null) {
      if (this.selected_filter.show_columns == val) {
        return true;
      } else {
        return false;
      }
    }
  }

  chnageCountry(event, value) {

    if (value == "us_stock") {
      if (event.target.checked) {
        this.selected_filter.us_stocks = true;
      } else {
        this.selected_filter.us_stocks = false;
      }
    }

    if (value == "cand_stcok") {
      if (event.target.checked) {
        this.selected_filter.canada_stocks = true;
      } else {
        this.selected_filter.canada_stocks = false;
      }
    }

  }

  checkCountry(val) {

    if (val == "us_stock") {
      if (this.selected_filter.us_stocks != undefined) {
        if (this.selected_filter.us_stocks == true) {
          return true;
        } else {
          return false;
        }
      }
    }

    if (val == "cand_stcok") {
      if (this.selected_filter.cand_stcoks != undefined) {
        if (this.selected_filter.cand_stcoks == true) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  chnageExchanges(event, value) {

    if (event.target.checked) {
      if (value == "nsd") {
        this.customclick = true;
        this.selected_filter.show_nsd_exchange = true;
      }
      if (value == "nye") {
        this.customclick = true;
        this.selected_filter.show_nye_exchange = true;
      }
      if (value == "tsx") {
        this.customclick = true;
        this.selected_filter.show_tsx_exchange = true;
      }
    } else {
      if (value == "nsd") {
        this.selected_filter.show_nsd_exchange = false;
      }
      if (value == "nye") {
        this.selected_filter.show_nye_exchange = false;
      }
      if (value == "tsx") {
        this.selected_filter.show_tsx_exchange = false;
      }
    }
    //this.filter_exchange_id = value;
  }

  chnageStocks(event, value) {
    this.columnIds = value;
  }

  checkExchanges(value) {
    if (value == "nsd") {
      if (this.selected_filter.show_nsd_exchange) {
        return true;
      }
    }
    if (value == "nye") {
      if (this.selected_filter.show_nye_exchange) {
        return true;
      }
    }
    if (value == "tsx") {
      if (this.selected_filter.show_tsx_exchange) {
        return true;
      }
    }
  }

  setFormValue() {
    this.filterForm.controls['filter_name'].setValue(this.filter_name);
    this.filterForm.controls['filter_country_id'].setValue(this.filter_country_id);
    this.filterForm.controls['filter_exchange_id'].setValue(this.filter_exchange_id);
    this.filterForm.controls['filter_column_id'].setValue(this.filter_column_id);
    this.filterForm.controls['columnId'].setValue(this.columnIds);

    this.columnsService.addFilters(this.filterForm.value).subscribe((data: any) => {
      if (data.success == true) {

        //this.columnList = data.data

        let arr = data.data;
        if (arr && arr.length > 0) {
          this.columnList = arr.forEach((element, index) => {
            element.conditionFlag = false;
          })
        }
      } else if (data.success == false) {
        this.toastr.error(data.message, 'Column');
      }
    },
      (error) => {
        throw error;
      });

  }

  checkValue(id) {
    let indexCol = this.selected_filter.columns.findIndex(x => x._id == id);
    if (indexCol != -1) {
      if (this.selected_filter.columns[indexCol].show_column) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  AddremoveColumns(column, event) {
    let indexCol = this.selected_filter.columns.findIndex(x => x._id == column._id);
    if (indexCol != -1) {
      this.selected_filter.columns[indexCol].show_column = event.target.checked;
    }
    for (var i = 0; i < this.selected_filter.columns.length; i++) {
      if (this.selected_filter.columns[i].show_column) {
        this.countcheck += 1;
      }
    }
    if (this.countcheck > this.count || this.countcheck < this.count) {
      this.customclick = true;
    }
  }

  columnOperatorChnaged(column, event) {
    this.customclick = true;
    let indexCol = this.selected_filter.columns.findIndex(x => x._id == column._id);
    if (indexCol != -1) {
      this.selected_filter.columns[indexCol].column_op = event.value;
      //this.filterService.setItem(this.slug,this.selected_filter);
    }
  }

  addOperatorValue(column, event) {
    let indexCol = this.selected_filter.columns.findIndex(x => x._id == column._id);
    if (indexCol != -1) {
      this.selected_filter.columns[indexCol].operator_value = event.target.value;
      //this.filterService.setItem(this.slug,this.selected_filter);
    }
  }

  saveFilter() {
    if (this.filterForm.invalid) {
      return;
    }
    this.filterService.addFilter(this.filterForm.value, this.selected_filter, this.userService.user_id, this.slug)
      .subscribe(data => {
        if (data.success) {
          this.toastr.success(data.message);
          this.getFilters();

        } else {
          this.toastr.error(data.message);
        }
      },
        error => {
          //console.log(error);
        });
  }

  get f() { return this.filterForm.controls; }

  removeFilter(filter) {
    this.filterService.removeFilter(this.userService.user_id, filter, this.slug)
      .subscribe(data => {
        if (data.success) {
          this.toastr.success(data.message);
          this.getFilters();
        } else {
          this.toastr.error(data.message);
        }
      },
        error => {
          //console.log(error);
        });
  }

  useFilter(filter) {
    this.custfilter = true;
    this.customefiltername = filter.name;
    this.selected_filter = filter.selected_filter;
    if (filter.selected_filter.us_stocks != undefined && filter.selected_filter.us_stocks) {
      this.selected_filter.us_stocks = true;
    }

    if (filter.selected_filter.cand_stcoks != undefined && filter.selected_filter.cand_stcoks) {
      this.selected_filter.cand_stcoks = true;
    }

    if (filter.selected_filter.show_nsd_exchange != undefined && filter.selected_filter.show_nsd_exchange) {
      this.selected_filter.show_nsd_exchange = true;
    }

    if (filter.selected_filter.show_nye_exchange != undefined && filter.selected_filter.show_nye_exchange) {
      this.selected_filter.show_nye_exchange = true;
    }

    if (filter.selected_filter.show_tsx_exchange != undefined && filter.selected_filter.show_tsx_exchange) {
      this.selected_filter.show_tsx_exchange = true;
    }

    if (filter.selected_filter.show_columns != undefined) {
      if (filter.selected_filter.show_columns == "all") {
        this.selected_filter.show_columns = "all";
      }

      if (filter.selected_filter.show_columns == "default") {
        this.selected_filter.show_columns = "default";
      }

    }
    // this.getFilters();

  }

  getSelectedIpVal(column) {
    let indexCol = this.selected_filter.columns.findIndex(x => x._id == column._id);
    if (indexCol != -1) {
      if (this.selected_filter.columns[indexCol].operator_value != undefined) {
        return this.selected_filter.columns[indexCol].operator_value
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  getSelectedIpOpt(column: any) {
    let indexCol = this.selected_filter.columns.findIndex(x => x._id == column._id);
    this.selected_value = '';
    if (indexCol != -1) {
      if (this.selected_filter.columns[indexCol].column_op != undefined) {
        let tempValue = this.selected_filter.columns[indexCol].column_op;
        let index = this.operatorList.findIndex(x => x.viewValue == tempValue);
        this.selected_value = this.operatorList[index]['viewValue'];
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getFreeAccMessage() {
    this.userService.getFreeAccMessage()
      .subscribe((response: any) => {
        // console.log(response);
        if (response.success == true) {
          this.messageContent = response.data;
        }
      },
        (error) => {
          throw error;
        });
  }

  getDefaultColumns(slug) {
    this.defaultColumns = [];
    this.count = 0;
    this.userService.getDefaultColumns(slug)
      .subscribe((response: any) => {
        var ids_data = response.data;
        for (let v in ids_data) {
          this.defaultColumns.push(ids_data[v]['column_name']);
        }
        if (this.selected_filter !== null) {
          for (var i = 0; i < this.selected_filter.columns.length; i++) {
            if (this.selected_filter.columns[i].show_column) {
              this.count += 1;
            }
          }
        }
      },
        (error) => {
          throw error;
        });
  }

  freeandcourse(content: any, type: string) {

    if (type == "menu_item") {
      this.messages = "This feature is only available to SI Report & Platform members.";
    }
    this.modalService.open(content, { size: 'md', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  upgradeNow() {
    window.location.href = "https://www.simplyinvesting.com/invest";
  }

  removeColumns(columnName: any) {
    var columnsArray = ["Delisted Stock", "Dummy Column 5-years", "Dummy Column 10-years", "Dummy Column 15-years", "Dummy Column 20-years", "Dividend Amount", "EPS (Year End)", "Stock Symbol", "Company Name", "Company Description"];
    if (columnsArray.findIndex(x => x == columnName) !== -1) {
      return false;
    } else {
      return true;
    }
  }

  removePlusSign(columnName: any) {
    var columnsArray = ["Dividend Currency", "Dividend Frequency", "Dividend Payment Date",
      "Dividend Record Data", "Ex-Dividend Date", "Exchange", "Industry", "Market", "Sector", "Share Price Date",
      "Undervalued or Overvalued (based on 5-year data), Rule #11.b",
      "Undervalued or Overvalued (based on 10-year data), Rule #11.b",
      "Undervalued or Overvalued (based on 15-year data), Rule #11.b",
      "Undervalued or Overvalued (based on 20-year data), Rule #11.b",
      "Fiscal Year-End Date", "Website", "Company Address1", "Company Address2",
      "Company Address, City", "Company Address, State", "Company Address, Country"];
    if (columnsArray.findIndex(x => x == columnName) !== -1) {
      return false;
    } else {
      return true;
    }
  }

  changeActiveFilter(filter: any) {
    if (this.customefiltername) {
      if (this.customefiltername == filter) {
        return "activeFilter";
      } else {
        return "notactiveFilter";
      }
    } else {
      return "notactiveFilter";
    }
  }

  changeActiveSymbol(filter: any) {
    if (this.customefiltername) {
      if (this.customefiltername == filter) {
        return "fa fa-hand-peace-o";
      } else {
        return "fa fa-check";
      }
    } else {
      return "fa fa-check";
    }
  }

}


export interface OperatorList {
  value: string;
  viewValue: string;
}