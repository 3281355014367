import { Component, OnInit } from '@angular/core';
import { PagesService } from '../../../services/admin/pages.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-backup-list',
  templateUrl: './backup-list.component.html',
  styleUrls: ['./backup-list.component.css']
})
export class BackupListComponent implements OnInit {

  folderList: any;

  constructor(
    private router: Router,
    private pagesService: PagesService,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.getDataBackUpFiles();
  }

  getDataBackUpFiles() {
    this.pagesService.getDataBackUpFile()
      .subscribe((res: any) => {
        if (res != null) {
          this.folderList = res.data;
        }
      },
        (err: HttpErrorResponse) => {
          this.toastr.error('Error in Fatching Data');
        });
  }

}
