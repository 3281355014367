import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { StocksService } from '../../../services/admin/stocks.service';
import { ToastrService } from 'ngx-toastr';
import { ReplaySubject, Subject, merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-stocks',
  templateUrl: './stocks.component.html',
  styleUrls: ['./stocks.component.css']
})
export class StocksComponent implements OnInit, AfterViewInit {


  filter = "";
  show_ids = [];
  stocksList: object[] = [];
  displayedColumns: string[] = [];
  header = {};
  pagesize = 50;
  resultsLength = 0;
  company_name = "";
  stock_symbol = "";
  public selection = new SelectionModel<any>(true, []);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(public fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private stocksService: StocksService,
    private toastr: ToastrService) { }

  ngOnInit(): void {

    this.company_name = environment.compnay_name_column_id;
    this.stock_symbol = environment.symbol_column_id;
    //this.getTopRankedList()
  }

  ngAfterViewInit() {


    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.stocksService!.getStocksByCriteria(this.filter, this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
        }),
        map(data => {


          //this.isLoadingResults = false;
          //this.isRateLimitReached = false;
          //this.resultsLength = data.count;

          return data.data;
        }),
        catchError(() => {
          //this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          //this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe((data: any) => {


        this.stocksList = data.stocks;
        this.resultsLength = data.count;
        var ids_data = data.ids;
        ids_data.sort(function (a, b) {
          return a.column_order - b.column_order;
        });
        this.displayedColumns = ['select'];
        for (let v in ids_data) {
          this.displayedColumns.push(ids_data[v]['column_id']);
          this.header[ids_data[v]['column_id']] = ids_data[v]['column_name'];

        }
      });
  }


  getTopRankedList() {
    this.stocksService.getStocksByCriteria(this.filter, "", "", 0, this.pagesize)
      .subscribe((data: any) => {
        if (data.success) {
          this.toastr.success(data.message);
          this.displayedColumns = ['select'];
          this.stocksList = data.data.stocks;
          this.resultsLength = data.data.count;
          var ids_data = data.data.ids;
          ids_data.sort(function (a, b) {
            return a.column_order - b.column_order;
          });
          for (let v in ids_data) {
            this.displayedColumns.push(ids_data[v]['column_id']);
            this.header[ids_data[v]['column_id']] = ids_data[v]['column_name'];

          }
        } else {
          this.toastr.error(data.message);
        }
      },
        (err: HttpErrorResponse) => {
          alert("Server not conncted");
        });
  }


  getColVal(column_id, stock_data) {
    let index = stock_data.values_data.findIndex(x => x.column_id == column_id);
    var val = "";
    if (index != -1) {
      if (typeof stock_data.values_data[index]['column_value'] === "number") {
        if (Number.isInteger(stock_data.values_data[index]['column_value'])) {

          val = stock_data.values_data[index]['column_value'];
          if (this.header[column_id] == "Dividends Paid Since" || this.header[column_id] == "Annual Forward Dividend vs Previous Year, Rule #9") {
            if (this.header[column_id] == "Annual Forward Dividend vs Previous Year, Rule #9") {
              if (stock_data.values_data[index]['column_value'] == 0) {
                return val;
              }
            } else {
              return val;
            }
          } else {
            val = val.toLocaleString();
          }
          if (this.header[column_id] == "Consecutive Years of Dividend Increases" || this.header[column_id] == "Consecutive Years of EPS Increases") {
            return val;
          }
          if (stock_data.values_data[index]['column_value'] == 0) {
            val = "N/A";
          }
          // val = val.toLocaleString();
        } else {
          if (this.header[column_id] == "Annual Forward Dividend vs Previous Year, Rule #9") {
            val = (parseFloat(stock_data.values_data[index]['column_value']).toFixed(4) + "%");
          } else {
            val = parseFloat(stock_data.values_data[index]['column_value']).toFixed(2);
          }
        }
      } else {
        if (stock_data.values_data[index]['column_value'] == "") {
          val = "N/A";
        } else {
          val = stock_data.values_data[index]['column_value'];
        }
        if (stock_data.values_data[index]['column_value'] == null) {
          val = "N/A";
        } else {
          val = stock_data.values_data[index]['column_value'];
        }
        // val = stock_data.values_data[index]['column_value'];
      }
    } else {
      val = "N/A";
    }

    var percent_arr = [
      '%difference between Graham Price vs Share Price',
      'Average Dividend Growth (5-year), Rule #6', 'Average Dividend Growth (10-year), Rule #6',
      'Average Dividend Growth (15-year), Rule #6', 'Average Dividend Growth (20-year), Rule #6',
      'Average Dividend Yield (5-year)', 'Average Dividend Yield (10-year)',
      'Average Dividend Yield (15-year)', 'Average Dividend Yield (20-year)',
      'Average EPS Growth (5-year), Rule #5a', 'Average EPS Growth (10-year), Rule #5a',
      'Average EPS Growth (15-year), Rule #5a',
      'Average EPS Growth (20-year), Rule #5a',
      'Average High Dividend Yield (5-year)',
      'Average High Dividend Yield (10-year)',
      'Average High Dividend Yield (15-year)',
      'Average High Dividend Yield (20-year)',
      'Average Low Dividend Yield (5-year)',
      'Average Low Dividend Yield (10-year)',
      'Average Low Dividend Yield (15-year)',
      'Average Low Dividend Yield (20-year)',
      'Current Dividend Yield',
      'Difference Between Current Dividend Yield and Average Dividend Yield (5-year)',
      'Difference Between Current Dividend Yield and Average Dividend Yield (10-year)',
      'Difference Between Current Dividend Yield and Average Dividend Yield (15-year)',
      'Difference Between Current Dividend Yield and Average Dividend Yield (20-year)',
      'Profit Margin (Total Operating)',
      'Total Debt to Equity Ratio',
      'Payout Ratio, Rule #7',
      'Long-term Debt/Equity Ratio, Rule #8',
      //  'Annual Forward Dividend vs Previous Year, Rule #9'
    ]
    if (percent_arr.indexOf(this.header[column_id]) !== -1) {
      if (val != "N/A" && val != null && val != " " && val != "undefined") {
        if (val != "0") {
          val = val + "%";
        } else {
          val = "N/A";
        }
      }
    }
    return val;
  }

  applyFilter(event: Event) {
    //this.filter = (event.target as HTMLInputElement).value;
    this.filter = (<HTMLInputElement>document.getElementById("myValue")).value;
    this.getTopRankedList();
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.stocksList.length;
    return numSelected === numRows;
  }

  public checkboxLabel(row?: any): string {
    return (!row)
      ? `${this.isAllSelected() ? 'select' : 'deselect'} all`
      : `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  public masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.stocksList.forEach(row => this.selection.select(row));
  }

  public freeStock(row, event) {
    this.stocksService.updateFreeStock(row.symbolstring, event.checked)
      .subscribe((data: any) => {
        if (data.success) {
          this.getTopRankedList;
        } else {
          this.toastr.error(data.message);
        }
      },
        (err: HttpErrorResponse) => {
          alert("Server not conncted");
        });
  }


  public isSticky(col) {

    if (col == this.stock_symbol) {
      return true;
    } else {
      return false;
    }
  }

}

