import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-adv-my-alert',
  templateUrl: './adv-my-alert.component.html',
  styleUrls: ['./adv-my-alert.component.scss']
})
export class AdvMyAlertComponent implements OnInit {

  constructor() { }

  status: boolean = false;
  
  clickEvent(){
      this.status = !this.status;       
  }

  ngOnInit(): void {
  }

}
