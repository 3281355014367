import { Component, ViewChild, AfterViewInit, OnInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../../../services/user/user.service';
import { StocksService } from '../../../services/user/stocks.service';
import { ToastrService } from 'ngx-toastr';
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { FilterService } from '../../../services/user/filter.service';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ViewportScroller } from '@angular/common';
import { PagesService } from '../../../services/admin/pages.service';
import { take } from 'rxjs/operators';
import { ModalService } from '../../../services/user/modal.service';
import { UserSideMenuComponent } from '../user-side-menu/user-side-menu.component';

@Component({
  selector: 'app-stock-quote',
  templateUrl: './stock-quote.component.html',
  styleUrls: ['./stock-quote.component.scss']
})
export class StockQuoteComponent implements OnInit {

  status: boolean = false;
  symbol = '';
  slug = 'stock_quote';
  previousIndex: number;
  initialColumns = [];
  header = {};
  selected_filter;
  closeResult: string;
  displayedColumns: string[] = [];
  stocksList: [] = [];
  stocksListSearch: [] = [];
  selectedstocks = [];
  first_multiple = true;
  second_multiple = true;
  show_custom_year = false;
  custom_year_arr = [];
  selected_stocks_ids = [];
  selected_watchlist_ids = [];
  selected_alert_ids = [];
  first_val = "";
  second_val = "";
  selectedCount = 0;
  graphYears: number[] = [];
  displayedColumnsHistor: string[] = [];
  stocksListData: [] = [];
  pagesize = 50;
  page = 0;
  resultsLength = 0;
  course_user: boolean = false;
  free_user: boolean = false;
  tooltip = "tooltip";
  allToolTips: any;
  disaplayToolTip: string;
  hide_content: boolean = false;
  custome_filter: string = "Default Filter";
  noData: boolean = false;

  filterList = [{ "name": "Dividends per Share", "value": "dividend_forward_amount" }, { "name": "Low Price", "value": "low_price" }, { "name": "High Price", "value": "high_price" }, { "name": "Average Price", "value": "avg_price" },
  { "name": "EPS", "value": "eps" },
  ];
  yearList = [{ "name": "5 years", "value": "5" }, { "name": "10 years", "value": "10" }, { "name": "15 years", "value": "15" }, { "name": "20 years", "value": "20" }, { 'name': "max", "value": "max" }];

  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;
  @ViewChild(UserSideMenuComponent) userSideMenuCom: UserSideMenuComponent;

  public lineChartData: ChartDataSets[] = [

  ];
  public lineChartLabels: Label[] = [];
  public lineChartOptions = {
    responsive: true,
    lineTension: 0,
  };

  clickEvent() {
    this.status = !this.status;
    this.filterService.setSideMenuStaus(!this.status);
  }

  public lineChartColors: Color[] = [
    { // first color
      backgroundColor: 'rgba(10,100,166,1)',

      borderColor: 'rgba(10,100,166,1)',
      // pointBackgroundColor: 'rgba(225,10,24,0.2)',
      // pointBorderColor: '#fff',
      // pointHoverBackgroundColor: '#fff',
      // pointHoverBorderColor: 'rgba(225,10,24,0.2)'
    },
    { // second color
      backgroundColor: 'rgba(255,144,0,1)',
      borderColor: 'rgba(255,144,0,1)',
      // pointBackgroundColor: 'rgba(225,10,24,0.2)',
      // pointBorderColor: '#fff',
      // pointHoverBackgroundColor: '#fff',
      // pointHoverBorderColor: 'rgba(225,10,24,0.2)'
    },
    { // second color
      backgroundColor: 'rgba(0,182,85,1)',
      borderColor: 'rgba(0,182,85,1)',
      // pointBackgroundColor: 'rgba(225,10,24,0.2)',
      // pointBorderColor: '#fff',
      // pointHoverBackgroundColor: '#fff',
      // pointHoverBorderColor: 'rgba(225,10,24,0.2)'
    },
    { // second color
      backgroundColor: 'rgba(255,57,0,1)',
      borderColor: 'rgba(255,57,0,1)',
      // pointBackgroundColor: 'rgba(225,10,24,0.2)',
      // pointBorderColor: '#fff',
      // pointHoverBackgroundColor: '#fff',
      // pointHoverBorderColor: 'rgba(225,10,24,0.2)'
    },
    { // second color
      backgroundColor: 'rgba(113,113,113,1)',
      borderColor: 'rgba(113,113,113,1)',
      // pointBackgroundColor: 'rgba(225,10,24,0.2)',
      // pointBorderColor: '#fff',
      // pointHoverBackgroundColor: '#fff',
      // pointHoverBorderColor: 'rgba(225,10,24,0.2)'
    },
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];
  get s() { return this.searchForm.controls; }

  searchForm = new FormGroup({
    stocks: new FormControl('', [Validators.required]),
    years: new FormControl('20', [Validators.required]),
    filter_column: new FormControl('avg_price', [Validators.required]),
    start_year: new FormControl(''),
    end_year: new FormControl('', [Validators.required]),
    stockMultiFilterCtrl: new FormControl(''),
    sortFilterCtrl: new FormControl(''),
  });
  stockForm = new FormGroup({
    symbol: new FormControl('', [Validators.required]),
    quantity: new FormControl('', [Validators.required]),
    stock_id: new FormControl('', [Validators.required]),
    account: new FormControl(''),
  });
  get st() { return this.stockForm.controls; }
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private stocksService: StocksService,
    private modalService: NgbModal,
    private filterService: FilterService,
    private _vps: ViewportScroller,
    private toastr: ToastrService,
    public pagesService: PagesService,
    private modelService: ModalService
  ) { }

  ngOnInit(): void {
    this.symbol = this.route.snapshot.paramMap.get("symbol");
    this.getToolTips();
    if (this.userService.user_type == "course_platform" || this.userService.user_type == "Free_platformCourse_platform") {
      this.course_user = true;
      this.free_user = true;
      this.hide_content = true;
    } else if (this.userService.user_type == "US_onlyCourse_platform") {
      if (this.symbol.includes(':CA')) {
        this.course_user = true;
        this.hide_content = true;
      }
    } else if (this.userService.user_type == "CAD_onlyCourse_platform") {
      if (!this.symbol.includes(':CA')) {
        this.course_user = true;
        this.hide_content = true;
      }
    }
    this.slug = "quote";
    this.selected_filter = this.filterService.getItem(this.slug);
    this.custome_filter = this.filterService.getFilterName(this.slug);
    if (this.custome_filter == null) {
      this.custome_filter = "Default filter";
    }
    this.getSymbolDetails();
    this.getUserSavedWatchListIds();
    this.getUserSavedStocksIds();
    this.getUserSavedAlertIds();
    this.clearStockForm();
    for (let i = 1999; i < (new Date().getFullYear()); i++) {
      this.custom_year_arr.push(i);
    }
    var sideMenuStatus = this.filterService.getSideMenuStaus();
    if (sideMenuStatus !== null) {
      this.status = !sideMenuStatus;
    }
    this.getToolTips();
  }

  changeSideIcon() {
    if (this.status == false) {
      return "fa fa-caret-left fa-2x";
    } else if (this.status == true) {
      return "fa fa-caret-right fa-2x";
    }
  }

  updateSelectedCount() {
    var count = this.searchForm.controls['stocks'].value.length;
    if (count > 5) {
      alert('You have maxed out!')
    }
  }

  getSymbolDetails() {
    this.stocksService.getStockDetails(this.symbol, this.selected_filter).subscribe((data: any) => {
      if (data.success == true) {
        this.displayedColumns = ['1'];
        var data = data.data;
        var ids_data = data.ids;
        this.stocksList = data.stocks
        this.selectedstocks = [data.stocks[0].stock_id];
        ids_data.sort(function (a, b) {
          return a.column_order - b.column_order;
        });
        for (let v in ids_data) {
          this.displayedColumns.push(ids_data[v]['column_id']);
          this.initialColumns.push(ids_data[v]['column_id']);
          this.header[ids_data[v]['column_id']] = ids_data[v]['column_name'];
        }
        if (this.selected_filter == null) {
          var filter_obj = { 'columns': data.columns };
          this.filterService.setItem(this.slug, filter_obj);
          this.selected_filter = this.filterService.getItem(this.slug);
        }
        this.getStocks();

      }
    },
      (error) => {
        this.loginPopUpNow();
        throw error;
      });

  }

  getUserSavedStocksIds() {
    this.stocksService.getAllSavedStockIds(this.userService.user_id).subscribe((data: any) => {
      if (data.success == true) {
        this.selected_stocks_ids = data.data.ids;
      } else if (data.success == false) {
        console.log(data.message);
      }
    },
      (error) => {
        this.loginPopUpNow();
        throw error;
      });
  }

  getUserSavedAlertIds() {
    this.stocksService.getAllUserAlerts(this.userService.user_id).subscribe((data: any) => {
      if (data.success == true) {
        this.selected_alert_ids = data.data;
      } else if (data.success == false) {
        console.log(data.message);
      }
    },
      (error) => {
        this.loginPopUpNow();
        throw error;
      });
  }

  addStocksToList() {
    var selectedstocks = this.stockForm.controls['stock_id'].value;
    var quantity = this.stockForm.controls['quantity'].value;
    var account = this.stockForm.controls['account'].value;
    this.stocksService.saveUserStocks(selectedstocks, quantity, account, this.userService.user_id)
      .subscribe((data: any) => {
        if (data != null) {
          if (this.selected_stocks_ids.indexOf(data.data.stock_id) != -1) {
            this.selected_stocks_ids.splice(this.selected_stocks_ids.indexOf(data.data.stock_id), 1);
          }
        }
        if (data.success) {
          this.toastr.success(data.message);
          this.getUserSavedStocksIds();
        } else if (data.success == false) {
          this.toastr.error(data.message);
        }
      },
        (error) => {
          throw error;
        });
  }

  open(content) {
    this.modalService.open(content, { size: 'xl', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  opendialog(content, stock) {
    if (this.selected_stocks_ids.indexOf(stock.stock_id) !== -1) {
      this.router.navigate(['/user/my-stocks']);
      // this.addToList("stocks", stock);
    } else {
      let newStockName = (stock.longname) + "  " + ('(' + stock.symbolstring + ')');
      this.stockForm.controls['symbol'].setValue(newStockName);
      this.stockForm.controls['stock_id'].setValue(stock.stock_id);

      this.modalService.open(content, { size: 'xl', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  addToList(type, stock) {
    console.log(stock);
    if (type == 'watchlist') {

      if (this.selected_watchlist_ids.indexOf(stock.stock_id) !== -1) {
        this.removeFromList(type, stock);
      } else {
        this.saveToList(type, stock);
      }
    } else if (type == 'stocks') {
      if (this.selected_stocks_ids.indexOf(stock.stock_id) !== -1) {
        this.removeFromList(type, stock);
      } else {
        this.saveToList(type, stock);
      }
    }

  }

  saveToList(type, stock) {
    this.stocksService.addToList(type, stock.stock_id, this.userService.user_id).subscribe((data: any) => {
      if (data.success == true) {
        if (type == 'watchlist') {
          this.selected_watchlist_ids.push(stock.stock_id);
          this.toastr.success(data.message);
        } else if (type == 'stocks') {
          this.selected_stocks_ids.push(stock.stock_id);
          this.toastr.success(data.message);
        } else {
          // this.toastr.error(data.message, 'Error in fetching stocks ids');
        }
      } else if (data.success == false) {
        this.toastr.error(data.message);
      }
    },
      (error) => {
        throw error;
      });
  }

  removeFromList(type, stock) {
    this.stocksService.addToList(type, stock.stock_id, this.userService.user_id).subscribe((data: any) => {
      if (this.selected_watchlist_ids.indexOf(data.data.stock_id) != -1) {
        this.selected_watchlist_ids.splice(this.selected_stocks_ids.indexOf(data.data.stock_id), 1);
      }
      if (data.success == true) {
        this.toastr.success(data.message);
        this.getUserSavedWatchListIds();
      }
    },
      (error) => {
        throw error;
      });
  }

  getUserSavedWatchListIds() {
    this.stocksService.getAllWatchlistStockIds(this.userService.user_id).subscribe((data: any) => {
      if (data.success == true) {
        this.selected_watchlist_ids = data.data.ids;
      } else if (data.success == false) {
        console.log(data.message);
      }
    },
      (error) => {
        this.loginPopUpNow();
        throw error;
      });
  }

  getIcon(icon_type: string, stock) {

    if (icon_type == 'watchlist') {

      if (this.selected_watchlist_ids.indexOf(stock.stock_id) !== -1) {
        return "assets/images/marketing-yellow.png";
      } else {
        return "assets/images/marketing.png";
      }
    } else if (icon_type == 'stocks') {
      //console.log(this.selected_stocks_ids.indexOf(stock.stock_id));
      if (this.selected_stocks_ids.indexOf(stock.stock_id) !== -1) {
        return "assets/images/stock-icon2.png";
      } else {
        return "assets/images/growth.png";
      }
    } else if (icon_type == 'alerts') {
      if (this.selected_alert_ids != undefined) {
        if (this.selected_alert_ids.indexOf(stock.stock_id) !== -1) {
          return "assets/images/alerts-blue.png";
        } else {
          return "assets/images/alerts.png";
        }
      } else {
        return "assets/images/alerts.png";
      }
    }


  }

  getTitle(icon_type: string, stock) {
    if (icon_type == 'watchlist') {

      if (this.selected_watchlist_ids.indexOf(stock.stock_id) !== -1) {
        return "Remove From My Watchlist ";
      } else {
        return "Add to My Watchlist";
      }
    } else if (icon_type == 'stocks') {

      if (this.selected_stocks_ids.indexOf(stock.stock_id) !== -1) {
        return "Remove From My Stocks";
      } else {
        return "Add to My Stocks";
      }
    } else if (icon_type == 'alerts') {
      if (this.selected_alert_ids != undefined) {
        if (this.selected_alert_ids.indexOf(stock.stock_id) !== -1) {
          return "View My Alerts";
        } else {
          return "Add to My Alerts";
        }
      } else {
        return "Add to My Alerts";
      }
    }
  }


  //   getColVal(column_id,stock_data){

  //     if(stock_data != undefined){
  //     let index= stock_data.values_data.findIndex(x => x.column_id == column_id);
  //     var val ="";
  //     if(index != -1){
  //       if(isNaN(parseFloat(stock_data.values_data[index]['column_value']))){
  //         val = stock_data.values_data[index]['column_value'];
  //       }else{
  //         val = parseFloat(stock_data.values_data[index]['column_value']).toFixed(2);
  //       }
  //     }else{
  //       val = "N/A"
  //     }
  //     return val;
  //   }
  // }

  getColCss(column_id, stock_data) {
    let dummy_column_index5 = stock_data.values_data.findIndex(x => x.column_id == "60781c759008e551ad66d40c");
    let dummy_column_index10 = stock_data.values_data.findIndex(x => x.column_id == "60791e199008d379355a36f9");
    let dummy_column_index15 = stock_data.values_data.findIndex(x => x.column_id == "60791e219008d379355a36fb");
    let dummy_column_index20 = stock_data.values_data.findIndex(x => x.column_id == "60791e299008d379355a36fd");
    let dummy_column_dy = stock_data.values_data.findIndex(x => x.column_id == "63c9355b2265834d3487fcf2");

    if (dummy_column_dy != -1) {
      var values = stock_data.values_data[dummy_column_dy]['column_value'];
      for (let val in values) {
        if (values[val] == column_id) {
          return "highlightDY";
        }
      }
    }
    if (dummy_column_index5 != -1) {
      var values = stock_data.values_data[dummy_column_index5]['column_value'];
      for (let val in values) {
        if (values[val] == column_id) {
          return "highlight5";
        }
      }
    }
    if (dummy_column_index10 != -1) {
      var values = stock_data.values_data[dummy_column_index10]['column_value'];
      for (
        let val in values) {
        if (values[val] == column_id) {
          return "highlight10";
        }
      }
    }
    if (dummy_column_index15 != -1) {
      var values = stock_data.values_data[dummy_column_index15]['column_value'];
      for (let val in values) {
        if (values[val] == column_id) {
          return "highlight15";
        }
      }
    }
    if (dummy_column_index20 != -1) {
      var values = stock_data.values_data[dummy_column_index20]['column_value'];
      for (let val in values) {
        if (values[val] == column_id) {
          return "highlight20";
        }
      }
    }
  }

  getColVal(column_id, stock_data) {
    if (stock_data != undefined) {
      let index = stock_data.values_data.findIndex(x => x.column_id == column_id);
      var val = "";
      if (index != -1) {
        if (typeof stock_data.values_data[index]['column_value'] === "number") {
          if (Number.isInteger(stock_data.values_data[index]['column_value'])) {
            val = stock_data.values_data[index]['column_value'];
            // val = val.toLocaleString();
            if (this.header[column_id] == "Dividends Paid Since" || this.header[column_id] == "Annual Forward Dividend vs Previous Year, Rule #9") {
              if (this.header[column_id] == "Annual Forward Dividend vs Previous Year, Rule #9") {
                if (stock_data.values_data[index]['column_value'] == 0) {
                  return Number(val) + "%";
                } else {
                  return Number(val).toFixed(2) + "%";
                }
              }
              return val;
            } else {
              if (column_id == "5ff061e599f59c04038e8eb6") {
                return val;
              } else {
                val = val.toLocaleString();
              }
            }
            if (this.header[column_id] == "Consecutive Years of Dividend Increases" || this.header[column_id] == "Consecutive Years of EPS Increases") {
              return val;
            }
            if (this.header[column_id] == "Annual Forward Dividend") {
              return "$" + val;
            }
            if (stock_data.values_data[index]['column_value'] == 0) {
              val = "N/A";
            }
          } else {
            if (this.header[column_id] == "Annual Forward Dividend") {
              return "$" + parseFloat(stock_data.values_data[index]['column_value']).toFixed(2);
            }
            if (this.header[column_id] == "Annual Forward Dividend vs Previous Year, Rule #9") {
              val = (parseFloat(stock_data.values_data[index]['column_value']).toFixed(2) + "%");
            } else {
              val = parseFloat(stock_data.values_data[index]['column_value']).toFixed(2);
            }
            // val = parseFloat(stock_data.values_data[index]['column_value']).toFixed(2);
          }
        } else {
          if (stock_data.values_data[index]['column_value'] == "") {
            return val = "N/A";
          } else {
            val = stock_data.values_data[index]['column_value'];
          }
          if (stock_data.values_data[index]['column_value'] == null) {
            val = "N/A";
          } else {
            val = stock_data.values_data[index]['column_value'];
          }
          // val = stock_data.values_data[index]['column_value'];
        }
      } else {
        val = "N/A";
      }

      var percent_arr = [
        '%difference between Graham Price vs Share Price',
        'Average Dividend Growth (5-year), Rule #6', 'Average Dividend Growth (10-year), Rule #6',
        'Average Dividend Growth (15-year), Rule #6', 'Average Dividend Growth (20-year), Rule #6',
        'Average Dividend Yield (5-year)', 'Average Dividend Yield (10-year)',
        'Average Dividend Yield (15-year)', 'Average Dividend Yield (20-year)',
        'Average EPS Growth (5-year), Rule #5a', 'Average EPS Growth (10-year), Rule #5a',
        'Average EPS Growth (15-year), Rule #5a',
        'Average EPS Growth (20-year), Rule #5a',
        'Average High Dividend Yield (5-year)',
        'Average High Dividend Yield (10-year)',
        'Average High Dividend Yield (15-year)',
        'Average High Dividend Yield (20-year)',
        'Average Low Dividend Yield (5-year)',
        'Average Low Dividend Yield (10-year)',
        'Average Low Dividend Yield (15-year)',
        'Average Low Dividend Yield (20-year)',
        'Current Dividend Yield',
        'Difference Between Current Dividend Yield and Average Dividend Yield (5-year)',
        'Difference Between Current Dividend Yield and Average Dividend Yield (10-year)',
        'Difference Between Current Dividend Yield and Average Dividend Yield (15-year)',
        'Difference Between Current Dividend Yield and Average Dividend Yield (20-year)',
        'Profit Margin (Total Operating)',
        'Total Debt to Equity Ratio',
        'Payout Ratio, Rule #7',
        'Long-term Debt/Equity Ratio, Rule #8'
      ]
      if (percent_arr.indexOf(this.header[column_id]) !== -1) {
        if (val != "N/A" && val != null && val != "") {
          if (val != "0") {
            val = val + "%";
          } else {
            val = "N/A";
          }
        }
      }
      let dummy_column_dy = stock_data.values_data.findIndex(x => x.column_id == "63c9355b2265834d3487fcf2");
      if (this.header[column_id] == "Current Dividend Yield" && dummy_column_dy != -1) {
        var values = stock_data.values_data[dummy_column_dy]['column_value'];
        for (let vale in values) {
          if (values[vale] == column_id) {
            return val + ' <a target="_blank" href="user/pages/dividend-trap">DT</a>';
          }
        }
      }
    } else {
      val = "N/A";
    }
    return val;
  }

  dropListDropped(event: CdkDragDrop<string[]>) {
    this.displayedColumns.splice(0, 1);

    var element_id = this.displayedColumns[event.previousIndex];
    this.displayedColumns.splice(event.previousIndex, 1);
    this.displayedColumns.splice(event.currentIndex, 0, element_id);
    for (let i = 0; i < this.displayedColumns.length; i++) {
      let index = this.selected_filter.columns.findIndex(x => x._id == this.displayedColumns[i]);
      if (index != -1) {
        this.selected_filter.columns[index].column_order = i;
      }
    }
    this.displayedColumns.splice(0, 0, '1');

    this.filterService.setItem(this.slug, this.selected_filter);
    this.custome_filter = "Custom filter applied";
    this.checkFilterName();
  }

  removeColumn(index) {
    let sel_index = this.selected_filter.columns.findIndex(x => x._id == this.displayedColumns[index]);
    if (sel_index != -1) {
      this.selected_filter.columns[sel_index].show_column = 0;
    }
    this.displayedColumns.splice(index, 1);
    this.filterService.setItem(this.slug, this.selected_filter);
  }

  getStocks(symbolstring = "symbolstring", order = "asc", page = 0, limit = 200, filter = "") {

    this.stocksService.getAllStocks(symbolstring, order, page, limit, filter, this.selectedstocks, this.userService.user_type).subscribe((data: any) => {
      if (data.success == true) {
        this.stocksListSearch = data.data;
        this.searchForm.controls['stocks'].setValue(this.selectedstocks);
        this.getGraphData();
      } else if (data.success == false) {
        this.toastr.error(data.message);
      }
    },
      (error) => {
        throw error;
      });
  }

  private filterStocksMulti(event: Event) {
    if (!this.stocksListSearch) {
      return;
    }
    this.selectedstocks = this.searchForm.controls['stocks'].value;
    // get the search keyword
    let search = (event.target as HTMLInputElement).value;
    if (search != "") {
      this.getStocks('symbolstring', 'asc', 0, 200, search);
    } else {
      this.getStocks('symbolstring', 'asc', 0, 200, '');
    }

  }

  searchStocks(event: Event) {
    this.getGraphData();
  }

  getGraphData() {
    this.stocksService.getGraphData(this.searchForm.value).subscribe((data: any) => {
      if (data.success == true) {
        this.graphYears = data.data.years;
        if (this.graphYears.length <= 20) {
          this.lineChartData = data.data.data;
          this.lineChartLabels = data.data.years;
          if (this.graphYears.length >= 15) {
            this.searchForm.controls['years'].setValue("15");
          } else if (this.graphYears.length >= 10) {
            this.searchForm.controls['years'].setValue("10");
          }
        } else {
          this.lineChartData = data.data.data;
          this.lineChartLabels = data.data.years;
        }
      } else {
        this.noData = true;
        // this.toastr.error(data.message);
      }
    },
      (error) => {
        throw error;
      });
  }

  checkfirst(event) {
    if (event.value.length > 1) {
      if (this.second_val == "") {
        this.second_multiple = false;
      }
      this.first_val = event.value;
    } else {
      this.first_val = '';
      this.second_multiple = true;

    }
  }

  checksecond(event) {
    if (event.value.length > 1) {
      if (this.first_val == "") {
        this.first_multiple = false;
      }
      this.second_val = event.value;
    } else {
      this.second_val = '';
      this.first_multiple = true;
    }
  }

  historicalData(anchor: string) {
    this._vps.scrollToAnchor(anchor);
    if (this.symbol !== "") {
      this.stocksService.getUserStocksHistory(this.symbol, "", "", this.page, this.pagesize)
        .subscribe((data: any) => {
          if (data.success) {
            this.displayedColumnsHistor = [];
            this.stocksListData = data.data.stocks;
            this.resultsLength = data.data.count;
            var years = data.data.years;
            years.sort(function (a, b) {
              return b - a;
            });
            for (let v in data.data.years) {
              this.displayedColumnsHistor.push("" + data.data.years[v]);
            }
          } else if (data.success == false) {
            this.toastr.error(data.message);
          }
        },
          (error) => {
            throw error;
          });
    }
  }

  getDividendColVal(column_id, stock_data, column_name) {
    let arr = stock_data.data;
    let index = arr.findIndex(x => x.year == column_id);

    if (index != -1) {
      if (stock_data.data[index][column_name] == 0) {
        if (column_id == new Date().getFullYear()) {
          return "available after this calendar year";
        }
        return "N/A";
      } else {
        if (isNaN(parseFloat(stock_data.data[index][column_name]))) {
          return "N/A";
        } else {
          if (column_name == "avg_dividend_yield" || column_name == "low_dividend_yield" || column_name == "high_dividend_yield" || column_name == "year_over_growth" || column_name == "year_over_eps_growth") {
            var val = parseFloat(stock_data.data[index][column_name]).toFixed(4);
            return val + "%";
          } else {
            return parseFloat(stock_data.data[index][column_name]).toFixed(4);
          }
        }
      }
    }
  }

  getToolTips() {
    this.pagesService.getToolTips(this.tooltip)
      .subscribe(data => {
        if (data.success) {
          this.allToolTips = data.data;
        }
      },
        (error) => {
          throw error;
        });
  }

  showToolTipData(column_id) {
    this.disaplayToolTip = "";
    let index = this.allToolTips.findIndex(x => x.column_name == column_id);
    if (index != -1) {
      this.disaplayToolTip = this.allToolTips[index].tool_tips;
      return true;
    } else {
      return false;
    }
  }

  checkFilterName() {
    this.filterService.removeFilterName(this.slug);
    this.filterService.setFilterName(this.slug, this.custome_filter);
  }

  clearStockForm() {
    localStorage.removeItem('active-form');
    localStorage.removeItem("search-stock2");
    localStorage.removeItem("search-stock-con");
    localStorage.removeItem("checkMarket");
  }

  openFilterpop() {
    if (this.userService.user_type == "Free_platform" || this.userService.user_type == "course_platform" || this.userService.user_type == "Free_platformCourse_platform") {
      this.modelService.confirmMessage().pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    } else {
      this.modelService.confirm().pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    }
  }

  restore() {
    if (this.userService.user_type == "course_platform") {
      this.modelService.confirmMessage().pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    } else {
      this.userSideMenuCom.restore();
    }
  }

  columnDefination() {
    if (this.userService.user_type == "course_platform") {
      this.modelService.confirmMessage().pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    } else {
      this.router.navigate([]).then(result => {
        window.open('/user/pages/column-definitions', '_blank');
      })
    }
  }

  navigetToalert(data: any) {
    this.router.navigate([]).then((result) => {
      let encoID = btoa(data.symbolstring);
      window.open('/user/my-alert/' + encoID, '_blank');
    });
  }

  loginPopUpNow() {
    this.modelService.logoutPop().pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => { });
  }
}
