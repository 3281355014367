import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { PagesService } from '../../../services/admin/pages.service';
import { ToastrService } from 'ngx-toastr';
import { StocksService } from '../../../services/admin/stocks.service';
import { SelectionModel } from '@angular/cdk/collections';


@Component({
  selector: 'app-archives-table-new',
  templateUrl: './archives-table-new.component.html',
  styleUrls: ['./archives-table-new.component.css']
})
export class ArchivesTableNewComponent implements OnInit {
  filter = "";
  show_ids = [];
  stocksList: object[] = [];
  displayedColumns: string[] = [];
  header = {};
  pagesize = 50;
  resultsLength = 0;
  company_name = "";
  stock_symbol = "";
  year = "";
  pagename = "";
  month = "";
  noStocks = false;

  public selection = new SelectionModel<any>(true, []);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private stocksService: StocksService,
    private pagesService: PagesService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.year = this.route.snapshot.paramMap.get("year");
    this.pagename = this.route.snapshot.paramMap.get("pagename");
    this.month = this.route.snapshot.paramMap.get("month");
    this.company_name = environment.compnay_name_column_id;
    this.stock_symbol = environment.symbol_column_id;

    this.getAllMonthStockList();
  }

  getAllMonthStockList() {
    this.pagesService.getAllMonthStockList(this.year, this.pagename, this.month)
      .subscribe((data: any) => {
        if ((data.data.stocks).length > 0) {
          if (data.success) {
            this.toastr.success(data.message);
            this.displayedColumns = ['0', '1'];
            this.stocksList = data.data.stocks;
            this.resultsLength = data.data.count;
            var ids_data = data.data.ids;
            ids_data.sort(function (a, b) {
              return a.column_order - b.column_order;
            });
            if (this.resultsLength > 0) {
              for (let v in ids_data) {
                this.displayedColumns.push(ids_data[v]['column_id']);
                this.header[ids_data[v]['column_id']] = ids_data[v]['column_name'];
              }
            }
          }
        }else {
          this.noStocks = true;
        }
      },
        (err: HttpErrorResponse) => {
          this.toastr.error('Error in updating Column');
        });
  }

  getColVal(column_id, stock_data) {
    let index = stock_data.values_data.findIndex(x => x.column_id == column_id);
    var val = "";
    if (index != -1) {
      if (typeof stock_data.values_data[index]['column_value'] === "number") {
        if (Number.isInteger(stock_data.values_data[index]['column_value'])) {

          val = stock_data.values_data[index]['column_value'];
          if (this.header[column_id] == "Dividends Paid Since") {
            return val;
          } else {
            val = val.toLocaleString();
          }
          // val = val.toLocaleString();
        } else {
          if (this.header[column_id] == "Annual Forward Dividend vs Previous Year, Rule #9") {
            val = (parseFloat(stock_data.values_data[index]['column_value']).toFixed(4) + "%");
          } else {
            val = parseFloat(stock_data.values_data[index]['column_value']).toFixed(2);
            // val = (Math.round((stock_data.values_data[index]['column_value'] + Number.EPSILON) * 100) / 100).toString();
          }
        }
      } else {

        val = stock_data.values_data[index]['column_value'];
      }
    } else {
      val = "N/A";
    }

    var percent_arr = [
      '%difference between Graham Price vs Share Price',
      'Average Dividend Growth (5-year), Rule #6', 'Average Dividend Growth (10-year), Rule #6',
      'Average Dividend Growth (15-year), Rule #6', 'Average Dividend Growth (20-year), Rule #6',
      'Average Dividend Yield (5-year)', 'Average Dividend Yield (10-year)',
      'Average Dividend Yield (15-year)', 'Average Dividend Yield (20-year)',
      'Average EPS Growth (5-year), Rule #5a', 'Average EPS Growth (10-year), Rule #5a',
      'Average EPS Growth (15-year), Rule #5a',
      'Average EPS Growth (20-year), Rule #5a',
      'Average High Dividend Yield (5-year)',
      'Average High Dividend Yield (10-year)',
      'Average High Dividend Yield (15-year)',
      'Average High Dividend Yield (20-year)',
      'Average Low Dividend Yield (5-year)',
      'Average Low Dividend Yield (10-year)',
      'Average Low Dividend Yield (15-year)',
      'Average Low Dividend Yield (20-year)',
      'Current Dividend Yield',
      'Difference Between Current Dividend Yield and Average Dividend Yield (5-year)',
      'Difference Between Current Dividend Yield and Average Dividend Yield (10-year)',
      'Difference Between Current Dividend Yield and Average Dividend Yield (15-year)',
      'Difference Between Current Dividend Yield and Average Dividend Yield (20-year)',
      'Profit Margin (Total Operating)',
      'Total Debt to Equity Ratio',
      'Payout Ratio, Rule #7',
      'Long-term Debt/Equity Ratio, Rule #8',
      //  'Annual Forward Dividend vs Previous Year, Rule #9'
    ]
    if (percent_arr.indexOf(this.header[column_id]) !== -1) {
      if (val != "N/A" && val != null && val != " ") {
        val = val + "%";
      }
    }
    return val;
  }

  applyFilter(event: Event) {
    //this.filter = (event.target as HTMLInputElement).value;
    this.filter = (<HTMLInputElement>document.getElementById("myValue")).value;
    this.getAllMonthStockList();
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.stocksList.length;
    return numSelected === numRows;
  }

  public checkboxLabel(row?: any): string {
    return (!row)
      ? `${this.isAllSelected() ? 'select' : 'deselect'} all`
      : `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  public masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.stocksList.forEach(row => this.selection.select(row));
  }

  public freeStock(row, event) {
    this.stocksService.updateFreeStock(row.symbolstring, event.checked)
      .subscribe((data: any) => {
        if (data.success) {
          this.getAllMonthStockList();
        } else {
          this.toastr.error(data.message);
        }
      },
        (err: HttpErrorResponse) => {
          alert("Server not conncted");
        });
  }


  public isSticky(col) {

    if (col == this.stock_symbol) {
      return true;
    } else {
      return false;
    }
  }
}



