import { Component, OnInit } from '@angular/core';
import { PagesService } from '../../../services/admin/pages.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-access-log',
  templateUrl: './access-log.component.html',
  styleUrls: ['./access-log.component.css']
})
export class AccessLogComponent implements OnInit {
  userList:any;
  userLogsFile:any;
  newIp:any;
  constructor(
    private router: Router,
    private pagesService: PagesService,
    private toastr: ToastrService

  ){ }

  ngOnInit(): void {
    this.getUserLog();
    this.getUserLogsFile();
  }

  getUserLog(){
    this.pagesService.getUserLoginLogs()
    .subscribe((res: any) => {
      if (res != null) {
        this.userList = res.data;
      }
    },
    (err: HttpErrorResponse) => {
      this.toastr.error('Error in updating Column');
    });
  }

  getUserLogsFile(){
    this.pagesService.getUserLogsFile()
    .subscribe((res: any) => {
      // console.log("res");
      // console.log(res);
      
      if(res != null){
        this.userLogsFile = res.response;
      }
    },
    (err: HttpErrorResponse) => {
      this.toastr.error('Error in user-logs file');
    });
  }

  // getIPReplace(value){
  //    this.newIp = value.replace('::ffff:','');
  //    console.log(this.newIp);
    
  //    //  return  this.newIp.replace("::ffff:",'');
  // }
}
