import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PagesService } from '../../../services/admin/pages.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-upload-video',
  templateUrl: './upload-video.component.html',
  styleUrls: ['./upload-video.component.css']
})

export class UploadVideoComponent implements OnInit {
  pageList: object[] = [];
  videoForm: FormGroup;
  temp_variable = "sumeet";

  constructor(
    public fb: FormBuilder,
    public pagesService: PagesService,
    private toastr: ToastrService
  ) { }

  get f() { return this.videoForm.controls; }

  ngOnInit() {
    this.videoForm = this.fb.group({
      select_page: ['', [Validators.required]],
      videoID: ['', [Validators.required]],
      heading: ['', [Validators.required]],
    });
    this.getPageLists();
  }

  getPageLists(): void {
    this.pagesService.getPagesDetails(this.temp_variable)
      .subscribe((data: any) => {
        if (data.success == true) {
          this.pageList = data.data;
        }
      },
        (error) => {
          throw error;
        });
  }

  // videoDetails.model.js

  submit() {
    this.pagesService.saveVideoDetails(this.videoForm.value)
      .subscribe((data: any) => {
        if (data.success == true) {
          this.toastr.success(data.message);
          this.videoForm.reset();
        }
      },
        (error) => {
          throw error;
        });
  }
}


