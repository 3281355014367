import { Component, OnInit } from '@angular/core';
import { PagesService } from '../../../services/admin/pages.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-unsubscribe-notify',
  templateUrl: './unsubscribe-notify.component.html',
  styleUrls: ['./unsubscribe-notify.component.css']
})
export class UnsubscribeNotifyComponent implements OnInit {

  userAlertList: any;
  userNotifyList: any;
  newIp: any;
  userId: string = "";
  userStatus: string = "";
  constructor(
    private router: Router,
    private pagesService: PagesService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getUserAlertsNotify();
    this.getUserNotifyAlerts();
  }

  getUserAlertsNotify() {
    this.pagesService.getUserAlertsNotify()
      .subscribe((res: any) => {
        if (res != null) {
          var filteruniquebyID = res.data.filter((v, i, a) => a.findIndex(t => t.user_id === v.user_id) === i);
          this.userAlertList = filteruniquebyID;
        }
      },
        (err: HttpErrorResponse) => {
          this.toastr.error('Error in updating Column');
        });
  }


  getUserNotifyAlerts() {
    this.pagesService.getUserNotifyAlerts()
      .subscribe((res: any) => {
        if (res != null) {
          var filteruniquebyID = res.data.filter((v, i, a) => a.findIndex(t => t.user_id === v.user_id) === i);
          this.userNotifyList = filteruniquebyID;
        }
      },
        (err: HttpErrorResponse) => {
          this.toastr.error('Error in updating Column');
        });
  }

  changeAlertStatus(user_id, status) {
    this.userStatus = ""
    this.userId = ""
    this.userId = user_id;
    this.userStatus = status;
    this.pagesService.updateUserAlert(this.userId, this.userStatus)
      .subscribe((data: any) => {
        if (data.success) {
          this.toastr.success(data.message, "User");
        } else {
          this.toastr.error(data.message);
        }
        this.getUserAlertsNotify();
        this.ngOnInit();
      },
        (err: HttpErrorResponse) => {
          this.toastr.error('Error in updating Column');
        });
  }

  changeNotifyStatus(user_id, status) {
    this.userStatus = ""
    this.userId = ""
    this.userId = user_id;
    this.userStatus = status;
    this.pagesService.updateUserNotification(this.userId, this.userStatus)
      .subscribe((data: any) => {
        if (data.success) {
          this.toastr.success(data.message, "User");
        } else {
          this.toastr.error(data.message);
        }
        this.getUserNotifyAlerts();
        this.ngOnInit();
      },
        (err: HttpErrorResponse) => {
          this.toastr.error('Error in updating Column');
        });
  }

}
