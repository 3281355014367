import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SymbolService {

  symbolstring = "tempSymbol";

  constructor() { }
}
