import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.css']
})
export class CourseComponent implements OnInit {

  status: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }
  clickEvent() {
    this.status = !this.status;
  }

  changeSideIcon() {
    if (this.status == false) {
      return "fa fa-caret-left fa-2x";
    } else if (this.status == true) {
      return "fa fa-caret-right fa-2x";
    }
  }

}
