import { Component, ViewChild, AfterViewInit, OnInit, ElementRef, Pipe } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { PagesService } from '../../../services/admin/pages.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from "@angular/platform-browser";
import { UserService } from '../../../services/user/user.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { ModalService } from '../../../services/user/modal.service';
import { FilterService } from '../../../services/user/filter.service';

@Pipe({
  name: 'safeHtml',
})

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css']
})

export class PagesComponent implements OnInit {
  course_user: boolean = false;
  @ViewChild('videoPlayer') videoplayer: ElementRef;

  constructor(public pagesService: PagesService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private userService: UserService,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private modelService: ModalService,
    private filterService: FilterService,
  ) { }

  slug = "";
  content = "";
  page_name = "";
  closeResult: string;
  videoSource: any;
  status: boolean = false;
  videoID: string = "";
  videoHeading: string = "";

  ngOnInit(): void {
    this.slug = this.route.snapshot.paramMap.get("slug");
    var token = this.userService.getAccessToken();
    if (token) {
      var decoded = JSON.parse(atob(token.split('.')[1]));
      this.userService.user_type = decoded.type;
      this.userService.user_id = decoded.userId;
      this.userService.user_name = decoded.name;
    } else {
      this.userService.user_type = null;
      this.userService.user_id = null;
    }
    if (this.userService.user_type == "course_platform") {
      this.course_user = true;
    }
    this.route.params.subscribe(data => {
      this.slug = data.slug;
      this.getPageContent();
      this.clearStockForm();
      this.getVideoID();
      var sideMenuStatus = this.filterService.getSideMenuStaus();
      if (sideMenuStatus !== null) {
        this.status = !sideMenuStatus;
      }
    });
    this.getPageContent();
    this.clearStockForm();
  }

  getPageContent() {
    this.pagesService.getPageContent(this.slug)
      .subscribe((data: any) => {
        if (data.success) {
          this.content = data.data.content;
          this.page_name = data.data.page_name;
          // if (data.data.video_path) {
          //   var path = data.data.video_path.replace("src/", "");
          //   this.videoSource = path;
          // }
        } else if (data.success == false) {
          this.toastr.error(data.message);
        }
      },
        (error) => {
          this.loginPopUpNow();
          throw error;
        });
  }

  sameAsHtml(html_content) {
    return this.sanitizer.bypassSecurityTrustHtml(html_content);
  }

  open(content) {
    this.modalService.open(content, { size: 'xl', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  clearStockForm() {
    localStorage.removeItem('active-form');
    localStorage.removeItem("search-stock2");
    localStorage.removeItem("search-stock-con");
    localStorage.removeItem("checkMarket");
  }

  clickEvent() {
    this.status = !this.status;
    this.filterService.setSideMenuStaus(!this.status);
  }

  changeSideIcon() {
    if (this.status == false) {
      return "fa fa-caret-left fa-2x";
    } else if (this.status == true) {
      return "fa fa-caret-right fa-2x";
    }
  }

  getVideoID() {
    this.userService.getVideoID(this.slug)
      .subscribe((data: any) => {
        data = data.data;
        this.videoID = data.videoID;
        this.videoHeading = data.heading;
      });
  }

  openVideoPop() {
    if (this.videoID !== undefined && this.videoHeading !== undefined) {
      this.modelService.videoPop(this.videoID, this.videoHeading).pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    } else {
      this.videoHeading = "Video is not available on this page.";
      this.videoID = "";
      this.modelService.videoPop(this.videoID, this.videoHeading).pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => { });
    }
  }

  loginPopUpNow() {
    this.modelService.logoutPop().pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => { });
  }
}

