import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PopUpFilterComponent } from '../../components/user/pop-up-filter/pop-up-filter.component';
import { MessagePopUpComponent } from 'src/app/components/user/message-pop-up/message-pop-up.component';
import { LogoutPopComponent } from 'src/app/components/user/logout-pop/logout-pop.component';
import { VideoComponent } from 'src/app/components/user/video/video.component';


@Injectable({
    providedIn: 'root'
})
export class ModalService {

    constructor(private ngbModal: NgbModal) { }

    confirm(): Observable<boolean> {
        const modal = this.ngbModal.open(PopUpFilterComponent, { size: 'xl', ariaLabelledBy: 'modal-basic-title' });
        return from(modal.result).pipe(
            catchError(error => {
                return of(undefined);
            })
        );
    }

    confirmMessage(): Observable<boolean> {
        const modal = this.ngbModal.open(MessagePopUpComponent, { size: 'md', ariaLabelledBy: 'modal-basic-title' });
        modal.componentInstance.messages = "“This feature is only available to SI Report & Platform members.”";
        return from(modal.result).pipe(
            catchError(error => {
                return of(undefined);
            })
        );
    }

    logoutPop(): Observable<boolean> {
        const modal = this.ngbModal.open(LogoutPopComponent, { size: 'md', ariaLabelledBy: 'modal-basic-title' });
        return from(modal.result).pipe(
            catchError(error => {
                return of(undefined);
            })
        );
    }

    videoPop(videoID: string, videoHeading: string): Observable<boolean> {
        const modal = this.ngbModal.open(VideoComponent, { size: 'xl', ariaLabelledBy: 'modal-basic-title' })
        modal.componentInstance.videoID = videoID;
        modal.componentInstance.videoHeading = videoHeading;
        return from(modal.result).pipe(
            catchError(error => {
                return of(undefined);
            })
        );
    }

}
