import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './services/admin/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    public authService: UserService,
    public router: Router
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isLoggedIn() !== false) {
      const accessToken = this.authService.getAccessToken();
      var decoded = JSON.parse(atob(accessToken.split('.')[1]));
      if (decoded.role === next.data.role) {
        return true;
      }
      // navigate to not found page
      window.alert("Access not allowed!");
      this.router.navigate(['login'])
      return true;
    }
  }
}