import { Component, OnInit, ViewChild, AfterViewInit, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { UserService } from '../../../services/user/user.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnsService } from '../../../services/admin/columns.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { FilterService } from '../../../services/user/filter.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { PageColumnsService } from '../../../services/user/page-columns.service';
import { SymbolService } from 'src/app/services/user/symbol.service';


@Component({
  selector: 'app-pop-up-filter',
  templateUrl: './pop-up-filter.component.html',
  styleUrls: ['./pop-up-filter.component.css']
})
export class PopUpFilterComponent implements OnInit {

  conditionFlag: boolean = false;
  columnList: any = [];
  filter: any;
  tableFilter: any = [];
  filterValue: any;
  filterForm: FormGroup;
  filter_name: any = '';
  filter_country_id: any = '';
  filter_exchange_id: any = '';
  filter_column_id: any = '';
  columnIds: any = [];
  selected_filter: any;
  slug = "top-ranked";
  columnForm: FormGroup;
  filterList: any = [];
  tableTitle = "Top Ranked";
  navigate_url = "";
  modalReference: any;
  user_type = "";
  messageContent: any;
  chek_si = 0;
  gradout = true;
  menuout = false;
  defaultColumns: any = [];
  free_course: boolean = false;
  messages: string = "";
  custfilter: boolean = false;
  customclick: boolean = false;
  customefilter: string;
  customefiltername: string;
  count = 0;
  countcheck = 0;
  symbolstring = "";
  selected_value = "";
  showColumnHeading: boolean = false;


  constructor(
    public userService: UserService,
    private modalService: NgbModal,
    private columnsService: ColumnsService,
    private toastr: ToastrService,
    private router: Router,
    private fb: FormBuilder,
    private filterService: FilterService,
    private route: ActivatedRoute,
    private activeModal: NgbActiveModal,
    private pageColumnsService: PageColumnsService,
    private symbolService: SymbolService,) { }

  closeResult: string;
  status: boolean = false;
  usaField: boolean = false;
  canadaField: boolean = false;
  all_checked = false;
  default_checked = false;
  company_name = "";
  stock_symbol = "";

  searchForm = new FormGroup({});

  operatorList: OperatorList[] = [
    { value: 'Less than or equal to', viewValue: '<=' },
    { value: 'Greater than or equal to', viewValue: '>=' },
    { value: 'Less than', viewValue: '<' },
    { value: 'Greater than', viewValue: '>' },
    { value: 'Equal to', viewValue: '==' },
    { value: 'Not equal to', viewValue: '!=' }
  ];

  ngOnInit(): void {
    var token = this.userService.getAccessToken();
    if (token) {
      var decoded = JSON.parse(atob(token.split('.')[1]));
      this.userService.user_type = decoded.type;
      this.userService.user_id = decoded.userId;
      this.userService.user_name = decoded.name;
      this.userService.user_email = decoded.email;
    } else {
      this.userService.user_type = null;
      this.userService.user_id = null;
    }
    this.user_type = this.userService.user_type;
    this.symbolstring = this.symbolService.symbolstring;

    if (this.userService.user_type == "Free_platform" || this.userService.user_type == "course_platform" || this.userService.user_type == "Free_platformCourse_platform") {
      this.free_course = false;
    } else {
      this.free_course = true;
    }
    // if (this.router.url == '/user/my-stocks') {
    //   this.slug = 'my-stocks';
    // }
    this.getColumns();
    if (this.symbolstring != null) {
      this.tableTitle = "Stock Data";
      if (this.router.url == '/user/stock-quote/' + this.symbolstring) {
        this.menuout = true;
        this.slug = 'quote';
        this.navigate_url = '/user/stock-quote/' + this.symbolstring;
        this.getDefaultColumns(this.slug);
      }
    } else if (this.router.url == '/user/runner-up') {
      this.slug = 'runner-ups';
      this.tableTitle = "Runners-Up Stocks";
      this.navigate_url = '/user/runner-up';
      this.showColumnHeading = false;
      this.getDefaultColumns(this.slug);
    } else if (this.router.url == '/user/valued/dividend/undervalued') {
      this.slug = 'dividend-undervalued';
      this.tableTitle = "Undervalued Dividend Stocks";
      this.navigate_url = '/user/valued/dividend/undervalued';
      this.showColumnHeading = false;
      this.getDefaultColumns(this.slug);
    } else if (this.router.url == '/user/valued/dividend/overvalued') {
      this.slug = 'dividend-overvalued';
      this.tableTitle = "Overvalued Dividend Stocks";
      this.navigate_url = '/user/valued/dividend/overvalued';
      this.showColumnHeading = false;
      this.getDefaultColumns(this.slug);
    } else if (this.router.url == '/user/valued/dividend/deep-valued') {
      this.slug = 'dividend-deep-valued';
      this.tableTitle = "Deep Valued Dividend Stocks";
      this.navigate_url = '/user/valued/dividend/deep-valued';
      this.showColumnHeading = false;
      this.getDefaultColumns(this.slug);
    } else if (this.router.url == '/user/non-valued/non-dividend/undervalued') {
      this.slug = 'non-dividend-undervalued';
      this.tableTitle = "Undervalued Non-Dividend Stocks";
      this.navigate_url = '/user/non-valued/non-dividend/undervalued';
      this.showColumnHeading = false;
      this.getDefaultColumns(this.slug);
    } else if (this.router.url == '/user/non-valued/non-dividend/overvalued') {
      this.slug = 'non-dividend-overvalued';
      this.tableTitle = "Overvalued Non-Dividend Stocks";
      this.navigate_url = '/user/non-valued/non-dividend/overvalued';
      this.showColumnHeading = false;
      this.getDefaultColumns(this.slug);
    } else if (this.router.url == '/user/non-valued/non-dividend/deep-valued') {
      this.slug = 'non-dividend-deep-valued';
      this.tableTitle = "Deep Valued Non-Dividend Stocks";
      this.navigate_url = '/user/non-valued/non-dividend/deep-valued';
      this.showColumnHeading = false;
      this.getDefaultColumns(this.slug);
    } else if (this.router.url == '/user/my-stocks') {
      this.menuout = true;
      this.slug = "my-stocks";
      this.tableTitle = "My Stocks";
      this.navigate_url = '/user/my-stocks';
      this.showColumnHeading = true;
      this.getDefaultColumns(this.slug);
    } else if (this.router.url == '/user/my-watchlist') {
      this.menuout = true;
      this.slug = "my-watchlist";
      this.tableTitle = "My Watchlist";
      this.navigate_url = '/user/my-watchlist';
      this.showColumnHeading = false;
      this.getDefaultColumns(this.slug);
    } else if (this.router.url == '/user/dashboard') {
      this.slug = "top-ranked";
      this.tableTitle = "Top Ranked Stocks";
      this.navigate_url = '/user/dashboard';
      this.showColumnHeading = false;
      this.getDefaultColumns(this.slug);
    } else if (this.router.url == '/user/search-stocks') {
      this.menuout = true;
      this.tableTitle = "Search Stocks";
      this.slug = 'search-stocks';
      this.showColumnHeading = false;
      this.getDefaultColumns(this.slug);
    } else if (this.router.url == '/user/compare-stocks') {
      this.menuout = true;
      this.tableTitle = "Compare Stocks";
      this.slug = 'compare-stocks';
      this.showColumnHeading = false;
      this.getDefaultColumns(this.slug);
    }

    this.selected_filter = this.filterService.getItem(this.slug);
    this.filterForm = this.fb.group({
      filter_name: ['', [Validators.required]],
    });

    // if (this.router.url == '/user/compare-stocks') {
    //   this.menuout = true;
    // }
    // if (this.router.url == '/user/my-stocks') {
    //   this.menuout = true;
    // }
    // if (this.router.url == '/user/my-watchlist') {
    //   this.menuout = true;
    // }
    // if (this.router.url == '/user/search-stocks') {
    //   this.menuout = true;
    // }
    this.columnForm = this.fb.group({
      column_op: [''],
      column_value: [''],
    });

    // this.detailsFunction();
    this.getFilters();
    this.checkUser();
    this.company_name = environment.compnay_name_column_id;
    this.stock_symbol = environment.symbol_column_id;
  }

  checkUser() {
    if (this.user_type == "US_onlyCourse_platform" || this.user_type == "US_only") {
      this.usaField = true;
      this.canadaField = false;
    } else if (this.user_type == "CAD_onlyCourse_platform" || this.user_type == "CAD_only") {
      this.usaField = false;
      this.canadaField = true;
    } else if (this.user_type == "US_and_CAD" || this.user_type == "US_and_CADCourse_platform") {
      this.usaField = true;
      this.canadaField = true;
    }
  }

  checkUserTag() {
    if (this.user_type == "course_platform") {
      return "greyOut";
    }
  }

  detailsFunction() {
    if (this.symbolstring != null) {
      this.tableTitle = "Stock Data";
      if (this.router.url == '/user/stock-quote/' + this.symbolstring) {
        this.slug = 'quote';
        this.navigate_url = '/user/stock-quote/' + this.symbolstring;
        this.getDefaultColumns(this.slug);
      }
    } else if (this.router.url == '/user/runner-up') {
      this.slug = 'runner-ups';
      this.tableTitle = "Runners-Up Stocks";
      this.navigate_url = '/user/runner-up';
      this.getDefaultColumns(this.slug);
    } else if (this.router.url == '/user/valued/dividend/undervalued') {
      this.slug = 'dividend-undervalued';
      this.tableTitle = "Undervalued Dividend Stocks";
      this.navigate_url = '/user/valued/dividend/undervalued';
      this.getDefaultColumns(this.slug);
    } else if (this.router.url == '/user/valued/dividend/overvalued') {
      this.slug = 'dividend-overvalued';
      this.tableTitle = "Overvalued Dividend Stocks";
      this.navigate_url = '/user/valued/dividend/overvalued';
      this.getDefaultColumns(this.slug);
    } else if (this.router.url == '/user/valued/dividend/deep-valued') {
      this.slug = 'dividend-deep-valued';
      this.tableTitle = "Deep Value Dividend Stocks";
      this.navigate_url = '/user/valued/dividend/deep-valued';
      this.getDefaultColumns(this.slug);
    } else if (this.router.url == '/user/valued/non-dividend/undervalued') {
      this.slug = 'non-dividend-undervalued';
      this.tableTitle = "Undervalued Non-Dividend Stocks";
      this.navigate_url = '/user/valued/non-dividend/undervalued';
      this.getDefaultColumns(this.slug);
    } else if (this.router.url == '/user/valued/non-dividend/overvalued') {
      this.slug = 'non-dividend-overvalued';
      this.tableTitle = "Overvalued Non-Dividend Stocks";
      this.navigate_url = '/user/valued/non-dividend/overvalued';
      this.getDefaultColumns(this.slug);
    } else if (this.router.url == '/user/valued/non-dividend/deep-valued') {
      this.slug = 'non-dividend-deep-valued';
      this.tableTitle = "Deep Value Non-Dividend Stocks";
      this.navigate_url = '/user/valued/non-dividend/deep-valued';
      this.getDefaultColumns(this.slug);
    } else if (this.router.url == '/user/my-stocks') {
      this.slug = "my-stocks";
      this.tableTitle = "My Stocks";
      this.navigate_url = '/user/my-stocks';
      this.getDefaultColumns(this.slug);
    } else if (this.router.url == '/user/my-watchlist') {
      this.slug = "my-watchlist";
      this.tableTitle = "My Watchlist";
      this.navigate_url = '/user/my-watchlist';
      this.getDefaultColumns(this.slug);
    } else if (this.router.url == '/user/dashboard') {
      this.slug = "top-ranked";
      this.tableTitle = "Top Ranked Stocks";
      this.navigate_url = '/user/dashboard';
      this.getDefaultColumns(this.slug);
    } else if (this.router.url == '/user/search-stocks') {
      this.tableTitle = "Search Stocks";
      this.slug = 'search-stocks';
      this.getDefaultColumns(this.slug);
    } else if (this.router.url == '/user/compare-stocks') {
      this.tableTitle = "Compare Stock";
      this.slug = 'compare-stocks';
      this.getDefaultColumns(this.slug);
    }
  }

  clickEvent() {
    this.status = !this.status;
  }

  getColumns() {
    this.columnsService.getAllColumns(this.filter).subscribe((data: any) => {
      if (data.success == true) {
        this.columnList = data.data;
      } else {
        // this.toastr.error(data.message, 'Column');
      }
    },
      (error) => {
        throw error;
      });
  }

  getFilters() {
    this.filterService.getFilters(this.userService.user_id, this.slug).subscribe((data: any) => {
      if (data.success == true) {
        if (data.data != "") {
          this.filterList = data.data;
        } else {
          this.filterList = [];
        }
      } else {
        // this.toastr.error(data.message, 'Column');
      }
    },
      (error) => {
        throw error;
      });

  }

  applyFilter(value) {
    this.filter = value;
    this.getColumns();
  }

  drop(event: CdkDragDrop<any>) {
    moveItemInArray(this.columnList, event.previousIndex, event.currentIndex);
  }

  setFilterValue(value) {
    this.filterValue = value;
  }

  saveUpdateFilter() {
    for (var i = 0; i < this.selected_filter.columns.length; i++) {
      if (this.selected_filter.columns[i]._id == "5f3bd442cd95fa3b5815b218" || this.selected_filter.columns[i]._id == "5f3bd442cd95fa3b5815b237") {
        this.selected_filter.columns[i].show_column = false;
      }
    }
    for (var i = 0; i < this.selected_filter.columns.length; i++) {
      if (this.selected_filter.columns[i].column_op !== undefined && this.selected_filter.columns[i].operator_value !== undefined && this.selected_filter.columns[i].conditionFlag == true) {
        this.selected_filter.columns[i].show_column = true;
      }
    }
    this.navigate_url = this.router.url;
    if ((this.navigate_url == "/user/my-stocks")) {
      let tempArray = [
        { column_id: "60a50ee1900801bfa078b24e" },
        { column_id: "60a50f02900801bfa078b252" },
        { column_id: "60a50f0d900801bfa078b254" },
        { column_id: "60a50f19900801bfa078b256" },
        { column_id: "60a50f2c900801bfa078b258" }
      ]
      for (var i = 0; i < this.selected_filter.columns.length; i++) {
        for (let j = 0; j < tempArray.length; j++) {
          if (this.selected_filter.columns[i]._id == tempArray[j].column_id) {
            this.selected_filter.columns[i].show_column = true;
          }
        }
      }
    }
    this.filterService.setItem(this.slug, this.selected_filter);
    if (this.customclick) {
      this.customefilter = "Custom filter applied";
    } else if (this.custfilter) {
      this.customefilter = this.customefiltername + " filter applied";
    } else {
      this.customefilter = "Default filter";
    }
    this.filterService.removeFilterName(this.slug);
    this.filterService.setFilterName(this.slug, this.customefilter);
    if (this.router.url != '/user/search-stocks') {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate([this.navigate_url]));
    }
    this.activeModal.close();
    if (this.router.url == '/user/search-stocks') {
      window.location.reload();
    }
  }

  closeModal() {
    if (this.modalReference !== undefined) {
      this.activeModal.close();
    }
  }

  chnageColumn(event, value) {
    this.selected_filter.show_columns = "";
    if (value == "all") {
      let description = "5fcfabf38b0b4e5423a40984";
      let delisted = "5fcfabf38b0b4e5423a40985";
      let epsYear = "5f3bd442cd95fa3b5815b203";
      let address = "5f3bd442cd95fa3b5815b206";
      if (event.target.checked) {
        this.selected_filter.show_columns = "all";
        for (var i = 0; i < this.selected_filter.columns.length; i++) {
          if (this.selected_filter.columns[i]._id != this.company_name &&
            this.selected_filter.columns[i]._id != this.stock_symbol &&
            this.selected_filter.columns[i]._id != description &&
            this.selected_filter.columns[i]._id != delisted &&
            this.selected_filter.columns[i]._id != epsYear &&
            this.selected_filter.columns[i]._id != address) {
            this.selected_filter.columns[i].show_column = true;
          } else {
            this.selected_filter.columns[i].show_column = false;
          }
        }
      } else {
        for (var i = 0; i < this.selected_filter.columns.length; i++) {
          this.selected_filter.columns[i].show_column = false;
        }
      }
    }
  }

  chnageColumnSi5(event, value) {

    if (value == "si_5") {
      this.customefiltername = null;
      for (var i = 0; i < this.selected_filter.columns.length; i++) {
        this.selected_filter.columns[i].show_column = false;
      }
      if (event.target.checked) {
        this.selected_filter.show_columns = "si_5";
        var si5Array = [
          { id: 1, text: "Average EPS Growth (5-year), Rule #5a" },
          { id: 2, text: "Number of EPS increases in the last 5-years, Rule #5b" },
          { id: 3, text: "Average Dividend Growth (5-year), Rule #6" },
          { id: 4, text: "Payout Ratio, Rule #7" },
          { id: 5, text: "Long-term Debt/Equity Ratio, Rule #8" },
          { id: 6, text: "Share Buyback?, Rule #10" },
          { id: 7, text: "P/E Ratio (TTM), Rule #11.a" },
          { id: 8, text: "Undervalued or Overvalued (based on 5-year data), Rule #11.b" },
          { id: 9, text: "P/B Ratio, Rule #11.c" },
          { id: 10, text: "SI Criteria (out of 10) using 5-year data" },
          { id: 11, text: "Annual Forward Dividend vs Previous Year, Rule #9" },
        ];
        for (let i = 0; i < this.selected_filter.columns.length; i++) {
          for (let j = 0; j < si5Array.length; j++) {
            if (this.selected_filter.columns[i].column_name == si5Array[j].text) {
              this.selected_filter.columns[i].show_column = true;
            }
          }
        }
        // console.log(this.selected_filter.columns);
      } else {
        for (var i = 0; i < this.selected_filter.columns.length; i++) {
          this.selected_filter.columns[i].show_column = false;
        }
      }
    }
  }

  chnageColumnSi10(event, value) {

    if (value == "si_10") {
      this.customefiltername = null;
      for (var i = 0; i < this.selected_filter.columns.length; i++) {
        this.selected_filter.columns[i].show_column = false;
      }
      if (event.target.checked) {
        this.selected_filter.show_columns = "si_10";
        var si5Array = [
          { id: 1, text: "Average EPS Growth (10-year), Rule #5a" },
          { id: 2, text: "Number of EPS increases in the last 10-years, Rule #5b" },
          { id: 3, text: "Average Dividend Growth (10-year), Rule #6" },
          { id: 4, text: "Payout Ratio, Rule #7" },
          { id: 5, text: "Long-term Debt/Equity Ratio, Rule #8" },
          { id: 6, text: "Share Buyback?, Rule #10" },
          { id: 7, text: "P/E Ratio (TTM), Rule #11.a" },
          { id: 8, text: "Undervalued or Overvalued (based on 10-year data), Rule #11.b" },
          { id: 9, text: "P/B Ratio, Rule #11.c" },
          { id: 10, text: "SI Criteria (out of 10) using 10-year data" },
          { id: 11, text: "Annual Forward Dividend vs Previous Year, Rule #9" },
        ];
        for (let i = 0; i < this.selected_filter.columns.length; i++) {
          for (let j = 0; j < si5Array.length; j++) {
            if (this.selected_filter.columns[i].column_name == si5Array[j].text) {
              this.selected_filter.columns[i].show_column = true;
            }
          }
        }
        // console.log(this.selected_filter.columns);
      } else {
        for (var i = 0; i < this.selected_filter.columns.length; i++) {
          this.selected_filter.columns[i].show_column = false;
        }
      }
    }
  }

  chnageColumnSi15(event, value) {

    if (value == "si_15") {
      this.customefiltername = null;
      for (var i = 0; i < this.selected_filter.columns.length; i++) {
        this.selected_filter.columns[i].show_column = false;
      }
      if (event.target.checked) {
        this.selected_filter.show_columns = "si_15";
        var si5Array = [
          { id: 1, text: "Average EPS Growth (15-year), Rule #5a" },
          { id: 2, text: "Number of EPS increases in the last 15-years, Rule #5b" },
          { id: 3, text: "Average Dividend Growth (15-year), Rule #6" },
          { id: 4, text: "Payout Ratio, Rule #7" },
          { id: 5, text: "Long-term Debt/Equity Ratio, Rule #8" },
          { id: 6, text: "Share Buyback?, Rule #10" },
          { id: 7, text: "P/E Ratio (TTM), Rule #11.a" },
          { id: 8, text: "Undervalued or Overvalued (based on 15-year data), Rule #11.b" },
          { id: 9, text: "P/B Ratio, Rule #11.c" },
          { id: 10, text: "SI Criteria (out of 10) using 15-year data" },
          { id: 11, text: "Annual Forward Dividend vs Previous Year, Rule #9" },
        ];
        for (let i = 0; i < this.selected_filter.columns.length; i++) {
          for (let j = 0; j < si5Array.length; j++) {
            if (this.selected_filter.columns[i].column_name == si5Array[j].text) {
              this.selected_filter.columns[i].show_column = true;
            }
          }
        }
        // console.log(this.selected_filter.columns);
      } else {
        for (var i = 0; i < this.selected_filter.columns.length; i++) {
          this.selected_filter.columns[i].show_column = false;
        }
      }
    }
  }

  chnageColumnSi20(event, value) {

    if (value == "si_20") {
      this.customefiltername = null;
      for (var i = 0; i < this.selected_filter.columns.length; i++) {
        this.selected_filter.columns[i].show_column = false;
      }
      if (event.target.checked) {
        this.selected_filter.show_columns = "si_20";
        var si5Array = [
          { id: 1, text: "Average EPS Growth (20-year), Rule #5a" },
          { id: 2, text: "Number of EPS increases in the last 20-years, Rule #5b" },
          { id: 3, text: "Average Dividend Growth (20-year), Rule #6" },
          { id: 4, text: "Payout Ratio, Rule #7" },
          { id: 5, text: "Long-term Debt/Equity Ratio, Rule #8" },
          { id: 6, text: "Share Buyback?, Rule #10" },
          { id: 7, text: "P/E Ratio (TTM), Rule #11.a" },
          { id: 8, text: "Undervalued or Overvalued (based on 20-year data), Rule #11.b" },
          { id: 9, text: "P/B Ratio, Rule #11.c" },
          { id: 10, text: "SI Criteria (out of 10) using 20-year data" },
          { id: 11, text: "Annual Forward Dividend vs Previous Year, Rule #9" },
        ];
        for (let i = 0; i < this.selected_filter.columns.length; i++) {
          for (let j = 0; j < si5Array.length; j++) {
            if (this.selected_filter.columns[i].column_name == si5Array[j].text) {
              this.selected_filter.columns[i].show_column = true;
            }
          }
        }
      } else {
        for (var i = 0; i < this.selected_filter.columns.length; i++) {
          this.selected_filter.columns[i].show_column = false;
        }
      }
    }
  }

  chnageColumnDefault(event, value) {

    if (value == "default") {
      this.customefiltername = null;
      for (var i = 0; i < this.selected_filter.columns.length; i++) {
        this.selected_filter.columns[i].show_column = false;
      }
      if (event.target.checked) {
        this.selected_filter.show_columns = "default";
        for (let i = 0; i < this.selected_filter.columns.length; i++) {
          for (let j = 0; j < this.defaultColumns.length; j++) {
            if (this.selected_filter.columns[i].column_name == this.defaultColumns[j]) {
              this.selected_filter.columns[i].show_column = true;
            }
          }
        }
      } else {
        for (var i = 0; i < this.selected_filter.columns.length; i++) {
          this.selected_filter.columns[i].show_column = false;
        }
      }
    }
  }

  checkColumn(val) {
    if (this.selected_filter.show_columns !== undefined && this.selected_filter.show_columns !== null) {
      if (this.selected_filter.show_columns == val) {
        return true;
      } else {
        return false;
      }
    }
  }

  chnageCountry(event, value) {

    if (value == "us_stock") {
      if (event.target.checked) {
        this.selected_filter.us_stocks = true;
      } else {
        this.selected_filter.us_stocks = false;
      }
    }

    if (value == "cand_stcok") {
      if (event.target.checked) {
        this.selected_filter.canada_stocks = true;
      } else {
        this.selected_filter.canada_stocks = false;
      }
    }

  }

  checkCountry(val) {
    if (val == "us_stock") {
      if (this.selected_filter.us_stocks != undefined) {
        if (this.selected_filter.us_stocks == true) {
          return true;
        } else {
          return false;
        }
      }
    }

    if (val == "cand_stock") {
      if (this.selected_filter.canada_stocks != undefined) {
        if (this.selected_filter.canada_stocks == true) {
          return true;
        } else {
          console.log("false");
          return false;
        }
      }
    }

  }

  chnageExchanges(event, value) {
    if (event.target.checked) {
      if (value == "nsd") {
        this.customclick = true;
        this.selected_filter.show_nsd_exchange = true;
      }
      if (value == "nye") {
        this.customclick = true;
        this.selected_filter.show_nye_exchange = true;
      }
      if (value == "tsx") {
        this.customclick = true;
        this.selected_filter.show_tsx_exchange = true;
      }
    } else {
      if (value == "nsd") {
        this.selected_filter.show_nsd_exchange = false;
      }
      if (value == "nye") {
        this.selected_filter.show_nye_exchange = false;
      }
      if (value == "tsx") {
        this.selected_filter.show_tsx_exchange = false;
      }
    }
  }

  chnageStocks(event, value) {
    this.columnIds = value;
  }

  setFormValue() {

    this.filterForm.controls['filter_name'].setValue(this.filter_name);
    this.filterForm.controls['filter_country_id'].setValue(this.filter_country_id);
    this.filterForm.controls['filter_exchange_id'].setValue(this.filter_exchange_id);
    this.filterForm.controls['filter_column_id'].setValue(this.filter_column_id);
    this.filterForm.controls['columnId'].setValue(this.columnIds);

    this.columnsService.addFilters(this.filterForm.value).subscribe((data: any) => {
      if (data.success == true) {
        let arr = data.data;
        if (arr && arr.length > 0) {
          this.columnList = arr.forEach((element, index) => {
            element.conditionFlag = false;
          })
        }
      } else {
        this.toastr.error(data.message, 'Column');
      }
    },
      (error) => {
        throw error;
      });

  }

  checkValue(id) {
    let indexCol = this.selected_filter.columns.findIndex(x => x._id == id);
    if (indexCol != -1) {
      if (this.selected_filter.columns[indexCol].show_column) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  AddremoveColumns(column: any, event) {
    this.countcheck = 0;
    let indexCol = this.selected_filter.columns.findIndex(x => x._id == column._id);
    if (indexCol != -1) {
      this.selected_filter.columns[indexCol].show_column = event.target.checked;
      if (this.selected_filter.columns[indexCol].show_column == false && event.target.checked == false) {
        if (this.selected_filter.columns[indexCol].column_op !== undefined && this.selected_filter.columns[indexCol].operator_value !== undefined) {
          this.selected_filter.columns[indexCol].operator_value = "";
          this.selected_filter.columns[indexCol].conditionFlag = false;
          this.selected_filter.columns[indexCol].column_op = "";
        }
      }
    }
    for (var i = 0; i < this.selected_filter.columns.length; i++) {
      if (this.selected_filter.columns[i].show_column) {
        this.countcheck += 1;
      }
    }
    if (this.countcheck > this.count || this.countcheck < this.count) {
      this.customclick = true;
    }
  }

  columnOperatorChnaged(column, event) {
    this.customclick = true;
    let indexCol = this.selected_filter.columns.findIndex(x => x._id == column._id);
    if (indexCol != -1) {
      this.selected_filter.columns[indexCol].column_op = event.value;
    }
  }

  addOperatorValue(column, event) {
    let indexCol = this.selected_filter.columns.findIndex(x => x._id == column._id);
    if (indexCol != -1) {
      this.selected_filter.columns[indexCol].operator_value = event.target.value;
    }
  }

  saveFilter() {
    if (this.filterForm.invalid) {
      return;
    }
    this.filterService.addFilter(this.filterForm.value, this.selected_filter, this.userService.user_id, this.slug)
      .subscribe(data => {
        if (data.success) {
          this.toastr.success(data.message);
          this.getFilters();
        } else {
          this.toastr.error(data.message);
        }
      });
  }

  get f() { return this.filterForm.controls; }

  removeFilter(filter) {
    this.filterService.removeFilter(this.userService.user_id, filter, this.slug)
      .subscribe(data => {
        if (data.success) {
          this.toastr.success(data.message);
          this.getFilters();
        } else {
          this.toastr.error(data.message);
        }
      },
        error => {
          console.log(error);
        });
  }

  useFilter(filter) {
    this.custfilter = true;
    this.customefiltername = filter.name;
    this.selected_filter = filter.selected_filter;

    if (filter.selected_filter.us_stocks != undefined && filter.selected_filter.us_stocks) {
      this.selected_filter.us_stocks = true;
    }

    if (filter.selected_filter.cand_stcoks != undefined && filter.selected_filter.cand_stcoks) {
      this.selected_filter.cand_stcoks = true;
    }

    if (filter.selected_filter.show_nsd_exchange != undefined && filter.selected_filter.show_nsd_exchange) {
      this.selected_filter.show_nsd_exchange = true;
    }

    if (filter.selected_filter.show_nye_exchange != undefined && filter.selected_filter.show_nye_exchange) {
      this.selected_filter.show_nye_exchange = true;
    }

    if (filter.selected_filter.show_tsx_exchange != undefined && filter.selected_filter.show_tsx_exchange) {
      this.selected_filter.show_tsx_exchange = true;
    }

    if (filter.selected_filter.show_columns != undefined) {
      if (filter.selected_filter.show_columns == "all") {
        this.selected_filter.show_columns = "all";
      }

      if (filter.selected_filter.show_columns == "default") {
        this.selected_filter.show_columns = "default";
      }
    }

    // this.getFilters();
  }

  getSelectedIpVal(column: any) {
    let indexCol = this.selected_filter.columns.findIndex(x => x._id == column._id);
    if (indexCol != -1) {
      if (this.selected_filter.columns[indexCol].operator_value != undefined) {
        return this.selected_filter.columns[indexCol].operator_value;
      } else {
        return "";
      }
    } else {
      return "";
    }

  }

  getSelectedIpOpt(column: any) {
    let indexCol = this.selected_filter.columns.findIndex(x => x._id == column._id);
    this.selected_value = '';
    if (indexCol != -1) {
      if (this.selected_filter.columns[indexCol].column_op != undefined) {
        let tempValue = this.selected_filter.columns[indexCol].column_op;
        if (tempValue) {
          let index = this.operatorList.findIndex(x => x.viewValue == tempValue);
          this.selected_value = this.operatorList[index]['viewValue'];
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getDefaultColumns(slug) {
    this.defaultColumns = [];
    this.count = 0;
    this.userService.getDefaultColumns(slug)
      .subscribe((response: any) => {
        var ids_data = response.data;
        for (let v in ids_data) {
          this.defaultColumns.push(ids_data[v]['column_name']);
        }
        if (this.selected_filter !== null) {
          for (var i = 0; i < this.selected_filter.columns.length; i++) {
            if (this.selected_filter.columns[i].show_column) {
              this.count += 1;
            }
          }
        }
      },
        (error) => {
          throw error;
        });
  }

  checkExchanges(value) {
    if (value == "nsd") {
      if (this.selected_filter.show_nsd_exchange) {
        return true;
      }
    }
    if (value == "nye") {
      if (this.selected_filter.show_nye_exchange) {
        return true;
      }
    }
    if (value == "tsx") {
      if (this.selected_filter.show_tsx_exchange) {
        return true;
      }
    }
  }


  removeColumns(columnName: any) {
    var columnsArray = ["Delisted Stock", "Dummy Column 5-years", "Dummy Column 10-years", "Dummy Column 15-years", "Dummy Column 20-years", "Dividend Amount", "EPS (Year End)", "Stock Symbol", "Company Name", "Company Description", "Annual Forward Dividend vs Previous Year Stock"];
    if (columnsArray.findIndex(x => x == columnName) !== -1) {
      return false;
    } else {
      return true;
    }
  }

  removePlusSign(columnName: any) {
    if (this.router.url == '/user/my-stocks') {
      return false;
    } else {
      var columnsArray = ["Dividend Currency", "Dividend Frequency", "Dividend Payment Date",
        "Dividend Record Date", "Ex-Dividend Date", "Exchange", "Industry", "Market", "Sector", "Share Price Date",
        "Undervalued or Overvalued (based on 5-year data), Rule #11.b",
        "Undervalued or Overvalued (based on 10-year data), Rule #11.b",
        "Undervalued or Overvalued (based on 15-year data), Rule #11.b",
        "Undervalued or Overvalued (based on 20-year data), Rule #11.b",
        "Fiscal Year-End Date", "Website", "Company Address1", "Company Address2",
        "Company Address, City", "Company Address, State", "Company Address, Country"];
      if (columnsArray.findIndex(x => x == columnName) !== -1) {
        return false;
      } else {
        return true;
      }
    }
  }

  changeActiveFilter(filter: any) {
    if (this.customefiltername) {
      if (this.customefiltername == filter) {
        return "activeFilter";
      } else {
        return "notactiveFilter";
      }
    } else {
      return "notactiveFilter";
    }
  }

  changeActiveSymbol(filter: any) {
    if (this.customefiltername) {
      if (this.customefiltername == filter) {
        return "fa fa-hand-peace-o";
      } else {
        return "fa fa-check";
      }
    } else {
      return "fa fa-check";
    }
  }

  checkSelectedToggleColumn(column: any, valueChange: boolean) {
    let indexCol = this.selected_filter.columns.findIndex(x => x._id == column._id);
    if (indexCol != -1) {
      if (this.selected_filter.columns[indexCol].conditionFlag == true && this.selected_filter.columns[indexCol].show_column == true
        && this.selected_filter.columns[indexCol].operator_value !== undefined && this.selected_filter.columns[indexCol].column_op !== undefined) {
      } else {
        if (this.selected_filter.columns[indexCol].conditionFlag) {
          this.selected_filter.columns[indexCol].conditionFlag = false;
        } else {
          this.selected_filter.columns[indexCol].conditionFlag = valueChange;
        }
      }
    }
  }

  checkSelectedColumn(column: any) {
    let indexCol = this.selected_filter.columns.findIndex(x => x._id == column._id);
    if (indexCol != -1) {
      if (this.selected_filter.columns[indexCol].conditionFlag !== undefined) {
        if (this.selected_filter.columns[indexCol].conditionFlag) {
          return "tgl_menu_icon ng-star-inserted toggle-me";
        } else {
          return "tgl_menu_icon";
        }
      } else {
        return "tgl_menu_icon";
      }
    } else {
      return "tgl_menu_icon";
    }
  }

}

export interface OperatorList {
  value: string;
  viewValue: string;
}