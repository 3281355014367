import { BrowserModule, Title } from '@angular/platform-browser';
import { FileSelectDirective } from 'ng2-file-upload';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
//import { AngularEditorModule } from '@kolkov/angular-editor';
import { SignUpComponent } from './components/admin/sign-up/sign-up.component';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from 'ngx-ckeditor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxPaginationModule } from 'ngx-pagination';
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ChartsModule } from 'ng2-charts';
import { NgxSpinnerModule } from "ngx-spinner";
// import * as XLSX from "xlsx";

//routes
import { appRoutes } from './routes';
import { LoginComponent } from './components/admin/login/login.component';
import { DashboardComponent } from './components/admin/dashboard/dashboard.component';
import { AuthInterceptor } from './auth.interceptor';
import { SideMenuComponent } from './components/admin/side-menu/side-menu.component';
import { TopMenuComponent } from './components/admin/top-menu/top-menu.component';
import { LayoutComponent } from './components/admin/layout/layout.component';
import { AddPageComponent } from './components/admin/pages/add-page/add-page.component';
import { ListPageComponent } from './components/admin/pages/list-page/list-page.component';
import { ColumnsComponent } from './components/admin/columns/columns.component';
import { BaselineImportComponent } from './components/admin/baseline-import/baseline-import.component';
import { BaselineConsecutiveDivImportComponent } from './components/admin/baseline-consecutive-div-import/baseline-consecutive-div-import.component';
import { BaselineConsecutiveEpsImportComponent } from './components/admin/baseline-consecutive-eps-import/baseline-consecutive-eps-import.component';
import { TopRankedStocksComponent } from './components/admin/top-ranked-stocks/top-ranked-stocks.component';
import { SiCriteriaFiveYearsComponent } from './components/admin/si-criteria-five-years/si-criteria-five-years.component';
import { SiCriteriaTenYearsComponent } from './components/admin/si-criteria-ten-years/si-criteria-ten-years.component';
import { SiCriteriaFifteenYearsComponent } from './components/admin/si-criteria-fifteen-years/si-criteria-fifteen-years.component';
import { SiCriteriaTwentyYearsComponent } from './components/admin/si-criteria-twenty-years/si-criteria-twenty-years.component';
import { TopRankedStocksListComponent } from './components/admin/top-ranked-stocks-list/top-ranked-stocks-list.component';
import { RunnerUpComponent } from './components/admin/runner-up/runner-up.component';
import { ValuedComponent } from './components/admin/valued/valued.component';
import { DeepValueComponent } from './components/admin/deep-value/deep-value.component';
import { CompareStocksComponent } from './components/admin/compare-stocks/compare-stocks.component';
import { QuoteComponent } from './components/admin/quote/quote.component';
import { RunnerUpsListComponent } from './components/admin/runner-ups-list/runner-ups-list.component';
import { ValuedListComponent } from './components/admin/valued-list/valued-list.component';
import { ResponseResetComponent } from './components/admin/response-reset/response-reset.component';
import { RequestResetComponent } from './components/admin/request-reset/request-reset.component';
import { DeepValuedListComponent } from './components/admin/deep-valued-list/deep-valued-list.component';
import { UserPagesComponent } from './components/admin/user-pages/user-pages.component';
import { UserPagesListComponent } from './components/admin/user-pages-list/user-pages-list.component';
import { StocksHistoryComponent } from './components/admin/stocks-history/stocks-history.component';
import { StocksComponent } from './components/admin/stocks/stocks.component';
import { UserLoginComponent } from './components/user/login/login.component';
import { UserLayoutComponent } from './components/user/user-layout/user-layout.component';
import { UserSideMenuComponent } from './components/user/user-side-menu/user-side-menu.component';
import { HeaderComponent } from './components/user/header/header.component';
import { FooterComponent } from './components/user/footer/footer.component';
import { AdvMyAlertComponent } from './components/user/adv-my-alert/adv-my-alert.component';
import { AdvMatchingStockComponent } from './components/user/adv-matching-stock/adv-matching-stock.component';
import { MyStocksComponent } from './components/user/my-stocks/my-stocks.component';
import { UserDashboardComponent } from './components/user/dashboard/dashboard.component';
import { MyWatchlistComponent } from './components/user/my-watchlist/my-watchlist.component';
import { MyAlertComponent } from './components/user/my-alert/my-alert.component';
import { UserValuedComponent } from './components/user/user-valued/user-valued.component';
import { CompareStockComponent } from './components/user/compare-stock/compare-stock.component';
import { SearchStockComponent } from './components/user/search-stock/search-stock.component';
import { NotificationComponent } from './components/user/notification/notification.component';
import { StockQuoteComponent } from './components/user/stock-quote/stock-quote.component';
import { AddFaqComponent } from './components/admin/faq/add-faq/add-faq.component';
import { ListFaqComponent } from './components/admin/faq/list-faq/list-faq.component';
import { UserFaqComponent } from './components/user/user-faq/user-faq.component';
import { PagesComponent } from './components/user/pages/pages.component';
import { HistoricalStockComponent } from './components/user/historical-stock/historical-stock.component';
import { NondividendDeepValueComponent } from './components/admin/nondividend-deep-value/nondividend-deep-value.component';
import { MyAccountComponent } from './components/user/my-account/my-account.component';
import { MessageComponent } from './components/admin/pages/message/message.component';
import { AccessLogComponent } from './components/admin/access-log/access-log.component';
import { StocksValueEditComponent } from './components/admin/stocks-value-edit/stocks-value-edit.component';
import { ArchivesComponent } from './components/admin/archives/archives.component';
import { ArchivesTableNewComponent } from './components/admin/archives-table-new/archives-table-new.component';
import { FreeAccountColumnsComponent } from './components/admin/free-account-columns/free-account-columns.component';
import { FreeAccountStocksComponent } from './components/admin/free-account-stocks/free-account-stocks.component';
import { Dow30Component } from './components/admin/dow30/dow30.component';
import { Tsx60Component } from './components/admin/tsx60/tsx60.component';
import { Displaydow30Component } from './components/user/displaydow30/displaydow30.component';
import { Displaytsx60Component } from './components/user/displaytsx60/displaytsx60.component';
import { ExportStocksComponent } from './components/admin/export-stocks/export-stocks.component';
import { EditStockValueComponent } from './components/admin/edit-stock-value/edit-stock-value.component';
import { UnsubscribeNotifyComponent } from './components/admin/unsubscribe-notify/unsubscribe-notify.component';
import { CourseComponent } from './components/user/course/course.component';
import { DividendStockComponent } from './components/admin/dividend-stock/dividend-stock.component';
import { NotifiyHeadingComponent } from './components/admin/notifiy-heading/notifiy-heading.component';
import { SecurityQuestionComponent } from './components/admin/security-question/security-question.component';
import { AdminLoginComponent } from './components/admin/admin-login/admin-login.component';
import { ToolTipsComponent } from './components/admin/tool-tips/tool-tips.component';
import { AddStockComponent } from './components/admin/add-stock/add-stock.component';
import { UpdateSplitComponent } from './components/admin/update-split/update-split.component';
import { EditPageComponent } from './components/admin/edit-page/edit-page.component';
import { CornJobComponent } from './components/admin/corn-job/corn-job.component';
import { LoginPageMessageComponent } from './components/admin/login-page-message/login-page-message.component';
import { BaselineHighlowImportComponent } from './components/admin/baseline-highlow-import/baseline-highlow-import.component';
import { BackupListComponent } from './components/admin/backup-list/backup-list.component';
import { EditHistoryDataComponent } from './components/admin/edit-history-data/edit-history-data.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { PopUpFilterComponent } from './components/user/pop-up-filter/pop-up-filter.component';
import { LogoutPopComponent } from './components/user/logout-pop/logout-pop.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { VideoComponent } from './components/user/video/video.component';
import { UploadVideoComponent } from './components/admin/upload-video/upload-video.component';
import { ScrolltopService } from './services/user/scrolltop.service';
import { UserNonValuedComponent } from './components/user/user-non-valued/user-non-valued.component';
import { DividendTrapSettingsComponent } from './components/admin/dividend-trap-settings/dividend-trap-settings.component';
import { UndervaluedStocksGenerateListComponent } from './components/admin/undervalued-stocks-generate-list/undervalued-stocks-generate-list.component';
import { MyAlertsComponent } from './components/admin/export-to-csv/my-alerts/my-alerts.component';
import { MyEmailListComponent } from './components/admin/export-to-csv/my-email-list/my-email-list.component';

// const MATERIALMODULE = [MaterialModule];

@NgModule({
  declarations: [
    AppComponent,
    SignUpComponent,
    LoginComponent,
    DashboardComponent,
    SideMenuComponent,
    TopMenuComponent,
    LayoutComponent,
    AddPageComponent,
    ListPageComponent,
    ColumnsComponent,
    BaselineImportComponent,
    BaselineConsecutiveDivImportComponent,
    BaselineConsecutiveEpsImportComponent,
    TopRankedStocksComponent,
    SiCriteriaFiveYearsComponent,
    SiCriteriaTenYearsComponent,
    SiCriteriaFifteenYearsComponent,
    SiCriteriaTwentyYearsComponent,
    TopRankedStocksListComponent,
    RunnerUpComponent,
    ValuedComponent,
    DeepValueComponent,
    CompareStocksComponent,
    QuoteComponent,
    RunnerUpsListComponent,
    ValuedListComponent,
    ResponseResetComponent,
    RequestResetComponent,
    DeepValuedListComponent,
    UserPagesComponent,
    UserPagesListComponent,
    StocksHistoryComponent,
    StocksComponent,
    UserLoginComponent,
    UserLayoutComponent,
    UserSideMenuComponent,
    HeaderComponent,
    FooterComponent,
    AdvMatchingStockComponent,
    AdvMyAlertComponent,
    MyStocksComponent,
    UserDashboardComponent,
    MyWatchlistComponent,
    MyAlertComponent,
    UserValuedComponent,
    CompareStockComponent,
    SearchStockComponent,
    NotificationComponent,
    StockQuoteComponent,
    AddFaqComponent,
    ListFaqComponent,
    UserFaqComponent,
    PagesComponent,
    HistoricalStockComponent,
    FileSelectDirective,
    NondividendDeepValueComponent,
    MyAccountComponent,
    MessageComponent,
    AccessLogComponent,
    StocksValueEditComponent,
    ArchivesComponent,
    ArchivesTableNewComponent,
    FreeAccountColumnsComponent,
    FreeAccountStocksComponent,
    Dow30Component,
    Tsx60Component,
    Displaydow30Component,
    Displaytsx60Component,
    ExportStocksComponent,
    EditStockValueComponent,
    UnsubscribeNotifyComponent,
    CourseComponent,
    DividendStockComponent,
    NotifiyHeadingComponent,
    SecurityQuestionComponent,
    AdminLoginComponent,
    ToolTipsComponent,
    AddStockComponent,
    UpdateSplitComponent,
    EditPageComponent,
    CornJobComponent,
    LoginPageMessageComponent,
    BaselineHighlowImportComponent,
    BackupListComponent,
    EditHistoryDataComponent,
    PopUpFilterComponent,
    LogoutPopComponent,
    UploadVideoComponent,
    VideoComponent,
    UserNonValuedComponent,
    DividendTrapSettingsComponent,
    UndervaluedStocksGenerateListComponent,
    MyAlertsComponent,
    MyEmailListComponent,
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    //AngularEditorModule,
    CKEditorModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    NgxPaginationModule,
    DragDropModule,
    MatButtonModule,
    MatIconModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatRadioModule,
    NgxMatSelectSearchModule,
    ChartsModule,
    RouterModule.forRoot(appRoutes),
    NgxSpinnerModule,
    AutocompleteLibModule,
    YouTubePlayerModule,
  ],
  providers: [
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    ScrolltopService,
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }
